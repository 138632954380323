import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Button,
  TextField,
  MenuItem,
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
} from "@mui/material";
import { CURRENCIES, DEFAULT_CURRENCY } from "../../../consts/currencies";
import { getCurrencySymbol } from "../../../utils/currencies";
import { useTranslation } from "react-i18next";
import { OrderInfo } from "../../../types/orders";

interface OrderCarrierDetailsProps {
  order: OrderInfo;
  loading: boolean;
  onSubmit: (data: BrokerageOrderData) => void;
}

interface BrokerageOrderData {
  subcontractor_cargo_name: string;
  subcontractor_freight_rate: number | null;
  subcontractor_currency: string;
  subcontractor_payment_days: number;
  subcontractor_cargo_remarks: string;
}

const OrderCarrierDetails: React.FC<OrderCarrierDetailsProps> = ({
  order,
  loading,
  onSubmit,
}) => {
  const [isOpen, setIsModalOpen] = useState(false);
  const handleDialogClose = () => {
    setIsModalOpen(false);
  };
  const { handleSubmit, control } = useForm<BrokerageOrderData>({
    defaultValues: {
      subcontractor_cargo_name: order.subcontractor_cargo_name || "",
      subcontractor_freight_rate: order.subcontractor_freight_rate || null,
      subcontractor_currency: order.subcontractor_currency || DEFAULT_CURRENCY,
      subcontractor_payment_days: order.subcontractor_payment_days || 30,
      subcontractor_cargo_remarks:
        order.subcontractor_cargo_remarks || order.requirements?.remarks || "",
    },
  });
  const { t } = useTranslation();
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setIsModalOpen(true)}
      >
        {t("orderDetails.carrier.createOrderFile.create")}
      </Button>
      <Dialog open={isOpen} onClose={handleDialogClose}>
        <DialogTitle>
          {t("orderDetails.carrier.createOrderFile.create")}
        </DialogTitle>
        <DialogContent style={{ width: "600px" }}>
          <form
            className="flex flex-col items-center justify-center space-y-4 p-4 w-full"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="subcontractor_cargo_name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("orderDetails.carrier.createOrderFile.cargo")}
                  variant="outlined"
                  className="w-64"
                />
              )}
            />
            <Controller
              name="subcontractor_freight_rate"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("orderDetails.carrier.createOrderFile.rate")}
                  variant="outlined"
                  className="w-64"
                  type="number"
                />
              )}
            />
            <Controller
              name="subcontractor_currency"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label={t("orderDetails.carrier.createOrderFile.currency")}
                  variant="outlined"
                  className="w-64"
                >
                  {CURRENCIES.map((currencyOption) => (
                    <MenuItem key={currencyOption} value={currencyOption}>
                      {getCurrencySymbol(currencyOption)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <Controller
              name="subcontractor_payment_days"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("orderDetails.carrier.createOrderFile.dueDays")}
                  variant="outlined"
                  type="number"
                  className="w-64"
                />
              )}
            />
            <Controller
              name="subcontractor_cargo_remarks"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("orderDetails.carrier.createOrderFile.remarks")}
                  variant="outlined"
                  className="w-64"
                  multiline
                  rows={4}
                />
              )}
            />
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={loading}
            >
              {t("orderDetails.carrier.createOrderFile.create")}
            </Button>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            {t("common.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OrderCarrierDetails;
