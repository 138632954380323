import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { GRAPH_COLORS } from "../../../utils/colors";
import NoDataAvailable from "../NoData";

interface ExpensesGraphProps {
  margins: { [forwarder: string]: number };
}

const ContractorCarrierMarginsGraph: React.FC<ExpensesGraphProps> = ({
  margins,
}) => {
  const data = Object.keys(margins).map((key, index) => {
    return {
      forwarder: key,
      expense: margins[key],
      color: GRAPH_COLORS[index % GRAPH_COLORS.length],
    };
  });
  data.sort((a, b) => b.expense - a.expense);

  const renderCustomAxisTick = ({
    x,
    y,
    payload,
  }: {
    x: number;
    y: number;
    payload: any;
  }) => {
    return (
      <foreignObject
        x={x - 50}
        y={y}
        width={100}
        height={50}
        style={{ overflow: "visible" }}
      >
        <div className="flex flex-col items-center justify-center">
          <span>{payload.value}</span>
        </div>
      </foreignObject>
    );
  };

  return (
    <div>
      <h2 className="text-center">Forwarders Margins on Contractor Carriers</h2>
      {data.length === 0 ? (
        <NoDataAvailable message="Your forwarders have not lead any contractor carriers yet." />
      ) : (
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            width={600}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="forwader"
              tick={renderCustomAxisTick}
              interval={0}
            />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="margin">
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default ContractorCarrierMarginsGraph;
