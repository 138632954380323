import { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import IncomesComponent from "./Incomes/IncomesComponent";
import Expenses from "./Expenses/Expenses";
import Forwarders from "./Forwarders/Forwarders";
import Shippers from "./Shippers/Shippers";
import TruckInsights from "./Trucks/Trucks";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers";
import { format, subMonths, startOfMonth, endOfMonth } from "date-fns";
import ProfitComponent from "./Profit/ProfitComponent";

interface InsightsSummary {
  shippers: number;
  trucks: number;
  kilometers_driven: number;
  kilometers_driven_last_month: number;
  orders_completed: number;
  orders_completed_last_month: number;
  fuel_consumed_last_month: number;
}

const InsightsContainer = () => {
  const { t } = useTranslation();
  const axios = useAxios();
  const [summary, setSummary] = useState<InsightsSummary | null>(null);
  const previousMonth = subMonths(new Date(), 1);
  const [startDate, setStartDate] = useState<Date>(startOfMonth(previousMonth));
  const [endDate, setEndDate] = useState<Date>(endOfMonth(previousMonth));

  useEffect(() => {
    axios
      .get("/insights/", {
        params: {
          start_date: format(startDate, "yyyy-MM-dd"),
          end_date: format(endDate, "yyyy-MM-dd"),
        },
      })
      .then((response) => {
        setSummary(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [startDate, endDate]);

  return (
    <div className="text-gray-800 flex flex-col p-8 bg-white shadow-lg rounded-lg">
      <div className="p-6 space-y-4 w-full">
        <h2 className="text-2xl font-semibold tracking-tight text-gray-900">
          {t("insights.title")}
        </h2>
        <div className="grid grid-cols-7 w-full gap-4 justify-around">
          {summary &&
            Object.entries(summary).map(([key, value]) => (
              <div
                key={key}
                className="flex justify-between items-center flex-col bg-secondary py-4 rounded-lg"
              >
                <span className="text-xl font-bold">{value}</span>
                <span className="text-gray-600">
                  {t(`insights.summary.${key}`)}
                </span>
              </div>
            ))}
        </div>
      </div>
      <div className="bg-gray-100 p-4 flex justify-end space-x-4">
        <DatePicker
          label={t("insights.datePicker.startDate")}
          value={startDate}
          maxDate={endDate}
          disableFuture
          onChange={(newDate) => {
            if (newDate) {
              setStartDate(newDate);
            }
          }}
        />
        <DatePicker
          label={t("insights.datePicker.endDate")}
          value={endDate}
          disableFuture
          minDate={startDate}
          onChange={(newDate) => {
            if (newDate) {
              setEndDate(newDate);
            }
          }}
        />
      </div>
      <Tabs>
        <TabList>
          <Tab>{t("insights.tabs.incomes")}</Tab>
          <Tab>{t("insights.tabs.expenses")}</Tab>
          <Tab>{t("insights.tabs.forwarders")}</Tab>
          <Tab>{t("insights.tabs.shippers")}</Tab>
          <Tab>{t("insights.tabs.trucks")}</Tab>
          <Tab>{t("insights.tabs.profitLoss")}</Tab>
          <Tab>{t("insights.tabs.fuel")}</Tab>
        </TabList>
        <TabPanel>
          <IncomesComponent startDate={startDate} endDate={endDate} />
        </TabPanel>
        <TabPanel>
          <Expenses startDate={startDate} endDate={endDate} />
        </TabPanel>
        <TabPanel>
          <Forwarders  />
        </TabPanel>
        <TabPanel>
          <Shippers/>
        </TabPanel>
        <TabPanel>
          <TruckInsights startDate={startDate} endDate={endDate} />
        </TabPanel>
        <TabPanel>
          <ProfitComponent startDate={startDate} endDate={endDate} />
        </TabPanel>
        <TabPanel>
          <p>Coming soon...</p>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default InsightsContainer;
