import { Fragment, useState } from "react";
import { Operation } from "../../../types/orders";
import CountryFlag from "react-country-flag";
import { formatOrderOperationTime } from "../../../utils/time";
import OperationDetail from "./OperationDetail";
import { useTranslation } from "react-i18next";

interface OrderOperationsViewProps {
  operations: Operation[];
  fileUploading: boolean;
  onUploadCmrFiles(file: FileList, operation: number): void;
  handleOrderOperationSubmit(data: any, position: number): void;
  onDeleteCmrFile(fileId: number, operation: number): void;
  setIsCompleted: (status: string, operation: number) => void;
}

const OrderOperationsView: React.FC<OrderOperationsViewProps> = ({
  operations,
  fileUploading,
  onUploadCmrFiles,
  handleOrderOperationSubmit,
  onDeleteCmrFile,
  setIsCompleted,
}) => {
  const { t } = useTranslation();
  const [selectedOperationIndex, setSelectedOperationIndex] = useState(0);

  const handleSelectOperation = (index: number) => {
    setSelectedOperationIndex(index);
  };

  const selectedOperation = operations[selectedOperationIndex];

  return (
    <div className="flex flex-col space-y-4 w-full mt-4 items-center">
      <div className="flex w-full">
        <div className="flex flex-col gap-2 items-center dark:bg-zinc-900 bg-secondary shadow-lg rounded-xl p-6 border border-gray-300 w-1/3">
          {operations.map((operation, index) => (
            <Fragment key={operation.position}>
              <div
                className={`grid grid-cols-12 items-center justify-between space-x-4 p-3 w-full cursor-pointer rounded-lg transition-colors duration-200  hover:text-black  ${
                  index === selectedOperationIndex
                    ? "bg-neutral-400"
                    : "hover:bg-gray-100"
                }`}
                onClick={() => handleSelectOperation(index)}
              >
                <div className="text-xl font-semibold col-span-1">
                  {index + 1}
                </div>
                <CountryFlag
                  countryCode={operation.country}
                  svg
                  style={{ width: "2.2em", height: "2.2em" }}
                  className="rounded-full shadow-sm col-span-1"
                />
                <p className="text-xs font-medium col-span-2">
                  {t(`operationTypes.${operation.operation_type}`)}
                </p>
                <p className="text-base flex-grow font-medium col-span-2">
                  {operation.locality}
                </p>
                <p className="text-xs flex-grow font-medium col-span-1">
                  {formatOrderOperationTime(operation)}
                </p>
                <div className="col-span-4 flex items-center justify-center">
                  <label>
                    <input
                      type="checkbox"
                      checked={operation.status === "done"}
                      onChange={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setIsCompleted(
                          e.target.checked ? "done" : "in_progress",
                          operation.position
                        );
                      }}
                    />
                    <span className="ml-2 ">{t("operationStatus.done")}</span>
                  </label>
                </div>
              </div>
            </Fragment>
          ))}
        </div>

        <div className="flex-grow dark:bg-zinc-900 bg-secondary shadow-md rounded-lg p-6 border border-gray-300 ml-4">
          <OperationDetail
            operation={selectedOperation}
            onSubmit={handleOrderOperationSubmit}
            onUploadCmrFiles={onUploadCmrFiles}
            fileUploading={fileUploading}
            onDeleteCmrFile={onDeleteCmrFile}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderOperationsView;
