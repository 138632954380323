import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  MenuItem,
  Button as MuiButton,
  Button,
  CircularProgress,
} from "@mui/material";
import { BaseTruck } from "../../types/truck";
import { Driver } from "../../types/driver";
import { OrderInfo, SubcontractorOrderFile } from "../../types/orders";
import { FaDownload, FaMagnifyingGlass } from "react-icons/fa6";
import { getFileName } from "../../utils/file";
import Modal from "../../components/modal/Modal";
import PdfPreview from "../../components/pdfPreview/PdfPreview";
import { useTranslation } from "react-i18next";
import OrderCarrierDetails from "./OrderCarrierDetails/OrderCarrierDetails";
import { Employee } from "../../types/employees";
import { useStrider } from "../../contexts/StriderContext";
import CarrierSelect from "../../components/carrierSelect/CarrierSelect";
import { useAuth } from "../../contexts/AuthContext";
import ErrorPage from "../../error-page";

interface CarrierFormProps {
  order: OrderInfo;
  loading: boolean;
  onFormSubmit: (data: any) => void;
  onCreateOrderFile: (data: any) => void;
  onDeleteSubcontractorOrderFile: (fileId: number) => void;
}

const CarrierForm: React.FC<CarrierFormProps> = ({
  order,
  loading,
  onFormSubmit,
  onCreateOrderFile,
  onDeleteSubcontractorOrderFile,
}) => {
  const { t } = useTranslation();
  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      carrier_id: order.carrier?.id,
      driver_id: order.driver?.id || null,
      license_plate: order.truck?.license_plate || null,
      carrier_assignee_id: order.carrier_assignee?.id || null,
      own_order: order.own_order,
    },
  });
  const selectedCarrier = watch("carrier_id");
  const ownOrder = watch("own_order");

  const [previewPdfVisible, setPreviewPdfVisible] = useState(false);
  const [currentTransportOrderFile, setCurrentTransportOrderFile] =
    useState<SubcontractorOrderFile | null>(null);

  const { carriers } = useStrider();
  const { company, companyLoading } = useAuth();

  const [drivers, setDrivers] = useState<Driver[]>([]);
  const [trucks, setTrucks] = useState<BaseTruck[]>([]);
  const [assignees, setAssignees] = useState<Employee[]>([]);

  useEffect(() => {
    if (companyLoading || !company) return;
    if (ownOrder) {
      setDrivers(company.drivers);
      setAssignees(company.employees);
      setTrucks(company.trucks);
    } else {
      const carrier = carriers.find((c) => c.id === selectedCarrier);
      if (carrier) {
        setDrivers(carrier.drivers);
        setAssignees(carrier.employees);
        setTrucks(carrier.trucks);
      }
    }
  }, [ownOrder, selectedCarrier, company, companyLoading, carriers]);

  const handleCarrierChange = (value: number, isCompany: boolean) => {
    if (isCompany) {
      setValue("carrier_id", undefined);
      setValue("own_order", true);
      setValue("carrier_assignee_id", null);
      if (order.own_order) {
        setValue("driver_id", order.driver?.id);
        setValue("license_plate", order.truck?.license_plate || null);
      } else {
        setValue("driver_id", null);
        setValue("license_plate", null);
      }
    } else {
      setValue("carrier_id", value);
      setValue("own_order", false);
      const carrier = carriers.find((c) => c.id === value);
      if (carrier) {
        if (carrier.id === order.carrier?.id) {
          setValue("driver_id", order.driver?.id);
          setValue("license_plate", order.truck?.license_plate || null);
          setValue("carrier_assignee_id", order.carrier_assignee?.id || null);
        } else {
          setValue("driver_id", null);
          setValue("license_plate", null);
          setValue("carrier_assignee_id", null);
        }
      }
    }
  };

  if (companyLoading) return <CircularProgress />;

  if (!company) return <ErrorPage />;

  return (
    <div className="flex flex-row p-4 gap-8 items-start">
      <form
        onSubmit={handleSubmit(onFormSubmit)}
        className="w-full max-w-md space-y-4"
      >
        <CarrierSelect
          company={company}
          carriers={carriers}
          label={t("orders.selectCarrier")}
          value={
            ownOrder
              ? `company-${company.id}`
              : selectedCarrier
              ? `carrier-${selectedCarrier}`
              : null
          }
          onChange={handleCarrierChange}
          size="medium"
        />
        <Controller
          name="driver_id"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              select
              label={t("orderDetails.carrier.selectDriver")}
              fullWidth
              variant="outlined"
            >
              {drivers.map((driver) => (
                <MenuItem key={driver.id} value={driver.id}>
                  {driver.first_name} {driver.last_name}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <Controller
          name="license_plate"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              select
              label={t("orderDetails.carrier.selectTruck")}
              fullWidth
              variant="outlined"
            >
              {trucks.map((truck) => (
                <MenuItem key={truck.license_plate} value={truck.license_plate}>
                  {truck.license_plate}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        {!ownOrder && (
          <Controller
            name="carrier_assignee_id"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                select
                label={t("orderDetails.carrier.selectCarrierAssignee")}
                fullWidth
                variant="outlined"
              >
                {assignees.map((employee) => (
                  <MenuItem key={employee.id} value={employee.id}>
                    {employee.full_name} - {employee.email}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        )}
        <MuiButton
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading}
        >
          {t("orderDetails.carrier.assign")}
        </MuiButton>
      </form>
      <OrderCarrierDetails
        order={order}
        onSubmit={onCreateOrderFile}
        loading={loading}
      />
      {order.subcontractor_order_file ? (
        <div className="flex items-center justify-between border border-gray-300 rounded p-2">
          <div className="flex items-center gap-2">
            <a
              href={order.subcontractor_order_file.file}
              target="_blank"
              rel="noopener noreferrer"
              className="text-accent hover:text-blue-800 flex items-center"
            >
              <FaDownload className="mr-2" />
              {getFileName(order.subcontractor_order_file.file)}
            </a>
          </div>
          <div className="flex items-center gap-2">
            <Button
              variant="contained"
              className="flex items-center"
              onClick={() => {
                setCurrentTransportOrderFile(order.subcontractor_order_file);
                setPreviewPdfVisible(true);
              }}
            >
              <FaMagnifyingGlass />
              <span className="ml-1">
                {t("orderDetails.carrier.createOrderFile.preview")}
              </span>
            </Button>
            <Button
              variant="contained"
              className="flex items-center ml-2"
              onClick={() => {
                if (order.subcontractor_order_file) {
                  onDeleteSubcontractorOrderFile(
                    order.subcontractor_order_file.id
                  );
                }
              }}
            >
              {t("common.delete")}
            </Button>
          </div>
        </div>
      ) : null}
      <Modal
        isOpen={previewPdfVisible}
        onClose={() => setPreviewPdfVisible(false)}
      >
        {previewPdfVisible && currentTransportOrderFile && (
          <PdfPreview
            fileUrl={currentTransportOrderFile.file}
            fileId={currentTransportOrderFile.id}
          />
        )}
      </Modal>
    </div>
  );
};

export default CarrierForm;
