import { useTranslation } from "react-i18next";
import { InvoiceModel } from "../../../types/invoices";

interface MaintenanceIncomingProps {
  data: InvoiceModel;
}

const InvoiceNotSetTrackingOrDelivery: React.FC<MaintenanceIncomingProps> = ({
  data,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex items-center justify-between">
        <div>
          {data.id}
          <h3 className="font-semibold">{t("dashboard.invoiceNotSetTrackingOrder.date")}</h3>
        </div>
      </div>
    </>
  );
};

export default InvoiceNotSetTrackingOrDelivery;
