import { useTranslation } from "react-i18next";
import {
  DRIVER_PAYMENT_CATEGORY_TYPES,
  DriverPayment,
} from "../../../types/driver";
import { TextField, MenuItem, Button } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import {
  CURRENCIES,
} from "../../../consts/currencies";
import { getCurrencySymbol } from "../../../utils/currencies";
import { DatePicker } from "@mui/x-date-pickers";
import { calculateNewDriverPayment } from "../../../utils/payments";

interface DriverPaymentHistoryCreateFormProps {
  paymentInfo?: DriverPayment;
  onSubmit: (data: any) => void;
}

const DriverPaymentHistoryCreateForm: React.FC<
  DriverPaymentHistoryCreateFormProps
> = ({ paymentInfo, onSubmit }) => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm({
    defaultValues: calculateNewDriverPayment(paymentInfo),
  });
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="grid gap-4 grid-cols grid-cols-2 m-4"
    >
      <Controller
        name="taxable_net_pay"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t(
              "driverDetail.payments.addPaymentHistoryModal.taxableNetPay"
            )}
            variant="outlined"
            type="number"
          />
        )}
      />
      <Controller
        name="net_pay"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("driverDetail.payments.addPaymentHistoryModal.netPay")}
            variant="outlined"
            type="number"
          />
        )}
      />
      <Controller
        name="currency"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            select
            label={t("driverDetail.payments.addPaymentHistoryModal.currency")}
            variant="outlined"
          >
            {CURRENCIES.map((currency) => (
              <MenuItem key={currency} value={currency}>
                {getCurrencySymbol(currency)}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
      <Controller
        name="category"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            select
            label={t("driverDetail.payments.addPaymentHistoryModal.category")}
            variant="outlined"
          >
            {DRIVER_PAYMENT_CATEGORY_TYPES.map((type) => (
              <MenuItem key={type} value={type}>
                {t(`driverPaymentCategories.${type}`)}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
      <Controller
        name="gross_pay"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("driverDetail.payments.addPaymentHistoryModal.grossPay")}
            variant="outlined"
            type="number"
          />
        )}
      />
      <Controller
        name="vat_amount"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("driverDetail.payments.addPaymentHistoryModal.vatAmount")}
            variant="outlined"
          />
        )}
      />
      <Controller
        name="notes"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("driverDetail.payments.addPaymentHistoryModal.notes")}
            variant="outlined"
          />
        )}
      />
      <Controller
        name="date_start"
        control={control}
        render={({ field }) => (
          <DatePicker
            {...field}
            label={t("driverDetail.payments.addPaymentHistoryModal.dateStart")}
          />
        )}
      />
      <Controller
        name="date_end"
        control={control}
        render={({ field }) => (
          <DatePicker
            {...field}
            label={t("driverDetail.payments.addPaymentHistoryModal.dateEnd")}
          />
        )}
      />
      <Controller
        name="payment_date"
        control={control}
        render={({ field }) => (
          <DatePicker
            {...field}
            label={t(
              "driverDetail.payments.addPaymentHistoryModal.paymentDate"
            )}
          />
        )}
      />
      <Button variant="contained" color="primary" type="submit">
        {t("common.save")}
      </Button>
    </form>
  );
};

export default DriverPaymentHistoryCreateForm;
