import React from "react";
import { Link } from "react-router-dom";

interface IntegrationCardProps {
  name: string;
  description: string;
  logo: string;
  link: string;
}

const IntegrationCard: React.FC<IntegrationCardProps> = ({
  name,
  description,
  logo,
  link,
}) => {
  return (
    <Link
      to={link}
      className="bg-white shadow-md rounded-lg p-4 flex flex-col items-center justify-center transition-transform transform hover:scale-105"
    >
      <img src={logo} alt={`${name} logo`} className="w-12 h-12" />
      <div className="text-center mt-4">
        <h3 className="text-lg font-semibold">{name}</h3>
        <p className="text-gray-500">{description}</p>
      </div>
    </Link>
  );
};

export default IntegrationCard;
