import { Avatar, Drawer, IconButton } from "@mui/material";
import { useChat } from "../../contexts/ChatContext";
import { MdClose } from "react-icons/md";
import { getCurrencySymbol } from "../../utils/currencies";
import { DEFAULT_CURRENCY } from "../../consts/currencies";

const ChatContainer = () => {
  const {
    conversations,
    openedConversations,
    chatDrawerOpen,
    toggleChatDrawerOpen,
    openConversation,
    closeConversation,
  } = useChat();

  return (
    <>
      <Drawer
        anchor="right"
        open={chatDrawerOpen}
        onClose={toggleChatDrawerOpen}
        hideBackdrop
      >
        <div className="flex flex-col">
          <IconButton onClick={toggleChatDrawerOpen} className="self-end">
            <MdClose />
          </IconButton>
          {conversations.map((conversation) => (
            <div
              key={conversation.id}
              className={`p-4 border cursor-pointer
                ${conversation.status.unread ? "bg-accent" : "bg-secondary"}
                `}
              onClick={() => openConversation(conversation.id)}
            >
              <h3>{conversation.offer_bid?.offer.ref_id}</h3>

              <div className="flex items-center gap-2">
                <Avatar
                  src={conversation.offer_bid?.user.user.preferences?.avatar}
                  alt="my avatar"
                />
                <p className="text-sm">
                  {conversation.offer_bid?.user.first_name}{" "}
                  {conversation.offer_bid?.user.last_name}
                </p>
                <p className="text-sm font-bold">
                  {conversation.offer_bid?.user.email}
                </p>
              </div>
              <p className="text-sm">{conversation.conversation_type}</p>
              <p className="text-sm">
                {conversation.messages.length > 0 &&
                  conversation.messages[0].message}
              </p>
            </div>
          ))}
          {conversations.length === 0 && (
            <p className="text-center p-4">No conversations yet</p>
          )}
        </div>
      </Drawer>
      <div
        className="fixed bottom-0 right-64 flex space-x-4"
        style={{ zIndex: 10000 }}
      >
        {openedConversations.map((conversationId) => {
          const conversation = conversations.find(
            (conv) => conv.id === conversationId
          );
          return conversation ? (
            <div key={conversation.id}>
              <div className="w-72 bg-white shadow-lg border rounded-lg">
                <div className="flex justify-between items-center p-2 bg-primary text-white rounded-t-lg">
                  <div className="flex items-center gap-2">
                    <Avatar
                      src={
                        conversation.offer_bid?.user.user.preferences?.avatar
                      }
                      alt="my avatar"
                    />
                    <p className="text-sm">
                      {conversation.offer_bid?.user.first_name}{" "}
                      {conversation.offer_bid?.user.last_name}
                    </p>
                    <p className="text-sm font-bold">
                      {conversation.offer_bid?.user.email}
                    </p>
                  </div>
                  <IconButton
                    size="small"
                    onClick={() => closeConversation(conversation.id)}
                  >
                    <MdClose className="text-white" />
                  </IconButton>
                </div>
                <div className="p-2 max-h-64 overflow-y-auto">
                  {conversation.messages.map((message, index) => (
                    <div
                      key={index}
                      className={`p-2 mb-2 rounded ${
                        message.user_type === "U"
                          ? "bg-gray-200 text-right"
                          : "bg-blue-200 text-left"
                      }`}
                    >
                      <p className="text-sm">{message.message}</p>
                    </div>
                  ))}
                </div>
                <div className="p-4 bg-gray-100 border-t">
                  <p className="text-base font-semibold text-black">
                    {conversation.offer_bid?.price_value}{" "}
                    {getCurrencySymbol(
                      conversation.offer_bid?.price_currency || DEFAULT_CURRENCY
                    )}
                  </p>
                  <p className="text-xs text-gray-600">
                    Bid Offer from {conversation.offer_bid?.user.first_name}{" "}
                    {conversation.offer_bid?.user.last_name}
                  </p>
                  <div className="flex justify-between mt-4">
                    <button
                      onClick={() => {}}
                      className="bg-green-500 text-white text-sm font-semibold py-1 px-4 rounded hover:bg-green-600 transition duration-200"
                    >
                      Accept
                    </button>
                    <button
                      onClick={() => {}}
                      className="bg-red-500 text-white text-sm font-semibold py-1 px-4 rounded hover:bg-red-600 transition duration-200"
                    >
                      Decline
                    </button>
                  </div>
                </div>

                <div className="p-2">
                  <input
                    type="text"
                    placeholder="Type a message..."
                    className="w-full p-2 border rounded"
                    // Handle sending a message here
                  />
                </div>
              </div>
            </div>
          ) : null; // Return null if no conversation is found (just in case)
        })}
      </div>
    </>
  );
};

export default ChatContainer;
