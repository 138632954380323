import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { GRAPH_COLORS } from "../../../utils/colors";

interface ContractCarrierIncomesProps {
  contract_carrier_incomes: { [countries: string]: number };
}

const ContractCarrierIncomes: React.FC<ContractCarrierIncomesProps> = ({
  contract_carrier_incomes,
}) => {
  const data = Object.keys(contract_carrier_incomes).map((key, index) => {
    return {
      carrier: key,
      income: contract_carrier_incomes[key],
      color: GRAPH_COLORS[index % GRAPH_COLORS.length],
    };
  });
  data.sort((a, b) => b.income - a.income);

  return (
    <div>
      <h2 className="text-center">Contract Carrier Incomes</h2>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          width={600}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="carrier" interval={0} />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="income">
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ContractCarrierIncomes;
