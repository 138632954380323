import React from "react";
import { Link } from "react-router-dom";
import { FaPencilAlt } from "react-icons/fa";
import { IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CorporateDetails } from "../../types/base";

interface ShippersTableProps {
  loading: boolean;
  shippers: CorporateDetails[];
}

const ShippersTable: React.FC<ShippersTableProps> = ({ loading, shippers }) => {
  const { t } = useTranslation();

  return (
    <div className="overflow-x-auto w-full">
      <table className="bg-white dark:bg-zinc-800 shadow-xl rounded-2xl w-full">
        <thead className="bg-zinc-900 dark:bg-secondary dark:text-black">
          <tr>
            <th className="px-4 py-2 text-left">{t("shippers.tableHeader.name")}</th>
            <th className="px-4 py-2 text-left">{t("shippers.tableHeader.vatId")}</th>
            <th className="px-4 py-2 text-left">{t("shippers.tableHeader.country")}</th>
            <th className="px-4 py-2 text-left">{t("shippers.tableHeader.actions")}</th>
          </tr>
        </thead>
        {loading && (
          <tbody>
            <tr>
              <td colSpan={4} className="text-center">
                {t("loading")}
              </td>
            </tr>
          </tbody>
        )}
        {!loading && shippers.length === 0 && (
          <tbody>
            <tr>
              <td colSpan={4} className="text-center">
                {t("noData")}
              </td>
            </tr>
          </tbody>
        )}
        {!loading && shippers.length > 0 && (
          <tbody className="text-gray-600">
            {shippers.map((shipper) => (
              <tr
                key={shipper.id}
                className="border-b hover:bg-gray-300 transition duration-150 ease-in-out"
              >
                <td className="p-3 text-left">
                  {shipper.name}
                </td>
                <td className="p-3 text-left">
                  {shipper.vat_id}
                </td>
                <td className="p-3 text-left">
                  {shipper.country}
                </td>
                <td className="p-3 text-left">
                  <IconButton
                    component={Link}
                    to={`/shippers/${shipper.id}`}
                    aria-label="edit"
                    size="small"
                    className="text-accent"
                  >
                    <FaPencilAlt />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default ShippersTable;
