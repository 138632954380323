import React from "react";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";
import { Card, CardContent, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getCurrencySymbol } from "../../../utils/currencies";

interface FleetIncome {
  total_income: number;
  per_km_income: number;
}

interface Income {
  total_income: number;
  avg_order_income: number;
}

interface IncomeDataProps {
  fleet_income: FleetIncome;
  income: Income;
}

const IncomeData: React.FC<IncomeDataProps> = ({ fleet_income, income }) => {
  const { t } = useTranslation();

  const pieData = [
    {
      name: t("insights.incomes.totalIncomeBreakdown.fleetIncome"),
      value: fleet_income.total_income,
    },
    {
      name: t("insights.incomes.totalIncomeBreakdown.otherIncome"),
      value: income.total_income - fleet_income.total_income,
    },
  ];

  const COLORS = ["#8884d8", "#82ca9d"];

  return (
    <div>
      <h2 className="text-center">
        {t("insights.incomes.totalIncomeBreakdown.title")}
      </h2>
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={pieData}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={100}
            fill="#8884d8"
            label
          >
            {
              // @ts-ignore untyped recharts
              pieData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))
            }
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>

      <h2 className="text-center font-bold">
        {t("insights.incomes.metrics.title")}
      </h2>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: "20px",
        }}
      >
        <Card style={{ width: "45%", padding: "10px" }}>
          <CardContent>
            <Typography variant="h5" component="h2">
              {t("insights.incomes.metrics.fleetPerKm")}
            </Typography>
            <Typography variant="h4" component="p" color="textSecondary">
              {fleet_income.per_km_income.toFixed(2)} {getCurrencySymbol(
                "eur"
              )}/km
            </Typography>
          </CardContent>
        </Card>
        <Card style={{ width: "45%", padding: "10px" }}>
          <CardContent>
            <Typography variant="h5" component="h2">
              {t("insights.incomes.metrics.averageOrderValue")}
            </Typography>
            <Typography variant="h4" component="p" color="textSecondary">
              {income.avg_order_income.toFixed(2)} {getCurrencySymbol("eur")}
            </Typography>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default IncomeData;
