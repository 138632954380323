import { useEffect, useState } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { FiLoader } from "react-icons/fi";
import { TextField, Button as MUIButton } from "@mui/material";
import useAxios from "../../../hooks/useAxios";
import { formatSingleTimeDisplay } from "../../../utils/time";

interface IntegrationStatus {
  status: string;
  last_synced: string;
  created_at: string;
  created_by: string;
  client_id: string;
  client_login: string;
  client_password: string;
}

interface GBoxSettings {
  client_id: string;
  client_login: string;
  client_password: string;
}

const IneloContainer = () => {
  const axios = useAxios();
  const { control, handleSubmit } = useForm<GBoxSettings>();

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [integrationStatus, setIntegrationStatus] =
    useState<IntegrationStatus | null>(null);

  useEffect(() => {
    axios
      .get("integrations/gbox/status/")
      .then((res) => {
        setIntegrationStatus(res.data);
      })
      .catch((err) => {
        console.error(err);
        setError("Failed to check integration status.");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onIntegrate: SubmitHandler<GBoxSettings> = (data) => {
    axios
      .post("integrations/gbox/", data)
      .then((res) => {
        window.open(res.data.auth_url, "_blank");
      })
      .catch((err) => {
        console.error(err);
        setError("Failed to initiate integration.");
      });
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-secondary">
        <FiLoader className="animate-spin text-primary text-4xl" />
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="bg-white p-8 rounded-lg shadow-lg text-center w-3/4 max-w-md">
        {error && <div className="mb-4 text-red-500">{error}</div>}
        {integrationStatus?.status === "disconnected" && (
          <>
            <h2 className="text-2xl font-bold mb-4 text-gray-800">
              Integration with Inelo GBox is Disconnected
            </h2>
            <p className="mb-4 text-gray-600">
              Integrate with Inelo GBox to start syncing your data.
            </p>
            <form onSubmit={handleSubmit(onIntegrate)}>
              <div className="mb-4">
                <Controller
                  name="client_id"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Client ID"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </div>
              <div className="mb-4">
                <Controller
                  name="client_login"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Client Login"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </div>
              <div className="mb-4">
                <Controller
                  name="client_password"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Client Password"
                      variant="outlined"
                      type="password"
                      fullWidth
                    />
                  )}
                />
              </div>
              <MUIButton
                type="submit"
                variant="contained"
                color="primary"
                className="px-6 py-3"
              >
                Log In With Trans.eu
              </MUIButton>
            </form>
          </>
        )}
        {integrationStatus?.status === "connected" && (
          <>
            <h2 className="text-2xl font-bold mb-4 text-gray-800">
              Integration with Inelo GBox is Connected
            </h2>
            <div className="mb-4 text-left text-gray-700">
              <p>
                <strong>Last Synced:</strong>{" "}
                {formatSingleTimeDisplay(
                  integrationStatus.last_synced,
                  "dd/MM/yy HH:mm"
                )}
              </p>
              <p>
                <strong>Created At:</strong>{" "}
                {formatSingleTimeDisplay(
                  integrationStatus.created_at,
                  "dd/MM/yy HH:mm"
                )}
              </p>
              <p>
                <strong>Client ID:</strong> {integrationStatus.client_id}
              </p>
              <p>
                <strong>Client Login:</strong> {integrationStatus.client_login}
              </p>
              <p>
                <strong>Client Password:</strong>{" "}
                {integrationStatus.client_password}
              </p>
              <p>
                <strong>Created By:</strong> {integrationStatus.created_by}
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default IneloContainer;
