import { Controller, useForm } from "react-hook-form";
import { OrderInfo } from "../../types/orders";
import { useTranslation } from "react-i18next";
import { Button, CircularProgress, InputAdornment, MenuItem, TextField } from "@mui/material";
import { getCurrencySymbol } from "../../utils/currencies";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useAuth } from "../../contexts/AuthContext";
import NoData from "../../components/NoData";

interface BasicDetailsFormProps {
  order: OrderInfo;
  onFormSubmit: (data: any) => void;
  onCancelOrder: (orderId: number) => void;
}

const BasicDetailsForm: React.FC<BasicDetailsFormProps> = ({
  order,
  onFormSubmit,
  onCancelOrder,
}) => {
  const { t } = useTranslation();
  const { control, setValue, handleSubmit } = useForm({
    defaultValues: {
      ref_number: order.ref_number,
      owner_assignee_id: order.owner_assignee?.pk || null,
      completed_at: order.completed_at,
    },
  });

  const { company, companyLoading } = useAuth();

  if(companyLoading) return <CircularProgress size={60} />;

  if (!company) return <NoData />;

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <h3 className="text-xl font-semibold mb-2">
        {t("orderDetails.general.title")}
      </h3>
      <div className="flex gap-4">
        <Controller
          name="ref_number"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("orderDetails.general.number")}
              variant="outlined"
              className="w-64"
            />
          )}
        />
        <Controller
          name="owner_assignee_id"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              select
              label={t("orderDetails.general.assignee")}
              variant="outlined"
              className="w-64"
            >
              {company.users.map((user) => (
                <MenuItem key={user.user.pk} value={user.user.pk}>
                  {user.user.name} {user.user.email}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </div>
      {order.estimated_distance && (
        <div className="flex gap-4 mt-4">
          <TextField
            label={t("orders.orderCreate.estDistance")}
            value={order.estimated_distance}
            variant="outlined"
            className="w-64"
            disabled
            InputProps={{
              endAdornment: <InputAdornment position="end">km</InputAdornment>,
              readOnly: true,
            }}
          />
          <TextField
            label={t("orders.orderCreate.estRatePerKm")}
            sx={{ mb: 2 }}
            type="number"
            disabled
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {getCurrencySymbol(order.payment.price_currency)}
                </InputAdornment>
              ),
              readOnly: true,
            }}
            value={(
              order.payment.price_value / order.estimated_distance
            ).toFixed(2)}
          />
        </div>
      )}
      {(order.status === "done" || order.status === "canceled") && (
        <div className="my-4">
          <Controller
            name="completed_at"
            control={control}
            render={({ field }) => (
              <DateTimePicker
                label={t("orderDetails.general.completedAt")}
                sx={{ mb: 2 }}
                value={field.value ? new Date(field.value) : null}
                onChange={(date) => {
                  setValue(`completed_at`, date ? date.toISOString() : "");
                }}
              />
            )}
          />
        </div>
      )}
      <div className="flex gap-4 mt-4">
        <Button
          className="mt-4"
          type="submit"
          variant="contained"
          color="primary"
        >
          {t("common.save")}
        </Button>

        {order.status !== "canceled" && (
          <Button
            type="button"
            variant="contained"
            color="error"
            onClick={() => onCancelOrder(order.id)}
          >
            {t("orderDetails.cancelOrder")}
          </Button>
        )}
      </div>
    </form>
  );
};

export default BasicDetailsForm;
