import { useTranslation } from "react-i18next";
import { OfferOperationModel } from "../../../../types/offers";
import { formatOrderOperationTime } from "../../../../utils/time";

interface OperationDetailProps {
  operation: OfferOperationModel;
}

const OfferOperationDetail: React.FC<OperationDetailProps> = ({
  operation,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="text-xl font-bold text-gray-800 dark:text-secondary flex items-center">
        <div>{t(`operationTypes.${operation.operation_type}`)}</div>
      </div>
      <div className="grid grid-cols-2 gap-4 text-gray-700 mt-2">
        <div>
          <label className="font-semibold">
            {t("orderDetails.operations.latitude")}:
          </label>  
          <p>{operation.latitude}</p>
        </div>
        <div>
          <label className="font-semibold">
            {t("orderDetails.operations.longitude")}:
          </label>
          <p>{operation.longitude}</p>
        </div>
        <div>
          <label className="font-semibold">
            {t("orderDetails.operations.postalCode")}:
          </label>
          <p>{operation.postal_code}</p>
        </div>
        <div>
          <label className="font-semibold">
            {t("orderDetails.operations.address")}:
          </label>
          <p>{operation.name}</p>
        </div>
        <div>
          <label className="font-semibold">
            {t("orderDetails.operations.time")}:
          </label>
          <p>{formatOrderOperationTime(operation)}</p>
        </div>
      </div>
    </>
  );
};

export default OfferOperationDetail;
