import { useTranslation } from "react-i18next";
import successImage from "../../assets/truck-owner.webp";

import { useNavigate } from "react-router-dom";

const RegisterFirstStepSuccessContainer = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-prime">
      <img
        src={successImage}
        alt="Subscription Successful"
        className="w-1/4 mb-8 rounded-full"
      />
      <h1 className="text-4xl font-bold text-gray-800 mb-4">
        {t("registerFirstStepSuccess.successful")}
      </h1>
      <p className="text-gray-600 text-lg mb-4">
        {t("registerFirstStepSuccess.thankYou")}
      </p>
      <button
        onClick={() => navigate("/")}
        className="py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-200"
      >
        {t("registerFirstStepSuccess.goToSignIn")}
      </button>
    </div>
  );
};

export default RegisterFirstStepSuccessContainer;
