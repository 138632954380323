import React, { FC, InputHTMLAttributes } from "react";

interface FormInputProps {
  label?: string;
  className?: string;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errorMessage?: string;
  icon?: React.ReactNode;
  disabled?: boolean;
}

const FormInput: FC<FormInputProps> = ({
  label,
  className,
  inputProps,
  onChange = () => {},
  errorMessage,
  icon,
  disabled = false,
}) => (
  <div className={`flex flex-col items-start ${className}`}>
    <label className="text-sm text-gray-700">{label}</label>
    <div
      className={`w-full flex items-center border-gray-400 border-b-2 px-1 ${disabled ? "bg-gray-100 rounded-xl" : ""}
    `}
    >
      {icon && icon}
      <input
        disabled={disabled}
        onChange={onChange}
        {...inputProps}
        className={`
      transition duration-500 ease-in-out p-2 bg-transparent w-full 
      focus:outline-none focus:bg-transparent text-black
      ${disabled ? "cursor-not-allowed" : ""}
      ${inputProps?.className}`}
      />
    </div>
    {errorMessage && (
      <span className="bg-brand-primary p-2 rounded-lg text-red-500 font-bold">
        {errorMessage}
      </span>
    )}
  </div>
);

export default FormInput;
