import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import { DelegationModel } from "../../types/delegations";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CircularProgress,
  LinearProgress,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ReactCountryFlag from "react-country-flag";
import { format } from "date-fns-tz";
import { getCurrencySymbol } from "../../utils/currencies";
import Orders from "./Orders";
import { DateTimePicker } from "@mui/x-date-pickers";
import { MdWarningAmber } from "react-icons/md";
import { FaTruckFast } from "react-icons/fa6";
import DelegationMap from "./DelegationMap";
import { useAuth } from "../../contexts/AuthContext";
import { useToast } from "../../hooks/useToast";
import { saveAs } from "file-saver";

const DelegationDetailContainer = () => {
  const { delegationId } = useParams<{ delegationId: string }>();
  const [delegation, setDelegation] = useState<DelegationModel | null>(null);
  const [autofillLoading, setAutofillLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const axios = useAxios();
  const toast = useToast();

  const [delegationStartDate, setDelegationStartDate] = useState<Date | null>(
    null
  );
  const [delegationEndDate, setDelegationEndDate] = useState<Date | null>(null);
  const [standStartDate, setStandStartDate] = useState<Date | null>(null);

  const { company } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDelegation = async () => {
      try {
        setLoading(true);
        const { data } = await axios.get(`/delegations/${delegationId}/`);
        setDelegation(data);
        setDelegationStartDate(new Date(data.start_date));
        setDelegationEndDate(data.end_date ? new Date(data.end_date) : null);
        setStandStartDate(new Date(data.stand_start_date));
      } catch (error) {
        console.error(error);
        toast({
          type: "error",
          message: t("delegation.fetchError"),
        });
      } finally {
        setLoading(false);
      }
    };
    fetchDelegation();
  }, [delegationId]);

  const onRecalculateMap = async () => {
    try {
      setAutofillLoading(true);
      const { data } = await axios.patch(
        `/delegations/${delegationId}/recalculate-map/`
      );
      setDelegation(data);
    } catch (error) {
      console.error(error);
      toast({
        type: "error",
        message: t("delegation.recalculateMapError"),
      });
    } finally {
      setAutofillLoading(false);
    }
  };

  const onUpdate = async (data: Partial<DelegationModel>) => {
    try {
      const response = await axios.patch(`/delegations/${delegationId}/`, data);
      setDelegation(response.data);
    } catch (error) {
      console.error(error);
      toast({
        type: "error",
        message: t("delegation.updateError"),
      });
    }
  };

  const onChangeStartDate = async (date: Date | null) => {
    if (date) {
      await onUpdate({ start_date: date?.toISOString() });
    }
  };

  const onChangeEndDate = async (date: Date | null) => {
    if (date) {
      await onUpdate({ end_date: date?.toISOString() });
    }
  };

  const onChangeStandStartDate = async (date: Date | null) => {
    if (date) {
      await onUpdate({ stand_start_date: date?.toISOString() });
    }
  };

  const onChangeTruck = async (licensePlate: string) => {
    await onUpdate({ truck: licensePlate });
  };

  const onDelete = async () => {
    try {
      await axios.delete(`/delegations/${delegationId}/`);
      toast({
        type: "success",
        message: t("delegation.deleteSuccess"),
      });
      navigate("/delegations");
    } catch (error) {
      console.error(error);
      toast({
        type: "error",
        message: t("delegation.deleteError"),
      });
    }
  };

  const exportBorderCrossings = async () => {
    try {
      const response = await axios.get(
        `/delegations/${delegationId}/export/border-crossings/`,
        {
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], {
        type: "application/vnd.ms-excel",
      });
      saveAs(blob, `delegations_${delegationId}.xls`);
    } catch (error) {
      console.error(error);
      toast({
        type: "error",
        message: t("delegation.exportError"),
      });
    }
  };

  const exportOrders = async () => {
    try {
      const response = await axios.get(
        `/delegations/${delegationId}/export/orders/`,
        {
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], {
        type: "application/vnd.ms-excel",
      });
      saveAs(blob, `delegations_${delegationId}.xls`);
    } catch (error) {
      console.error(error);
      toast({
        type: "error",
        message: t("delegation.exportError"),
      });
    }
  };

  if (loading) {
    return <CircularProgress size={24} />;
  }

  if (!delegation) {
    return <div>{t("delegation.notFound")}</div>;
  }

  let route = null;

  if (delegation.stats && delegation.stats.routes.length > 0) {
    route = delegation.stats.routes[0];
  }

  return (
    <div className="p-4 dark:text-secondary text-black">
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/delegations">{t("breadcrumb.delegations")}</Link>
        <Typography color="text.primary">
          {t("delegation.title")} - {delegation.ref_number}
        </Typography>
      </Breadcrumbs>
      <div className="p-4 flex flex-col">
        <Button
          variant="contained"
          color="error"
          onClick={onDelete}
          className="self-end"
        >
          {t("delegation.delete")}
        </Button>
        <h1>{delegation.ref_number}</h1>
        <p>{delegation.driver_name}</p>
        <div className="flex items-center gap-4 p-4">
          <TextField
            select
            label={t("delegation.selectTruck")}
            value={delegation.truck}
            onChange={(e) => onChangeTruck(e.target.value as string)}
            variant="outlined"
          >
            {company?.trucks.map((truck) => (
              <MenuItem key={truck.license_plate} value={truck.license_plate}>
                {truck.license_plate}
              </MenuItem>
            ))}
          </TextField>
          <DateTimePicker
            label={t("delegations.standDate")}
            value={standStartDate}
            onChange={onChangeStandStartDate}
            format="dd/MM/yyyy, HH:mm"
          />
          <DateTimePicker
            label={t("delegation.startDate")}
            value={delegationStartDate}
            onChange={onChangeStartDate}
            format="dd/MM/yyyy, HH:mm"
          />
          <DateTimePicker
            label={t("delegation.endDate")}
            value={delegationEndDate}
            onChange={onChangeEndDate}
            format="dd/MM/yyyy, HH:mm"
          />
          <div className="flex items-center gap-4">
            <Button variant="contained" onClick={exportBorderCrossings}>
              {t("delegation.exportBorderCrossings")}
            </Button>
            <Button variant="contained" onClick={exportOrders}>
              {t("delegation.exportOrders")}
            </Button>
          </div>
        </div>
        {delegation.stats && delegation.stats.warnings.length > 0 && (
          <div className="p-4">
            {delegation.stats.warnings?.map((warning, index) => (
              <div
                key={index}
                className="bg-red-100 text-red-800 p-2 rounded-lg my-2"
              >
                <MdWarningAmber className="text-red-500 text-2xl" />
                <p>{warning}</p>
              </div>
            ))}
          </div>
        )}
        {delegation.stats && (
          <>
            <p className="text-gray-700 text-lg font-bold">
              {t("delegation.days")}: {delegation.stats.days}
            </p>
            <p className="text-gray-700 text-lg font-bold">
              {t("delegation.standDays")}: {delegation.stats.stand_days}
            </p>
            <h4 className="text-md font-semibold my-2 text-gray-800">
              {t("delegation.borderCrossings")}
            </h4>
          </>
        )}
      </div>
      <ul className="list-disc pl-5 text-gray-700">
        {delegation.border_crossings.map((crossing, index) => (
          <li key={index}>
            {crossing.old_country && (
              <ReactCountryFlag
                countryCode={crossing.old_country}
                svg
                style={{
                  width: "1.5em",
                  height: "1.5em",
                  marginRight: "0.5em",
                }}
              />
            )}
            {crossing.title} - {crossing.duration} min{" "}
            {crossing.old_country_at && (
              <span>
                {format(
                  new Date(crossing.old_country_at),
                  "dd MMM yyyy, HH:mm"
                )}
              </span>
            )}
            <span className="mx-2">-</span>
            <ReactCountryFlag
              countryCode={crossing.new_country}
              svg
              style={{
                width: "1.5em",
                height: "1.5em",
                marginRight: "0.5em",
              }}
            />
            {crossing.new_country_at && (
              <span>
                {format(
                  new Date(crossing.new_country_at),
                  "dd MMM yyyy, HH:mm"
                )}
              </span>
            )}
          </li>
        ))}
      </ul>
      <p className="text-gray-700">
        {t("delegation.borderCrossings")}:{delegation.border_crossings.length}
      </p>

      {delegation.stats && (
        <div className="p-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <Card variant="outlined" sx={{ mb: 2, p: 3 }}>
              <CardContent>
                <Typography variant="h5" component="div">
                  {t("delegation.mileageOverview")}
                </Typography>
                <Typography variant="h3" component="div" sx={{ mt: 1 }}>
                  {delegation.stats.actual_mileage} /{" "}
                  {delegation.stats.expected_min_mileage} km
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={
                    (delegation.stats.actual_mileage /
                      delegation.stats.expected_min_mileage) *
                    100
                  }
                  sx={{ mt: 2, height: 10 }}
                />
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mt: 1 }}
                >
                  {t("delegation.remainingMileage")}:{" "}
                  <span className="font-extrabold text-xl">
                    {(
                      delegation.stats.expected_min_mileage -
                      delegation.stats.actual_mileage
                    ).toFixed(2)}{" "}
                    km{" "}
                  </span>{" "}
                  (
                  {(
                    100 -
                    (delegation.stats.actual_mileage /
                      delegation.stats.expected_min_mileage) *
                      100
                  ).toFixed(2)}
                  %)
                </Typography>
                <p className="text-gray-500">
                  {t("delegation.plannedMileage")}:{" "}
                  {delegation.stats.planned_mileage} km
                </p>

                <p className="text-gray-500">
                  {t("delegation.expectedMileage")}:{" "}
                  {delegation.stats.expected_min_mileage} -{" "}
                  {delegation.stats.expected_max_mileage} km
                </p>
                <p className="text-gray-500">
                  {" "}
                  {t("delegation.mileagePerDay")}:{" "}
                  {delegation.stats.actual_mileage_per_day} (
                  {delegation.stats.planned_mileage_per_day}) km
                </p>
                <p className="text-gray-500">
                  {t("delegation.expectedMileagePerDay")}:{" "}
                  {delegation.stats.expected_min_mileage_per_day} -{" "}
                  {delegation.stats.expected_max_mileage_per_day} km
                </p>
              </CardContent>
            </Card>
            <Card variant="outlined" sx={{ mb: 2, p: 3 }}>
              <CardContent>
                <Typography variant="h5" component="div">
                  {t("delegation.revenueOverview")}
                </Typography>
                <Typography variant="h3" component="div" sx={{ mt: 1 }}>
                  {delegation.stats.planned_revenue} /{" "}
                  {delegation.stats.expected_revenue} {getCurrencySymbol("eur")}
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={
                    (delegation.stats.planned_revenue /
                      delegation.stats.expected_revenue) *
                    100
                  }
                  sx={{ mt: 2, height: 10 }}
                />
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mt: 1 }}
                >
                  {t("delegation.remainingRevenue")}:{" "}
                  <span className="font-extrabold text-xl">
                    {" "}
                    {(
                      delegation.stats.expected_revenue -
                      delegation.stats.planned_revenue
                    ).toFixed(2)}{" "}
                    {getCurrencySymbol("eur")}
                  </span>
                  (
                  {(
                    100 -
                    (delegation.stats.planned_revenue /
                      delegation.stats.expected_revenue) *
                      100
                  ).toFixed(2)}
                  %)
                </Typography>
                <p className="text-gray-500">
                  {t("delegation.revenuePerKm")}:{" "}
                  {delegation.stats.actual_revenue_per_km} (
                  {delegation.stats.planned_revenue_per_km}){" "}
                  {getCurrencySymbol("eur")}
                </p>
                <p className="text-gray-500">
                  {t("delegation.revenuePerOrder")}:{" "}
                  {delegation.stats.revenue_per_all_order}{" "}
                  {getCurrencySymbol("eur")}
                </p>
                <p className="text-gray-500">
                  {t("delegation.revenuePerDay")}:{" "}
                  {delegation.stats.actual_revenue_per_day !==
                  delegation.stats.planned_revenue_per_day ? (
                    <>
                      {delegation.stats.actual_revenue_per_day} -{" "}
                      {delegation.stats.planned_revenue_per_day}{" "}
                    </>
                  ) : (
                    delegation.stats.actual_revenue_per_day
                  )}{" "}
                  {getCurrencySymbol("eur")}
                </p>
                <p className="text-gray-500">
                  {t("delegation.expectedRevenuePerDay")}:{" "}
                  {delegation.stats.expected_revenue_per_day}{" "}
                  {getCurrencySymbol("eur")}
                </p>
              </CardContent>
            </Card>
            <Card variant="outlined" sx={{ mb: 2, p: 3 }}>
              <CardContent>
                <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                  {t("delegation.emptyFull")}
                </Typography>
                <div className="flex items-center gap-4">
                  <div className="relative">
                    <CircularProgress
                      variant="determinate"
                      value={
                        (delegation.stats.actual_full_kilometers /
                          delegation.stats.actual_mileage) *
                        100
                      }
                      size={150}
                      thickness={5}
                      sx={{ color: "#4caf50" }}
                    />
                    <Box
                      top={0}
                      left={0}
                      bottom={0}
                      right={0}
                      position="absolute"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="column"
                    >
                      <Typography
                        variant="h4"
                        component="div"
                        color="textSecondary"
                      >
                        {(
                          (delegation.stats.actual_full_kilometers /
                            delegation.stats.actual_mileage) *
                          100
                        ).toFixed(1)}{" "}
                        %
                      </Typography>
                      <FaTruckFast className="text-4xl text-green-500" />
                    </Box>
                  </div>
                  <div className="flex flex-col gap-4">
                    <div className="flex flex-col items-start justify-center">
                      <p className="text-xl font-bold">
                        {" "}
                        {delegation.stats.actual_full_kilometers} (
                        {delegation.stats.planned_full_kilometers}) km
                      </p>
                      <p>{t("delegation.orderKilometers")}</p>
                    </div>
                    <div className="flex flex-col items-start justify-center">
                      <p className="text-red-700 text-xl font-bold">
                        {delegation.stats.actual_empty_kilometers} (
                        {delegation.stats.planned_empty_kilometers}) km
                      </p>
                      <p>{t("delegation.emptyKilometers")}</p>
                    </div>
                    <div className="flex flex-col items-start justify-center">
                      <p className="text-xl font-bold">
                        {delegation.stats.actual_mileage} (
                        {delegation.stats.planned_mileage}) km
                      </p>
                      <p>{t("delegation.actualMileage")}</p>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
            <Card variant="outlined" sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="h5" component="div">
                  {t("delegation.costsBreakdown")}
                </Typography>
                <div className="text-sm flex flex-col">
                  <div className="grid grid-cols-4">
                    <p className="font-semibold">{t("delegation.category")}</p>
                    <p className="font-semibold">{t("delegation.estimated")}</p>
                    <p className="font-semibold">
                      {t("delegation.actualEstimated")}
                    </p>
                    <p className="text-sm">{t("delegation.actual")}</p>
                    <p> {t("delegation.fuel")}:</p>
                    <p>
                      {delegation.stats.estimated_min_fuel_cost} -{" "}
                      {delegation.stats.estimated_max_fuel_cost}
                      {getCurrencySymbol("eur")}{" "}
                    </p>
                    <div>
                      <p className="text-sm">
                        {delegation.stats.actual_estimated_fuel_cost}
                        {route && `(${route.summary.fuel_cost.toFixed(1)})`}
                        {getCurrencySymbol("eur")}{" "}
                      </p>
                    </div>
                    <p>
                      {delegation.stats.real_fuel_cost}
                      {getCurrencySymbol("eur")}
                    </p>
                    <p className="text-sm">{t("delegation.toll")}:</p>
                    <p>
                      {delegation.stats.estimated_min_toll_cost} -{" "}
                      {delegation.stats.estimated_max_toll_cost}
                      {getCurrencySymbol("eur")}{" "}
                    </p>
                    <p>
                      {delegation.stats.actual_estimated_toll_cost}{" "}
                      {route && `(${route.summary.total_tolls.toFixed(1)})`}
                      {getCurrencySymbol("eur")}{" "}
                    </p>
                    <p>
                      {delegation.stats.real_toll_cost}{" "}
                      {getCurrencySymbol("eur")}
                    </p>
                    <p className="text-sm">{t("delegation.driver")}:</p>
                    <p>
                      {delegation.stats.estimated_driver_cost}{" "}
                      {getCurrencySymbol("eur")}
                    </p>
                    <p>
                      {delegation.stats.actual_driver_cost}({" "}
                      {delegation.stats.planned_driver_cost} ){" "}
                      {getCurrencySymbol("eur")}
                    </p>
                    <p>
                      {delegation.stats.real_driver_cost}{" "}
                      {getCurrencySymbol("eur")}
                    </p>
                    <p>{t("delegation.lease")}:</p>
                    <p>
                      {delegation.stats.estimated_lease_cost}{" "}
                      {getCurrencySymbol("eur")}
                    </p>
                    <p>
                      {delegation.stats.actual_estimated_lease_cost} ({" "}
                      {delegation.stats.planned_lease_cost} )
                      {getCurrencySymbol("eur")}
                    </p>
                    <p>
                      {delegation.stats.real_lease_cost}{" "}
                      {getCurrencySymbol("eur")}
                    </p>
                    <p>{t("delegation.other")}:</p>
                    <p>
                      {delegation.stats.estimated_other_costs}{" "}
                      {getCurrencySymbol("eur")}
                    </p>
                    <p>
                      {delegation.stats.actual_estimated_other_costs} ({" "}
                      {delegation.stats.planned_other_costs} )
                      {getCurrencySymbol("eur")}
                    </p>
                    <p>
                      {delegation.stats.real_other_costs}{" "}
                      {getCurrencySymbol("eur")}
                    </p>
                    <p className="font-semibold">{t("delegation.total")}</p>
                    <p className="font-semibold">
                      {delegation.stats.estimated_total_costs}{" "}
                      {getCurrencySymbol("eur")}
                    </p>
                    <p>
                      {delegation.stats.actual_estimated_total_costs} ({" "}
                      {delegation.stats.planned_total_costs} )
                      {getCurrencySymbol("eur")}
                    </p>
                    <p className="font-semibold">
                      {delegation.stats.real_total_costs}{" "}
                      {getCurrencySymbol("eur")}
                    </p>
                  </div>
                </div>
              </CardContent>
            </Card>

            <Card variant="outlined" sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="h5" component="div">
                  {t("delegation.profit")}:
                </Typography>
                <p className="text-gray-500">
                  {t("delegation.actual")}:{" "}
                  {delegation.stats.actual_estimated_profit}{" "}
                  {getCurrencySymbol("eur")}
                </p>
                <p className="text-gray-500">
                  {t("delegation.estimated")}:{" "}
                  {delegation.stats.estimated_profit} {getCurrencySymbol("eur")}
                </p>
                <p className="text-gray-500">
                  {t("delegation.expected")}: {delegation.stats.expected_profit}{" "}
                  {getCurrencySymbol("eur")}
                </p>
              </CardContent>
            </Card>
            <Card variant="outlined" sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="h5" component="div">
                  {t("delegation.costPerKm")}:
                </Typography>
                <div className="grid grid-cols-2 gap-4 p-4 bg-gray-100 rounded-lg shadow-md">
                  <div className="flex flex-col items-start">
                    <span className="text-sm font-semibold text-gray-600">
                      Actual Estimated Fuel Cost per km:
                    </span>
                    <span className="text-lg font-bold text-gray-800">
                      {delegation.stats.actual_estimated_fuel_cost_per_km}
                    </span>
                  </div>
                  <div className="flex flex-col items-start">
                    <span className="text-sm font-semibold text-gray-600">
                      Actual Estimated Toll Cost per km:
                    </span>
                    <span className="text-lg font-bold text-gray-800">
                      {delegation.stats.actual_estimated_toll_cost_per_km}
                    </span>
                  </div>
                  <div className="flex flex-col items-start">
                    <span className="text-sm font-semibold text-gray-600">
                      Estimated Lease Cost per km:
                    </span>
                    <span className="text-lg font-bold text-gray-800">
                      {delegation.stats.estimated_lease_cost_per_km}
                    </span>
                  </div>
                  <div className="flex flex-col items-start">
                    <span className="text-sm font-semibold text-gray-600">
                      Estimated Other Costs per km:
                    </span>
                    <span className="text-lg font-bold text-gray-800">
                      {delegation.stats.estimated_other_costs_per_km}
                    </span>
                  </div>
                  <div className="flex flex-col items-start">
                    <span className="text-sm font-semibold text-gray-600">
                      Estimated Driver Cost per km:
                    </span>
                    <span className="text-lg font-bold text-gray-800">
                      {delegation.stats.estimated_driver_cost_per_km}
                    </span>
                  </div>
                  <div className="flex flex-col items-start">
                    <span className="text-sm font-semibold text-gray-600">
                      Estimated Total Costs per km:
                    </span>
                    <span className="text-lg font-bold text-gray-800">
                      {delegation.stats.estimated_total_costs_per_km}
                    </span>
                  </div>
                </div>
              </CardContent>
            </Card>
            <Card variant="outlined" sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="h5" component="div">
                  {t("delegation.costPerDay")}:
                </Typography>
                <div className="grid grid-cols-2 gap-4 p-4 bg-gray-100 rounded-lg shadow-md">
                  <div className="flex flex-col items-start">
                    <span className="text-sm font-semibold text-gray-600">
                      Actual Estimated Fuel Cost per day:
                    </span>
                    <span className="text-lg font-bold text-gray-800">
                      {delegation.stats.actual_estimated_fuel_cost_per_day}
                    </span>
                  </div>
                  <div className="flex flex-col items-start">
                    <span className="text-sm font-semibold text-gray-600">
                      Actual Estimated Toll Cost per day:
                    </span>
                    <span className="text-lg font-bold text-gray-800">
                      {delegation.stats.actual_estimated_toll_cost_per_day}
                    </span>
                  </div>
                  <div className="flex flex-col items-start">
                    <span className="text-sm font-semibold text-gray-600">
                      Estimated Lease Cost per day:
                    </span>
                    <span className="text-lg font-bold text-gray-800">
                      {delegation.stats.estimated_lease_cost_per_day}
                    </span>
                  </div>
                  <div className="flex flex-col items-start">
                    <span className="text-sm font-semibold text-gray-600">
                      Estimated Other Costs per day:
                    </span>
                    <span className="text-lg font-bold text-gray-800">
                      {delegation.stats.real_other_costs_per_day}
                    </span>
                  </div>
                  <div className="flex flex-col items-start">
                    <span className="text-sm font-semibold text-gray-600">
                      Estimated Driver Cost per day:
                    </span>
                    <span className="text-lg font-bold text-gray-800">
                      {delegation.stats.estimated_driver_cost_per_day}
                    </span>
                  </div>
                  <div className="flex flex-col items-start">
                    <span className="text-sm font-semibold text-gray-600">
                      Estimated Total Costs per day:
                    </span>
                    <span className="text-lg font-bold text-gray-800">
                      {delegation.stats.estimated_total_costs_per_day}
                    </span>
                  </div>
                </div>
              </CardContent>
            </Card>
            <Card variant="outlined" sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="h5" component="div">
                  {t("delegation.rates")}:
                </Typography>
                {delegation.stats.allowed_rate_per_km_table.map(
                  (rate, index) => (
                    <div key={index} className="flex items-center gap-4">
                      <p>{rate.kilometers} km</p>
                      <p>
                        {rate.rate} {getCurrencySymbol("eur")} / km
                      </p>
                    </div>
                  )
                )}
              </CardContent>
            </Card>
            <div className="col-span-3">
              <Card variant="outlined" sx={{ mb: 2 }}>
                <CardContent>
                  <Typography variant="h5" component="div">
                    {t("delegation.fuelConsumption")}:{" "}
                  </Typography>
                  <Typography variant="h3" component="div" sx={{ mt: 1 }}>
                    {delegation.stats.actual_fuel_consumption}{" "}
                    <span className="text-sm">l</span>
                  </Typography>
                  <p className="text-gray-500">
                    {(delegation.stats.actual_consumption_per_km * 100).toFixed(
                      1
                    )}{" "}
                    <span className="text-sm">l/100km</span>
                  </p>
                  {delegation.stats.refuels.map((refuel, index) => (
                    <div key={index} className="mb-1 border-b pb-2">
                      <div className="flex justify-between items-center">
                        <div className="text-xs text-gray-500">
                          {format(
                            new Date(refuel.created_at),
                            "dd MMM yyyy, HH:mm"
                          )}
                        </div>
                        <div className="flex items-center gap-2">
                          <ReactCountryFlag
                            countryCode={refuel.country}
                            svg
                            style={{
                              width: "1.25em",
                              height: "1.25em",
                              marginRight: "0.25em",
                            }}
                          />
                          <span className="text-base font-semibold">
                            {refuel.city}
                          </span>
                          <span className="text-xs text-gray-700">
                            - {refuel.title}
                          </span>
                        </div>
                      </div>

                      <div className="mt-2 grid grid-cols-3 gap-4 text-xs">
                        <p>
                          <span className="font-medium text-gray-600">
                            Fuel Added:
                          </span>{" "}
                          {refuel.fuel_add} l
                        </p>
                        <p>
                          <span className="font-medium text-gray-600">
                            Fuel Level:
                          </span>{" "}
                          {refuel.fuel_level} %
                        </p>
                        <p>
                          <span className="font-medium text-gray-600">
                            Mileage:
                          </span>{" "}
                          {refuel.mileage} km
                        </p>
                      </div>
                    </div>
                  ))}
                </CardContent>
              </Card>
            </div>
          </div>

          <div className="p-4">
            {route && (
              <div className="">
                <p className="text-gray-700">
                  {(route.summary.duration / 60 / 60).toFixed(1)} h
                </p>
              </div>
            )}
          </div>

          <p className="text-gray-700 pt-4">
            {t("delegation.orders")}: {delegation.stats.total_orders_count}
          </p>

          {delegation.stats.orders && (
            <Orders orders={delegation.stats.orders} />
          )}

          {delegation.stats.forwarder_incomes?.map((forwarderIncome, index) => (
            <Card key={index} variant="outlined" sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="h5" component="div">
                  {forwarderIncome.owner_assignee}
                </Typography>
                <Typography variant="h3" component="div" sx={{ mt: 1 }}>
                  {forwarderIncome.total_income}{" "}
                  <span className="text-sm">{getCurrencySymbol("eur")}</span>
                </Typography>
              </CardContent>
            </Card>
          ))}
          <div className="p-4">
            {" "}
            <Button
              onClick={onRecalculateMap}
              variant="contained"
              disabled={autofillLoading}
            >
              {t("delegation.recalculateMap")}
            </Button>
          </div>

          {delegation.stats &&
            delegation.stats.routes &&
            delegation.stats.locations && (
              <div className="px-28">
                <DelegationMap
                  routes={delegation.stats.routes}
                  orders={delegation.stats.orders}
                  locations={delegation.stats.locations}
                  refuels={delegation.stats.refuels}
                />
              </div>
            )}
        </div>
      )}
    </div>
  );
};

export default DelegationDetailContainer;
