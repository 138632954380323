import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { GRAPH_COLORS } from "../../../utils/colors";

interface AverageKmIncomePerTruckTypeProps {
  avg_incomes_per_truck_type: { [truck_type: string]: number };
}

const AverageKmIncomePerTruckType: React.FC<AverageKmIncomePerTruckTypeProps> = ({
  avg_incomes_per_truck_type,
}) => {
  const data = Object.keys(avg_incomes_per_truck_type).map((key, index) => {
    return {
      truck_type: key,
      income: avg_incomes_per_truck_type[key],
      color: GRAPH_COLORS[index % GRAPH_COLORS.length],
    };
  });
  data.sort((a, b) => b.income - a.income);
  return (
    <div>
      <h1 className="text-center">Average Income Per Truck Type</h1>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          width={600}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="truck_type"
            interval={0}
          />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="income">
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AverageKmIncomePerTruckType;
