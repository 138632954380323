import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { GRAPH_COLORS } from "../../../utils/colors";
import { useTranslation } from "react-i18next";

interface TruckIncome {
  truck: string;
  income: number;
}

interface MonthlyIncome {
  month: number;
  year: number;
  truck_incomes: TruckIncome[];
}

interface MonthlyIncomesGraphProps {
  monthlyIncomes: MonthlyIncome[];
}

const TruckIncomesGraph: React.FC<MonthlyIncomesGraphProps> = ({
  monthlyIncomes,
}) => {
  const { t } = useTranslation();
  const aggregatedData = monthlyIncomes.map((monthlyIncome) => {
    const incomeByCurrency: { [key: string]: number } = {};
    monthlyIncome.truck_incomes.forEach((income) => {
      if (incomeByCurrency[income.truck]) {
        incomeByCurrency[income.truck] += income.income;
      } else {
        incomeByCurrency[income.truck] = income.income;
      }
    });
    return {
      month: `${monthlyIncome.month}/${monthlyIncome.year}`,
      ...incomeByCurrency,
    };
  });

  const uniqueCurrencies = Array.from(
    new Set(
      monthlyIncomes.flatMap((monthlyIncome) =>
        monthlyIncome.truck_incomes.map((income) => income.truck)
      )
    )
  );

  return (
    <div>
      <h1 className="text-center">
        {t("insights.trucks.incomes.title")}
      </h1>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          width={600}
          height={300}
          data={aggregatedData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Legend />
          {uniqueCurrencies.map((currency, index) => (
            <Line
              type="monotone"
              dataKey={currency}
              stroke={GRAPH_COLORS[index % GRAPH_COLORS.length]}
              key={currency}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TruckIncomesGraph;
