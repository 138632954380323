
import { useRef, useState, useEffect } from "react";
import { TrackingOrderInfo } from "../../../types/orders";
import {  HereSection, RouteWithTracking } from "../../../types/routes";
import { TruckLocation } from "../../../types/truck";
import { generateTruckColors, createMap, getMarkerIcon, createTruckMarker, createOrderOperationMarker } from "../../Map/utils/maps";
import { createSectionsPolylines } from "../utils/slimPolyline";
import SlimMapBottomOverlay from "./SlimMapBottomOverlay";
import SlimTruckSideOverlay from "./SlimTruckSideOverlay";

interface MapComponentProps {
  order: TrackingOrderInfo;
  truck?: TruckLocation;
  hoveredRoute: RouteWithTracking | null;
  route?: RouteWithTracking;
  activeSection: HereSection | null;
  hoveredSection: HereSection | null;
  setHoveredRoute: (route: RouteWithTracking | null) => void;
  onSetActiveSection: (section: HereSection | null) => void;
  onSetHoveredSection: (section: HereSection | null) => void;
}
const MapComponent: React.FC<MapComponentProps> = ({
  order,
  truck,
  hoveredRoute,
  route,
  activeSection,
  hoveredSection,
  setHoveredRoute,
  onSetActiveSection,
  onSetHoveredSection,
}) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const map = useRef<H.Map | null>(null);
  const platform = useRef<H.service.Platform | null>(null);
  const truckMarkersGroup = useRef(new H.map.Group());
  const orderMarkersGroup = useRef(new H.map.Group());
  const routePolylinesGroups = useRef<Map<string, H.map.Group>>(new Map());
  const waypointsGroup = useRef(new H.map.Group());
  const hoveredRouteRef = useRef(hoveredRoute);
  const activeRouteRef = useRef(route);
  const activeSectionRef = useRef(activeSection);
  const hoveredSectionRef = useRef(hoveredSection);
  const [truckColors, setTruckColors] = useState<Record<string, string> | null>(null);
  const [truckColorsCreated, setTruckColorsCreated] = useState(false);

  useEffect(() => {
    if (!truck) return;
    setTruckColors(generateTruckColors([truck]));
    setTruckColorsCreated(true);
  }, [truck]);

  useEffect(() => {
    hoveredRouteRef.current = hoveredRoute;
    activeRouteRef.current = route;
    activeSectionRef.current = activeSection;
    hoveredSectionRef.current = hoveredSection;
  }, [hoveredRoute, route, activeSection, hoveredSection]);

  useEffect(() => {
    if (!map.current && mapRef && mapRef.current) {
      createMap(platform, mapRef, map);
    }
  }, [map, platform, mapRef]);

  useEffect(() => {
    if (
      map.current &&
      platform &&
      platform.current &&
      truckColorsCreated &&
      route
    ) {
      routePolylinesGroups.current.forEach((group) =>
        map.current?.removeObject(group)
      );
      routePolylinesGroups.current.clear();
      const routeGroup = new H.map.Group();
      const polylines = drawRoutePolylines(route);
      polylines.forEach((polyline) => routeGroup.addObject(polyline));
      routePolylinesGroups.current.set(route.id, routeGroup);
      map.current?.addObject(routeGroup);
      polylines.forEach((polyline) => routeGroup.addObject(polyline));
      routePolylinesGroups.current.set(route.id, routeGroup);
      map.current?.addObject(routeGroup);
    }
  }, [map, route, truckColorsCreated]);

  useEffect(() => {
    if (!map.current || !route) {
      return;
    }
    waypointsGroup.current.removeAll();

    route.waypoints.forEach((waypoint) => {
      const { latitude, longitude } = waypoint;
      const marker = new H.map.Marker(
        { lat: latitude, lng: longitude },
        {
          data: waypoint,
          icon: getMarkerIcon(waypoint.type, "#fff"),
        }
      );
      waypointsGroup.current.addObject(marker);
    });
    map.current.addObject(waypointsGroup.current);
  }, [map, route, activeSection, truckColors]);

  useEffect(() => {
    if (map.current && platform && platform.current && truck) {
      truckMarkersGroup.current.removeAll();
      const truckMarker = createTruckMarker(
        truck,
        map,
        truckColors ? truckColors[truck.truck] : "#fff",
      );
      truckMarkersGroup.current.addObject(truckMarker);
      map.current.addObject(truckMarkersGroup.current);
    }
  }, [map, truck, truckColors]);

  useEffect(() => {
    if (map.current && platform && platform.current && truck) {
      orderMarkersGroup.current.removeAll();

      let ordersToShow = [order];

      ordersToShow = [...ordersToShow];

      ordersToShow.forEach((order) => {
        order.operations.forEach((operation) => {
          const marker = createOrderOperationMarker(
            operation,
            map,
            truckColors ? truckColors[truck.truck] : "#fff",
            16
          );
          orderMarkersGroup.current.addObject(marker);
        });
      });
      map.current.addObject(orderMarkersGroup.current);
    }
  }, [map, order, truck, truckColors]);

  const drawRoutePolylines = (route: RouteWithTracking) => {
    return createSectionsPolylines(
      route,
      hoveredRoute,
      activeSection,
      hoveredSection,
      truckColors,
      activeRouteRef as any,
      activeSectionRef,
      hoveredSectionRef,
      routePolylinesGroups,
      setHoveredRoute,
      onSetHoveredSection,
      onSetActiveSection
    );
  };
  return (
    <div
      className="border border-black min-h-[90vh] max-h-screen-[90vh] relative "
      style={{ width: "100%", height: "600px" }}
      ref={mapRef}
    >
      {truck && (
        <SlimTruckSideOverlay truck={truck} truckColors={truckColors} />
      )}
      <SlimMapBottomOverlay route={route} activeSection={activeSection} />
    </div>
  );
};

export default MapComponent;
