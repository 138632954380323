import React from "react";
import StatusBadge from "../../components/statusBadge/StatusBadge";
import { ORDER_STATUS_FILTER_OPTIONS, OrderStatus } from "../../types/orders";
import { useTranslation } from "react-i18next";
import { MenuItem, SelectChangeEvent, TextField } from "@mui/material";

interface OrderFiltersProps {
  selectedOrderStatuses: string[];
  onSelectedOrderStatusesChange: (selectedOrderStatuses: string[]) => void;
}

const OrderFilters: React.FC<OrderFiltersProps> = ({
  selectedOrderStatuses,
  onSelectedOrderStatusesChange,
}) => {
  const { t } = useTranslation();

  const translatedOrderStatusOptions = ORDER_STATUS_FILTER_OPTIONS.map(
    (status) => ({
      value: status,
      label: t(`orders.status.${status}`),
    })
  );

  const handleSelectChange = (event: SelectChangeEvent<any>) => {
    const selectedValues = (event.target.value as string[]).map(
      (value) => value
    );
    onSelectedOrderStatusesChange(selectedValues);
  };

  return (
    <div>
      <div className="flex gap-4 min-w-48">
        <TextField
          select
          label={t("orders.orderStatus")}
          fullWidth
          variant="outlined"
          value={selectedOrderStatuses}
          SelectProps={{
            multiple: true,
            value: selectedOrderStatuses,
            onChange: handleSelectChange,
            renderValue: (selected: any) => (
              <div className="flex flex-wrap">
                {selected.map((value: any) => (
                  <StatusBadge key={value} status={value as OrderStatus} />
                ))}
              </div>
            )
          }}
        >
          {translatedOrderStatusOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <StatusBadge status={option.value as OrderStatus} />
            </MenuItem>
          ))}
        </TextField>
      </div>
    </div>
  );
};

export default OrderFilters;
