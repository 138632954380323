import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { TruckLocation } from "../types/truck";
import useAxios from "../hooks/useAxios";
import { OrderInfo } from "../types/orders";
import { generateTruckColors } from "../container/Map/utils/maps";
import { HereSection, Route } from "../types/routes";
import { useSearchParams } from "react-router-dom";
import { useToast } from "../hooks/useToast";
import { Waypoint as WaypointType } from "../types/geo";
import update from "immutability-helper";
import { DEFAULT_CURRENCY } from "../consts/currencies";

interface MapContextType {
  carrierTruckLocations: TruckLocation[];
  allMapOrders: OrderInfo[];
  truckColors: Record<string, string> | null;
  allMapRoutes: Route[];
  routeAlternatives: Route[];
  activeRoute: Route | null;
  selectedOrders: OrderInfo[];
  selectedTruck: TruckLocation | null;
  routePreviewLoading: boolean;
  excludeSwitzerland: boolean;
  hoveredRoute: Route | null;
  activeSection: HereSection | null;
  hoveredSection: HereSection | null;
  ordersToAddToRoute: OrderInfo[];
  waypoints: WaypointType[];
  startFromTruck: boolean;
  freightValue: number;
  selectedStartOrder: OrderInfo | null;
  selectedOrder: OrderInfo | null;
  currency: string;
  areWaypointsValid: boolean;
  waypointMessage: string;
  params: any;
  predefinedTruck: string | null;
  onSetSelectedTruck: (truck: TruckLocation | null) => void;
  onSetSelectedOrders: (orders: OrderInfo[]) => void;
  onMarkRouteAsCompleted: (route: Route) => void;
  onAssignRoute: (route: Route) => void;
  onSetActiveRoute: (route: Route | null) => void;
  onPreviewRoute: () => void;
  onRecalculateRoute: () => void;
  onAddOrderToRoute: (order: OrderInfo) => void;
  onRemoveOrderFromRoute: (order: OrderInfo) => void;
  onSetHoveredRoute: (route: Route | null) => void;
  onSetHoveredSection: (section: HereSection | null) => void;
  onSetActiveSection: (section: HereSection | null) => void;
  onSetExcludeSwitzerland: (exclude: boolean) => void;
  onSetSelectedStartOrder: (order: OrderInfo | null) => void;
  onSetSelectedOrder: (order: OrderInfo | null) => void;
  onSetWaypoints: (waypoints: WaypointType[]) => void;
  onSetStartFromTruck: (start: boolean) => void;
  onAddWaypoint: () => void;
  onSetFreightValue: (value: number) => void;
  onMoveWaypoint: (dragIndex: number, hoverIndex: number) => void;
  onRemoveWaypoint: (id: string) => void;
  onUpdateWaypoint: (id: string, waypoint: WaypointType) => void;
  onSetCurrency: (currency: string) => void;
  addOrdersToWaypoints: () => void;
  replaceWaypointsWithOrders: () => void;
  onSetPredefinedTruck: (truck: string) => void;
}

interface RouteCalcParams {
  truck: string;
  waypoints: WaypointType[];
  start_from_truck: boolean;
  freight_value: number;
  currency: string;
  order?: number | null;
  start_order?: number | null;
  exclude?: string;
}

interface RouteReorderParams {
  orders_to_add: number[];
  exclude?: string;
}

const MapContext = createContext<MapContextType>({
  carrierTruckLocations: [],
  allMapOrders: [],
  truckColors: null,
  allMapRoutes: [],
  routeAlternatives: [],
  activeRoute: null,
  selectedOrders: [],
  selectedTruck: null,
  routePreviewLoading: false,
  excludeSwitzerland: false,
  hoveredRoute: null,
  activeSection: null,
  hoveredSection: null,
  ordersToAddToRoute: [],
  waypoints: [],
  freightValue: 0,
  startFromTruck: false,
  selectedStartOrder: null,
  selectedOrder: null,
  currency: DEFAULT_CURRENCY,
  areWaypointsValid: false,
  waypointMessage: "",
  params: {},
  predefinedTruck: null,
  onSetSelectedTruck: () => {},
  onSetSelectedOrders: () => {},
  onMarkRouteAsCompleted: () => {},
  onAssignRoute: () => {},
  onSetActiveRoute: () => {},
  onPreviewRoute: () => {},
  onRecalculateRoute: () => {},
  onAddOrderToRoute: () => {},
  onRemoveOrderFromRoute: () => {},
  onSetHoveredRoute: () => {},
  onSetHoveredSection: () => {},
  onSetActiveSection: () => {},
  onSetExcludeSwitzerland: () => {},
  onSetSelectedStartOrder: () => {},
  onSetSelectedOrder: () => {},
  onSetWaypoints: () => {},
  onSetStartFromTruck: () => {},
  onSetFreightValue: () => {},
  onAddWaypoint: () => {},
  onMoveWaypoint: () => {},
  onRemoveWaypoint: () => {},
  onUpdateWaypoint: () => {},
  onSetCurrency: () => {},
  addOrdersToWaypoints: () => {},
  replaceWaypointsWithOrders: () => {},
  onSetPredefinedTruck: () => {},
});

interface MapProviderProps {
  children: React.ReactNode;
}

export const MapProvider: React.FC<MapProviderProps> = ({ children }) => {
  const [carrierTruckLocations, setCarrierTruckLocations] = useState<
    TruckLocation[]
  >([]);
  const [allMapRoutes, setAllMapRoutes] = useState<Route[]>([]);
  const [routeAlternatives, setRouteAlternatives] = useState<Route[]>([]);
  const [activeRoute, setActiveRoute] = useState<Route | null>(null);
  const [selectedOrders, setSelectedOrders] = useState<OrderInfo[]>([]);
  const [selectedTruck, setSelectedTruck] = useState<TruckLocation | null>(
    null
  );
  const [currency, setCurrency] = useState(DEFAULT_CURRENCY);
  const [ordersToAddToRoute, setOrdersToAddToRoute] = useState<OrderInfo[]>([]);
  const [excludeSwitzerland, setExcludeSwitzerland] = useState(false);
  const [routePreviewLoading, setRoutePreviewLoading] = useState(false);
  const [allMapOrders, setAllMapOrders] = useState<OrderInfo[]>([]);
  const [truckColors, setTruckColors] = useState<Record<string, string> | null>(
    null
  );
  const [hoveredRoute, setHoveredRoute] = useState<Route | null>(null);
  const [activeSection, setActiveSection] = useState<HereSection | null>(null);
  const [hoveredSection, setHoveredSection] = useState<HereSection | null>(
    null
  );
  const [areWaypointsValid, setAreWaypointsValid] = useState(false);
  const [waypointMessage, setWaypointMessage] = useState("");
  const [freightValue, setFreightValue] = useState(0);

  const [startFromTruck, setStartFromTruck] = useState(false);
  const [selectedStartOrder, setSelectedStartOrder] =
    useState<OrderInfo | null>(null);
  const [selectedOrder, setSelectedOrder] = useState<OrderInfo | null>(null);
  const [predefinedTruck, setPredefinedTruck] = useState<string | null>(null);

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [waypoints, setWaypoints] = useState<WaypointType[]>([
    {
      id: `id_${Date.now()}_${Math.random().toString(36)}`,
      time_begin: new Date(),
      time_end: new Date(),
      type: "loading",
      location: null,
    },
    {
      id: `id_${Date.now()}_${Math.random().toString(36)}`,
      time_begin: tomorrow,
      time_end: tomorrow,
      type: "unloading",
      location: null,
    },
  ]);

  const [params, setParams] = useSearchParams();
  const axios = useAxios();
  const toast = useToast();

  const onSetParams = (
    truck: TruckLocation | null,
    orders: OrderInfo[] | null,
    newFreightValue?: number | null,
    shouldExcludeSwitzerland?: boolean
  ) => {
    freightValue;

    const newParams = {} as any;
    if (truck) {
      newParams["truck"] = truck.truck;
    } else if (selectedTruck) {
      newParams["truck"] = selectedTruck.truck;
    }
    if (orders) {
      newParams["orders"] = orders.map((order) => order.id).join(",");
    } else if (selectedOrders.length > 0) {
      newParams["orders"] = selectedOrders.map((order) => order.id).join(",");
    }
    if (shouldExcludeSwitzerland !== undefined) {
      newParams["exclude"] = "CHE";
    } else {
      newParams["exclude"] = "";
    }
    if (newFreightValue !== undefined) {
      newParams["freight"] = newFreightValue;
    } else if (freightValue > 0) {
      newParams["freight"] = freightValue;
    }

    setParams(newParams);
  };

  const onSetCurrency = (currency: string) => {
    setCurrency(currency);
  };

  const onSetSelectedTruck = (truck: TruckLocation | null) => {
    setSelectedTruck(truck);
    setActiveRoute(null);
    setSelectedOrders([]);
    onSetParams(truck, null);
  };

  const onSetFreightValue = (value: number) => {
    setFreightValue(value);
    onSetParams(selectedTruck, null, value);
  };

  const onAddWaypoint = () => {
    const lastOperationDate = waypoints[waypoints.length - 1].time_end;
    const newOperationDate = new Date(lastOperationDate);
    newOperationDate.setDate(newOperationDate.getDate() + 1);
    const waypoint = {
      id: `id_${Date.now()}_${Math.random().toString(36)}`,
      time_begin: newOperationDate,
      time_end: newOperationDate,
      type: "unloading",
      location: null,
    };
    setWaypoints([...waypoints, waypoint]);
  };

  const onMoveWaypoint = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setWaypoints((prevWaypoints: WaypointType[]) =>
        update(prevWaypoints, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevWaypoints[dragIndex] as WaypointType],
          ],
        })
      );
    },
    []
  );

  const onRemoveWaypoint = (id: string) => {
    if (waypoints.length === 2) {
      return;
    }
    setWaypoints(
      waypoints.filter((waypoint: WaypointType) => waypoint.id !== id)
    );
  };
  const onUpdateWaypoint = (id: string, waypoint: WaypointType) => {
    const newWaypoints = waypoints.map((w: WaypointType) =>
      w.id === id ? waypoint : w
    );
    setWaypoints(newWaypoints);
  };

  const onSetWaypoints = (waypoints: WaypointType[]) => {
    setWaypoints(waypoints);
  };

  const onSetStartFromTruck = (start: boolean) => {
    setStartFromTruck(start);
    setSelectedStartOrder(null);
  };

  const onSetSelectedStartOrder = (order: OrderInfo | null) => {
    setSelectedStartOrder(order);
    if (order) {
      setStartFromTruck(false);
    }
  };

  const onSetSelectedOrder = (order: OrderInfo | null) => {
    setSelectedOrder(order);
    setFreightValue(order?.payment.price_value || 0);
    setCurrency(order?.payment.price_currency || DEFAULT_CURRENCY);
    if (order) {
      replaceWaypointsWithOrder(order);
    }
  };

  const onSetSelectedOrders = (orders: OrderInfo[]) => {
    if (activeRoute) {
      const routeOrders = orders.filter(
        (order) => order.assigned_route === activeRoute.id
      );
      setSelectedOrders(routeOrders);
      onSetParams(null, routeOrders);
    } else {
      const orderWithRoute = orders.find(
        (order) => order.assigned_route !== null
      );
      if (orderWithRoute) {
        const route = allMapRoutes.find(
          (r) => r.id === orderWithRoute.assigned_route
        );
        if (route) {
          setActiveRoute(route);
        }
      }
      setSelectedOrders(orders);
      onSetParams(null, orders);
    }
  };

  const onMarkRouteAsCompleted = (route: Route) => {
    axios
      .patch(`route/${route.id}/`, {
        is_completed: true,
      })
      .then(() => {
        setAllMapRoutes(allMapRoutes.filter((r) => r.id !== route.id));
        toast({
          message: "Route marked as completed",
          type: "success",
        });
      });
  };

  const onAssignRoute = (route: Route) => {
    axios
      .patch(`route/${route.id}/`, {
        is_selected_route: true,
      })
      .then(() => {
        setAllMapRoutes([...allMapRoutes, route]);
        setRouteAlternatives([]);
        setActiveRoute(null);
        setSelectedOrders([]);
        setSelectedTruck(null);
        toast({
          message: "Route assigned",
          type: "success",
        });
      });
  };

  const onSetActiveRoute = (route: Route | null) => {
    setActiveRoute(route);
    if (route === null) {
      setSelectedOrders([]);
      setRouteAlternatives([]);
      setOrdersToAddToRoute([]);
    } else {
      const truck = carrierTruckLocations.find(
        (truck) => truck.truck === route.license_plate
      );
      if (truck) {
        setSelectedTruck(truck);
      }
    }
  };

  const onPreviewRoute = () => {
    if (!selectedTruck) {
      toast({
        message: "Please select a truck",
        type: "error",
      });
      return;
    }
    setRoutePreviewLoading(true);
    const data: RouteCalcParams = {
      truck: selectedTruck.truck,
      start_from_truck: startFromTruck,
      start_order: selectedStartOrder?.id || null,
      order: selectedOrder?.id || null,
      waypoints: waypoints,
      exclude: excludeSwitzerland ? "CHE" : "",
      freight_value: freightValue,
      currency: currency,
    };
    axios
      .post(`route/preview/`, data)
      .then((response) => {
        if (response.data.length > 0) {
          setActiveRoute(response.data[0]);
          setRouteAlternatives(response.data);
        } else {
          toast({
            message: "No route found",
            type: "error",
          });
        }
      })
      .finally(() => {
        setRoutePreviewLoading(false);
      });
  };

  const onSetPredefinedTruck = (truck: string | null) => {
    if (truck) {
        setPredefinedTruck(truck);
        setSelectedTruck(null);
        onSetParams(null, null);
    }
    else{
        setPredefinedTruck(null);
    }
  }

  const onRecalculateRoute = () => {
    if (!activeRoute) {
      toast({
        message: "No route found",
        type: "error",
      });
      return;
    }
    setRoutePreviewLoading(true);

    const data: RouteReorderParams = {
      orders_to_add: ordersToAddToRoute.map((order) => order.id),
    };
    if (excludeSwitzerland) {
      data.exclude = "CHE";
    }

    axios
      .put(`route/${activeRoute?.id}/reorder/`, data)
      .then((response) => {
        if (response.data.length > 0) {
          setActiveRoute(response.data[0]);
          setRouteAlternatives(response.data);
          setSelectedOrders(selectedOrders.concat(ordersToAddToRoute));
          setOrdersToAddToRoute([]);
        } else {
          toast({
            message: "No route found",
            type: "error",
          });
        }
      })
      .finally(() => {
        setRoutePreviewLoading(false);
      });
  };

  const onAddOrderToRoute = (order: OrderInfo) => {
    setOrdersToAddToRoute([...ordersToAddToRoute, order]);
  };

  const onRemoveOrderFromRoute = (order: OrderInfo) => {
    setOrdersToAddToRoute(ordersToAddToRoute.filter((o) => o.id !== order.id));
  };

  const onSetHoveredRoute = (route: Route | null) => {
    setHoveredRoute(route);
  };

  const onSetHoveredSection = (section: HereSection | null) => {
    setHoveredSection(section);
  };

  const onSetActiveSection = (section: HereSection | null) => {
    setActiveSection(section);
  };

  const onSetExcludeSwitzerland = (exclude: boolean) => {
    setExcludeSwitzerland(exclude);
    onSetParams(null, null, undefined, exclude);
  };

  const addOrdersToWaypoints = () => {
    const newWaypoints: WaypointType[] = selectedOrders.flatMap((order) =>
      order.operations.map((operation) => ({
        id: `id_${Date.now()}_${Math.random().toString(36)}`,
        type: operation.operation_type,
        time_begin: new Date(operation.time_begin),
        time_end: new Date(operation.time_end),
        coordinates: {
          latitude: operation.latitude,
          longitude: operation.longitude,
        },
        location: operation.address
          ? operation.address
          : `${operation.locality}`,
      }))
    );
    const combinedWaypoints = [...waypoints, ...newWaypoints];
    combinedWaypoints.sort(
      (a, b) =>
        a.time_begin.getTime() - b.time_begin.getTime() ||
        a.time_end.getTime() - b.time_end.getTime()
    );
    setWaypoints(combinedWaypoints);
  };

  const replaceWaypointsWithOrder = (order: OrderInfo) => {
    const newWaypoints: WaypointType[] = order.operations.map((operation) => ({
      id: `id_${Date.now()}_${Math.random().toString(36)}`,
      type: operation.operation_type,
      time_begin: new Date(operation.time_begin),
      time_end: new Date(operation.time_end),
      coordinates: {
        latitude: operation.latitude,
        longitude: operation.longitude,
      },
      location: operation.address ? operation.address : `${operation.locality}`,
    }));
    newWaypoints.sort(
      (a, b) =>
        a.time_begin.getTime() - b.time_begin.getTime() ||
        a.time_end.getTime() - b.time_end.getTime()
    );
    setWaypoints(newWaypoints);
  };

  const replaceWaypointsWithOrders = () => {
    const newWaypoints: WaypointType[] = selectedOrders.flatMap((order) =>
      order.operations.map((operation) => ({
        id: `id_${Date.now()}_${Math.random().toString(36)}`,
        type: operation.operation_type,
        time_begin: new Date(operation.time_begin),
        time_end: new Date(operation.time_end),
        coordinates: {
          latitude: operation.latitude,
          longitude: operation.longitude,
        },
        location: operation.address
          ? operation.address
          : `${operation.locality}`,
      }))
    );
    newWaypoints.sort(
      (a, b) =>
        a.time_begin.getTime() - b.time_begin.getTime() ||
        a.time_end.getTime() - b.time_end.getTime()
    );
    setWaypoints(newWaypoints);
  };

  useEffect(() => {
    axios
      .get("telematics/locations/")
      .then((response) => {
        setCarrierTruckLocations(response.data);
        setTruckColors(generateTruckColors(response.data));
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axios
      .get("orders/?status=new,assigned,in_progress&no_pagination=true")
      .then((response) => {
        setAllMapOrders(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axios.get("route/").then((response) => {
      setAllMapRoutes(response.data);
    });
  }, []);

  useEffect(() => {
    if (selectedTruck) {
      const orderRoute = selectedOrders.find(
        (order) => order.assigned_route !== null
      );
      if (orderRoute) {
        const route = allMapRoutes.find(
          (route) => route.id === orderRoute.assigned_route
        );
        if (route) {
          setActiveRoute(route);
        }
      }
    } else {
      setActiveRoute(null);
    }
  }, [selectedTruck, selectedOrders, allMapRoutes]);

  useEffect(() => {
    let isValid = true;
    let hasLoading = false;
    let hasUnloading = false;
    let newWaypointsMessage = "";
    waypoints.forEach((waypoint) => {
      if (typeof waypoint.location !== "string" && (!waypoint.location?.latitude || !waypoint.location?.longitude)) {
        isValid = false;
        newWaypointsMessage = "invalid_coordinates";
      }
      if (waypoint.type === "loading") {
        hasLoading = true;
      }
      if (waypoint.type === "unloading") {
        hasUnloading = true;
      }
    });
    if (waypoints.length < 2) {
      isValid = false;
      newWaypointsMessage = "at_least_two";
    }
    if (!hasLoading || !hasUnloading) {
      isValid = false;
      newWaypointsMessage = "one_loading_one_unloading";
    }
    if (!selectedTruck) {
      isValid = false;
      newWaypointsMessage = "no_truck";
    }
    setWaypointMessage(newWaypointsMessage);
    setAreWaypointsValid(isValid);
  }, [waypoints, selectedTruck]);

  const value = useMemo(
    () => ({
      carrierTruckLocations,
      allMapOrders,
      truckColors,
      allMapRoutes,
      routeAlternatives,
      activeRoute,
      selectedOrders,
      selectedTruck,
      routePreviewLoading,
      excludeSwitzerland,
      hoveredRoute,
      activeSection,
      hoveredSection,
      ordersToAddToRoute,
      waypoints,
      freightValue,
      startFromTruck,
      selectedStartOrder,
      selectedOrder,
      currency,
      areWaypointsValid,
      waypointMessage,
      params,
      predefinedTruck,
      onSetSelectedTruck,
      onSetSelectedOrders,
      onMarkRouteAsCompleted,
      onAssignRoute,
      onSetActiveRoute,
      onPreviewRoute,
      onRecalculateRoute,
      onAddOrderToRoute,
      onSetFreightValue,
      onRemoveOrderFromRoute,
      onSetHoveredRoute,
      onSetHoveredSection,
      onSetActiveSection,
      onSetExcludeSwitzerland,
      onSetSelectedStartOrder,
      onSetSelectedOrder,
      onSetWaypoints,
      onSetStartFromTruck,
      onAddWaypoint,
      onMoveWaypoint,
      onRemoveWaypoint,
      onUpdateWaypoint,
      onSetCurrency,
      addOrdersToWaypoints,
      replaceWaypointsWithOrders,
      onSetPredefinedTruck,
    }),
    [
      carrierTruckLocations,
      allMapOrders,
      truckColors,
      allMapRoutes,
      routeAlternatives,
      activeRoute,
      selectedOrders,
      selectedTruck,
      routePreviewLoading,
      excludeSwitzerland,
      hoveredRoute,
      activeSection,
      hoveredSection,
      ordersToAddToRoute,
      waypoints,
      startFromTruck,
      selectedStartOrder,
      selectedOrder,
      currency,
      freightValue,
      areWaypointsValid,
      waypointMessage,
      params,
      predefinedTruck,
    ]
  );
  return <MapContext.Provider value={value}>{children}</MapContext.Provider>;
};

export const useMap = () => {
  return useContext(MapContext);
};
