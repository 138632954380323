import { Button } from "@mui/material";
import FormInput from "../../../components/input/FormInput";
import { BaseTruck } from "../../../types/truck";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface LoadingInfoFormProps {
  truck: BaseTruck;
  onSubmit: (data: TruckFormData) => void;
}

interface TruckFormData {
  vin: string;
  brand: string;
  model: string;
  parameters: {
    fuel_type: string;
    free_flow_speed_table: string;
    truck_type: string;
  };
  loading_info: {
    loading_width: number;
    loading_length: number;
    loading_height_min: number;
    loading_height_max: number;
    cargo_unit_max_weight: number;
    cargo_unit_max_height: number;
    cargo_volume: number;
    real_max_payload_weight: number;
  };
}

const LoadingInfoForm: React.FC<LoadingInfoFormProps> = ({
  truck,
  onSubmit,
}) => {
  const {t} = useTranslation();
  const { register, handleSubmit } = useForm<TruckFormData>({
    defaultValues: {
      vin: truck.vin,
      brand: truck.brand,
      model: truck.model,
      parameters: {
        fuel_type: truck.parameters?.fuel_type || "diesel",
        truck_type: truck.parameters?.truck_type || "straightTruck",
        free_flow_speed_table:
          truck.parameters?.free_flow_speed_table || "80,0.13,90,0.15",
      },
    },
  });

  const onFormSubmit = handleSubmit((data) => {
    onSubmit(data);
  });

  return (
    <form onSubmit={onFormSubmit} className="bg-neutral-300 dark:bg-zinc-800 p-4 border border-gray-400 text-black dark:text-white">
      <div className="flex justify-between items-center border-b p-2 my-2">
        <p className="text-lg font-bold">{truck.name}</p>
      </div>
      <div className="grid grid-cols-5 gap-4">
        <FormInput
          label="Loading Width (cm)"
          inputProps={{
            type: "number",
            ...register("loading_info.loading_width"),
          }}
        />
        <FormInput
          label="Loading Length (cm)"
          inputProps={{
            type: "number",
            ...register("loading_info.loading_length"),
          }}
        />
        <FormInput
          label="Loading Height Min (cm)"
          inputProps={{
            type: "number",
            ...register("loading_info.loading_height_min"),
          }}
        />
        <FormInput
          label="Loading Height Max (cm)"
          inputProps={{
            type: "number",
            ...register("loading_info.loading_height_max"),
          }}
        />
        <FormInput
          label="Cargo Unit Max Weight (kg)"
          inputProps={{
            type: "number",
            ...register("loading_info.cargo_unit_max_weight"),
          }}
        />
        <FormInput
          label="Cargo Unit Max Height (cm)"
          inputProps={{
            type: "number",
            ...register("loading_info.cargo_unit_max_height"),
          }}
        />
        <FormInput
          label="Cargo Volume (cm3)"
          inputProps={{
            type: "number",
            ...register("loading_info.cargo_volume"),
          }}
        />
        <FormInput
          label="Real Max Payload Weight (kg)"
          inputProps={{
            type: "number",
            ...register("loading_info.real_max_payload_weight"),
          }}
        />
      </div>
      <Button variant="contained" type="submit" className="py-2 mt-2">
        {t('common.save')}
      </Button>
    </form>
  );
};

export default LoadingInfoForm;
