import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { GRAPH_COLORS } from "../../../utils/colors";
import NoDataAvailable from "../NoData";

interface ForwardersIncome {
  email: string;
  total_income: number;
}

interface MonthlyForwarderIncomes {
  month: number;
  year: number;
  forwarders: ForwardersIncome[];
}

interface MonthlyExpensesGraphProps {
  monthlyForwarderIncomes: MonthlyForwarderIncomes[];
}

const MonthlyForwardersGraph: React.FC<MonthlyExpensesGraphProps> = ({
  monthlyForwarderIncomes,
}) => {
  const aggregatedData = monthlyForwarderIncomes.map((monthlyIncome) => {
    const incomeByForwarder: { [key: string]: number } = {};
    monthlyIncome.forwarders.forEach((forwarder) => {
      if (incomeByForwarder[forwarder.email]) {
        incomeByForwarder[forwarder.email] +=
          forwarder.total_income;
      } else {
        incomeByForwarder[forwarder.email] =
          forwarder.total_income;
      }
    });
    return {
      month: `${monthlyIncome.month}/${monthlyIncome.year}`,
      ...incomeByForwarder,
    };
  });

  const uniqueForwarders = Array.from(
    new Set(
      monthlyForwarderIncomes.flatMap((monthlyIncome) =>
        monthlyIncome.forwarders.map((expense) => expense.email)
      )
    )
  );
  return (
    <div>
      <h1>Monthly Forwarder Incomes By Forwarder</h1>
      {aggregatedData.length === 0 ? (
        <NoDataAvailable message="No forwarder incomes available." />
      ) : (
        <ResponsiveContainer width="100%" height={400}>
          <LineChart
            width={600}
            height={300}
            data={aggregatedData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Legend />
            {uniqueForwarders.map((forwarder, index) => (
              <Line
                type="monotone"
                dataKey={forwarder}
                stroke={GRAPH_COLORS[index % GRAPH_COLORS.length]}
                key={forwarder}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default MonthlyForwardersGraph;
