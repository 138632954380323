import { useTranslation } from "react-i18next";
import { OrderInfo } from "../../../types/orders";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

interface MaintenanceIncomingProps {
  data: {
    orders: OrderInfo[];
  };
}

const NotInvoicedOrders: React.FC<MaintenanceIncomingProps> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex items-center justify-between">
        <div>
          <h3 className="font-semibold">{t("dashboard.notInvoicedOrders")}</h3>
        </div>
      </div>
      <div className="mt-4">
        <Link
          to={`/orders/${data.orders[0].id}&tab=payment&recommendation=not_invoiced`}
        >
          <Button variant="contained" color="primary">
            {t("dashboard.notInvoicedOrders")}
          </Button>
        </Link>
      </div>
    </>
  );
};

export default NotInvoicedOrders;
