import { PERMISSIONS } from "../consts/permissions";

export const hasAccess = (role: string, module: string, action: string) => {
  const permissions = PERMISSIONS[role as keyof typeof PERMISSIONS];
  if (!permissions) {
    return false;
  }
  const modulePermissions = permissions[module as keyof typeof permissions];
  if (!modulePermissions) {
    return false;
  }
  return modulePermissions.includes(action);
};
