import { CircularProgress } from "@mui/material";
import NoData from "../NoData";
import CountryFlag from "react-country-flag";
import { formatOrderOperationTime } from "../../utils/time";
import { getCurrencySymbol } from "../../utils/currencies";
import { format } from "date-fns-tz";

interface Column {
  type: string;
  name: string;
  label: string;
}

interface TableProps {
  columns: Array<Column>;
  data: Array<any>;
  loading?: boolean;
  onRowClick: (row: any) => void;
}

const renderCell = (type: string, data: any) => {
  switch (type) {
    case "operation":
      return (
        <>
          <div className="flex items-center space-x-1 font-bold">
            <CountryFlag
              countryCode={data.operation.country}
              svg
              style={{
                width: "1em",
                height: "1em",
                borderRadius: "50%",
              }}
            />
            <p className="uppercase">
              {" "}
              {data.operation.country}
              {data.operation.postal_code.substring(0, 2)}
            </p>
            <p> {data.operation.locality}</p>
          </div>
          <p className="text-xs">{data.operation.address}</p>
          {formatOrderOperationTime(data.operation)}
          {data.distance !== null && (
            <p className="text-xs">{data.distance.toFixed(1)} km</p>
          )}
        </>
      );
    case "information":
      return (
        <>
          <p className="text-xs">{data.estimatedDistance} km</p>
          <p className="text-xs">
            {data.estimatedPricePerKm.toFixed(2)}
            {getCurrencySymbol(data.currency)}/km
          </p>
          <p className="text-xs">{data.truckTypes.join(", ")}</p>
          <p className="text-md font-semibold">{data.capacity} t</p>
          {data.ldm && <p className="text-xs">{data.ldm} LDM</p>}
        </>
      );
    case "price":
      return (
        <span className="font-semibold text-sm italic">
          {data.amount.toFixed(2)}
          {getCurrencySymbol(data.currency)}
        </span>
      );

    case "dates":
      return (
        <>
          <p className="text-md">
            {format(new Date(data.createdAt), "dd/MM/yyyy HH:mm")}
          </p>
        </>
      );
    case "shipper":
      return data.shipper.name;
    case "actions":
      return <div>Actions</div>;
    default:
      return type;
  }
};

const Table: React.FC<TableProps> = ({ columns, data, loading, onRowClick}) => {
  return (
    <div className="overflow-x-auto w-full">
      <div className="bg-white dark:bg-zinc-800 shadow-xl rounded-2xl w-full">
        <div
          className={`bg-zinc-900 dark:bg-secondary dark:text-black grid grid-cols-${columns.length}`}
        >
          {columns.map((column, index) => (
            <div key={index} className="text-left p-4">
              {column.label}
            </div>
          ))}
        </div>
        {loading && (
          <div className="text-gray-600">
            <div>
              <div className="p-4 text-center">
                <CircularProgress />
              </div>
            </div>
          </div>
        )}
        {!data.length && !loading && (
          <div className="text-gray-600">
            <div>
              <div className="p-4 text-center">
                <NoData />
              </div>
            </div>
          </div>
        )}
        {!loading &&
          data.map((row, index) => (
            <div
              onClick={() => onRowClick(row)}
              key={index}
              className={`text-gray-600 border-b hover:bg-gray-300 transition duration-150 ease-in-out grid grid-cols-${columns.length} cursor-pointer`}
            >
              {columns.map((column, index) => (
                <div key={index} className="px-4 py-3 text-left">
                  {renderCell(column.type, row[column.name])}
                </div>
              ))}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Table;
