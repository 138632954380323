export const OFFER_TYPES = ["full_truck_load", "less_than_truck_load"];

export const OFFER_TRUCK_TYPES = ["solo", "bus", "semi", "vehicle"];

export const OFFER_TRUCK_BODY_TYPES = [
  "refrigerator",
  "curtainsider",
  "tipper",
  "container",
  "tanker",
  "car_transporter",
];

export const OFFER_SEARCH_DISTANCES = [5, 10, 20, 50, 75, 100, 150, 200, 300, 400, 500, 1000];
