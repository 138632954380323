import { Checkbox, FormControlLabel } from "@mui/material";
import { t } from "i18next";
import { useMap } from "../../../../contexts/MapContext";

const ExcludeInput = () => {
  const { excludeSwitzerland, onSetExcludeSwitzerland } = useMap();
  return (
    <div className="flex items-center gap-2">
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={excludeSwitzerland}
            defaultChecked={excludeSwitzerland}
            value={excludeSwitzerland}
            onChange={(e) => onSetExcludeSwitzerland(e.target.checked)}
          />
        }
        labelPlacement="start"
        label={t("map.excludeSwitzerland")}
      />
    </div>
  );
};
export default ExcludeInput;
