import { Route } from "../types/routes";

export const calculateRouteDifference = (
  routeA: Route,
  routeB: Route
) => {
  const routeATimeDays = Math.floor(routeA.summary.duration / 86400);
  const routeATimeHours = Math.floor((routeA.summary.duration % 86400) / 3600);
  const routeATimeMinutes = Math.floor(
    ((routeA.summary.duration % 86400) % 3600) / 60
  );

  const routeBTimeDays = Math.floor(routeB.summary.duration / 86400);
  const routeBTimeHours = Math.floor((routeB.summary.duration % 86400) / 3600);
  const routeBTimeMinutes = Math.floor(
    ((routeB.summary.duration % 86400) % 3600) / 60
  );

  const difference = {
    totalDistanceDifference: routeA.summary.distance - routeB.summary.distance,
    totalDurationDifference: routeA.summary.duration - routeB.summary.duration,
    totalTimeDaysDifference: routeATimeDays - routeBTimeDays,
    totalTimeHoursDifference: routeATimeHours - routeBTimeHours,
    totalTimeMinutesDifference: routeATimeMinutes - routeBTimeMinutes,
    totalTollsDifference: routeA.summary.total_tolls - routeB.summary.total_tolls,
  };
  return {
    difference: difference,
  };
};
