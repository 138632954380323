import { useTranslation } from "react-i18next";
import { Subscription } from "../../../types/subscription";
import { Button } from "@mui/material";

interface MaintenanceIncomingProps {
  data: Subscription;
}

const SubscriptionEnding: React.FC<MaintenanceIncomingProps> = ({
  data,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex items-center justify-between">
        <div>
          <h3 className="font-semibold">{t("dashboard.incomingExpense.date")}</h3>
          <p>{ data.is_in_trial ? `${t("dashboard.subscriptionEnding.trial")} ${new Date(data.trial_end)}` : `${t("dashboard.subscriptionEnding.end")} ${new Date(data.current_period_end)}` }
            </p>
        </div>
        <div>
          <Button variant="contained" color="primary">
            {t("dashboard.subscriptionEnding.renew")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default SubscriptionEnding;
