import React from "react";
import { Payment } from "../../../types/orders";
import { Typography } from "@mui/material";
import { getCurrencySymbol } from "../../../utils/currencies";
import { useTranslation } from "react-i18next";

interface PaymentDetailsProps {
  payment: Payment;
}

const PaymentDetails: React.FC<PaymentDetailsProps> = ({ payment }) => {
  const { t } = useTranslation();
  return (
    <div className="p-4 border border-neutral-300 shadow-lg">
      <p className="text-3xl font-bold">
        {t("trackingOrderDetails.paymentDetails")}
      </p>{" "}
      <div className="w-full border-b border-neutral-400 my-4 opacity-70" />
      <Typography>
        {t("trackingOrderDetails.paymentAmount")}: {payment.price_value}{" "}
        {getCurrencySymbol(payment.price_currency)}
      </Typography>
      <Typography>
        {t("trackingOrderDetails.paymentTerms")}: {payment.days}
        {t("trackingOrderDetails.paymentDays")}
      </Typography>
    </div>
  );
};

export default PaymentDetails;
