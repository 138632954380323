import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  CircularProgress,
} from "@mui/material";
import { useState, useEffect, useMemo } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { DEFAULT_CURRENCY } from "../../../consts/currencies";
import { DEFAULT_COUNTRY } from "../../../consts/countries";
import {
  ORDER_CREATE_STEPS,
  OrderCreatedByAi,
  OrderFormData,
} from "../../../types/orders";
import useAxios from "../../../hooks/useAxios";
import { useTranslation } from "react-i18next";
import OrderCreateSummary from "./OrderCreateSummary";
import OrderCreateStepOne from "./OrderCreateStepOne";
import OrderCreateStepTwo from "./OrderCreateStepTwo";
import OrderCreateStepThree from "./OrderCreateStepThree";
import { useStrider } from "../../../contexts/StriderContext";
import { CorporateDetails } from "../../../types/base";
import DragDropInput from "../../../components/input/DragDropInput";
import { FaHandPaper, FaRobot } from "react-icons/fa";
import OrderCreateWithAI from "./OrderCreateWithAI";

interface OrderCreateProps {
  isOpen: boolean;
  onSubmit: (data: any) => void;
  onClose: () => void;
}

const OrderCreate: React.FC<OrderCreateProps> = ({
  isOpen,
  onSubmit,
  onClose,
}) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedOperationIndex, setSelectedOperationIndex] = useState(0);
  const [option, setOption] = useState<"manual" | "ai" | null>(null);
  const translatedSteps = useMemo(
    () =>
      ORDER_CREATE_STEPS.map((step) => ({
        label: t(`orders.orderCreate.${step}`),
        value: step,
      })),
    [t]
  );
  const axios = useAxios();
  const { carriers, shippers } = useStrider();

  const handleDialogClose = () => {
    onClose();
    setActiveStep(0);
    reset();
    setOption(null);
    setAiResult(null);
  };

  const handleNext = () => {
    if (isStepValid()) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmitOrder = async(data: OrderFormData) => {
    const payload = {
      ...data,
      operations: data.operations.map((operation) => ({
        ...operation,
        address:
          typeof operation.address === "string"
            ? operation.address
            : operation.address?.title || "",
      })),
    };
    await onSubmit(payload);
    handleDialogClose();
  };

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const { getValues, watch, handleSubmit, setValue, reset, control } =
    useForm<OrderFormData>({
      defaultValues: {
        distance: 0,
        payment: {
          price_value: 0,
          days: 0,
          price_currency: DEFAULT_CURRENCY,
        },
        operations: [
          {
            operation_type: "loading",
            time_begin: new Date().toISOString(),
            time_end: new Date().toISOString(),
            latitude: null,
            longitude: null,
            country: DEFAULT_COUNTRY,
            address: "",
          },
          {
            operation_type: "unloading",
            time_begin: tomorrow.toISOString(),
            time_end: tomorrow.toISOString(),
            latitude: null,
            longitude: null,
            country: DEFAULT_COUNTRY,
            address: "",
          },
        ],
      },
    });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "operations",
  });

  const ownOrder = watch("own_order");
  const carrierId = watch("carrier_id");

  useEffect(() => {
    const today = new Date();
    if (fields.length < 2) {
      append({
        operation_type: "unloading",
        time_begin: today.toISOString(),
        time_end: today.toISOString(),
        latitude: null,
        longitude: null,
        country: DEFAULT_COUNTRY,
        address: "",
      });
    }
  }, [fields.length, append]);

  const selectedCountry = watch(`operations.${selectedOperationIndex}.country`);

  const shipperIdWatch = watch("shipper_id");
  const carrierIdWatch = watch("carrier_id");
  const truckIdWatch = watch("truck_id");
  const operationsWatch = watch("operations");
  const [aiResult, setAiResult] = useState<any>(null);
  const [aiLoading, setAiLoading] = useState(false);

  const validateOperations = () => {
    const errors = operationsWatch.map((operation, index) => {
      let error = "";
      if (!operation.operation_type)
        error += `Operation type is missing for operation ${index + 1}. `;
      if (!operation.time_end)
        error += `End time is missing for operation ${index + 1}. `;
      if (!operation.time_begin)
        error += `Start time is missing for operation ${index + 1}. `;
      if (operation.latitude === null)
        error += `Latitude is missing for operation ${index + 1}. `;
      if (operation.longitude === null)
        error += `Longitude is missing for operation ${index + 1}. `;
      if (!operation.country)
        error += `Country is missing for operation ${index + 1}. `;
      if (!operation.address)
        error += `Address is missing for operation ${index + 1}. `;
      return error;
    });
    return errors.every((error) => error === "");
  };

  const isStepValid = () => {
    switch (activeStep) {
      case 0:
        return shipperIdWatch && (carrierIdWatch || ownOrder) && truckIdWatch;
      case 1:
        return true;
      case 2:
        return validateOperations();
      default:
        return true;
    }
  };

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <OrderCreateStepOne
            ownOrder={ownOrder}
            carrierId={carrierId}
            shippers={shippers}
            carriers={carriers}
            onAddShipper={(newShipper: CorporateDetails) => {
              setValue("shipper_id", newShipper.id);
            }}
            control={control}
            setValue={setValue}
          />
        );
      case 1:
        return <OrderCreateStepTwo control={control} />;
      case 2:
        return (
          <OrderCreateStepThree
            control={control}
            append={append}
            remove={remove}
            fields={fields}
            selectedCountry={selectedCountry}
            selectedOperationIndex={selectedOperationIndex}
            setSelectedOperationIndex={(index: number) =>
              setSelectedOperationIndex(index)
            }
            setValue={setValue}
          />
        );
      case 3:
        return (
          <OrderCreateSummary
            control={control}
            fields={fields}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
        );
      default:
        return null;
    }
  };

  const handleAiResult = (data: OrderCreatedByAi) => {
    setValue("distance", data.distance ? data.distance / 1000 : 0);
    if (data.carrier_id) {
      setValue("carrier_id", data.carrier_id);
    }
    if (data.shipper_id) {
      setValue("shipper_id", data.shipper_id);
    }
    if (data.vehicle) {
      setValue("truck_id", data.vehicle);
    }
    if (data.driver_id) {
      setValue("driver", data.driver_id);
    }
    if (data.payment?.amount) {
      setValue("payment.price_value", data.payment.amount);
    }
    if (data.payment?.days) {
      setValue("payment.days", data.payment.days);
    }
    if (data.payment?.currency) {
      setValue("payment.price_currency", data.payment.currency.toLowerCase());
    }
    if (data.operations) {
      const operations = data.operations.map((operation) => ({
        ...operation,
        country: operation.country?.toLowerCase() || DEFAULT_COUNTRY,
      }));
      setValue("operations", operations);
    }
    if (data.ref_number) {
      setValue("ref_number", data.ref_number);
    }
    if (data.is_own) {
      setValue("own_order", data.is_own);
    }
    if (data.job_id) {
      setValue("job_id", data.job_id);
    }
    setValue("own_order", data.is_own || false);
  };

  const onReadFileWithAI = async (files: FileList) => {
    const file = files[0];

    const reader = new FileReader();
    reader.onload = async () => {
      setAiLoading(true);
      try {
        const fileContent = reader.result;
        const newDocument = {
          file: {
            name: file.name,
            base64: fileContent,
          },
        };
        const response = await axios.post(
          "/orders/read-transport-file/",
          newDocument
        );
        handleAiResult(response.data);
        setAiResult(response.data);
        console.log("AI result", response.data);
      } finally {
        setAiLoading(false);
      }
    };
    reader.readAsDataURL(file);
    console.log("File uploaded successfully");
  };

  const renderDialogContent = () => {
    if (option === "ai" && aiResult !== null) {
      return (
        <OrderCreateWithAI
          data={aiResult}
          control={control}
          watch={watch}
          setValue={setValue}
          append={append}
          remove={remove}
          fields={fields}
          selectedCountry={selectedCountry}
          selectedOperationIndex={selectedOperationIndex}
          setSelectedOperationIndex={setSelectedOperationIndex}
        />
      );
    }
    if (option === "ai" && aiLoading) {
      return (
        <div>
          {t("orders.orderCreate.aiLoading")}
          <CircularProgress />
        </div>
      );
    }
    if (option === "ai") {
      return (
        <DragDropInput
          handleChange={onReadFileWithAI}
          types={[
            "PDF",
            "DOC",
            "DOCX",
            "ODT",
            "TXT",
            "RTF",
            "PNG",
            "JPG",
            "JPEG",
          ]}
          label={t("orders.orderCreate.uploadFileWithAI")}
          name={t("orders.orderCreate.file")}
          uploading={false}
          multiple={true}
        />
      );
    }
    if (option === "manual") {
      return (
        <Stepper activeStep={activeStep} orientation="vertical">
          {translatedSteps.map((step, index) => (
            <Step key={step.value}>
              <StepLabel>{step.label}</StepLabel>
              <StepContent>
                {renderStepContent(index)}
                <div className="flex gap-2 mt-4">
                  <Button
                    variant="contained"
                    type="button"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  >
                    {t("orders.orderCreate.prev")}
                  </Button>
                  <Button
                    variant="contained"
                    type="button"
                    onClick={handleNext}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    disabled={
                      !isStepValid() ||
                      activeStep === translatedSteps.length - 1
                    }
                  >
                    {t("orders.orderCreate.next")}
                  </Button>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      );
    }
    return (
      <div className="flex flex-row gap-4 items-stretch">
        <Button
          variant="contained"
          onClick={() => setOption("manual")}
          style={{
            backgroundColor: "#3f51b5",
            color: "white",
            padding: "10px 20px",
            fontSize: "16px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <FaHandPaper size={24} style={{ marginRight: "8px" }} />
          {t("orders.orderCreate.manual")}
        </Button>
        <Button
          variant="contained"
          onClick={() => setOption("ai")}
          style={{
            backgroundColor: "#3f51b5",
            color: "white",
            padding: "10px 20px",
            fontSize: "16px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <FaRobot size={24} style={{ marginRight: "8px" }} />
          {t("orders.orderCreate.ai")}
        </Button>
      </div>
    );
  };

  return (
    <>
      <Dialog open={isOpen} onClose={handleDialogClose}>
        <DialogTitle>{t("orders.orderCreateButton")}</DialogTitle>
        <DialogContent style={{ width: "600px" }}>
          {renderDialogContent()}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>
            {t("orders.orderCreate.cancel")}
          </Button>
          {option && (
            <Button
              variant="contained"
              type="button"
              onClick={handleSubmit(handleSubmitOrder)}
              disabled={
                option === "manual" &&
                (activeStep !== translatedSteps.length - 1 || !isStepValid())
              }
            >
              {t("orders.orderCreate.create")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OrderCreate;
