import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { Button, TextField } from "@mui/material";
import { Driver } from "../../types/driver";

interface DriverFormData {
  first_name: string;
  last_name: string;
  phone: string;
}
interface DriverBaseDetailsFormProps {
  driver: Driver;
  onSubmit: (data: DriverFormData) => void;
}

const DriverBaseDetailsForm: React.FC<DriverBaseDetailsFormProps> = ({
  driver,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const { handleSubmit, control } = useForm<DriverFormData>({
    defaultValues: {
      first_name: driver.first_name || "",
      last_name: driver.last_name || "",
      phone: driver.phone || "",
    },
  });
  return (
    <form
      className="flex flex-col space-y-4 px-64"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="first_name"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("driverDetail.general.firstName")}
            variant="outlined"
          />
        )}
      />
      <Controller
        name="last_name"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("driverDetail.general.lastName")}
            variant="outlined"
          />
        )}
      />
      <TextField
        disabled
        label={t("driverDetail.general.email")}
        variant="outlined"
        value={driver.email}
      />
      <Controller
        name="phone"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("driverDetail.general.phone")}
            variant="outlined"
          />
        )}
      />
      <Button variant="contained" color="primary" type="submit">
        {t("common.save")}
      </Button>
    </form>
  );
};

export default DriverBaseDetailsForm;
