import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CURRENCIES, DEFAULT_CURRENCY } from "../../consts/currencies";
import { OfferFormData } from "../../types/offers";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import { ORDER_OPERATION_TYPES } from "../../types/orders";
import { MdDelete, MdArrowUpward, MdArrowDownward } from "react-icons/md"; // Import arrow icons
import { DateTimePicker } from "@mui/x-date-pickers";
import GeoAutocomplete from "../../components/geoAutocomplete/GeoAutocomplete";
import { useExchange } from "../../contexts/ExchangeContext";
import { getCurrencySymbol } from "../../utils/currencies";
import {
  OFFER_TRUCK_BODY_TYPES,
  OFFER_TRUCK_TYPES,
  OFFER_TYPES,
} from "../../consts/offers";
import { useState } from "react";

interface AddOfferFormProps {
  onClose: () => void;
}

const AddOfferForm: React.FC<AddOfferFormProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const today = new Date();
  const todayPlus4Hours = new Date(today);
  todayPlus4Hours.setHours(today.getHours() + 2);
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  const { addFreightOffer } = useExchange();

  const onAddOffer = async(data: OfferFormData) => {
    try{
      setLoading(true);
    await addFreightOffer(data);
    onClose();
    } finally {
      setLoading(false);
    }
  };

  const { control, handleSubmit, setValue } = useForm<OfferFormData>({
    defaultValues: {
      price_currency: DEFAULT_CURRENCY,
      price_value: 0,
      type: "",
      description: "",
      valid_until: todayPlus4Hours.toISOString(),
      operations: [
        {
          operation_type: "loading",
          time_begin: todayPlus4Hours.toISOString(),
          time_end: todayPlus4Hours.toISOString(),
          suggestion: null,
        },
        {
          operation_type: "unloading",
          time_begin: tomorrow.toISOString(),
          time_end: tomorrow.toISOString(),
          suggestion: null,
        },
      ],
      truck_types: [],
      truck_body_types: [],
    },
  });

  const { fields, append, remove, swap } = useFieldArray({
    control,
    name: "operations",
  });


  return (
    <form onSubmit={handleSubmit(onAddOffer)} className="p-4">
      <div className="grid grid-cols-12 gap-4 py-3 px-1 w-full">
        <Controller
          name="price_value"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("exchange.addOfferModal.priceValue")}
              type="number"
              required
              sx={{ gridColumn: "span 2" }}
            />
          )}
        />
        <Controller
          name="price_currency"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              select
              label={t("exchange.addOfferModal.priceCurrency")}
              variant="outlined"
              required
            >
              {CURRENCIES.map((currency) => (
                <MenuItem key={currency} value={currency}>
                  {getCurrencySymbol(currency)}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              select
              label={t("exchange.addOfferModal.offerType")}
              required
            >
              {OFFER_TYPES.map((offerType) => (
                <MenuItem key={offerType} value={offerType}>
                  {t(`offerTypes.${offerType}`)}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("exchange.addOfferModal.description")}
              placeholder={t("exchange.addOfferModal.descriptionPlaceholder")}
              multiline
              rows={4}
              sx={{ gridColumn: "span 12" }}
            />
          )}
        />
        <Controller
          name="valid_until"
          control={control}
          render={({ field }) => (
            <DateTimePicker
              {...field}
              value={field.value ? new Date(field.value) : null}
              label={t("exchange.addOfferModal.validUntil")}
              sx={{ gridColumn: "span 2" }}
            />
          )}
        />
      </div>
      <p className="text-lg font-semibold py-3 px-1">
        {t("exchange.addOfferModal.requirements")}
      </p>
      <div className="grid grid-cols-12 gap-4 py-3 px-1 w-full">
        <FormControl
          component="fieldset"
          className="mb-4"
          sx={{ gridColumn: "span 2" }}
          required
        >
          <FormGroup>
            {OFFER_TRUCK_TYPES.map((requirement) => (
              <Controller
                key={requirement}
                name="truck_types"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value.includes(requirement)}
                        onChange={() => {
                          const newValue = field.value.includes(requirement)
                            ? field.value.filter(
                                (id: string) => id !== requirement
                              )
                            : [...field.value, requirement];
                          field.onChange(newValue);
                        }}
                      />
                    }
                    label={t(`offerRequirements.${requirement}`)}
                  />
                )}
              />
            ))}
          </FormGroup>
        </FormControl>
        <Controller
          name="truck_body_types"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              select
              label={t("exchange.addOfferModal.truckBodyType")}
              variant="outlined"
              fullWidth
              required
              sx={{ gridColumn: "span 2" }}
            >
              {OFFER_TRUCK_BODY_TYPES.map((type) => (
                <MenuItem key={type} value={type}>
                  {t(`truckBodyTypes.${type}`)}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <Controller
          name="capacity"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("exchange.addOfferModal.capacity")}
              type="number"
              required
              sx={{ gridColumn: "span 2" }}
              InputProps={{
                endAdornment: <InputAdornment position="end">t</InputAdornment>,
              }}
            />
          )}
        />
        <Controller
          name="ldm"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("exchange.addOfferModal.ldm")}
              type="number"
              sx={{ gridColumn: "span 2" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">ldm</InputAdornment>
                ),
              }}
            />
          )}
        />
      </div>

      <p className="text-lg font-semibold py-3 px-1">
        {t("exchange.addOfferModal.operations")}
      </p>
      {fields.map((field, index) => (
        <div
          key={field.id}
          className={`flex items-center justify-between space-x-4 py-3 px-1 w-full cursor-pointer rounded-lg transition-colors duration-200`}
        >
          <div className="flex">
            <IconButton
              size="small"
              onClick={() => swap(index, index - 1)}
              disabled={index === 0} // Disable swap up for the first item
            >
              <MdArrowUpward />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => swap(index, index + 1)}
              disabled={index === fields.length - 1} // Disable swap down for the last item
            >
              <MdArrowDownward />
            </IconButton>
          </div>
          <div className="w-1/3">
            <Controller
              name={`operations.${index}.operation_type`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label={t("orders.orderCreate.operationType")}
                  variant="outlined"
                  fullWidth
                >
                  {ORDER_OPERATION_TYPES.map((type) => (
                    <MenuItem key={type} value={type}>
                      <div className="flex items-center gap-2">
                        {t(`operationTypes.${type}`)}
                      </div>
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </div>
          <div className="w-1/3">
            <GeoAutocomplete
              size="medium"
              value={field.suggestion}
              label={t("waypoints.location")}
              onChange={(location) => {
                if (typeof location !== "string") {
                  setValue(`operations.${index}.suggestion`, location, {
                    shouldValidate: true,
                  });
                }
              }}
            />
          </div>
          <div className="w-1/3">
            <Controller
              name={`operations.${index}.time_begin`}
              control={control}
              render={({ field }) => (
                <DateTimePicker
                  {...field}
                  format="yyyy-MM-dd HH:mm"
                  value={field.value ? new Date(field.value) : null}
                  label={t("exchange.addOfferModal.timeBegin")}
                  slotProps={{ textField: { fullWidth: true } }}
                />
              )}
            />
          </div>
          <div className="w-1/3">
            <Controller
              name={`operations.${index}.time_end`}
              control={control}
              render={({ field }) => (
                <DateTimePicker
                  {...field}
                  format="yyyy-MM-dd HH:mm"
                  value={field.value ? new Date(field.value) : null}
                  label={t("exchange.addOfferModal.timeEnd")}
                  slotProps={{ textField: { fullWidth: true } }}
                />
              )}
            />
          </div>
          <div className="flex space-x-2">
            <IconButton
              onClick={() => remove(index)}
              disabled={fields.length <= 2}
            >
              <MdDelete />
            </IconButton>
          </div>
        </div>
      ))}
      <div className="flex justify-between items-center">
        <Button
          onClick={() =>
            append({
              operation_type: "loading",
              time_begin: todayPlus4Hours.toISOString(),
              time_end: todayPlus4Hours.toISOString(),
              suggestion: null,
            })
          }
        >
          {t("exchange.addOfferModal.addOperation")}
        </Button>

        <Button type="submit" variant="contained" disabled={loading}>
          {t("exchange.addOfferModal.submit")}
        </Button>
      </div>
    </form>
  );
};

export default AddOfferForm;
