import { useTranslation } from "react-i18next";

const NoOcp = () => {
  const { t } = useTranslation();

  return (
    <div>
      {t("dashboard.noOcp")}
    </div>
  );
};

export default NoOcp;
