import { TextField, Button, MenuItem } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { OfferModel } from "../../../types/offers";
import { CURRENCIES } from "../../../consts/currencies";
import { getCurrencySymbol } from "../../../utils/currencies";

interface NegotiateFormProps {
  offer: OfferModel;
  onSubmit: (data: any) => void;
}

const NegotiateForm: React.FC<NegotiateFormProps> = ({ offer, onSubmit }) => {
  const { t } = useTranslation();
  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      price_currency: offer.price_currency,
      price_value: offer.price_value,
    },
  });

  const currentPrice = watch("price_value");
  const currentCurrency = watch("price_currency");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex gap-2 items-center justify-center">
        {" "}
        <Controller
          name="price_value"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label={t("exchange.offerDetails.amount")}
              variant="outlined"
              margin="normal"
              type="number"
              sx={{ width: "75%" }}
            />
          )}
        />
        <Controller
          name="price_currency"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              select
              label={t("exchange.offerDetails.currency")}
              variant="outlined"
              margin="normal"
              sx={{ width: "25%" }}
            >
              {CURRENCIES.map((currency) => (
                <MenuItem key={currency} value={currency}>
                  {getCurrencySymbol(currency)}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </div>
      <Button variant="contained" color="primary" type="submit">
        {currentPrice == offer.price_value &&
        currentCurrency === offer.price_currency
          ? t("exchange.offerDetails.acceptOffer")
          : t("exchange.offerDetails.placeBid")}
      </Button>
    </form>
  );
};

export default NegotiateForm;
