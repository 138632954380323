import { CorporateDetails } from "./base";
import { Driver } from "./driver";
import { Employee } from "./employees";
import { BaseTruck } from "./truck";

export const CARRIER_DOCUMENT_TYPES = [
  "ocp",
  "ocs",
  "ckz",
  "licencja_spedycyjna",
  "licencja_wspolnotowa",
  "zezwolenie_przewoznik",
  "other",
];

export type Carrier = CorporateDetails & {
  trucks: BaseTruck[];
  drivers: Driver[];
  employees: Employee[];
};
