import { useState, useEffect, useCallback } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import  useResizeObserver  from "../../hooks/useResizeObserver";
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { CircularProgress } from "@mui/material";

interface PreviewPdfProps {
  fileId: number;
  fileUrl: string;
}
const maxWidth = 800;
const resizeObserverOptions = {};
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();
const options = {
  cMapUrl: "/cmaps/",
  standardFontDataUrl: "/standard_fonts/",
};

const PdfPreview: React.FC<PreviewPdfProps> = ({ fileId, fileUrl }) => {
  const [previewLoading, setPreviewLoading] = useState(true);
  const [pdfFiles, setPdfFiles] = useState<{ [key: number]: string }>({});
  const [numPages, setNumPages] = useState<number | null>(null);
  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);
  const [containerWidth, setContainerWidth] = useState<number>();
  const onResize = useCallback<ResizeObserverCallback>((entries) => {
    const [entry] = entries;

    if (entry) {
      setContainerWidth(entry.contentRect.width);
    }
  }, []);
  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
    setPreviewLoading(false);
  };
  useResizeObserver(containerRef, resizeObserverOptions, onResize);

  useEffect(() => {
    const fetchPdfFile = async () => {
      try {
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);
        setPdfFiles((prev) => ({ ...prev, [fileId]: blobUrl }));
        setPreviewLoading(false);
      } catch (error) {
        console.error("Error fetching PDF file:", error);
      }
    };
    fetchPdfFile();

    return () => {
      Object.values(pdfFiles).forEach((url) => URL.revokeObjectURL(url));
    };
  }, [fileUrl, fileId]);

  return (
    <div className="">
      {previewLoading && <CircularProgress size={60} />}
      {!previewLoading && (
        <div className="Example__container">
          <div className="Example__container__document" ref={setContainerRef}>
            {!previewLoading && (
              <Document
                file={pdfFiles[fileId]}
                onLoadSuccess={onDocumentLoadSuccess}
                options={options}
              >
                {Array.from(new Array(numPages), (_, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    width={
                      containerWidth
                        ? Math.min(containerWidth, maxWidth)
                        : maxWidth
                    }
                  />
                ))}
              </Document>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PdfPreview;
