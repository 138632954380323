import { useTranslation } from "react-i18next";
import { RecurringDetail } from "../../../types/expenses";
import { getCurrencySymbol } from "../../../utils/currencies";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

interface MaintenanceIncomingProps {
  data: RecurringDetail;
}

const ExpenseIncoming: React.FC<MaintenanceIncomingProps> = ({ data }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex items-center justify-between">
        <div>
          <h3 className="font-semibold">
            {t("dashboard.incomingExpense.lastDate")}
          </h3>
          {data.last_occurrence && (
            <p>{new Date(data.last_occurrence?.date).toLocaleDateString()}</p>
          )}{" "}
          <p>{data.expense.description}</p>
        </div>
      </div>
      <div className="mt-4">
        <p>
          {data.expense.net_amount}
          {getCurrencySymbol(data.expense.currency)}
        </p>
        <p>{t(`expenseTypes.${data.expense.category}`)}</p>
        <Link
          to={
            data.expense.truck
              ? `/my-fleet/trucks/${data.expense.truck}?tab=expenses&recommendation=expense_incoming&expense=${data.id}`
              : `/my-fleet?tab=expenses&recommendation=expense_incoming&expense=${data.id}`
          }
        >
          <Button variant="contained" color="primary">
            {t("dashboard.incomingExpense.pay")}
          </Button>
        </Link>
      </div>
    </>
  );
};

export default ExpenseIncoming;
