import { Controller, useForm } from "react-hook-form";
import { BankAccount } from "../../types/companies";
import { Button, Checkbox, FormControlLabel, MenuItem, TextField } from "@mui/material";
import { CURRENCIES, DEFAULT_CURRENCY } from "../../consts/currencies";
import { getCurrencySymbol } from "../../utils/currencies";
import { useTranslation } from "react-i18next";

interface BankAccountFormProps {
  onSubmit: (data: BankAccount) => void;
}

const BankAccountForm: React.FC<BankAccountFormProps> = ({ onSubmit }) => {
const { control, handleSubmit } = useForm<BankAccount>({
    defaultValues: {
      currency: DEFAULT_CURRENCY,
    }
  });
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-2 gap-4 p-4">
      <Controller
        name="account_number"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            {...field}
            label={t("bankAccountForm.fields.accountNumber")}
            required
          />
        )}
      />
      <Controller
        name="bank_name"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("bankAccountForm.fields.bankName")}
            required
          />
        )}
      />
      <Controller
        name="swift"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            {...field}
            label={t("bankAccountForm.fields.swift")}
            required
          />
        )}
      />
      <Controller
        name="is_default"
        control={control}
        render={({ field }) => (
          <FormControlLabel
            control={
              <Checkbox {...field} checked={field.value} color="primary" />
            }
            label={t("bankAccountForm.fields.default")}
          />
        )}
      />
      <Controller
        name="currency"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            select
            label={t("bankAccountForm.fields.currency")}
            variant="outlined"
          >
            {CURRENCIES.map((currency) => (
              <MenuItem key={currency} value={currency}>
                {getCurrencySymbol(currency)}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
      <Button type="submit" variant="contained" color="primary">
        {t("common.save")}
      </Button>
    </form>
  );
};

export default BankAccountForm;
