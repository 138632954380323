import React from "react";
import { useTranslation } from "react-i18next";
import { WidgetSkeleton } from "../../../components/skeletons/WidgetSkeleton";
import NoData from "../../../components/NoData";
import { MilagePerformance as PerformanceType } from "../../../types/insights";
import "react-circular-progressbar/dist/styles.css";
import { Card, CardContent, Typography } from "@mui/material";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Bar,
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
  Tooltip,
} from "recharts";
import { getCurrencySymbol } from "../../../utils/currencies";
import { format } from "date-fns-tz";

interface PerformanceProps {
  performance: PerformanceType | null;
  loading: boolean;
}

const calculatePercentageDifference = (current: number, previous: number) => {
  if (previous === 0) return 0;
  return ((current - previous) / previous) * 100;
};

const calculateProgress = (current: number, target: number) => {
  if (target === 0) return 0;
  return (current / target) * 100;
};
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const MilagePerformance: React.FC<PerformanceProps> = ({
  performance,
  loading,
}) => {
  const { t } = useTranslation();

  if (loading) {
    return <WidgetSkeleton />;
  }
  if (!performance) {
    return <NoData message={t("dashboard.noPerformance")} />;
  }

  const percentageData = [
    {
      name: t("dashboard.milage.fleetMilageThisMonth"),
      value: Math.round(performance.fleet_km_this_month),
      oldVal: Math.round(performance.fleet_km_from_last_month_up_to_current_month_day),
      difference: calculatePercentageDifference(
        performance.fleet_km_this_month,
        performance.fleet_km_from_last_month_up_to_current_month_day
      ),
      progress: calculateProgress(
        performance.fleet_km_this_month,
        performance.target_km_own
      ),
      target: performance.target_km_own,
    },
    {
      name: t("dashboard.milage.orderMilageThisMonth"),
      value: Math.round(performance.order_km_this_month),
      oldVal: Math.round(performance.order_km_from_last_month_up_to_current_month_day),
      difference: calculatePercentageDifference(
        performance.order_km_this_month,
        performance.order_km_from_last_month_up_to_current_month_day
      ),
      progress: calculateProgress(
        performance.order_km_this_month,
        performance.target_km_total
      ),
      target: Math.round(performance.target_km_total),
    },
    {
      name: t("dashboard.milage.subcontractorMilageThisMonth"),
      value: performance.fleet_km_from_last_30_days,
      oldVal: performance.fleet_km_from_before_last_30_days,
      difference: calculatePercentageDifference(
        performance.fleet_km_from_last_30_days,
        performance.fleet_km_from_before_last_30_days
      ),
      progress: null,
      target: null,
    },
    {
      name: t("dashboard.milage.totalMilageThisMonth"),
      value: Math.round(performance.total_km_from_last_30_days),
      oldVal: Math.round(performance.total_km_from_before_last_30_days),
      difference: calculatePercentageDifference(
        performance.total_km_from_last_30_days,
        performance.total_km_from_before_last_30_days
      ),
      progress: null,
      target: null,
    },
    {
      name: t("dashboard.milage.kmLast30Days"),
      value: performance.order_km_from_last_30_days.toFixed(1),
      oldVal: performance.order_km_from_before_last_30_days.toFixed(1),
      difference: calculatePercentageDifference(
        performance.order_km_from_last_30_days,
        performance.order_km_from_before_last_30_days
      ),
      progress: null,
      target: null,
    },
    {
      name: t("dashboard.milage.emptyMilageLast30Days"),
      value: performance.empty_km_from_last_30_days.toFixed(1),
      oldVal: performance.empty_km_from_before_last_30_days,
      difference: calculatePercentageDifference(
        performance.empty_km_from_last_30_days,
        performance.empty_km_from_before_last_30_days
      ),
      progress: null,
      target: null,
    },
  ];

  const barData = [
    {
      name: t("dashboard.milage.fleetKm"),
      thisMonth: performance.fleet_km_this_month,
      lastMonth: performance.fleet_km_last_month,
    },
    {
      name: t("dashboard.milage.orderKm"),
      thisMonth: performance.order_km_this_month,
      lastMonth: performance.order_km_last_month,
    },
    {
      name: t("dashboard.milage.subcontractorKm"),
      thisMonth: performance.subcontractor_km_this_month,
      lastMonth: performance.subcontractor_km_last_month,
    },
    {
      name: t("dashboard.milage.totalKm"),
      thisMonth: performance.total_km_this_month,
      lastMonth: performance.total_km_last_month,
    },
  ];

  const pieData = [
    {
      name: t("dashboard.milage.emptyKm"),
      value: performance.empty_km_from_last_30_days,
    },
    {
      name: t("dashboard.milage.orderKm"),
      value: performance.order_km_from_last_30_days,
    },
  ];

  const emptyLoadPercentage =
    (performance.empty_km_from_last_30_days /
      performance.total_km_from_last_30_days) *
    100;
  const fuelEfficiency =
    (performance.consumption_from_last_30_days /
      performance.fleet_km_from_last_30_days) *
    100;
  const costEfficiency =
    performance.fuel_cost_from_last_30_days /
    performance.fleet_km_from_last_30_days;

  const weeklyConsumption = performance.weekly_consumption.map((item) => ({
    date: format(new Date(item.date), "dd/MM"),
    consumption: item.consumption,
  }));

  const weeklyMilage = performance.weekly_milage.map((item) => ({
    date: format(new Date(item.date), "dd/MM"),
    milage: item.milage,
  }));

  const consumptionPer100kmData = weeklyConsumption.map((item) => {
    const milageItem = weeklyMilage.find(
      (milageItem) => milageItem.date === item.date
    );
  
    return {
      date: item.date,
      consumptionPer100km: milageItem
        ? (item.consumption / milageItem.milage) * 100
        : 0, 
    };
  });
  return (
    <div className="p-4">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {percentageData.map((item) => (
          <Card
            variant="outlined"
            sx={{ minWidth: 275, mb: 2 }}
            key={item.name}
          >
            <CardContent>
              <Typography variant="h5" component="div">
                {item.name}
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                {item.progress !== null && (
                  <div
                    style={{
                      width: "100px",
                      height: "100px",
                      marginRight: "20px",
                    }}
                  >
                    <CircularProgressbar
                      value={item.progress}
                      text={`${item.progress.toFixed(2)}%`}
                      styles={buildStyles({
                        textColor: item.progress >= 100 ? "green" : "red",
                        pathColor: item.progress >= 100 ? "green" : "red",
                      })}
                    />
                  </div>
                )}
                <div>
                  <Typography
                    variant="h3"
                    component="div"
                    sx={{ color: item.difference > 0 ? "green" : "red" }}
                  >
                    {item.value}
                    km
                    <Typography variant="body1" component="span">
                      {item.difference > 0
                        ? `+${item.difference.toFixed(2)}% MoM`
                        : `${item.difference.toFixed(2)}% MoM`}
                    </Typography>
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {t("dashboard.milage.previous")}: {item.oldVal}
                    km
                  </Typography>
                  {item.target !== null && (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ mt: 1 }}
                    >
                      {t("dashboard.performance.target")}: {item.target} km
                    </Typography>
                  )}
                </div>
              </div>
            </CardContent>
          </Card>
        ))}
        <div className="bg-white shadow-lg rounded-lg p-4">
          <h2 className="text-xl font-semibold mb-2">
            {t("dashboard.milage.kmComparison")}
          </h2>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={barData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="thisMonth" fill="#8884d8" />
              <Bar dataKey="lastMonth" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="bg-white shadow-lg rounded-lg p-4">
          <h2 className="text-xl font-semibold mb-2">
            {t("dashboard.milage.efficiency")}
          </h2>
          <div>
            <Typography variant="h6">
              {t("dashboard.milage.emptyLoadPercentage")}:
            </Typography>
            <Typography variant="body1">
              {emptyLoadPercentage.toFixed(2)}%
            </Typography>
          </div>
          <div>
            <Typography variant="h6">
              {t("dashboard.milage.fuelEfficiency")}:
            </Typography>
            <Typography variant="body1">
              {fuelEfficiency.toFixed(2)} L/100km
            </Typography>
          </div>
          <div>
            <Typography variant="h6">
              {t("dashboard.milage.costEfficiency")}:
            </Typography>
            <Typography variant="body1">
              {costEfficiency.toFixed(2)} {getCurrencySymbol("eur")}/km
            </Typography>
          </div>
        </div>
        <div className="bg-white shadow-lg rounded-lg p-4">
          <h2 className="text-xl font-semibold mb-2">
            {t("dashboard.milage.emptyFullDistribution")}
          </h2>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={pieData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={100}
                fill="#8884d8"
              >
                {pieData.map((_, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className="bg-white shadow-lg rounded-lg p-4">
          <h2 className="text-xl font-semibold mb-2">
            {t("dashboard.milage.consumption")}
          </h2>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              data={weeklyConsumption}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="consumption"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div className="bg-white shadow-lg rounded-lg p-4">
          <h2 className="text-xl font-semibold mb-2">
            {t("dashboard.milage.milage")}
          </h2>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              data={weeklyMilage}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="milage"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div className="bg-white shadow-lg rounded-lg p-4">
          <h2 className="text-xl font-semibold mb-2">
            {t("dashboard.milage.consumptionPer100km")}
          </h2>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              data={consumptionPer100kmData}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="consumptionPer100km"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default MilagePerformance;
