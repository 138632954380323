import { Controller, useForm } from "react-hook-form";
import { Button, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

interface PasswordResetProps {
  onResetPassword: (data: PasswordResetFormData) => void;
  onIsResettingPassword: () => void;
}

interface PasswordResetFormData {
  email: string;
}

const PasswordReset: React.FC<PasswordResetProps> = ({
  onResetPassword,
  onIsResettingPassword,
}) => {
  const { control, handleSubmit } = useForm<PasswordResetFormData>();
  const { t } = useTranslation();
  const onResetSubmit = handleSubmit((data) => {
    onResetPassword(data);
  });

  return (
    <form
      onSubmit={onResetSubmit}
      className="flex flex-col items-center justify-center px-16 py-8 shadow-2xl dark:bg-zinc-800 bg-secondary rounded-xl w-full max-w-xl gap-4"
    >
      <h1 className="font-semibold text-4xl mb-16 text-center dark:text-secondary text-black">
        {t("signIn.resetPassword")}
      </h1>
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("signIn.email")}
            variant="outlined"
            type="email"
            required
            className="w-full"
          />
        )}
      />
      <div className="w-full h-10 rounded-lg mx-auto mb-4 items-center justify-center flex px-8 py-4">
        <Button variant="contained" type="submit" className="w-full py-2">
          {t("signIn.resetPassword")}
        </Button>
      </div>
      <div className="w-full h-10 rounded-lg mx-auto mb-4 items-center justify-center flex px-8 py-4">
        <button
          type="button"
          onClick={onIsResettingPassword}
          className="text-blue-500 underline"
        >
          {t("signIn.backToSignIn")}
        </button>
      </div>
    </form>
  );
};

export default PasswordReset;
