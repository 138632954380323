import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { Button, TextField } from "@mui/material";
import { EmployeeDetails } from "../../types/employees";

interface EmployeeFormData {
  first_name: string;
  last_name: string;
  phone: string;
}
interface EmployeeBaseDetailsFormProps {
  employee: EmployeeDetails;
  onSubmit: (data: EmployeeFormData) => void;
}

const EmployeeBaseDetailsForm: React.FC<EmployeeBaseDetailsFormProps> = ({
  employee,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const { handleSubmit, control } = useForm<EmployeeFormData>({
    defaultValues: {
      first_name: employee.first_name || "",
      last_name: employee.last_name || "",
      phone: employee.phone || "",
    },
  });
  return (
    <form
      className="flex flex-col space-y-4 px-64"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="first_name"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("employeeDetail.general.firstName")}
            variant="outlined"
          />
        )}
      />
      <Controller
        name="last_name"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("employeeDetail.general.lastName")}
            variant="outlined"
          />
        )}
      />
      <TextField
        disabled
        label={t("employeeDetail.general.email")}
        variant="outlined"
        value={employee.email}
      />
      <Controller
        name="phone"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("employeeDetail.general.phone")}
            variant="outlined"
          />
        )}
      />
      <Button variant="contained" color="primary" type="submit">
        {t("common.save")}
      </Button>
    </form>
  );
};

export default EmployeeBaseDetailsForm;
