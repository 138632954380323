import { Link, useParams } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import { useEffect, useState } from "react";
import {
  BaseTruck,
} from "../../types/truck";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useToast } from "../../hooks/useToast";
import { extractErrorMessages } from "../../utils/errors";
import { useTranslation } from "react-i18next";
import { Carrier } from "../../types/carriers";
import { Breadcrumbs, Typography } from "@mui/material";
import BasicForm from "../../components/Truck/forms/BasicForm";
import LoadingInfoForm from "../../components/Truck/forms/LoadingInfoForm";
import ParametersForm from "../../components/Truck/forms/ParametersForm";
import TruckDocuments from "../../components/Truck/TruckDocuments";
import { TruckDocumentFormData } from "../../types/documents";

const TruckContainer = () => {
  const { t } = useTranslation();
  const { carrierId, truckId } = useParams<{
    truckId: string;
    carrierId: string;
  }>();
  const [carrier, setCarrier] = useState<Carrier | null>(null);

  const [truckDetails, setTruckDetails] = useState<BaseTruck | null>(null);
  const axios = useAxios();
  const toast = useToast();

  useEffect(() => {
    if (!truckId) return;

    axios
      .get(`/carriers/${carrierId}/trucks/${truckId}`)
      .then((response) => {
        setTruckDetails(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
    axios
      .get(`/carriers/${carrierId}`)
      .then((response) => {
        setCarrier(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [truckId]);

  const onTruckUpdate = (data: any) => {
    axios
      .patch(`/carriers/${carrierId}/trucks/${truckId}/`, data)
      .then((response) => {
        setTruckDetails(response.data);
        toast({
          message: "Truck updated successfully",
          type: "success",
        });
      })
      .catch((error) => {
        console.error(error);
        toast({
          message: extractErrorMessages(error.response.data).join(" "),
          type: "error",
        });
      });
  };

  const onAddDocument = async (document: TruckDocumentFormData) => {
    if (!truckDetails) return;
    const response = await axios.post(
      `/carriers/${carrierId}/trucks/${truckDetails.id}/documents/`,
      document
    );
    setTruckDetails((prevTruck) => {
      if (!prevTruck) return null;
      return {
        ...prevTruck,
        documents: [...prevTruck.documents, response.data],
      };
    });
    toast({
      message: "Document added",
      type: "success",
    });
  };

  const onDeleteDocument = async (fileId: number) => {
    if (!truckDetails) return;
    try {
      const response = await axios.delete(
        `/carriers/${carrierId}/trucks/${truckDetails.id}/documents/${fileId}/`
      );
      if (response.status === 204) {
        setTruckDetails((prevTruck) => {
          if (!prevTruck) return null;
          return {
            ...prevTruck,
            documents: prevTruck.documents.filter((doc) => doc.id !== fileId),
          };
        });
        toast({
          message: "Document deleted",
          type: "success",
        });
      } else {
        console.error("Upload failed:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };



  const onUpdateDocument = async (documentId: number, data: any) => {
    if (!truckDetails) return;

    const response = await axios.patch(
      `/carriers/${carrierId}/trucks/${truckDetails.id}/documents/${documentId}/`,
      data
    );
    setTruckDetails((prevTruck) => {
      if (!prevTruck) return null;
      return {
        ...prevTruck,
        documents: prevTruck.documents.map((doc) =>
          doc.id === documentId ? response.data : doc
        ),
      };
    });
    toast({
      message: "Document updated",
      type: "success",
    });
  };

  return (
    <div className="p-4 mb-2">
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">{t("breadcrumb.home")}</Link>
        <Link to="/carriers">{t("breadcrumb.carriers")}</Link>
        <Link to={`/carriers/${carrierId}`}>
          {t("carrierDetail.title")} - {carrier?.name}
        </Link>
        <Typography color="text.primary">
          {t("truckDetail.title")} - {truckDetails?.name}
        </Typography>
      </Breadcrumbs>

      {truckDetails && (
        <Tabs selectedTabClassName="bg-neutral-300 dark:bg-zinc-800 text-black dark:text-secondary border border-neutral-400">
          <TabList className="text-black dark:text-secondary">
            <Tab>{t("truckDetail.tabs.general")}</Tab>
            <Tab>{t("truckDetail.tabs.parameters")}</Tab>
            <Tab>{t("truckDetail.tabs.documents")}</Tab>
            <Tab>{t("truckDetail.tabs.loadingInfo")}</Tab>
          </TabList>

          <TabPanel>
            <BasicForm truck={truckDetails} onSubmit={onTruckUpdate} />
          </TabPanel>
          <TabPanel>
            <ParametersForm truck={truckDetails} onSubmit={onTruckUpdate} />
          </TabPanel>
          <TabPanel>
            <TruckDocuments
              truck={truckDetails}
              onAddDocument={onAddDocument}
              onDeleteDocument={onDeleteDocument}
              onUpdateDocument={onUpdateDocument}
            />
          </TabPanel>

          <TabPanel>
            <LoadingInfoForm truck={truckDetails} onSubmit={onTruckUpdate} />
          </TabPanel>
        </Tabs>
      )}
    </div>
  );
};

export default TruckContainer;
