import { Link, useParams } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import { useEffect, useState } from "react";
import { OrderInfo } from "../../types/orders";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import React from "react";
import { FaFileInvoice, FaPencilAlt } from "react-icons/fa";
import { FaMapLocation } from "react-icons/fa6";
import StatusBadge from "../../components/statusBadge/StatusBadge";
import { getCurrencySymbol } from "../../utils/currencies";
import { formatOrderOperationTime, formatSingleTimeDisplay } from "../../utils/time";
import CountryFlag from "react-country-flag";

const ShipperOrders = () => {
  const { t } = useTranslation();
  const { shipperId } = useParams<{ shipperId: string }>();
  const axios = useAxios();
  const [orders, setOrders] = useState<OrderInfo[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!shipperId) return;
    setLoading(true);
    axios
      .get(`/shippers/${shipperId}/orders/`)
      .then((res) => {
        setOrders(res.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [shipperId]);

  return (
    <div className="">
      <table className="min-w-full bg-white table-auto shadow-lg rounded-lg">
        <thead className="bg-gray-800 text-white">
          <tr>
            <th className="px-4 py-2 text-left"></th>
            <th className="px-4 py-2 text-left">
              {t("orders.tableHeader.loading")}
            </th>
            <th className="px-4 py-2 text-left">
              {t("orders.tableHeader.unloading")}
            </th>
            <th className="px-4 py-2 text-left">
              {t("orders.tableHeader.rate")}
            </th>
            <th className="px-4 py-2 text-left">
              {t("orders.tableHeader.refNo")}
            </th>
            <th className="px-4 py-2 text-left">
              {t("orders.tableHeader.status")}
            </th>
            <th className="px-4 py-2 text-left">
              {t("orders.tableHeader.actions")}
            </th>
          </tr>
        </thead>
        {loading ? (
          <tbody>
            <tr>
              <td colSpan={10} className="text-center py-4">
                <CircularProgress />
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody className="text-gray-600">
            {orders.map((order) => {
              return (
                <React.Fragment key={order.id}>
                  <tr
                    key={order.id}
                    className="border-b hover:bg-gray-300 transition duration-150 ease-in-out"
                  >
                    <td className="px-4 py-3 text-left">
                      {order.truck && (
                        <Link
                          to={`/map?order=${order.id}&truck=${order.truck.license_plate}`}
                        >
                          <FaMapLocation className="w-6 h-6" />
                        </Link>
                      )}
                    </td>
                    <td className="px-4 py-3 text-left flex flex-col items-start">
                      <div className="flex items-center space-x-1 font-bold">
                        <CountryFlag
                          countryCode={order.operations[0].country}
                          svg
                          style={{
                            width: "1em",
                            height: "1em",
                            borderRadius: "50%",
                          }}
                        />
                        <p className="uppercase">
                          {" "}
                          {order.operations[0].country}
                          {order.operations[0].postal_code.substring(0, 2)}
                        </p>
                        <p> {order.operations[0].locality}</p>
                      </div>
                      <p className="text-xs">{order.operations[0].address}</p>
                      {formatOrderOperationTime(order.operations[0])}
                    </td>
                    <td className="px-4 py-3  text-left">
                      <div className="flex items-center space-x-1 font-bold">
                        <CountryFlag
                          countryCode={
                            order.operations[order.operations.length - 1]
                              .country
                          }
                          svg
                          style={{
                            width: "1em",
                            height: "1em",
                            borderRadius: "50%",
                          }}
                        />
                        <p className="uppercase">
                          {
                            order.operations[order.operations.length - 1]
                              .country
                          }
                          {order.operations[
                            order.operations.length - 1
                          ].postal_code.substring(0, 2)}
                        </p>
                        <p>
                          {
                            order.operations[order.operations.length - 1]
                              .locality
                          }
                        </p>
                      </div>
                      <p className="text-xs">
                        {order.operations[order.operations.length - 1].address}
                      </p>

                      {formatOrderOperationTime(
                        order.operations[order.operations.length - 1]
                      )}
                    </td>
                    <td className="px-4 py-3  text-left">
                      <span className="font-semibold text-sm italic">
                        {order.payment.price_value.toFixed(2)}
                        {getCurrencySymbol(order.payment.price_currency)}
                      </span>

                      {order.estimated_distance && (
                        <>
                          <p className="text-xs">
                            {order.estimated_distance} km
                          </p>
                          <p className="text-xs">
                            {(
                              order.payment.price_value /
                              order.estimated_distance
                            ).toFixed(2)}{" "}
                            {getCurrencySymbol(order.payment.price_currency)}/km
                          </p>
                        </>
                      )}
                    </td>
                    <td className="px-4 py-3  text-left text-xs">
                      <div className="text-accent font-semibold">
                        {order.trans_ref_number}
                      </div>
                      <div className="">
                        {formatSingleTimeDisplay(
                          order.trans_date ? order.trans_date : order.created_at
                        )}
                      </div>
                      {order.ref_number && (
                        <div className="text-accent font-semibold">
                          {order.ref_number}
                        </div>
                      )}
                    </td>
                    <td className="px-4 py-3  text-left ">
                      <StatusBadge status={order.status} />
                      {order.invoice && (
                        <a
                          href={order.invoice.file}
                          target="_blank"
                          rel="noreferrer"
                          className="text-accent hover:text-blue-800"
                        >
                          <FaFileInvoice />
                        </a>
                      )}
                    </td>
                    <td className="px-4 py-3">
                      <div className="flex items-center">
                        <Link to={`/orders/${order.id}`}>
                          <button className="text-white font-bold py-1 px-4 rounded text-sm bg-danger">
                            <FaPencilAlt className="h-5 w-5 text-accent" />
                          </button>
                        </Link>
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default ShipperOrders;
