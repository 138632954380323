import { Breadcrumbs, Typography } from "@mui/material";
import { t } from "i18next";
import { Link } from "react-router-dom";

const ForwardersContainer = () => {
  return (
    <div className="text-black p-4">
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">{t("breadcrumb.home")}</Link>
        <Typography color="text.primary">{t("breadcrumb.forwarders")}</Typography>
      </Breadcrumbs>
    </div>
  );
};

export default ForwardersContainer;
