import { TextField, MenuItem, Button } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { t } from "i18next";
import ReactCountryFlag from "react-country-flag";
import { Controller } from "react-hook-form";
import { Fragment } from "react/jsx-runtime";
import GeoAutocomplete from "../../../components/geoAutocomplete/GeoAutocomplete";
import { DEFAULT_COUNTRY, COUNTRIES } from "../../../consts/countries";
import { ORDER_OPERATION_TYPES } from "../../../types/orders";
import useAxios from "../../../hooks/useAxios";
import { useToast } from "../../../hooks/useToast";

interface OrderCreateStepThreeProps {
  control: any;
  fields: any;
  selectedOperationIndex: number;
  selectedCountry: string;
  setSelectedOperationIndex: (index: number) => void;
  append: (data: any) => void;
  setValue: (name: any, value: any) => void;
  remove: (index: number) => void;
}

const OrderCreateStepThree: React.FC<OrderCreateStepThreeProps> = ({
  control,
  fields,
  selectedOperationIndex,
  selectedCountry,
  setSelectedOperationIndex,
  append,
  setValue,
  remove,
}) => {
  const axios = useAxios();
  const toast = useToast();

  const lookupPlace = async (placeId: string) => {
    try {
      const lookupResponse = await axios.post(`integrations/here/lookup/`, {
        id: placeId,
        lang: "pl",
      });
      const { lat, lng } = lookupResponse.data.result.position;
      setValue(`operations.${selectedOperationIndex}.latitude`, lat);
      setValue(`operations.${selectedOperationIndex}.longitude`, lng);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const geocodePlace = async (query: string) => {
    try {
      const lookupResponse = await axios.post(`integrations/here/geocode/`, {
        query: query,
        lang: "pl",
      });
      if (lookupResponse.data.items.length === 0) {
        toast({
          message: "No results found",
          type: "error",
        });
        return;
      }
      const { lat, lng } = lookupResponse.data.items[0].position;
      setValue(`operations.${selectedOperationIndex}.latitude`, lat);
      setValue(`operations.${selectedOperationIndex}.longitude`, lng);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  return (
    <div className="flex flex-col w-full mt-4 items-center">
      <div className="flex w-full">
        <div className="flex flex-col items-center shadow-lg rounded-xl py-6 px-1 border border-gray-200 w-1/3 gap-2">
          {fields.map((operation: any, index: number) => (
            <Fragment key={operation.id}>
              <div
                className={`flex items-center justify-between space-x-4 py-3 px-1 w-full cursor-pointer rounded-lg transition-colors duration-200 ${
                  index === selectedOperationIndex
                    ? "bg-accent"
                    : "hover:bg-secondary"
                }`}
                onClick={() => setSelectedOperationIndex(index)}
              >
                <div className="text-xl font-semibold text-gray-800">
                  {index + 1}
                </div>
                <p className="text-base flex-grow font-medium text-gray-700">
                  {t(`operationTypes.${operation.operation_type}`)}
                </p>
                {fields.length > 2 && (
                  <button
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      remove(index);
                      if (selectedOperationIndex >= index) {
                        setSelectedOperationIndex(
                          selectedOperationIndex === 0
                            ? 0
                            : selectedOperationIndex - 1
                        );
                      }
                    }}
                    className="text-red-500 hover:text-red-700 transition-colors"
                  >
                    {t("common.delete")}
                  </button>
                )}
              </div>
            </Fragment>
          ))}
          <Button
            onClick={() => {
              append({
                operation_type: "unloading",
                time_end: today.toISOString(),
                time_begin: tomorrow.toISOString(),
                latitude: null,
                longitude: null,
                country: DEFAULT_COUNTRY,
                address: "",
              });
              setSelectedOperationIndex(fields.length);
            }}
            variant="contained"
            type="button"
          >
            {t("orders.orderCreate.addOperation")}
          </Button>
        </div>
        <div className="flex-grow shadow-md rounded-lg p-6 border border-gray-200 ml-4">
          {fields.length > 0 && (
            <div key={fields[selectedOperationIndex].id} className="space-y-4">
              <Controller
                name={`operations.${selectedOperationIndex}.operation_type`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    label={t("orders.orderCreate.operationType")}
                    fullWidth
                    variant="outlined"
                  >
                    {ORDER_OPERATION_TYPES.map((type) => (
                      <MenuItem key={type} value={type}>
                        <div className="flex items-center gap-2">
                          {t(`operationTypes.${type}`)}
                        </div>
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <Controller
                name={`operations.${selectedOperationIndex}.time_begin`}
                control={control}
                render={({ field }) => (
                  <DateTimePicker
                    label="Początek operacji"
                    sx={{ mb: 2 }}
                    value={field.value ? new Date(field.value) : today}
                    onChange={(date) => {
                      setValue(
                        `operations.${selectedOperationIndex}.time_begin`,
                        date ? date.toISOString() : ""
                      );
                    }}
                    slotProps={{
                      textField: {
                        required: true,
                        fullWidth: true,
                      },
                    }}
                    maxDate={new Date(fields[selectedOperationIndex].time_end)}
                  />
                )}
              />
              <Controller
                name={`operations.${selectedOperationIndex}.time_end`}
                control={control}
                render={({ field }) => (
                  <DateTimePicker
                    label="Koniec operacji"
                    sx={{ mb: 2 }}
                    value={field.value ? new Date(field.value) : tomorrow}
                    onChange={(date) => {
                      setValue(
                        `operations.${selectedOperationIndex}.time_end`,
                        date ? date.toISOString() : ""
                      );
                    }}
                    slotProps={{
                      textField: {
                        required: true,
                        fullWidth: true,
                      },
                    }}
                    minDate={new Date(fields[selectedOperationIndex].time_end)}
                  />
                )}
              />
              <Controller
                name={`operations.${selectedOperationIndex}.address`}
                control={control}
                render={({ field }) => (
                  <div>
                    <GeoAutocomplete
                      country={selectedCountry}
                      label={t("orders.orderCreate.address")}
                      value={field.value}
                      onChange={(suggestion) => {
                        console.log(suggestion);
                        setValue(
                          `operations.${selectedOperationIndex}.address`,
                          suggestion || ""
                        );
                        
                        if (typeof suggestion === "string" || !suggestion) {
                          console.log("");
                        } else {
                          setValue(
                            `operations.${selectedOperationIndex}.latitude`,
                            suggestion?.latitude
                          );
                          setValue(
                            `operations.${selectedOperationIndex}.longitude`,
                            suggestion.longitude
                          );
                          if (suggestion.address) {
                            console.log(suggestion.address);
                            setValue(
                              `operations.${selectedOperationIndex}.postal_code`,
                              suggestion.address.postalCode
                            );
                            setValue(
                              `operations.${selectedOperationIndex}.locality`,
                              suggestion.address.city
                            );
                            setValue(
                              `operations.${selectedOperationIndex}.country`,
                              suggestion.address.country
                            );
                          }
                        }
                      }}
                    />
                    <Button
                      sx={{ mt: 2 }}
                      disabled={!field.value}
                      onClick={() => {
                        if (!field.value) return;
                        if (typeof field.value === "string") {
                          geocodePlace(field.value);
                        } else {
                          lookupPlace(field.value.id);
                        }
                      }}
                      variant="contained"
                    >
                      {t("orders.orderCreate.lookupCoordinates")}
                    </Button>
                  </div>
                )}
              />
              <Controller
                name={`operations.${selectedOperationIndex}.country`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    label={t("orders.orderCreate.shipperModal.country")}
                    fullWidth
                    variant="outlined"
                  >
                    {COUNTRIES.map((country) => (
                      <MenuItem key={country} value={country}>
                        <div className="flex items-center gap-2">
                          <ReactCountryFlag countryCode={country} svg />
                          {t(`countries.${country}`)}
                        </div>
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <Controller
                name={`operations.${selectedOperationIndex}.postal_code`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t("orders.orderCreate.postalCode")}
                    sx={{ mb: 2 }}
                    required
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
              <Controller
                name={`operations.${selectedOperationIndex}.locality`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t("orders.orderCreate.locality")}
                    sx={{ mb: 2 }}
                    required
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />

              <div className="flex gap-2">
                {" "}
                <Controller
                  name={`operations.${selectedOperationIndex}.latitude`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t("orders.orderCreate.latitude")}
                      sx={{ mb: 2 }}
                      type="number"
                      required
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
                <Controller
                  name={`operations.${selectedOperationIndex}.longitude`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required
                      label={t("orders.orderCreate.longitude")}
                      sx={{ mb: 2 }}
                      type="number"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderCreateStepThree;
