import { format } from "date-fns";
import { Operation } from "../types/orders";
import { utcToZonedTime } from "date-fns-tz";
import { OfferOperationModel } from "../types/offers";

export const formatStartEndTime = (
  rawStartTime: string,
  rawEndTime: string
) => {
  const startTime = utcToZonedTime(rawStartTime, "Europe/Warsaw");
  const endTime = utcToZonedTime(rawEndTime, "Europe/Warsaw");

  const dateFormat = "dd-MM";
  const timeFormat = "HH:mm";
  const formattedStartDate = format(startTime, dateFormat);
  const formattedEndDate = format(endTime, dateFormat);
  const formattedStartTime = format(startTime, timeFormat);
  const formattedEndTime = format(endTime, timeFormat);

  // Check if both the dates and times are the same
  if (
    formattedStartDate === formattedEndDate &&
    formattedStartTime === formattedEndTime
  ) {
    return `${formattedStartDate} ${formattedStartTime}`;
  } else if (formattedStartDate === formattedEndDate) {
    // If only dates are same, show the date once and different times
    return `${formattedStartDate} ${formattedStartTime} - ${formattedEndTime}`;
  } else {
    // If both dates and times are different, show full start and end timestamps
    return `${formattedStartDate} ${formattedStartTime} - ${formattedEndDate} ${formattedEndTime}`;
  }
};

export const formatSingleTimeDisplay = (
  time: string,
  dateFormat = "dd/MM/yy"
) => {
  const timeZone = "Europe/Warsaw";
  const startTime = utcToZonedTime(time, timeZone);

  return format(startTime, dateFormat);
};

export const formatDistance = (meters: number) => {
  return (meters / 1000).toFixed(1) + " km"; // Converts meters to kilometers with one decimal place
};

export const formatDuration = (seconds: number) => {
  const days = Math.floor(seconds / (3600 * 24));
  seconds -= days * 3600 * 24;
  const hrs = Math.floor(seconds / 3600);
  seconds -= hrs * 3600;
  const mnts = Math.floor(seconds / 60);

  let formattedDuration = "";
  if (days > 0) formattedDuration += `${days}d `;
  if (hrs > 0) formattedDuration += `${hrs}h `;
  formattedDuration += `${mnts}m`;

  return formattedDuration.trim(); // Formats in day-hour-minute format, omitting zero values
};

export const formatOrderOperationTime = (operation: Operation | OfferOperationModel) => {
  const timeZone = "Europe/Warsaw";
  const firstOperationBegin = operation.time_begin;
  const lastOperationEnd = operation.time_end;

  const startTime = utcToZonedTime(firstOperationBegin, timeZone);
  const endTime = utcToZonedTime(lastOperationEnd, timeZone);

  const timeFormat = "(dd/MM) HH:mm";
  const formattedStartTime = format(startTime, timeFormat);

  const isSameDay =
    format(startTime, "yyyyMMdd") === format(endTime, "yyyyMMdd");

  const formattedFullStartTime = format(startTime, "dd/MM HH:mm");
  const formattedFullEndTime = format(endTime, "dd/MM HH:mm");

  const formattedEndTime = isSameDay
    ? format(endTime, "HH:mm")
    : format(endTime, timeFormat);

  return `${formattedStartTime} ${
    formattedFullStartTime === formattedFullEndTime
      ? ""
      : `- ${formattedEndTime}`
  }`;
};

export const getDayDifference = (date1: string, date2: string) => {
  const startDate = new Date(date1);
  const endDate = new Date(date2);
  const timeDifference = Math.abs(endDate.getTime() - startDate.getTime());
  return Math.ceil(timeDifference / (1000 * 3600 * 24));
};
