import { TextField, MenuItem, Button, Avatar, FormLabel } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import { DEFAULT_LANGUAGE, LANGUAGES } from "../../consts/settings";
import { useAuth } from "../../contexts/AuthContext";
import { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import DragDropInput from "../../components/input/DragDropInput";
import { useToast } from "../../hooks/useToast";

interface AccountDetailsFormProps {
  onSubmit: (data: any) => void;
}

interface AccountFormData {
  preferences: {
    language: string;
  };
}

const AccountDetailsForm: React.FC<AccountDetailsFormProps> = ({
  onSubmit,
}) => {
  const toast = useToast();
  const { accountLoading, account } = useAuth();
  const { t } = useTranslation();
  const { handleSubmit, control, setValue } = useForm<AccountFormData>({
    defaultValues: {
      preferences: {
        language: account?.preferences?.language || DEFAULT_LANGUAGE,
      },
    },
  });
  const [avatarUploading, setAvatarUploading] = useState(false);

  const [avatarPreview, setAvatarPreview] = useState<string | null>(null);
  const axios = useAxios();

  useEffect(() => {
    if (!accountLoading && account) {
      setValue(
        "preferences.language",
        account.preferences?.language || DEFAULT_LANGUAGE
      );
      if (account.preferences?.avatar) {
        setAvatarPreview(account.preferences.avatar);
      }
    }
  }, [accountLoading, account]);

  const handleAvatarChange = async (files: FileList) => {
    if (files.length === 0) return;

    const file = files[0];
    const reader = new FileReader();
    reader.onload = async () => {
      setAvatarUploading(true);
      const fileContent = reader.result;
      const payload = {
        file: {
          name: file.name,
          base64: fileContent,
        },
      };
      try {
        const response = await axios.patch(
          "/account/profile/upload-avatar/",
          payload
        );
        setAvatarPreview(response.data.preferences.avatar);
      } catch (error) {
        console.error(error);
        toast({
          message: "Failed to add document",
          type: "error",
        });
      } finally {
        setAvatarUploading(false);
      }
    };

    reader.readAsDataURL(file);
  };

  return (
    <div className="bg-neutral-300 dark:bg-zinc-800 p-4 border border-gray-400 text-black dark:text-white">
      <form
        className="w-1/2 text-black p-16 flex flex-col gap-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h1 className="text-2xl font-bold">
          {t("account.accountDetails.title")}
        </h1>
        <div className="">
          <Controller
            name="preferences.language"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                select
                label={t("account.accountDetails.language")}
                fullWidth
                variant="outlined"
              >
                {LANGUAGES.map((language) => (
                  <MenuItem key={language.code} value={language.code}>
                    <div className="flex items-center gap-2">
                      <ReactCountryFlag countryCode={language.country} svg />
                      {t(`common.languages.${language.code}`)}
                    </div>
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </div>
        <Button variant="contained" color="primary" type="submit">
          {t("common.save")}
        </Button>
      </form>
      <div className="flex flex-col items-start gap-2">
        <FormLabel>{t("account.accountDetails.avatar")}</FormLabel>
        {avatarPreview && (
          <Avatar
            src={avatarPreview}
            alt="Avatar Preview"
            sx={{ width: 64, height: 64 }}
          />
        )}
        <DragDropInput
          uploading={avatarUploading}
          name={t("account.accountDetails.avatar")}
          types={["png", "jpg", "jpeg", "svg"]}
          handleChange={handleAvatarChange}
          label={t("account.accountDetails.uploadNewAvatar")}
          multiple={true}
        />
      </div>
    </div>
  );
};

export default AccountDetailsForm;
