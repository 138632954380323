import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const NoExpenses = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Link to="/my-fleet?tab=expenses&recommendation=add_expense">
        <Button variant="contained" color="primary">
          {t("dashboard.addExpenses")}{" "}
        </Button>
      </Link>
    </div>
  );
};

export default NoExpenses;
