export const LANGUAGES = [
  {
    code: "en",
    country: "us",
  },
  {
    code: "pl",
    country: "pl",
  },
];

export const DEFAULT_LANGUAGE = "pl";
