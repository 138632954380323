import { HereSection, RouteWithTracking } from "../../../types/routes";
import SlimRouteAndAlternatives from "./SlimRouteAndAlternatives";

interface MapBottomOverlayProps {
  route?: RouteWithTracking;
  activeSection: HereSection | null;
}

export const MapBottomOverlay: React.FC<MapBottomOverlayProps> = ({
  route,
  activeSection,
}) => {
  return (
    <div className="absolute left-0 bottom-0 w-full z-50 bg-secondary bg-opacity-75 flex flex-row gap-3 items-center justify-center max-h-[300px]">
      <>
        <div className="w-10/12  overflow-y-auto max-h-[300px] border-r">
          <SlimRouteAndAlternatives
            route={route}
            activeSection={activeSection}
          />
        </div>
      </>
    </div>
  );
};

export default MapBottomOverlay;
