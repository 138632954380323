import { useParams } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import { useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js"; // Import the CategoryScale

Chart.register(CategoryScale); // Register the CategoryScale

interface MonthlyStats {
  [key: string]: {
    revenue: { [currency: string]: number };
    order_count: number;
  };
}

const TruckInsightsContainer = () => {
  const { truckId } = useParams();
  const axios = useAxios();
  const [monthlyStats, setStats] = useState<MonthlyStats>({});
  const chartRef = useRef<Chart<"bar">>(null);

  useEffect(() => {
    const fetchStats = async () => {
      const response = await axios.get(`/orders/trucks/${truckId}/stats`,);
      setStats(response.data.stats_per_month);
    };
    fetchStats();

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [truckId]);

  const chartData = {
    labels: Object.keys(monthlyStats),
    datasets: [
      {
        label: "Revenue",
        data: Object.values(monthlyStats).map(
          (stats) => stats.revenue["eur"] || 0
        ),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  const options = {
    onResize: (chart: Chart) => {
      if (chartRef.current && chart.id !== chartRef.current.id) {
        chartRef.current.destroy();
      }
    },
  };

  return (
    <div className="text-black bg-secondary p-8 flex flex-col items-center justify-center">
      <h1 className="text-center font-bold text-xl">Truck {truckId}</h1>

      <div className="flex w-1/2">
        <Bar ref={chartRef} data={chartData} options={options} />{" "}
      </div>

    </div>
  );
};

export default TruckInsightsContainer;
