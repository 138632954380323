import { useTranslation } from "react-i18next";
import { Maintenance } from "../../../types/truck";

interface MaintenanceIncomingProps {
  data: Maintenance;
}

const MaintenanceDueMilageIncoming: React.FC<MaintenanceIncomingProps> = ({
  data,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex items-center justify-between">
        <div>
          <h3 className="font-semibold">{t("maintenance.currentMilage")}</h3>
          <p>
            {data.truck_mileage} {t("maintenance.km")}
          </p>
          <h3 className="font-semibold">{t("maintenance.dueMilage")}</h3>
          <p>
            {data.due_mileage} {t("maintenance.km")}
          </p>
          <h3 className="font-semibold">{t("maintenance.remainingMilage")}</h3>
          <p>
            {data.due_mileage - data.truck_mileage} {t("maintenance.km")}
          </p>{" "}
          <p>{data.truck_license_plate}</p>
        </div>
      </div>
      <div className="mt-4">
        <h3 className="font-semibold">{t("maintenance.details")}</h3>
        <p>{data.details}</p>
        <p>{t(`maintenanceType.${data.service_type}`)}</p>
      </div>
    </>
  );
};

export default MaintenanceDueMilageIncoming;
