import {
  Autocomplete,
  Button,
  CircularProgress,
  TextField,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ShipperCreate from "../ShipperCreate";
import { useState } from "react";
import { Carrier } from "../../../types/carriers";
import { useToast } from "../../../hooks/useToast";
import { CorporateDetails } from "../../../types/base";
import { useStrider } from "../../../contexts/StriderContext";
import CarrierSelect from "../../../components/carrierSelect/CarrierSelect";
import { useAuth } from "../../../contexts/AuthContext";
import ErrorPage from "../../../error-page";

interface OrderCreateStepOneProps {
  ownOrder: boolean;
  carrierId: number;
  shippers: CorporateDetails[];
  carriers: Carrier[];
  onAddShipper: (shipper: CorporateDetails) => void;
  control: any;
  setValue: any;
}

const OrderCreateStepOne: React.FC<OrderCreateStepOneProps> = ({
  ownOrder,
  carrierId,
  shippers,
  carriers,
  control,
  setValue,
  onAddShipper,
}) => {
  const [isShipperModalOpen, setShipperModalOpen] = useState(false);
  const toast = useToast();
  const { addShipper } = useStrider();
  const { company, companyLoading } = useAuth();

  const { t } = useTranslation();

  const handleCarrierChange = (value: number, isCompany: boolean) => {
    if (isCompany) {
      setValue("carrier_id", null);
      setValue("own_order", true);
    } else {
      setValue("carrier_id", value);
      setValue("own_order", false);
    }
    setValue("truck_id", null);
  };

  if (companyLoading) return <CircularProgress />;

  if (!company) return <ErrorPage />;

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-2">
        <Controller
          name="shipper_id"
          control={control}
          render={({ field }) => {
            const selectedShipper =
              shippers.find((shipper) => shipper.id === field.value) || null;
            return (
              <Autocomplete
                {...field}
                value={selectedShipper}
                options={shippers}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                  event.stopPropagation();
                  field.onChange(newValue?.id || null);
                }}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("orders.orderCreate.shipper")}
                    fullWidth
                    variant="outlined"
                    required
                  />
                )}
              />
            );
          }}
        />
        <Button
          variant="contained"
          type="button"
          onClick={() => setShipperModalOpen(true)}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          {t("orders.orderCreate.addShipper")}
        </Button>
      </div>

      <CarrierSelect
        company={company}
        carriers={carriers}
        label={t("orders.selectCarrier")}
        size="medium"
        value={ownOrder ? `company-${company.id}` : `carrier-${carrierId}`}
        onChange={handleCarrierChange}
      />
      <Controller
        name="truck_id"
        control={control}
        render={({ field }) => {
          const isDisabled = !ownOrder && !carrierId;
          const trucks = ownOrder ? company.trucks : carriers.find((carrier) => carrier.id === carrierId)?.trucks || [];

          const selectedTruck = trucks.find(
            (truck) => truck.license_plate === field.value
          ) || null;
          return (
            <Autocomplete
              {...field}
              value={selectedTruck}
              options={trucks}
              getOptionLabel={(option) => option.license_plate}
              onChange={(event, newValue) => {
                event.stopPropagation();
                field.onChange(newValue?.license_plate || null);
              }}
              disabled={isDisabled}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("orders.orderCreate.truck")}
                  fullWidth
                  variant="outlined"
                  required
                />
              )}
            />
          );
        }}
      />
      <ShipperCreate
        isShipperModalOpen={isShipperModalOpen}
        onCloseShipperModal={() => setShipperModalOpen(false)}
        onCreateShipper={async (data) => {
          const shipper = await addShipper(data);
          onAddShipper(shipper);
          setShipperModalOpen(false);
          toast({
            message: "Shipper created successfully",
            type: "success",
          });
        }}
      />
    </div>
  );
};
export default OrderCreateStepOne;
