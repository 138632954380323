import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { GRAPH_COLORS } from "../../../utils/colors";

interface ShipperIncomesProps {
  shipper_incomes: { [countries: string]: number };
}

const ShipperIncomes: React.FC<ShipperIncomesProps> = ({ shipper_incomes }) => {
  const data = Object.keys(shipper_incomes).map((key, index) => {
    return {
      shipper: key,
      income: shipper_incomes[key],
      color: GRAPH_COLORS[index % GRAPH_COLORS.length],
    };
  });
  data.sort((a, b) => b.income - a.income);
  const displayedData = data.slice(0, 20);

  const renderCustomAxisTick = ({
    x,
    y,
    payload,
  }: {
    x: number;
    y: number;
    payload: any;
  }) => {
    const truncatedValue = payload.value.length > 5 ? `${payload.value.slice(0, 5)}...` : payload.value;
    return (
      <foreignObject
        x={x - 50}
        y={y}
        width={100}
        height={50}
        style={{ overflow: "visible" }}
      >
        <div className="flex flex-col items-center justify-center">
          <span>{truncatedValue}</span>
        </div>
      </foreignObject>
    );
  };

  return (
    <div>
      <h2 className="text-center">Shipper Incomes</h2>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          width={600}
          height={300}
          data={displayedData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="shipper" tick={renderCustomAxisTick} interval={0} />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="income">
            {displayedData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ShipperIncomes;
