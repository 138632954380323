import { useTranslation } from "react-i18next";
import { BaseTruck } from "../../../types/truck";

interface MaintenanceIncomingProps {
  data: BaseTruck;
}

const NoOcz: React.FC<MaintenanceIncomingProps> = ({
  data,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex items-center justify-between">
        <div>
          <h3 className="font-semibold">{t("dashboard.incomingExpense.date")}</h3>
          <p>{data.license_plate}</p>

        </div>
        <div>
          <h3 className="font-semibold">{t("")}</h3>
        </div>
      </div>
    </>
  );
};

export default NoOcz;
