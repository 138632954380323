import { RouteWithTracking, HereSection } from "../../../types/routes";
import { getCurrencySymbol } from "../../../utils/currencies";

interface RouteAndAlternativesProps {
  route?: RouteWithTracking;
  activeSection: HereSection | null;
}

const RouteAndAlternatives: React.FC<RouteAndAlternativesProps> = ({
  route,
  activeSection,
}) => {
  return (
    <div className="flex flex-col items-end">
      {route && (
        <div className="flex flex-col justify-between items-center p-8 space-y-2 z-40 text-black w-full">
          <div className="w-full border-b-2 ">
            <div className="grid grid-cols-4 px-4">
              <div>Route</div>
              <div>Tolls</div>
              <div>Distance</div>
              <div>Time</div>
            </div>
          </div>

          <div className="flex flex-col w-full p-4 bg-white shadow-md rounded-lg">
            <div className="flex justify-between items-center">
              <div className="font-medium text-lg text-gray-700">
                Active route
              </div>
              <div className="text-center">
                <div className="text-sm text-slate-500">Time</div>
                <div className="text-lg font-semibold text-gray-800">
                  {(route.summary.duration / 3600).toFixed(1)}h
                </div>
              </div>
              <div className="text-center">
                <div className="text-sm text-slate-500">Total distance</div>
                <div className="text-lg font-semibold text-gray-800">
                  {(route.summary.distance / 1000).toFixed(1)} km
                </div>
              </div>
            </div>
            <div>
              <div className="grid grid-cols-5 justify-between my-2">
                <div className="text-center">
                  <div className="text-sm text-slate-500">
                    Gross Price Per Km
                  </div>
                  <div className="text-lg font-semibold text-gray-800">
                    {route.summary.price_per_km.toFixed(2)}
                    {getCurrencySymbol(route.summary.currency)}
                  </div>
                </div>
                <div className="text-center">
                  <div className="text-sm text-slate-500">Gross Price</div>
                  <div className="text-lg font-semibold text-gray-800">
                    {route.summary.price.toFixed(2)}
                    {getCurrencySymbol(route.summary.currency)}
                  </div>
                </div>
              </div>{" "}
            </div>
          </div>

          <div className="flex w-full justify-between">
            {activeSection && (
              <div className="flex justify-between items-center w-1/2 p-4 bg-white shadow-md rounded-lg self-start">
                <div className="font-medium text-lg text-gray-700">Section</div>
                <div className="text-center">
                  <div className="text-sm text-slate-500">Time</div>
                  <div className="text-lg font-semibold text-gray-800">
                    {(activeSection.summary.duration / 3600).toFixed(1)}h
                  </div>
                </div>
                <div className="text-center">
                  <div className="text-sm text-slate-500">Distance</div>
                  <div className="text-lg font-semibold text-gray-800">
                    {(activeSection.summary.length / 1000).toFixed(1)} km
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {!route && (
        <div className="flex flex-col justify-between items-center p-8 space-y-2 z-40 text-black w-full">
          <p className="text-lg">
            Przepraszamy, Planista nie wyznaczył jeszcze trasy dla tego zlecenia.
          </p>
          <p>
            Skontaktuj się z nami, aby uzyskać więcej informacji na ten temat: +48 696 555 402 
          </p>
        </div>
      )}
    </div>
  );
};

export default RouteAndAlternatives;
