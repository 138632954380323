import { useEffect, useRef } from "react";

import H from "@here/maps-api-for-javascript";
import {
  createMap,
  createOrderOperationMarker,
  getMarkerIcon,
} from "../Map/utils/maps";
import { Route } from "../../types/routes";
import { OrderInfo } from "../../types/orders";
import { Locations, Refuel } from "../../types/delegations";

interface DelegationMapProps {
  routes: Route[];
  orders: OrderInfo[];
  locations: Locations[];
  refuels: Refuel[];
}

const DelegationMap: React.FC<DelegationMapProps> = ({
  routes,
  orders,
  locations,
  refuels,
}) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const map = useRef<H.Map | null>(null);
  const platform = useRef<H.service.Platform | null>(null);
  const routePolylinesGroups = useRef<Map<string, H.map.Group>>(new Map());
  const orderMarkersGroup = useRef(new H.map.Group());
  const locationsMarkersGroup = useRef(new H.map.Group());
  const refuelMarkersGroup = useRef(new H.map.Group());

  useEffect(() => {
    if (!map.current && mapRef && mapRef.current) {
      createMap(platform, mapRef, map);
    }
  }, [map, platform, mapRef]);

  useEffect(() => {
    if (map.current && platform && platform.current && routes.length > 0) {
      routePolylinesGroups.current.forEach((group) =>
        map.current?.removeObject(group)
      );
      routePolylinesGroups.current.clear();
      const route = routes[0];
      const routeGroup = new H.map.Group({
        volatility: true,
        data: route,
      });
      const polylines = drawRoutePolylines(route);
      polylines.forEach((polyline) => routeGroup.addObject(polyline));
      routePolylinesGroups.current.set(route.id, routeGroup);
      map.current?.addObject(routeGroup);
    }
  }, [map, routes]);

  useEffect(() => {
    if (map.current && orders.length > 0 && platform && platform.current) {
      orderMarkersGroup.current.removeAll();

      orders.forEach((order) => {
        order.operations.forEach((operation) => {
          const marker = createOrderOperationMarker(operation, map, "#000", 40);
          orderMarkersGroup.current.addObject(marker);
        });
      });
      map.current.addObject(orderMarkersGroup.current);
    }
  }, [map, orders]);

  useEffect(() => {
    if (map.current && refuels.length > 0 && platform && platform.current) {
      refuelMarkersGroup.current.removeAll();

      refuels.forEach((refuel) => {
        const marker = new H.map.Marker(
          { lat: refuel.latitude, lng: refuel.longitude },
          {
            data: refuel,
            icon: getMarkerIcon("F", "rgb(0, 200, 0)", 10),
          }
        );
        refuelMarkersGroup.current.addObject(marker);
      });
      map.current.addObject(refuelMarkersGroup.current);
    }
  }, [map, refuels]);

  useEffect(() => {
    if (map.current && locations.length > 0 && platform && platform.current) {
      locationsMarkersGroup.current.removeAll();

      locations.forEach((location, index) => {
        const marker = new H.map.Marker(
          { lat: location.latitude, lng: location.longitude },
          {
            data: location,
            icon: getMarkerIcon(index.toString(), "rgb(200, 0, 0)", 6),
          }
        );
        locationsMarkersGroup.current.addObject(marker);
      });
      map.current.addObject(locationsMarkersGroup.current);
    }
  }, [map, locations]);

  const drawRoutePolylines = (route: Route) => {
    const sections = route.sections;
    const polylines: H.map.Polyline[] = [];
    sections.forEach((section) => {
      const lineStrings: H.geo.LineString[] = [];
      lineStrings.push(H.geo.LineString.fromFlexiblePolyline(section.polyline));
      const multiLineString = new H.geo.MultiLineString(lineStrings);
      const sectionPolyline = new H.map.Polyline(multiLineString, {
        data: section,
        style: {
          lineWidth: 8,
          strokeColor: "rgb(0, 0, 255)",
        },
      });
      polylines.push(sectionPolyline);
    });
    return polylines;
  };

  return (
    <div className="min-h-screen max-h-screen relative flex w-full">
      <div className="w-full min-h-screen max-h-screen" ref={mapRef} />
    </div>
  );
};

export default DelegationMap;
