import { useCallback, useEffect, useState } from "react";
import { OrderInfo } from "../../types/orders";
import useAxios from "../../hooks/useAxios";
import Orders from "./OrdersTable/Orders";
import OrderFilters from "./OrderFilters";
import { DatePicker } from "@mui/x-date-pickers";
import debounce from "lodash/debounce";
import OrderCreate from "./OrderCreate/OrderCreate";
import { extractEndpoint, getPageNumBasedOnUrl } from "../../utils/pagination";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import InvoicedFilter from "./InvoicedFilter";
import { Button, Chip, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import CarrierExcludeFilter from "./CarrierExcludeFilter";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { getCurrencySymbol } from "../../utils/currencies";
import { DEFAULT_CURRENCY } from "../../consts/currencies";

const ORDERS_PER_PAGE = 20;

interface Notification {
  id: number;
  shipper: string;
}

const OrdersContainer = () => {
  const { t } = useTranslation();
  const axios = useAxios();
  const [orders, setOrders] = useState<OrderInfo[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalOrders, setTotalOrders] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [revenue, setRevenue] = useState<number>(0);
  const [contractorPayments, setContractorPayments] = useState<number>(0);
  const [nextUrl, setNextUrl] = useState("");
  const [prevUrl, setPrevUrl] = useState("");
  const [selectedOrderStatusFilters, setSelectedOrderStatusFilters] =
    useLocalStorage("selectedOrderStatuses", [
      "new",
      "assigned",
      "in_progress",
    ]);
  const [excludedCarriers, setExcludedCarriers] = useLocalStorage<number[]>(
    "excludedCarriers",
    []
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dateFrom, setDateFrom] = useLocalStorage(
    "dateFrom",
    new Date(new Date().setDate(new Date().getDate() - 15)).toISOString(),
    60 * 60 * 24 * 1000
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [dateTo, setDateTo] = useState<Date | null>(new Date());

  const fetchOrders = async (url: string) => {
    setLoading(true);
    try {
      const { data } = await axios.get(url);
      setOrders(data.results);
      setRevenue(data.total_revenue);
      setContractorPayments(data.contractor_payments);
      setTotalOrders(data.count);
      setNextUrl(extractEndpoint(data.next));
      setPrevUrl(extractEndpoint(data.previous));
      setCurrentPage(getPageNumBasedOnUrl(url));
    } finally {
      setLoading(false);
    }
  };

  const [selectedInvoicedStatus, setSelectedInvoicedStatus] = useState({
    invoiced: false,
    notInvoiced: false,
    all: true,
  });
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const buildUrl = () => {
    const invoicedStatus = selectedInvoicedStatus.all
      ? ""
      : selectedInvoicedStatus.invoiced
      ? "invoiced"
      : "not_invoiced";

    const excludedCarries = excludedCarriers.join(",");
    return `/orders?search=${searchQuery}&status=${selectedOrderStatusFilters.join(
      ","
    )}&date_from=${dateFrom}&date_to=${dateTo?.toISOString()}&invoiced_status=${invoicedStatus}&excluded_carriers=${excludedCarries}`;
  };

  const debouncedFetchOrders = useCallback(
    debounce((url) => fetchOrders(url), 500),
    []
  );

  useEffect(() => {
    const initialUrl = buildUrl();
    debouncedFetchOrders(initialUrl);
  }, [
    excludedCarriers,
    searchQuery,
    selectedOrderStatusFilters,
    dateFrom,
    dateTo,
    selectedInvoicedStatus,
  ]);

  const onUpdateOrder = async (data: any, orderId: number) => {
    axios
      .patch(`orders/${orderId}/`, data)
      .then((response) => {
        const newOrder = response.data;
        setOrders((currentOrders) =>
          currentOrders.map((o) => (o.id === orderId ? newOrder : o))
        );
      })
      .catch((error) => {
        console.error("Error assigning order to truck:", error);
      });
  };

  const onSelectCarrierForOrder = async (
    carrierId: number,
    order: OrderInfo
  ) => {
    axios
      .patch(`orders/${order.id}/`, {
        carrier_id: carrierId,
      })
      .then((response) => {
        const newOrder = response.data;
        setOrders((currentOrders) =>
          currentOrders.map((o) => (o.id === order.id ? newOrder : o))
        );
      })
      .catch((error) => {
        console.error("Error assigning order to carrier:", error);
      });
  };

  const onDiscardOrder = (orderId: number) => {
    axios
      .delete(`/orders/${orderId}/`)
      .then(() => {
        setOrders((currentOrders) =>
          currentOrders.filter((o) => o.id !== orderId)
        );
      })
      .catch((error) => {
        console.error("Error discarding order:", error);
      });
  };

  const fetchOrdersByPage = async (page: number) => {
    try {
      setLoading(true);
      const initialUrl = buildUrl();
      const url = `${initialUrl}&page=${page}`;
      const { data } = await axios.get(url);
      setOrders(data.results);
      setRevenue(data.total_revenue);
      setContractorPayments(data.contractor_payments);
      setTotalOrders(data.count);
      setNextUrl(extractEndpoint(data.next));
      setPrevUrl(extractEndpoint(data.previous));
      setCurrentPage(page);
    } catch (error) {
      console.error("Error fetching orders by page:", error);
    } finally {
      setLoading(false);
    }
  };

  const onSetOwnCarrierForOrder = async (order: OrderInfo) => {
    axios
      .patch(`orders/${order.id}/`, {
        own_order: true,
      })
      .then((response) => {
        const newOrder = response.data;
        setOrders((currentOrders) =>
          currentOrders.map((o) => (o.id === order.id ? newOrder : o))
        );
      })
      .catch((error) => {
        console.error("Error assigning order to carrier:", error);
      });
  };

  const renderSmartDots = () => {
    const totalPages = Math.ceil(totalOrders / ORDERS_PER_PAGE);
    const pages: any[] = [];
    pages.push(1);

    const startPage = Math.max(2, currentPage - 2);
    const endPage = Math.min(totalPages - 1, currentPage + 2);

    if (startPage > 2) {
      pages.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    if (endPage < totalPages - 1) {
      pages.push("...");
    }

    if (totalPages > 1) {
      pages.push(totalPages);
    }

    return pages.map((page, index) => {
      if (page === "...") {
        return (
          <span key={index} className="px-2 py-1 text-black">
            ...
          </span>
        );
      } else {
        return (
          <button
            key={index}
            onClick={() => fetchOrdersByPage(page as number)}
            className={`mx-1 px-2 py-1 rounded ${
              page === currentPage
                ? "bg-accent text-white"
                : "bg-gray-300 text-black"
            }`}
            aria-label={`Go to page ${page}`}
          >
            {page}
          </button>
        );
      }
    });
  };

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };
  const handleSubmitOrder = async (data: any) => {
    const response = await axios.post("/orders/", data);
    setOrders([response.data, ...orders]);
    setIsDialogOpen(false);
  };

  const handleDateFromChange = (date: Date | null) => {
    if (date) {
      setDateFrom(date.toISOString());
    }
  };

  const handleDateToChange = (date: Date | null) => {
    setDateTo(date);
  };
  const handleSearchQueryChange = (e: any) => {
    setSearchQuery(e.target.value);
  };

  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
    `${import.meta.env.VITE_PUBLIC_BACKEND_URL}ws/orders/`,
    {
      share: false,
      onOpen: () => console.log("WebSocket connection opened."),
      onClose: () => console.log("WebSocket connection closed."),
      onError: (event) => console.error("WebSocket error:", event),
      shouldReconnect: () => true,
    }
  );

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      sendJsonMessage({
        event: "subscribe",
        data: {
          channel: "orders",
        },
      });
    }
  }, [readyState]);

  useEffect(() => {
    if (lastJsonMessage) {
      const notification = lastJsonMessage as Notification;
      setNotifications((currentNotifications) => [
        notification,
        ...currentNotifications,
      ]);
    }
  }, [lastJsonMessage]);

  const handleNewOrderClick = () => {
    const initialUrl = buildUrl();
    debouncedFetchOrders(initialUrl);
    setNotifications([]);
  };

  return (
    <div className="flex flex-col  w-full text-center  min-h-screen ">
      {notifications.length > 0 && (
        <Chip
          label={`New orders available: ${notifications.length}. Click to refresh.`}
          color="primary"
          onClick={handleNewOrderClick}
          style={{
            position: "fixed",
            top: "20%",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1000,
            padding: "1.2rem",
          }}
        />
      )}
      <div className="w-full px-4 py-4 border-t-2 border-black flex flex-col space-y-4">
        <div className="flex justify-between items-start flex-col">
          <div>
            {" "}
            {revenue > 0 && (
              <h2 className="text-2xl font-bold dark:text-secondary text-green-600">
                {t("orders.totalRevenue")}: {""}
                {revenue}
                {getCurrencySymbol(DEFAULT_CURRENCY)}
              </h2>
            )}
          </div>
          <div>
            {contractorPayments > 0 && (
              <h2 className="text-2xl font-bold dark:text-secondary text-red-500">
                {t("orders.totalContractorPayments")}: {""}
                {contractorPayments}
                {getCurrencySymbol(DEFAULT_CURRENCY)}
              </h2>
            )}
          </div>
          <div>
            {contractorPayments > 0 && revenue > 0 && (
              <h2
                className={`text-2xl font-bold dark:text-secondary border-t-2 mt-1 border-black
              ${
                revenue - contractorPayments > 0
                  ? "text-green-600"
                  : "text-red-500"
              }
              `}
              >
                ={revenue - contractorPayments}
                {getCurrencySymbol(DEFAULT_CURRENCY)}
              </h2>
            )}
          </div>
        </div>
        <div className="flex justify-between items-end w-full">
          <OrderFilters
            selectedOrderStatuses={selectedOrderStatusFilters}
            onSelectedOrderStatusesChange={setSelectedOrderStatusFilters}
          />
          <InvoicedFilter
            selectedInvoicedStatus={selectedInvoicedStatus}
            onChange={setSelectedInvoicedStatus}
          />
          <div className="flex flex-col-reverse gap-2 items-end">
            <div className="flex gap-4">
              <TextField
                value={searchQuery}
                onChange={handleSearchQueryChange}
                placeholder={t("orders.searchPlaceholder")}
                fullWidth
              />
              <CarrierExcludeFilter
                excludedCarriers={excludedCarriers}
                onExcludedCarriersChange={setExcludedCarriers}
              />
              <Button variant="contained" onClick={handleDialogOpen}>
                {t("orders.orderCreateButton")}
              </Button>
            </div>
            <div className="flex gap-4 mt-4">
              <DatePicker
                label={t("orders.dateFromLabel")}
                value={new Date(dateFrom)}
                onChange={handleDateFromChange}
              />
              <DatePicker
                label={t("orders.dateToLabel")}
                value={dateTo}
                onChange={handleDateToChange}
              />
            </div>
          </div>
        </div>
        <Orders
          orders={orders}
          ordersLoading={loading}
          onDiscard={onDiscardOrder}
          onSelectCarrierForOrder={onSelectCarrierForOrder}
          onUpdateOrder={onUpdateOrder}
          onSetOwnCarrierForOrder={onSetOwnCarrierForOrder}
        />
        <div className="text-center my-2">
          <span className="inline-block bg-blue-100 text-blue-800 text-xs px-2 py-1 rounded-full font-semibold tracking-wide">
            {t("orders.totalOrders")}: {totalOrders}
          </span>
        </div>
        <div className="flex justify-center items-center gap-1 my-2">
          {renderSmartDots()}
        </div>
        <div className="flex justify-center gap-4">
          <Button
            variant="contained"
            onClick={() => prevUrl && fetchOrders(prevUrl)}
            disabled={!prevUrl}
          >
            {t("common.prev")}
          </Button>
          <Button
            variant="contained"
            onClick={() => nextUrl && fetchOrders(nextUrl)}
            disabled={!nextUrl}
          >
            {t("common.next")}
          </Button>
        </div>
      </div>
      <OrderCreate
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onSubmit={handleSubmitOrder}
      />
    </div>
  );
};

export default OrdersContainer;
