import { useTranslation } from "react-i18next";
import { Maintenance } from "../../../types/truck";

interface MaintenanceIncomingProps {
  data: Maintenance;
}

const MaintenanceDueDateIncoming: React.FC<MaintenanceIncomingProps> = ({
  data,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex items-center justify-between">
        <div>
          <h3 className="font-semibold">{t("maintenance.date")}</h3>
          <p>{new Date(data.due_date).toLocaleDateString()}</p>
          <p className="font-semibold"> {data.truck_license_plate}</p>
        </div>
      </div>
      <div className="mt-4">
        <h3 className="font-semibold">{t("maintenance.details")}</h3>
        <p>{data.details}</p>
        <p>{t(`maintenanceType.${data.service_type}`)}</p>
      </div>
    </>
  );
};

export default MaintenanceDueDateIncoming;
