import { TrackingOrderInfo } from "../../../types/orders";
import { RouteWithTracking } from "../../../types/routes";
import AssigneeDetails from "./AssigneeDetails";
import CarrierDetails from "./CarrierDetails";
import OrderStatus from "./OrderStatus";
import PaymentDetails from "./PaymentDetails";
import ShipmentDetails from "./ShipmentDetails";
import CarrierAssigneeDetails from "./CarrierAsigneeDetails";

interface TrackingOrderDetailsProps {
  order: TrackingOrderInfo;
  route?: RouteWithTracking | null;
}

const TrackingOrderDetails: React.FC<TrackingOrderDetailsProps> = ({
  order,
  route,
}) => {
  console.log(route);
  return (
    <div className="text-black p-2">
      <OrderStatus status={order.status} />
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <CarrierDetails carrier={order.carrier} />
        <PaymentDetails payment={order.payment} />
        <AssigneeDetails assignee={order.shipper_assignee} />
        <CarrierAssigneeDetails carrier={order.carrier} />
      </div>{" "}
      <ShipmentDetails operations={order.operations} />

    </div>
  );
};

export default TrackingOrderDetails;
