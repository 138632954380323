import axios from "axios";
import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { TrackingOrderInfo } from "../../types/orders";
import { RouteWithTracking } from "../../types/routes";
import { useToast } from "../../hooks/useToast";
import TrackingOrderDetails from "./TrackingOrderDetails/TrackingOrderDetails";
import SlimMapComponent from "./TrackingMap/SlimMapComponent";
import TrackingOrderChat from "./TrackingOrderDetails/TrackingOrderChat";
import { CircularProgress } from "@mui/material";

const TrackingContainer = () => {
  const [order, setOrder] = useState<TrackingOrderInfo | null>(null);
  const [route, setRoute] = useState<RouteWithTracking | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const { uuid } = useParams<{ uuid: string }>();
  const toast = useToast();

  useEffect(() => {
    axios
      .get(`${import.meta.env.VITE_PUBLIC_API_URL}orders/tracking/${uuid}/`)
      .then((response) => {
        setOrder(response.data);
      })
      .catch(() => {
        toast({
          type: "error",
          message: "Order not found",
        });
        setError(true);
      });
  }, [uuid]);

  useEffect(() => {
    if (!order) return;
    axios
      .get(`${import.meta.env.VITE_PUBLIC_API_URL}route/tracking/${uuid}/`)
      .then((response) => {
        setRoute(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [order, uuid]);

  if (error) {
    return (
      <Navigate to="/error" replace={true} state={{ from: "/tracking" }} />
    );
  }

  if (!order || loading) return <CircularProgress size={60} />;
  return (
    <div className="grid grid-cols-2">
      <TrackingOrderDetails order={order} route={route} />
      <div className="">
        <SlimMapComponent
          truck={order.truck}
          order={order}
          route={route as any}
          hoveredRoute={null}
          activeSection={null}
          hoveredSection={null}
          setHoveredRoute={() => {}}
          onSetHoveredSection={() => {}}
          onSetActiveSection={() => {}}
        />
      </div>
      <div className="col-span-2 w-full h-full mx-auto max-w-6xl">
        <TrackingOrderChat order={order} />
      </div>
    </div>
  );
};

export default TrackingContainer;
