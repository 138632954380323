interface EventCardProps {
  title: string;
  children: React.ReactNode;
}

const EventCard: React.FC<EventCardProps> = ({ title, children }) => {
  return (
    <div className="p-4 shadow-lg rounded-lg text-black bg-white">
      <h2 className="text-xl font-bold mb-2">{title}</h2>
      {children}
    </div>
  );
};

export default EventCard;
