import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { FaTrash } from "react-icons/fa6";
import { useTranslation } from "react-i18next";

interface TableRow {
  speed: number;
  consumption: number;
}

interface FreeFlowTableProps {
  value: string;
  onChange: (value: string) => void;
}

const FreeFlowTable: React.FC<FreeFlowTableProps> = ({ value, onChange }) => {
  const { t } = useTranslation();

  const parseFromString = (value: string) => {
    const rows = value.split(",");
    const tableRows: TableRow[] = [];

    for (let i = 0; i < rows.length; i += 2) {
      const speed = parseInt(rows[i], 10);
      const consumption = parseFloat(rows[i + 1]) * 100; // Convert from milliliters to liters
      tableRows.push({ speed, consumption });
    }

    return tableRows;
  };

  const toString = (rows: TableRow[]): string => {
    return rows.map((row) => `${row.speed},${row.consumption / 100}`).join(","); // Convert from liters to milliliters
  };

  const [rows, setRows] = useState<TableRow[]>(parseFromString(value));

  const addRow = () => {
    setRows([...rows, { speed: 0, consumption: 0 }]);
  };

  const deleteRow = (index: number) => {
    const newRows = rows.filter((_, i) => i !== index);
    setRows(newRows);
    handleChange(newRows);
  };

  const updateRow = (index: number, updatedRow: TableRow) => {
    const newRows = rows.map((row, i) => (i === index ? updatedRow : row));
    setRows(newRows);
    handleChange(newRows);
  };

  const handleChange = (newRows: TableRow[]) => {
    onChange(toString(newRows));
  };

  return (
    <div className="p-4">
      <h3 className="font-bold">{t("truckDetail.general.freeFlowSpeed.title")}</h3>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                {t("truckDetail.general.freeFlowSpeed.speed")}{" "}
                (km/h)</TableCell>
              <TableCell>
                {t("truckDetail.general.freeFlowSpeed.consumption")} {""}
                (L/100 km)</TableCell>
              <TableCell>
               
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell>
                  <TextField
                    type="number"
                    value={row.speed}
                    onChange={(e) => {
                      updateRow(index, {
                        ...row,
                        speed: parseInt(e.target.value, 10),
                      });
                    }}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    value={row.consumption}
                    onChange={(e) => {
                      updateRow(index, {
                        ...row,
                        consumption: parseFloat(e.target.value),
                      });
                    }}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => deleteRow(index)}
                    color="secondary"
                  >
                    <FaTrash />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        onClick={addRow}
        variant="contained"
        color="primary"
        type="button"
        sx={{ mt: 2 }}
      >
        {t("truckDetail.general.freeFlowSpeed.add")}
      </Button>
    </div>
  );
};

export default FreeFlowTable;
