import {
  Typography,
  List,
  Divider,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddEmployeeForm from "./AddEmployeeForm";
import { Employee } from "../../types/employees";
import useAxios from "../../hooks/useAxios";
import { useToast } from "../../hooks/useToast";

interface EmployeesProps {
  link: string;
}

const Employees: React.FC<EmployeesProps> = ({ link }) => {
  const { t } = useTranslation();
  const [isOpen, setIsModalOpen] = useState(false);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [loading, setLoading] = useState(false);
  const axios = useAxios();
  const toast = useToast();

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get(link);
        setEmployees(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchEmployees();
  }, [link]);

  const onAddEmployee = async (data: any) => {
    setLoading(true);
    try {
      const response = await axios.post(link, data);
      const newEmployee = response.data;
      setEmployees((prevEmployees) => [...prevEmployees, newEmployee]);
      toast({
        message: "Employee added",
        type: "success",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleAddEmployee = async (data: any) => {
    await onAddEmployee(data);
    setIsModalOpen(false);
  };

  const handleDialogClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="bg-neutral-300 dark:bg-zinc-800 p-4 border border-gray-400 text-black dark:text-white">
      <div className="mb-4">
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          {t("employeeList.addEmployee")}
        </Button>
      </div>
      <Typography variant="h5" component="h2" gutterBottom>
        {t("employeeList.title")}
      </Typography>
      {employees.length > 0 ? (
        <List>
          {employees.map((employee) => (
            <>
              <Link
                to={`${link}/${employee.id}`}
                className="flex justify-between w-full text-black"
              >
                <div className="flex flex-col justify-center">
                  <p>{employee.full_name}</p>
                  <p>{employee.email}</p>
                </div>
              </Link>
              <Divider />
            </>
          ))}
        </List>
      ) : (
        <Typography variant="body1">{t("employeeList.noEmployees")}</Typography>
      )}
      <Dialog open={isOpen} onClose={handleDialogClose}>
        <DialogTitle>{t("employeeList.addEmployeeModal.title")}</DialogTitle>
        <DialogContent style={{ width: "600px" }}>
          <AddEmployeeForm onSubmit={handleAddEmployee} loading={loading} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            {t("common.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Employees;
