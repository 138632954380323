import { useState } from "react";
import { SuggestionType } from "../../types/geo";
import useAxios from "../../hooks/useAxios";
import { Autocomplete, TextField } from "@mui/material";
import ReactCountryFlag from "react-country-flag";
import { debounce } from "lodash";

interface GeoAutocompleteProps {
  size?: "small" | "medium";
  value: string | SuggestionType | null;
  label: string;
  country?: string;
  onChange: (
    value: string | null | SuggestionType
  ) => void;
}

const GeoAutocomplete: React.FC<GeoAutocompleteProps> = ({
  size,
  value,
  label,
  country,
  onChange,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<SuggestionType[]>([]);
  const axios = useAxios();

  const lookupPlace = async (placeId: string) => {
    setLoading(true);
    try {
      const lookupResponse = await axios.post(`integrations/here/lookup/`, {
        id: placeId,
        lang: "pl",
      });
      const { address } = lookupResponse.data.result;
      const { lat: latitude, lng: longitude } =
        lookupResponse.data.result.position;
      return { latitude, longitude, address };
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSuggestions = async (query: string) => {
    setLoading(true);
    setSuggestions([]);

    try {
      const response = await axios.post(`integrations/here/autocomplete/`, {
        query,
        lang: "pl",
        country,
      });
      setSuggestions(response.data.suggestions);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchSuggestionsWithDebounce = debounce(async (query: string) => {
    if (!query) return;
    if (query.length < 3) return;
    fetchSuggestions(query);
  }, 300);

  return (
    <Autocomplete
      value={value}
      onChange={async (event, newValue) => {
        event?.preventDefault();
        if (newValue && typeof newValue !== "string") {
          const lookupResult = await lookupPlace(newValue.id);
          newValue = { ...newValue, ...lookupResult };
          onChange(newValue);
        } else {
          onChange(newValue);
        }
      }}
      size={size}
      freeSolo
      fullWidth
      options={suggestions}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.title
      }
      onInputChange={(event, newInputValue) => {
        event?.preventDefault();
        fetchSuggestionsWithDebounce(newInputValue);
      }}
      filterOptions={(options) => {
        return options;
      }}
      loading={loading}
      renderInput={(params) => (
        <TextField {...params} label={label} variant="outlined" />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.title}>
            <div className="flex items-center gap-2">
              {option.address?.country && (
                <ReactCountryFlag
                  countryCode={option.address.country}
                  svg
                  style={{ width: "0.7em", height: "0.7em" }}
                  title={option.address.country}
                />
              )}
              {option.title}
            </div>
          </li>
        );
      }}
    />
  );
};

export default GeoAutocomplete;
