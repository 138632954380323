import { OrderInfo } from "../../../../types/orders";
import TruckOrders from "./TruckOrders";

interface MapTruckOrdersProps {
  label: string;
  clickHandler: (order: OrderInfo) => void;
}

export const MapTruckOrders: React.FC<MapTruckOrdersProps> = ({
  label,
  clickHandler,
}) => {
  return (
    <div className="w-full z-50 flex flex-col gap-3 items-center justify-center ">
      <TruckOrders label={label} clickHandler={clickHandler} />
    </div>
  );
};

export default MapTruckOrders;
