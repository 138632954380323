import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { GRAPH_COLORS } from "../../../utils/colors";
import { useTranslation } from "react-i18next";

interface Income {
  payment__price_currency: string;
  total_income: number;
}

interface MonthlyIncome {
  month: number;
  year: number;
  incomes: Income[];
}

interface MonthlyIncomesGraphProps {
  monthlyIncomes: MonthlyIncome[];
}

const MonthlyIncomesGraph: React.FC<MonthlyIncomesGraphProps> = ({
  monthlyIncomes,
}) => {
  const {t} = useTranslation();
  const aggregatedData = monthlyIncomes.map((monthlyIncome) => {
    const incomeByCurrency: { [key: string]: number } = {};
    monthlyIncome.incomes.forEach((income) => {
      if (incomeByCurrency[income.payment__price_currency]) {
        incomeByCurrency[income.payment__price_currency] += income.total_income;
      } else {
        incomeByCurrency[income.payment__price_currency] = income.total_income;
      }
    });
    return {
      month: `${monthlyIncome.month}/${monthlyIncome.year}`,
      ...incomeByCurrency,
    };
  });

  const uniqueCurrencies = Array.from(
    new Set(
      monthlyIncomes.flatMap((monthlyIncome) =>
        monthlyIncome.incomes.map((income) => income.payment__price_currency)
      )
    )
  );

  return (
    <div>
      <h1 className="text-center">
        {t("insights.incomes.monthlyIncome.title")}
      </h1>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          width={600}
          height={300}
          data={aggregatedData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Legend />
          {uniqueCurrencies.map((currency, index) => (
            <Line
              type="monotone"
              dataKey={currency}
              stroke={GRAPH_COLORS[index % GRAPH_COLORS.length]}
              key={currency}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MonthlyIncomesGraph;
