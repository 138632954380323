import { TextField, Button } from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface AddDriverFormFormProps {
  loading: boolean;
  onSubmit: (data: any) => void;
}

const AddDriverForm: React.FC<AddDriverFormFormProps> = ({
  loading,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-2 gap-4 p-4">
      <Controller
        name="first_name"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("driversList.addDriverModal.firstName")}
            variant="outlined"
            required
          />
        )}
      />
      <Controller
        name="last_name"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("driversList.addDriverModal.lastName")}
            variant="outlined"
            required
          />
        )}
      />
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("driversList.addDriverModal.email")}
            variant="outlined"
            type="email"
          />
        )}
      />
      <Controller
        name="phone"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("driversList.addDriverModal.phone")}
            variant="outlined"
          />
        )}
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={loading}
      >
        {t("driversList.addDriverModal.save")}
      </Button>
    </form>
  );
};

export default AddDriverForm;
