import { FaCheckCircle, FaDownload } from "react-icons/fa";
import { Operation } from "../../../types/orders";
import DragDropInput from "../../../components/input/DragDropInput";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import { getFileName } from "../../../utils/file";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import { Button, TextField } from "@mui/material";

interface OperationDetailProps {
  operation: Operation;
  fileUploading: boolean;
  onSubmit: (data: any, position: number) => void;
  onUploadCmrFiles: (file: FileList, operation: number) => void;
  onDeleteCmrFile: (fileId: number, operation: number) => void;
}

const OperationDetail: React.FC<OperationDetailProps> = ({
  operation,
  fileUploading,
  onSubmit,
  onUploadCmrFiles,
  onDeleteCmrFile,
}) => {
  const { t } = useTranslation();

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      latitude: operation.latitude,
      longitude: operation.longitude,
      postal_code: operation.postal_code,
      time_begin: operation.time_begin,
      time_end: operation.time_end,
      completed_at: operation.completed_at,
      address: operation.address,
      remarks: operation.remarks,
    },
  });

  const onOperationSubmit = (data: any) => {
    onSubmit(data, operation.position);
  };

  useEffect(() => {
    setValue("latitude", operation.latitude);
    setValue("longitude", operation.longitude);
    setValue("postal_code", operation.postal_code);
    setValue("time_begin", operation.time_begin);
    setValue("time_end", operation.time_end);
    setValue("completed_at", operation.completed_at);
    setValue("address", operation.address);
    setValue("remarks", operation.remarks);
  }, [operation]);

  return (
    <>
      <div className="text-xl font-bold text-gray-800 dark:text-secondary flex items-center ">
        <div>{t(`operationTypes.${operation.operation_type}`)}</div>
        {operation.completed_at && (
          <FaCheckCircle className="ml-2 text-green-500" />
        )}
      </div>
      <form
        className="grid grid-cols-2 gap-4 text-gray-700 mt-2"
        onSubmit={handleSubmit(onOperationSubmit)}
      >
        <Controller
          name="latitude"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("orderDetails.operations.latitude")}
              variant="outlined"
              type="number"
            />
          )}
        />
        <Controller
          name="longitude"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("orderDetails.operations.longitude")}
              variant="outlined"
              type="number"
            />
          )}
        />
        <Controller
          name="postal_code"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("orderDetails.operations.postalCode")}
              variant="outlined"
            />
          )}
        />
        <Controller
          name="address"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("orderDetails.operations.address")}
              variant="outlined"
            />
          )}
        />
        <Controller
          name={`time_begin`}
          control={control}
          render={({ field }) => (
            <DateTimePicker
              label="Początek operacji"
              sx={{ mb: 2 }}
              value={field.value ? new Date(field.value) : null}
              onChange={(date) => {
                if (!date) return;
                setValue(`time_begin`, new Date(date).toISOString());
              }}
            />
          )}
        />
        <Controller
          name={`time_end`}
          control={control}
          render={({ field }) => (
            <DateTimePicker
              label="Koniec operacji"
              sx={{ mb: 2 }}
              value={field.value ? new Date(field.value) : null}
              onChange={(date) => {
                if (!date) return;
                setValue(`time_end`, new Date(date).toISOString());
              }}
            />
          )}
        />
        <Controller
          name={`completed_at`}
          control={control}
          render={({ field }) => (
            <DateTimePicker
              label="Realna Data zakończenia"
              sx={{ mb: 2 }}
              value={field.value ? new Date(field.value) : null}
              onChange={(date) => {
                setValue(`completed_at`, date ? new Date(date).toISOString() : null);
              }}
            />
          )}
        />
        <Controller
          name="remarks"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("orderDetails.operations.remarks")}
              variant="outlined"
              multiline
              rows={4}
            />
          )}
        />
        <div>
          {operation.cmrs &&
            operation.cmrs.map((cmr, index) => (
              <div key={index} className="flex items-center gap-2">
                <a
                  href={cmr.cmr_file}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-accent hover:text-blue-800 flex items-center"
                >
                  <FaDownload className="mr-2" />
                  {getFileName(cmr.cmr_file)}
                </a>
                <Button
                  variant="contained"
                  color="error"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onDeleteCmrFile(cmr.id, operation.position);
                  }}
                >
                  {t("common.delete")}
                </Button>
              </div>
            ))}
        </div>
        <div className="mt-4">
          <DragDropInput
            uploading={fileUploading}
            handleChange={(file) => onUploadCmrFiles(file, operation.position)}
            label="CMR Upload"
            name="CMR"
            types={["PDF", "PNG", "JPG", "JPEG"]}
            multiple={true}
          />
        </div>
        <div className="mt-4 col-span-2">
          <Button type="submit" variant="contained" color="primary" fullWidth>
            {t("common.save")}
          </Button>
        </div>
      </form>
    </>
  );
};

export default OperationDetail;
