import { useTranslation } from "react-i18next";
import { RecurringDetail } from "../../../types/expenses";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { getCurrencySymbol } from "../../../utils/currencies";

interface MaintenanceIncomingProps {
  data: RecurringDetail[];
}

const RecurringExpenseNotPaid: React.FC<MaintenanceIncomingProps> = ({
  data,
}) => {
  const { t } = useTranslation();
  const expense = data[0];

  return (
    <>
      <div className="flex items-center justify-between">
        <div>
          <h3 className="font-semibold">
            {t("dashboard.incomingExpense.lastDate")}
          </h3>
          {expense.last_occurrence && (
            <p>{new Date(expense.last_occurrence.date).toLocaleDateString()}</p>
          )}{" "}
          <p>{expense.expense.description}</p>
        </div>
      </div>
      <div className="mt-4">
        <p>
          {expense.expense.net_amount}
          {getCurrencySymbol(expense.expense.currency)}
        </p>
        <p>{t(`expenseTypes.${expense.expense.category}`)}</p>

        <Link
          to={
            expense.expense.truck
              ? `/my-fleet/trucks/${expense.expense.truck}?tab=expenses&recommendation=expense_incoming&expense=${expense.id}`
              : `/my-fleet?tab=expenses&recommendation=expense_incoming&expense=${expense.id}`
          }
        >
          <Button variant="contained" color="primary">
            {t("dashboard.incomingExpense.pay")}
          </Button>
        </Link>
      </div>
    </>
  );
};

export default RecurringExpenseNotPaid;
