import React from "react";
import { OrderStatus } from "../../types/orders";
import { useTranslation } from "react-i18next";

const statusStyles = {
  new: "bg-blue-100 text-blue-800",
  assigned: "bg-green-100 text-green-800",
  in_progress: "bg-yellow-100 text-yellow-800",
  done: "bg-purple-100 text-purple-800",
  canceled: "bg-red-100 text-red-800",
  discarded: "bg-gray-100 text-gray-800",
};

interface StatusBadgeProps {
  status: OrderStatus;
}

const StatusBadge: React.FC<StatusBadgeProps> = ({ status }) => {
  const style = statusStyles[status] || "bg-gray-100 text-gray-800";
  const { t } = useTranslation();

  return (
    <span
      className={`text-xs font-semibold mr-2 px-2.5 py-0.5 rounded ${style}`}
    >
      {t(`orders.status.${status}`)}
    </span>
  );
};

export default StatusBadge;
