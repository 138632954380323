import { useEffect, useState } from "react";
import { FiLoader } from "react-icons/fi";
import useAxios from "../../../hooks/useAxios";
import { formatSingleTimeDisplay } from "../../../utils/time";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";

interface IntegrationStatus {
  status: string;
  last_synced: string;
  created_at: string;
  created_by: string;
}

const TransEuContainer = () => {
  const axios = useAxios();
  const {t} = useTranslation();

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [integrationStatus, setIntegrationStatus] =
    useState<IntegrationStatus | null>(null);

  useEffect(() => {
    axios
      .get("integrations/trans/status/")
      .then((res) => {
        setIntegrationStatus(res.data);
      })
      .catch((err) => {
        console.error(err);
        setError("Failed to check integration status.");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onIntegrate = () => {
    axios
      .post("integrations/trans/")
      .then((res) => {
        window.open(res.data.auth_url, "_blank");
      })
      .catch((err) => {
        console.error(err);
        setError("Failed to initiate integration.");
      });
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-secondary">
        <FiLoader className="animate-spin text-primary text-4xl" />
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="bg-white p-8 rounded-lg shadow-lg text-center w-3/4 max-w-md">
        {error && <div className="mb-4 text-red-500">{error}</div>}
        {integrationStatus?.status === "disconnected" && (
          <>
            <h2 className="text-2xl font-bold mb-4 text-gray-800">
              {t("integrations.freightExchange.transeu.disconnected")}
            </h2>
            <p className="mb-4 text-gray-600">
              {t("integrations.freightExchange.transeu.connectToFetchOrders")}
            </p>
            <Button
              variant="contained"
              onClick={onIntegrate}
              
            >
              {t("integrations.trans.connect")}
            </Button>
          </>
        )}
        {integrationStatus?.status === "connected" && (
          <>
            <h2 className="text-2xl font-bold mb-4 text-gray-800">
              {t("integrations.freightExchange.transeu.connected")}
            </h2>
            <div className="mb-4 text-left text-gray-700">
              <p>
                <strong>{t("integrations.freightExchange.transeu.lastFetch")}:</strong>{" "}
                {formatSingleTimeDisplay(integrationStatus.last_synced, "dd/MM/yy HH:mm")}
              </p>
              <p>
                <strong>{t("integrations.freightExchange.transeu.createdAt")}:</strong>{" "}
                {formatSingleTimeDisplay(integrationStatus.created_at, "dd/MM/yy HH:mm")}
              </p>
              <p>
                <strong>{t("integrations.freightExchange.transeu.createdBy")}:</strong> {integrationStatus.created_by}
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TransEuContainer;
