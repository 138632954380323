import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { GRAPH_COLORS } from "../../../utils/colors";
import { useTranslation } from "react-i18next";

interface TruckMilage {
  truck: string;
  milage: number;
}

interface MonthlyMilage {
  month: number;
  year: number;
  truck_milage: TruckMilage[];
}

interface MonthlyIncomesGraphProps {
  monthlyMilages: MonthlyMilage[];
}

const TruckMilageGraph: React.FC<MonthlyIncomesGraphProps> = ({
  monthlyMilages,
}) => {
  const { t } = useTranslation();
  const aggregatedData = monthlyMilages.map((monthlyMilage) => {
    const incomeByCurrency: { [key: string]: number } = {};
    monthlyMilage.truck_milage.forEach((milage) => {
      if (incomeByCurrency[milage.truck]) {
        incomeByCurrency[milage.truck] += milage.milage;
      } else {
        incomeByCurrency[milage.truck] = milage.milage;
      }
    });
    return {
      month: `${monthlyMilage.month}/${monthlyMilage.year}`,
      ...incomeByCurrency,
    };
  });

  const uniqueCurrencies = Array.from(
    new Set(
      monthlyMilages.flatMap((monthlyMilage) =>
        monthlyMilage.truck_milage.map((milage) => milage.truck)
      )
    )
  );

  return (
    <div>
      <h1 className="text-center">
        {t("insights.trucks.milage.title")}
      </h1>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          width={600}
          height={300}
          data={aggregatedData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Legend />
          {uniqueCurrencies.map((currency, index) => (
            <Line
              type="monotone"
              dataKey={currency}
              stroke={GRAPH_COLORS[index % GRAPH_COLORS.length]}
              key={currency}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TruckMilageGraph;
