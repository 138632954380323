import { createContext, useContext, useEffect, useMemo, useState } from "react";
import useAxios from "../hooks/useAxios";
import { BankAccount, BankAccountModel } from "../types/companies";

interface BankAccountsContextType {
  bankAccounts: BankAccountModel[];
  loading: boolean;
  addBankAccount: (bankAccount: BankAccount) => Promise<BankAccountModel>;
  updateBankAccount: (
    id: number, bankAccount: BankAccount
  ) => Promise<BankAccountModel>;
  deleteBankAccount: (bankAccountId: number) => Promise<void>;
}

const BankAccountsContext = createContext<BankAccountsContextType>({
  bankAccounts: [],
  loading: true,
  addBankAccount: async () => {
    throw new Error("StriderProvider not found");
  },
  updateBankAccount: async () => {
    throw new Error("StriderProvider not found");
  },
  deleteBankAccount: async () => {
    throw new Error("StriderProvider not found");
  },
});

interface BankAccountsProviderProps {
  children: React.ReactNode;
}

export const BankAccountsProvider: React.FC<BankAccountsProviderProps> = ({
  children,
}) => {
  const [bankAccounts, setBankAccounts] = useState<BankAccountModel[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const axios = useAxios();

  const addBankAccount = async (bankAccount: BankAccount) => {
    const response = await axios.post(
      "companies/my-company/bank-accounts",
      bankAccount
    );
    setBankAccounts((prev) => [...prev, response.data]);
    return response.data;
  };

  useEffect(() => {
    const fetchBankAccounts = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`companies/my-company/bank-accounts`);
        setBankAccounts(response.data);
      } catch (error) {
        console.error("Error fetching bank accounts:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchBankAccounts();
  }, []);

  const updateBankAccount = async (id: number, bankAccount: BankAccount) => {
    const response = await axios.put(
      `companies/my-company/bank-accounts/${id}`,
      bankAccount
    );
    setBankAccounts((prev) =>
      prev.map((account) =>
        account.id === response.data.id ? response.data : account
      )
    );
    return response.data;
  };

  const deleteBankAccount = async (bankAccountId: number) => {
    await axios.delete(`companies/my-company/bank-accounts/${bankAccountId}`);
    setBankAccounts((prev) =>
      prev.filter((account) => account.id !== bankAccountId)
    );
  };

  const value = useMemo(
    () => ({
      loading,
      bankAccounts,
      addBankAccount,
      deleteBankAccount,
      updateBankAccount,
    }),
    [loading, bankAccounts]
  );
  return (
    <BankAccountsContext.Provider value={value}>
      {children}
    </BankAccountsContext.Provider>
  );
};

export const useBankAccounts = () => {
  return useContext(BankAccountsContext);
};
