import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import useWebSocket from "react-use-websocket";
import { useLocalStorage } from "../hooks/useLocalStorage";
import useAxios from "../hooks/useAxios";
import { useAuth } from "./AuthContext";
import { Conversation } from "../types/chat";

interface ChatContextType {
  conversations: Conversation[];
  openedConversations: number[];
  notifications: any[];
  chatDrawerOpen: boolean;
  toggleChatDrawerOpen: () => void;
  openConversation: (conversationId: number) => void;
  closeConversation: (conversationId: number) => void;
}

const ChatContext = createContext<ChatContextType>({
  conversations: [],
  openedConversations: [],
  notifications: [],
  chatDrawerOpen: false,
  toggleChatDrawerOpen: () => {},
  openConversation: () => {},
  closeConversation: () => {},
});

interface ChatProviderProps {
  children: React.ReactNode;
}

interface Notification {
  type: string;
  data: any;
}

export const ChatProvider: React.FC<ChatProviderProps> = ({ children }) => {
  const { token } = useAuth();
  const [notifications, setNotifications] = useState<any[]>([]);
  const { lastMessage } = useWebSocket(
    `${import.meta.env.VITE_PUBLIC_BACKEND_URL}ws/chat/`,
    {
      protocols: token ? ["authorization", token] : [],
    }
  );
  const [chatDrawerOpen, setChatDrawerOpen] = useLocalStorage(
    "chatDrawerOpen",
    false
  );
  const [openedConversations, setOpenedConversations] = useLocalStorage<number[]>(
    "openedConversations",
    []
  );

  const axios = useAxios();
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const toggleChatDrawerOpen = () => {
    setChatDrawerOpen(!chatDrawerOpen);
  };

  const handleIncomingNotification = (notification: Notification) => {
    console.log("New notification", notification);
    if (notification.type === "new_bid") {
      const conversationId = notification.data.conversation_id;
      axios.get(`/chat/conversations/${conversationId}/`).then((response) => {
        setConversations((prevConversations) => [
          ...prevConversations,
          response.data,
        ]);
      });
    }
  };

  const openConversation = (conversationId: number) => {
    if (!openedConversations.includes(conversationId)) {
      setOpenedConversations([...openedConversations, conversationId]);
    }
  };

  const closeConversation = (conversationId: number) => {
    setOpenedConversations(
      openedConversations.filter((conv: number) => conv !== conversationId)
    );
  };

  useEffect(() => {
    if (openedConversations.length > 0) {
      const conversationId =
        openedConversations[openedConversations.length - 1];
      const existingConversation = conversations.find(
        (conv) => conv.id === conversationId
      );

      if (!existingConversation) {
        axios.get(`/chat/conversations/${conversationId}/`).then((response) => {
          setConversations((prevConversations) => [
            ...prevConversations,
            response.data,
          ]);
        });
      }
    }
  }, [openedConversations]);

  useEffect(() => {
    if (lastMessage !== null) {
      const notification = JSON.parse(lastMessage.data);
      handleIncomingNotification(notification);
      setNotifications((prevNotifications) => [
        ...prevNotifications,
        notification,
      ]);
    }
  }, [lastMessage]);

  useEffect(() => {
    axios.get("/chat/conversations/").then((response) => {
      setConversations(response.data);
    });
  }, []);

  const value = useMemo(
    () => ({
      conversations,
      notifications,
      chatDrawerOpen,
      openedConversations,
      toggleChatDrawerOpen,
      openConversation,
      closeConversation,
    }),
    [notifications, chatDrawerOpen, conversations, openedConversations]
  );

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

export const useChat = () => useContext(ChatContext);
