import { Link, useParams } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import { useEffect, useState } from "react";
import { useToast } from "../../hooks/useToast";
import ShipperForm from "./ShipperForm";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useTranslation } from "react-i18next";
import ShipperOrders from "./ShipperOrders";
import { Breadcrumbs, Typography } from "@mui/material";
import { CorporateDetails } from "../../types/base";

const ShipperContainer = () => {
  const { t } = useTranslation();
  const { shipperId } = useParams<{ shipperId: string }>();
  const axios = useAxios();
  const toast = useToast();

  const [shipper, setShipper] = useState<CorporateDetails | null>(null);

  useEffect(() => {
    if (!shipperId) return;
    axios
      .get(`/shippers/${shipperId}/`)
      .then((res) => {
        setShipper(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [shipperId]);

  const handleSubmit = (formData: any) => {
    axios
      .patch(`/shippers/${shipperId}/`, formData)
      .then((res) => {
        toast({
          message: "Shipper updated successfully",
          type: "success",
        });
        setShipper(res.data);
      })
      .catch((err) => {
        const parsedError = Object.keys(err.response.data)
          .map((key) => `${key}: ${err.response.data[key]}`)
          .join("\n");

        console.error(err);
        toast({
          message: parsedError,
          type: "error",
        });
      });
  };

  if (!shipper) return null;

  return (
    <div className="p-4 text-black">
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">{t("breadcrumb.home")}</Link>
        <Link to="/shippers">{t("breadcrumb.shippers")}</Link>
        <Typography color="text.primary">
          {t("shipperDetails.title")} - {shipper.name}
        </Typography>
      </Breadcrumbs>

      <Tabs>
        <TabList>
          <Tab>{t("shipperDetails.tabs.general")}</Tab>
          <Tab>{t("shipperDetails.tabs.orders")}</Tab>
        </TabList>

        <TabPanel>
          <ShipperForm shipper={shipper} onFormSubmit={handleSubmit} />
        </TabPanel>

        <TabPanel>
          <ShipperOrders />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default ShipperContainer;
