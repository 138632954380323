import React from "react";
import StatusBadge from "../../../components/statusBadge/StatusBadge";

interface OrderStatusProps {
  status: string;
}

const OrderStatus: React.FC<OrderStatusProps> = ({ status }) => {
  return (
    <div className="p-4">
      <StatusBadge status={status as any} />
    </div>
  );
};

export default OrderStatus;
