import React, { useCallback } from "react";

import { Waypoint as WaypointType } from "../../types/geo";
import { useTranslation } from "react-i18next";
import Waypoint from "./Waypoint";
import { IconButton, InputLabel } from "@mui/material";
import { IoMdAddCircleOutline } from "react-icons/io";
import { FaCube } from "react-icons/fa6";
import { useMap } from "../../contexts/MapContext";

interface WaypointsProps {
  onFillOrderCallback?: () => void;
}

const Waypoints: React.FC<WaypointsProps> = ({ onFillOrderCallback }) => {
  const {
    waypoints,
    onMoveWaypoint,
    onRemoveWaypoint,
    onUpdateWaypoint,
    onAddWaypoint,
  } = useMap();
  const { t } = useTranslation();

  const renderWaypoint = useCallback(
    (waypoint: WaypointType, index: number) => {
      return (
        <Waypoint
          key={waypoint.id}
          index={index}
          waypoint={waypoint}
          moveWaypoint={onMoveWaypoint}
          removeWaypoint={onRemoveWaypoint}
          updateWaypoint={onUpdateWaypoint}
        />
      );
    },
    [onMoveWaypoint, waypoints]
  );

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <h2>
          {t("waypoints.waypoints")} ({waypoints.length})
        </h2>
        {onFillOrderCallback && (
          <div className="flex">
            <InputLabel className="mt-2">{t("map.fillFromOrder")}</InputLabel>
            <IconButton onClick={onFillOrderCallback}>
              <FaCube className="text-accent"/>
            </IconButton>
          </div>
        )}
      </div>
      <div>
        {waypoints.map((waypoint, index) => renderWaypoint(waypoint, index))}
      </div>
      <div
        className="cursor-pointer hover:bg-zinc-700 rounded-3xl"
        onClick={onAddWaypoint}
      >
        <IconButton>
          <IoMdAddCircleOutline className="text-accent" />
        </IconButton>
        <span>{t("waypoints.addWaypoint")}</span>
      </div>
    </div>
  );
};

export default Waypoints;
