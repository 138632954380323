import { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { useNavigate } from "react-router-dom";

const TransEuAuthCallbackContainer = () => {
  const params = new URLSearchParams(window.location.search);
  const axios = useAxios();
  const navigate = useNavigate();

  const error = params.get("error");
  const error_description = params.get("error_description");

  const [connectError, setConnectError] = useState<string>("");

  useEffect(() => {
    if (error) {
      return;
    }
    const code = params.get("code");
    if (!code) {
      return;
    }
    const state = params.get("state");

    if(!code || !state) return;

    if (localStorage.getItem("setting_code") === "true") {
      return;
    }
    localStorage.setItem("setting_code", "true");
    axios
      .post("integrations/trans/finish/", {
        code: code,
        state: state,
      })
      .then(() => {
        localStorage.setItem("setting_code", "false");
        navigate("/", { replace: true });
      })
      .catch((err) => {
        localStorage.setItem("setting_code", "false");
        console.error(err);
        console.error(err.response.data.non_field_errors[0]);
        setConnectError(
          `Error connecting: ${err.response.data.non_field_errors[0]}`
        );
      });
  }, [params]);

  if (error) {
    return (
      <div className="text-black">
        <h1>There was an error</h1>
        <p>{error_description}</p>
      </div>
    );
  }

  if (connectError)
    return (
      <div className="text-black">
        <h1>There was an error</h1>
        <p>{connectError}</p>
      </div>
    );

  return null;
};

export default TransEuAuthCallbackContainer;
