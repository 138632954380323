import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import useAxios from "../hooks/useAxios";
import { OfferFormData, OfferModel } from "../types/offers";
import { debounce } from "lodash";
import { SuggestionType } from "../types/geo";
import { useToast } from "../hooks/useToast";
import { OFFER_SEARCH_DISTANCES } from "../consts/offers";
import { useLocalStorage } from "../hooks/useLocalStorage";

interface ExchangeContextType {
  offers: OfferModel[];
  loading: boolean;
  tabs: any[];
  activeTab: number;
  hasMore: boolean;
  page: number;
  onSetPage: (page: number) => void;
  addFreightOffer: (data: OfferFormData) => void;
  setActiveTab: (index: number) => void;
  addTab: () => void;
  removeTab: (index: number) => void;
  swapLocation: () => void;
  onUpdateTab: (index: number, key: string, value: any) => void;
}

const ExchangeContext = createContext<ExchangeContextType>({
  offers: [],
  loading: true,
  tabs: [],
  activeTab: 0,
  hasMore: true,
  page: 1,
  onSetPage: () => {},
  addFreightOffer: () => {},
  setActiveTab: () => {},
  addTab: () => {},
  removeTab: () => {},
  swapLocation: () => {},
  onUpdateTab: () => {},
});

interface ExchangeProviderProps {
  children: React.ReactNode;
}

interface ExchangeTabFormData {
  loadingSearch: null | SuggestionType;
  unloadingSearch: null | SuggestionType;
  loadingDistance: number | null;
  unloadingDistance: number | null;
  weightFrom: number | null;
  weightTo: number | null;
  ldm: number | null;
  truckTypes: string[];
}

export const ExchangeProvider: React.FC<ExchangeProviderProps> = ({
  children,
}) => {
  const [offers, setOffers] = useState<OfferModel[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [tabs, setTabs] = useLocalStorage<ExchangeTabFormData[]>("exchangeTabs", [
    {
      loadingSearch: null,
      unloadingSearch: null,
      loadingDistance: OFFER_SEARCH_DISTANCES[0],
      unloadingDistance: OFFER_SEARCH_DISTANCES[0],
      weightFrom: null,
      weightTo: null,
      ldm: null,
      truckTypes: [],
    },
  ]);
  const [activeTab, setActiveTab] = useState(0);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const toast = useToast();

  const axios = useAxios();

  const buildUrl = (tabIndex: number) => {
    const url = "/offers";
    const params = new URLSearchParams();
    params.append("page", page.toString());
    const currentTab = tabs[tabIndex];
    if (
      currentTab.loadingSearch?.latitude &&
      currentTab.loadingSearch?.longitude
    ) {
      params.append("origin_lat", currentTab.loadingSearch.latitude.toString());
      params.append(
        "origin_lon",
        currentTab.loadingSearch.longitude.toString()
      );
    }
    if (
      currentTab.unloadingSearch?.latitude &&
      currentTab.unloadingSearch?.longitude
    ) {
      params.append(
        "destination_lat",
        currentTab.unloadingSearch.latitude.toString()
      );
      params.append(
        "destination_lon",
        currentTab.unloadingSearch.longitude.toString()
      );
    }
    if (currentTab.loadingDistance) {
      params.append(
        "origin_max_distance",
        currentTab.loadingDistance.toString()
      );
    }
    if (currentTab.unloadingDistance) {
      params.append(
        "destination_max_distance",
        currentTab.unloadingDistance.toString()
      );
    }
    if (currentTab.weightFrom) {
      params.append("weight_from", currentTab.weightFrom.toString());
    }
    if (currentTab.weightTo) {
      params.append("weight_to", currentTab.weightTo.toString());
    }
    if (currentTab.truckTypes?.length) {
      params.append("truck_types", currentTab.truckTypes.join(","));
    }
    if (currentTab.ldm) {
      params.append("ldm", currentTab.ldm.toString());
    }
    return `${url}?${params.toString()}`;
  };

  const fetchOffers = useCallback(
    async (url: string, append = false) => {
      setLoading(true);
      try {
        const { data } = await axios.get(url);
        if (append) {
          setOffers((prevOffers) => [...prevOffers, ...data.results]);
        } else {
          setOffers(data.results);
        }
        setHasMore(!!data.next);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [axios]
  );

  const debouncedFetchOffers = useCallback(
    debounce((url, append) => fetchOffers(url, append), 500),
    [fetchOffers]
  );

  useEffect(() => {
    const initialUrl = buildUrl(activeTab);
    debouncedFetchOffers(initialUrl, false);
  }, [activeTab, tabs]);

  useEffect(() => {
    if (page > 1) {
      const url = buildUrl(activeTab);
      debouncedFetchOffers(url, true);
    }
  }, [page]);

  const onChangeLoadingSearch = (value: null | SuggestionType) => {
    const updatedTabs = [...tabs];
    updatedTabs[activeTab].loadingSearch = value;
    setTabs(updatedTabs);
    setPage(1);
  };

  const onChangeUnloadingSearch = (value: null | SuggestionType) => {
    const updatedTabs = [...tabs];
    updatedTabs[activeTab].unloadingSearch = value;
    setTabs(updatedTabs);
    setPage(1);
  };

  const onSetPage = (page: number) => {
    setPage(page);
  };

  const addFreightOffer = async (data: OfferFormData) => {
    const response = await axios.post("/offers/", data);
    if (response.status === 201) {
      toast({
        message: "Offer added successfully",
        type: "success",
      });
    }
  };

  const addTab = () => {
    const tabsLength = tabs.length;
    setTabs([
      ...tabs,
      {
        loadingSearch: null,
        unloadingSearch: null,
        loadingDistance: OFFER_SEARCH_DISTANCES[0],
        unloadingDistance: OFFER_SEARCH_DISTANCES[0],
        weightFrom: 0,
        weightTo: 100,
        ldm: 0,
        truckTypes: [],
      },
    ]);
    setActiveTab(tabsLength);
  };

  const removeTab = (index: number) => {
    if (tabs.length === 1) return; // Prevent removing the last tab
    const newTabs = tabs.filter((_: any, i: number) => i !== index);
    setTabs(newTabs);
    setActiveTab(Math.max(0, activeTab - 1));
  };

  const swapLocation = () => {
    const updatedTabs = [...tabs];
    const tmp = updatedTabs[activeTab].loadingSearch;
    updatedTabs[activeTab].loadingSearch =
      updatedTabs[activeTab].unloadingSearch;
    updatedTabs[activeTab].unloadingSearch = tmp;
    setTabs(updatedTabs);
  };

  const onUpdateTab = (index: number, key: string, value: any) => {
    const updatedTabs = [...tabs];
    updatedTabs[index][key as keyof ExchangeTabFormData] = value;
    setTabs(updatedTabs);
  };

  const value = useMemo(
    () => ({
      offers,
      loading,
      hasMore,
      tabs,
      activeTab,
      page,
      onChangeLoadingSearch,
      onChangeUnloadingSearch,
      onSetPage,
      addFreightOffer,
      swapLocation,
      setActiveTab,
      addTab,
      removeTab,
      onUpdateTab,
    }),
    [offers, loading, hasMore, tabs, activeTab, page]
  );

  return (
    <ExchangeContext.Provider value={value}>
      {children}
    </ExchangeContext.Provider>
  );
};

export const useExchange = () => {
  return useContext(ExchangeContext);
};
