export const COUNTRIES = [
  "pl",
  "de",
  "fr",
  "it",
  "es",
  "nl",
  "be",
  "lu",
  "cz",
  "gb",
  "at",
  "pt",
  "ch",
  "lt",
  "sk",
  "se",
  "lv",
  "ee",
];

export const VAT_RATES = {
  pl: 23,
  de: 19,
  fr: 20,
  it: 22,
  es: 21,
  nl: 21,
  be: 21,
  lu: 17,
  cz: 21,
  at: 20,
  pt: 23,
  ch: 0,
  lt: 21,
  sk: 20,
  se: 25,
  gb: 20,
  lv: 21,
  ee: 20,
};

export const DEFAULT_COUNTRY = "pl";
