import { useCallback, useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import ShippersTable from "./ShippersTable";
import { Link } from "react-router-dom";
import { Breadcrumbs, Button, TextField, Typography } from "@mui/material";
import { t } from "i18next";
import { CorporateDetails } from "../../types/base";
import { extractEndpoint, getPageNumBasedOnUrl } from "../../utils/pagination";
import { debounce } from "lodash";

const SHIPPERS_PER_PAGE = 20;

const ShippersContainer = () => {
  const axios = useAxios();
  const [shippers, setShippers] = useState<CorporateDetails[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalShippers, setTotalShippers] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [nextUrl, setNextUrl] = useState("");
  const [prevUrl, setPrevUrl] = useState("");
  const [loading, setLoading] = useState(true);

  const fetchShippers = async (url: string) => {
    setLoading(true);
    try {
      const response = await axios.get(url);
      setShippers(response.data.results);
      setTotalShippers(response.data.count);
      setNextUrl(response.data.next);
      setPrevUrl(response.data.previous);
      setCurrentPage(getPageNumBasedOnUrl(url));
    } finally {
      setLoading(false);
    }
  };

  const handleSearchQueryChange = (e: any) => {
    setSearchQuery(e.target.value);
  };

  const buildUrl = () => {
    let url = "/shippers/";
    if (searchQuery) {
      url += `?search=${searchQuery}`;
    }
    return url;
  };

  const debouncedFetchShippers = useCallback(
    debounce((url) => fetchShippers(url), 500),
    []
  );

  const fetchShippersByPage = async (page: number) => {
    try {
      setLoading(true);
      const initialUrl = buildUrl();
      const url = searchQuery ? `${initialUrl}&page=${page}` : `${initialUrl}?page=${page}`;
      const { data } = await axios.get(url);
      setShippers(data.results);
      setTotalShippers(data.count);
      setNextUrl(extractEndpoint(data.next));
      setPrevUrl(extractEndpoint(data.previous));
      setCurrentPage(page);
    } catch (error) {
      console.error("Error fetching shippers by page", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const initialUrl = buildUrl();
    debouncedFetchShippers(initialUrl);
  }, [searchQuery]);

  const renderSmartDots = () => {
    const totalPages = Math.ceil(totalShippers / SHIPPERS_PER_PAGE);
    const pages: any[] = [];
    pages.push(1);

    const startPage = Math.max(2, currentPage - 2);
    const endPage = Math.min(totalPages - 1, currentPage + 2);

    if (startPage > 2) {
      pages.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    if (endPage < totalPages - 1) {
      pages.push("...");
    }

    if (totalPages > 1) {
      pages.push(totalPages);
    }

    return pages.map((page, index) => {
      if (page === "...") {
        return (
          <span key={index} className="px-2 py-1 text-black">
            ...
          </span>
        );
      } else {
        return (
          <button
            key={index}
            onClick={() => fetchShippersByPage(page as number)}
            className={`mx-1 px-2 py-1 rounded ${
              page === currentPage
                ? "bg-accent text-white"
                : "bg-gray-300 text-black"
            }`}
            aria-label={`Go to page ${page}`}
          >
            {page}
          </button>
        );
      }
    });
  };

  return (
    <div className="p-4">
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">{t("breadcrumb.home")}</Link>
        <Typography color="text.primary">{t("breadcrumb.shippers")}</Typography>
      </Breadcrumbs>

      <div className="container mx-auto p-4 space-y-4">
        <h1 className="text-2xl font-bold mb-4 text-black">
          {t("shippers.title")}
        </h1>
        <div className="flex justify-between items-end w-full">
          <div className="flex flex-col-reverse gap-2 items-end">
            <div className="flex gap-4">
              <TextField
                value={searchQuery}
                onChange={handleSearchQueryChange}
                placeholder={t("shippers.searchPlaceholder")}
                fullWidth
              />
            </div>
          </div>
        </div>
        <ShippersTable shippers={shippers} loading={loading} />
        <div className="flex justify-center items-center gap-1 my-2">
          {renderSmartDots()}
        </div>
        <div className="flex justify-center gap-4">
          <Button
            variant="contained"
            onClick={() => prevUrl && fetchShippers(prevUrl)}
            disabled={!prevUrl}
          >
            {t("common.prev")}
          </Button>
          <Button
            variant="contained"
            onClick={() => nextUrl && fetchShippers(nextUrl)}
            disabled={!nextUrl}
          >
            {t("common.next")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ShippersContainer;
