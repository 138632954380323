import { useState } from "react";

export const useLocalStorage = <T>(
  keyName: string,
  defaultValue: T,
  validDuration?: number
): [T, (newValue: T) => void] => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const value = window.localStorage.getItem(keyName);
      if (value) {
        const parsedItem = JSON.parse(value);
        const currentTime = new Date().getTime();
        const parsedTimestamp = parsedItem.timestamp;

        if (
          parsedTimestamp !== undefined &&
          (!validDuration || currentTime - parsedTimestamp < validDuration)
        ) {
          return parsedItem.value;
        } else {
          window.localStorage.removeItem(keyName);
        }
      }
      window.localStorage.setItem(
        keyName,
        JSON.stringify({ value: defaultValue, timestamp: new Date().getTime() })
      );

      return defaultValue;
    } catch (err) {
      console.error(err);
      return defaultValue;
    }
  });

  const setValue = (newValue: T) => {
    try {
      const valueToStore = { value: newValue, timestamp: new Date().getTime() };
      window.localStorage.setItem(keyName, JSON.stringify(valueToStore));
    } catch (err) {
      console.error(err);
    }
    setStoredValue(newValue);
  };

  return [storedValue, setValue];
};
