import { Controller, useForm } from "react-hook-form";
import { Button, MenuItem, TextField } from "@mui/material";
import { CompanyUser } from "../../types/companies";
import { useTranslation } from "react-i18next";
import { CARRIER_USER_ROLES } from "../../consts/user";
import { hasAccess } from "../../utils/permissions";
import { useAuth } from "../../contexts/AuthContext";
import { useState } from "react";
import useAxios from "../../hooks/useAxios";
import { useToast } from "../../hooks/useToast";

interface UserFormProps {
  user: CompanyUser;
}

const UserForm: React.FC<UserFormProps> = ({ user }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { control, handleSubmit } = useForm({
    defaultValues: {
      first_name: user.first_name,
      last_name: user.last_name,
      role: user.role,
      phone: user.phone,
    },
  });

  const { account } = useAuth();
  const axios = useAxios();
const toast = useToast();
  const onSubmit = (data: any) => {
    setLoading(true);
    axios
      .put(`companies/my-company/users/${user.id}/`, data)
      .then(() => {
        setLoading(false);
        toast({
            type: "success",
            message: "User updated successfully",
        })
      })
      .finally(() => {
        setLoading(false);
      });

    setLoading(false);
  };

  const { t } = useTranslation();

  if (!account) {
    return null;
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="mt-4 grid grid-cols-2 gap-4"
    >
      <TextField label={t("user.email")} fullWidth disabled />
      <Controller
        name="first_name"
        control={control}
        render={({ field }) => (
          <TextField label={t("user.firstName")} fullWidth {...field} />
        )}
      />
      <Controller
        name="last_name"
        control={control}
        render={({ field }) => (
          <TextField label={t("user.lastName")} fullWidth {...field} />
        )}
      />
      <Controller
        name="role"
        control={control}
        render={({ field }) => (
          <TextField
            select
            label={t("account.users.role")}
            variant="outlined"
            disabled={loading || !hasAccess(account.role, "users", "write")}
            {...field}
          >
            {CARRIER_USER_ROLES.map((role) => (
              <MenuItem key={role} value={role}>
                <div className="flex items-center gap-2">
                  {t(`account.users.roles.${role}`)}
                </div>
              </MenuItem>
            ))}
          </TextField>
        )}
      />
      <Controller
        name="phone"
        control={control}
        render={({ field }) => (
          <TextField label={t("user.phone")} fullWidth {...field} />
        )}
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={loading || !hasAccess(account.role, "users", "write")}
      >
        {t("common.save")}
      </Button>
    </form>
  );
};

export default UserForm;
