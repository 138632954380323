import { Link, useParams, useSearchParams } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import { useEffect, useState } from "react";
import {
  BaseTruck,
  Maintenance,
  MaintenanceModel,
} from "../../types/truck";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import TruckMaintenance from "./TruckMaintenance/TruckMaintenance";
import { useToast } from "../../hooks/useToast";
import { extractErrorMessages } from "../../utils/errors";
import TruckExpenses from "./TruckExpenses/TruckExpenses";
import Modal from "../../components/modal/Modal";
import TruckMaintenanceEdit from "./TruckMaintenance/TruckMaintenanceEdit";
import { useTranslation } from "react-i18next";
import { Breadcrumbs, Typography } from "@mui/material";
import BasicForm from "../../components/Truck/forms/BasicForm";
import ParametersForm from "../../components/Truck/forms/ParametersForm";
import TruckDocuments from "../../components/Truck/TruckDocuments";
import LoadingInfoForm from "../../components/Truck/forms/LoadingInfoForm";
import { TruckDocumentFormData } from "../../types/documents";

const MyFleetTruckContainer = () => {
  const { t } = useTranslation();
  const { truckId } = useParams<{
    truckId: string;
  }>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [maintenanceToEdit, setMaintenanceToEdit] =
    useState<MaintenanceModel | null>(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [truckDetails, setTruckDetails] = useState<BaseTruck | null>(null);
  const axios = useAxios();
  const toast = useToast();
  const [query, setQuery] = useSearchParams();

  const tabToIndex = {
    general: 0,
    parameters: 1,
    documents: 2,
    loadingInfo: 3,
    expenses: 4,
    maintenance: 5,
  };
  const indexToTab = {
    0: "general",
    1: "parameters",
    2: "documents",
    3: "loadingInfo",
    4: "expenses",
    5: "maintenance",
  };
  useEffect(() => {
    const tab = query.get("tab");
    if (tab && tabToIndex[tab as keyof typeof tabToIndex] !== undefined) {
      setSelectedTab(tabToIndex[tab as keyof typeof tabToIndex]);
    }
  }, [query]);

  const handleTabSelect = (index: number) => {
    setSelectedTab(index);
    setQuery({ tab: indexToTab[index as keyof typeof indexToTab] });
  };

  useEffect(() => {
    if (!truckId) return;

    axios
      .get(`/my-fleet/trucks/${truckId}`)
      .then((response) => {
        setTruckDetails(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [truckId]);

  const onTruckUpdate = (data: any) => {
    axios
      .patch(`/my-fleet/trucks/${truckId}/`, data)
      .then((response) => {
        setTruckDetails(response.data);
        toast({
          message: "Truck updated successfully",
          type: "success",
        });
      })
      .catch((error) => {
        console.error(error);
        toast({
          message: extractErrorMessages(error.response.data).join(" "),
          type: "error",
        });
      });
  };

  const onAddDocument = async (document: TruckDocumentFormData) => {
    if (!truckDetails) return;
    const response = await axios.post(
      `/my-fleet/trucks/${truckDetails.id}/documents/`,
      document
    );
    setTruckDetails((prevTruck) => {
      if (!prevTruck) return null;
      return {
        ...prevTruck,
        documents: [...prevTruck.documents, response.data],
      };
    });
    toast({
      message: "Document added",
      type: "success",
    });
  };

  const onDeleteDocument = async (fileId: number) => {
    if (!truckDetails) return;
    try {
      const response = await axios.delete(
        `/my-fleet/trucks/${truckDetails.id}/documents/${fileId}/`
      );
      if (response.status === 204) {
        setTruckDetails((prevTruck) => {
          if (!prevTruck) return null;
          return {
            ...prevTruck,
            documents: prevTruck.documents.filter((doc) => doc.id !== fileId),
          };
        });
        toast({
          message: "Document deleted",
          type: "success",
        });
      } else {
        console.error("Upload failed:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const onAddMaintenance = async (maintenance: Maintenance) => {
    if (!truckDetails) return;

    const response = await axios.post(
      `/my-fleet/trucks/${truckDetails.id}/maintenances/`,
      maintenance
    );
    setTruckDetails((prevTruck) => {
      if (!prevTruck) return null;
      return {
        ...prevTruck,
        maintenances: [...prevTruck.maintenances, response.data],
      };
    });
    toast({
      message: "Maintenance added",
      type: "success",
    });
  };

  const onEditMaintenanceSubmit = async (data: Maintenance) => {
    if (!truckDetails || maintenanceToEdit === null) return;

    const response = await axios.patch(
      `/my-fleet/trucks/${truckDetails.id}/maintenances/${maintenanceToEdit.id}/`,
      data
    );
    setTruckDetails((prevTruck) => {
      if (!prevTruck) return null;
      return {
        ...prevTruck,
        maintenances: prevTruck.maintenances.map((maintenance) =>
          maintenance.id === maintenanceToEdit.id ? response.data : maintenance
        ),
      };
    });
    toast({
      message: "Maintenance updated",
      type: "success",
    });
    setIsModalOpen(false);
  };

  const onEditMaintenanceClicked = (maintenance: MaintenanceModel) => {
    setMaintenanceToEdit(maintenance);
    setIsModalOpen(true);
  };

  const onDeleteMaintenance = async (maintenance: MaintenanceModel) => {
    if (!truckDetails) return;

    const response = await axios.delete(
      `/my-fleet/trucks/${truckDetails.id}/maintenances/${maintenance.id}/`
    );
    if (response.status === 204) {
      setTruckDetails((prevTruck) => {
        if (!prevTruck) return null;
        return {
          ...prevTruck,
          maintenances: prevTruck.maintenances.filter(
            (m) => m.id !== maintenance.id
          ),
        };
      });
      toast({
        message: "Maintenance deleted",
        type: "success",
      });
    } else {
      console.error("Deletion failed:", response.status, response.statusText);
    }
  };

  const onUpdateDocument = async (documentId: number, data: any) => {
    if (!truckDetails) return;

    const response = await axios.patch(
      `/my-fleet/trucks/${truckDetails.id}/documents/${documentId}/`,
      data
    );
    setTruckDetails((prevTruck) => {
      if (!prevTruck) return null;
      return {
        ...prevTruck,
        documents: prevTruck.documents.map((doc) =>
          doc.id === documentId ? response.data : doc
        ),
      };
    });
    toast({
      message: "Document updated",
      type: "success",
    });
  };

  return (
    <div className="p-4 mb-2">
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">{t("breadcrumb.home")}</Link>
        <Link to="/my-fleet">{t("breadcrumb.myFleet")}</Link>
        <Typography color="text.primary">
          {t("truckDetail.title")} - {truckDetails?.name}
        </Typography>
      </Breadcrumbs>

      {truckDetails && (
        <Tabs
          selectedTabClassName="bg-neutral-300 dark:bg-zinc-800 text-black dark:text-secondary border border-neutral-400"
          selectedIndex={selectedTab}
          onSelect={handleTabSelect}
        >
          <TabList className="text-black dark:text-secondary">
            <Tab>{t("truckDetail.tabs.general")}</Tab>
            <Tab>{t("truckDetail.tabs.parameters")}</Tab>
            <Tab>{t("truckDetail.tabs.documents")}</Tab>
            <Tab>{t("truckDetail.tabs.loadingInfo")}</Tab>
            <Tab>{t("truckDetail.tabs.expenses")}</Tab>
            <Tab>{t("truckDetail.tabs.maintenance")}</Tab>
          </TabList>

          <TabPanel>
            <BasicForm truck={truckDetails} onSubmit={onTruckUpdate} />
          </TabPanel>
          <TabPanel>
            <ParametersForm truck={truckDetails} onSubmit={onTruckUpdate} />
          </TabPanel>
          <TabPanel>
            <TruckDocuments
              truck={truckDetails}
              onAddDocument={onAddDocument}
              onDeleteDocument={onDeleteDocument}
              onUpdateDocument={onUpdateDocument}
            />
          </TabPanel>

          <TabPanel>
            <LoadingInfoForm truck={truckDetails} onSubmit={onTruckUpdate} />
          </TabPanel>

          <TabPanel>
            <TruckExpenses truck={truckDetails} />
          </TabPanel>

          <TabPanel>
            <TruckMaintenance
              maintenances={truckDetails.maintenances}
              onAddMaintenance={onAddMaintenance}
              onDeleteMaintenance={onDeleteMaintenance}
              onEditMaintenanceClicked={onEditMaintenanceClicked}
            />
          </TabPanel>
        </Tabs>
      )}
      {maintenanceToEdit && (
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <TruckMaintenanceEdit
            onEditMaintenanceSubmit={onEditMaintenanceSubmit}
            maintenance={maintenanceToEdit}
          />
        </Modal>
      )}
    </div>
  );
};

export default MyFleetTruckContainer;
