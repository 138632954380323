import { Button } from "@mui/material";
import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";

// Define the conditional type
type FileInputType<Multiple extends boolean> = Multiple extends true ? FileList : File;

interface FileUploadProps<Multiple extends boolean> {
  handleChange: (files: FileInputType<Multiple>) => void;
  types: string[];
  label: string;
  name: string;
  uploading: boolean;
  multiple?: Multiple;
}

const DragDropInput = <Multiple extends boolean>({
  handleChange,
  types,
  label,
  name,
  uploading,
  multiple = false as Multiple,
}: FileUploadProps<Multiple>) => {
  const [progress, setProgress] = useState(0);

  const clear = () => {
    setProgress(0);
  };

  const onChange = (files: FileInputType<Multiple>) => {
    handleChange(files);
  };

  let content;
  if (uploading) {
    content = (
      <div className="w-full">
        <h2>Uploading...</h2>
        <progress value={progress} max="100" />
      </div>
    );
  } else if (progress === 100) {
    content = (
      <div className="flex space-x-8">
        <Button onClick={clear} className="mt-4">
          Upload new files
        </Button>
      </div>
    );
  } else {
    content = (
      <FileUploader
        handleChange={onChange}
        name="file"
        types={types}
        multiple={multiple}
        label={label}
        classes="w-full h-full flex"
      />
    );
  }

  return (
    <div className="flex flex-col w-full items-center justify-center">
      <div className="text-center mb-4">
        <h2>{name}</h2>
      </div>
      <div className="w-full justify-center items-center flex">{content}</div>
    </div>
  );
};

export default DragDropInput;
