import { useForm } from "react-hook-form";
import FormInput from "../../components/input/FormInput";
import { OrderInfo } from "../../types/orders";
import Select from "react-select";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
interface RequirementsFormProps {
  order: OrderInfo;
  onFormSubmit: (data: any) => void;
}

const RequirementsForm: React.FC<RequirementsFormProps> = ({
  order,
  onFormSubmit,
}) => {
  const { t } = useTranslation();
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      requirements: {
        type: order.requirements?.type || "",
        remarks: order.requirements?.remarks || "",
        monitoring: order.requirements?.monitoring || "",
        capacity_unit: order.requirements?.capacity_unit || "t",
        capacity_value: order.requirements?.capacity_value || 0,
      },
    },
  });

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <div className="grid grid-cols-5 gap-4">
        <FormInput
          label="Cargo Type"
          inputProps={{ ...register("requirements.type") }}
        />
        <FormInput
          label="Remarks"
          inputProps={{ ...register("requirements.remarks") }}
        />
        <FormInput
          label="Monitoring"
          inputProps={{ ...register("requirements.monitoring") }}
        />
        <div className="grid grid-cols-2 items-center">
          <FormInput
            label="Capacity Value"
            inputProps={{ ...register("requirements.capacity_value") }}
          />
          <Select
            options={[
              {
                value: "t",
                label: "T",
              },
            ]}
            onChange={(option) => {
              if (!option) {
                return;
              }
              setValue("requirements.capacity_unit", option?.value);
            }}
            defaultValue={order.requirements?.capacity_unit && {
              value: order.requirements.capacity_unit,
              label: order.requirements.capacity_unit.toUpperCase(),
            } || undefined}
          />
        </div>
      </div>
      <Button className="my-2" type="submit" variant="contained">
        {t('common.save')}
      </Button>
    </form>
  );
};

export default RequirementsForm;
