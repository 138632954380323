import { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Link, useParams } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import { useTranslation } from "react-i18next";
import { Breadcrumbs, CircularProgress, Typography } from "@mui/material";
import EmployeeBaseDetailsForm from "./EmployeeBaseDetailsForm";
import { useToast } from "../../hooks/useToast";
import {
  EmployeeDetails,
} from "../../types/employees";
import { Carrier } from "../../types/carriers";

const CarrierEmployeeDetailContainer = () => {

  const { t } = useTranslation();
  const { carrierId, employeeId } = useParams();
  const axios = useAxios();
  const [carrier, setCarrier] = useState<Carrier | null>(null);
  const [employee, setEmployee] = useState<EmployeeDetails | null>(null);
  const toast = useToast();
  useEffect(() => {
    if (!employeeId || !carrierId) return;
    axios
      .get(`/carriers/${carrierId}/employees/${employeeId}`)
      .then((response) => {
        setEmployee(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
    axios
      .get(`/carriers/${carrierId}`)
      .then((response) => {
        setCarrier(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [carrierId, employeeId]);

  const onSubmit = (data: any) => {
    axios
      .patch(`/carriers/${carrierId}/employees/${employeeId}/`, data)
      .then(() => {
        toast({
          message: t("employeeDetail.toast.success"),
          type: "success",
        });
      });
  };

  return employee ? (
    <div className="p-4 text-black">
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">{t("breadcrumb.home")}</Link>
        <Link to="/carriers">{t("breadcrumb.carriers")}</Link>
        <Link to={`/carriers/${carrierId}`}>
          {t("carrierDetail.title")} - {carrier?.name}
        </Link>
        <Typography color="text.primary">
          {t("employeeDetail.title")} - {employee.full_name}
        </Typography>
      </Breadcrumbs>

      <Tabs>
        <TabList>
          <Tab>{t("employeeDetail.tabs.general")}</Tab>
        </TabList>

        <TabPanel>
          <EmployeeBaseDetailsForm employee={employee} onSubmit={onSubmit} />
        </TabPanel>
      </Tabs>
    </div>
  ) : (
    <CircularProgress />
  );
};

export default CarrierEmployeeDetailContainer;
