export const extractErrorMessages = (errorsObj: any) => {
  const messages: string[] = [];

  const recursiveSearch = (obj: any, parentField = "") => {
    Object.entries(obj).forEach(([key, value]) => {
      const fieldName = parentField ? `${parentField} - ${key}` : key;

      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          if (typeof item === "object" && item !== null) {
            recursiveSearch(item, `${fieldName}[${index}]`);
          } else {
            messages.push(`${fieldName}[${index}]: ${item}`);
          }
        });
      } else if (typeof value === "object" && value !== null) {
        recursiveSearch(value, fieldName); 
      } else {
        messages.push(`${fieldName}: ${value}`);
      }
    });
  };

  recursiveSearch(errorsObj);
  return messages;
};
