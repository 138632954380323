import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const NoFreightExchange = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex items-center justify-between">
        <Link to="/integrations?recommendation=connect_freight_exchange">
          <Button variant="contained" color="primary">
            {t("dashboard.connectFreightExchange")}
          </Button>
        </Link>
      </div>
    </>
  );
};

export default NoFreightExchange;
