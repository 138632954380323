import React, { useEffect, useRef, useState } from "react";
import Modal from "../../components/modal/Modal";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  TextField,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import DragDropInput from "../../components/input/DragDropInput";
import { useToast } from "../../hooks/useToast";
import {
  BaseTruck,
  TRUCK_DOCUMENT_TYPES,
} from "../../types/truck";
import { useTranslation } from "react-i18next";
import TruckDocumentEdit from "./TruckDocumentEdit";
import { useSearchParams } from "react-router-dom";
import { TruckDocument, TruckDocumentFormData } from "../../types/documents";

interface TruckDocumentsProps {
  truck: BaseTruck;
  onAddDocument: (document: TruckDocumentFormData) => void;
  onDeleteDocument: (documentId: number) => void;
  onUpdateDocument: (documentId: number, data: any) => void;
}

const TruckDocuments: React.FC<TruckDocumentsProps> = ({
  truck,
  onAddDocument,
  onDeleteDocument,
  onUpdateDocument,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [isModalOpen, setModalOpen] = useState(false);
  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      file: "",
      type: "",
      end_date: null,
      description: "",
    },
  });
  const [file, setFile] = useState<File | null>(null);
  const [selectedDocument, setSelectedDocument] =
    useState<TruckDocument | null>(null);
  const [isDocumentEditModalOpen, setDocumentEditModalOpen] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const [searchParams] = useSearchParams();
  const highlightRef = useRef<{ [key: number]: HTMLDivElement | null }>({});
  const onSubmit = async (data: TruckDocumentFormData) => {
    if (!file) {
      toast({
        message: "Please upload a file",
        type: "error",
      });
      return;
    }
    setFileUploading(true);
    const reader = new FileReader();
    reader.onload = async () => {
      const fileContent = reader.result;
      const newDocument = {
        ...data,
        file: {
          name: file.name,
          base64: fileContent,
        },
      };
      try {
        await onAddDocument(newDocument);
        setModalOpen(false);
        reset();
      } catch (error) {
        console.error(error);
        toast({
          message: "Failed to add document",
          type: "error",
        });
      } finally {
        setFileUploading(false);
      }
    };

    reader.readAsDataURL(file);
  };

  const handleFileChange = (files: FileList) => {
    if (files.length > 0) {
      setFile(files[0]);
      setValue("file", files[0].name);
    }
  };

  useEffect(() => {
    const recommendation = searchParams.get("recommendation");
    const documentId = parseInt(searchParams.get("document") || "", 10);

    if (recommendation === "document_ending" && documentId) {
      const element = highlightRef.current[documentId];
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
        element.classList.add("highlight-animation");
        setTimeout(() => {
          element.classList.remove("highlight-animation");
        }, 8000); 
      }
    }
  }, [searchParams]);

  return (
    <div className="bg-neutral-300 dark:bg-zinc-800 p-4 border border-gray-400 text-black dark:text-white">
      <Button
        variant="contained"
        color="primary"
        onClick={() => setModalOpen(true)}
      >
        {t("truckDetail.documents.addDocument")}
      </Button>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 border-t border-gray-400 mt-4 pt-4">
        {truck.documents.map((document) => (
          <div
            key={document.id}
            ref={(el) => (highlightRef.current[document.id] = el)}
            className="flex flex-col items-center justify-between bg-slate-300 p-4 rounded-lg shadow hover:shadow-lg transition-shadow duration-200"
          >
            <div className="flex flex-col items-center w-full">
              <span className="text-sm text-gray-500 mb-1">
                {t("truckDetail.documents.fields.type")}
              </span>
              <span className="text-lg font-medium text-gray-800 capitalize">
                {t(`documentTypes.${document.type}`)}
              </span>
            </div>
            {document.end_date && (
              <div className="flex flex-col items-center w-full mt-2">
                <span className="text-sm text-gray-500 mb-1">
                  {t("truckDetail.documents.fields.validTo")}
                </span>
                <span className="text-md text-gray-700">
                  {new Date(document.end_date).toLocaleDateString()}
                </span>
              </div>
            )}
            <div className="flex items-center w-full mt-2 justify-center gap-4">
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  onDeleteDocument(document.id);
                }}
              >
                {t("common.delete")}
              </Button>
              <a
                href={document.file}
                target="_blank"
                rel="noopener noreferrer"
                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200"
              >
                {t("truckDetail.documents.fields.view")}{" "}
              </a>
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={() => {
                  setSelectedDocument(document);
                  setDocumentEditModalOpen(true);
                }}
              >
                {t("common.edit")}
              </Button>
            </div>
          </div>
        ))}
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            p: 4,
            backgroundColor: "white",
            borderRadius: 1,
            boxShadow: 24,
          }}
        >
          <h2>{t("truckDetail.documents.addDocumentModal.title")}</h2>
          <DragDropInput
            uploading={fileUploading}
            name={t("truckDetail.documents.addDocumentModal.dragDropTitle")}
            label={t("truckDetail.documents.addDocumentModal.cta")}
            types={[
              "PDF",
              "DOC",
              "DOCX",
              "ODT",
              "TXT",
              "RTF",
              "PNG",
              "JPG",
              "JPEG",
            ]}
            multiple={true}
            handleChange={handleFileChange}
          />
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("truckDetail.documents.addDocumentModal.documentType")}
                variant="outlined"
                fullWidth
                select
                required
              >
                {TRUCK_DOCUMENT_TYPES.map((type) => (
                  <MenuItem key={type} value={type}>
                    {t(`documentTypes.${type}`)}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("truckDetail.documents.addDocumentModal.description")}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                fullWidth
                multiline
                rows={4}
              />
            )}
          />
          <Controller
            name="end_date"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("truckDetail.documents.addDocumentModal.validTo")}
                type="date"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                fullWidth
              />
            )}
          />
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </Box>
      </Modal>
      <Dialog
        open={isDocumentEditModalOpen}
        onClose={() => setDocumentEditModalOpen(false)}
      >
        <DialogTitle>
          {t("truckDetail.documents.editDocumentModal.title")}
        </DialogTitle>
        <DialogContent style={{ width: "600px" }}>
          {selectedDocument && (
            <TruckDocumentEdit
              document={selectedDocument}
              onUpdateDocument={async (data: any) => {
                await onUpdateDocument(selectedDocument.id, data);
                setDocumentEditModalOpen(false);
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDocumentEditModalOpen(false)}
            color="primary"
          >
            {t("common.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TruckDocuments;
