import { useTranslation } from "react-i18next";
import { BaseTruck } from "../../../types/truck";

interface MaintenanceIncomingProps {
  data: BaseTruck;
}

const MissingParameters: React.FC<MaintenanceIncomingProps> = ({
  data,
}) => {
  const { t } = useTranslation();

  return (
    <>
       <div className="flex items-center justify-between">
        <div>
          <p>{t("dashboard.missingParameters")}</p>
          <p>{data.license_plate}</p>
        </div>
      </div>
    </>
  );
};

export default MissingParameters;
