import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const NoTelematics = () => {
  const { t } = useTranslation();

  return (
    <div>
        <Link to="/integrations?recommendation=connect_telematics">
        <Button variant="contained" color="primary">
          {t("dashboard.addTelematics")}{" "}
        </Button>
      </Link>
    </div>
  );
};

export default NoTelematics;
