import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Table from "../../components/Table/Table";
import { useExchange } from "../../contexts/ExchangeContext";
import GeoAutocomplete from "../../components/geoAutocomplete/GeoAutocomplete";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  TextField,
  Tabs,
  Tab,
  Box,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Drawer,
} from "@mui/material";
import AddOfferForm from "./AddOfferForm";
import { OFFER_SEARCH_DISTANCES, OFFER_TRUCK_TYPES } from "../../consts/offers";
import { MdClose, MdSwapHoriz } from "react-icons/md";
import { OfferModel } from "../../types/offers";
import OfferContent from "./OfferContent/OfferContent";

const ExchangeContainer = () => {
  const {
    offers,
    tabs,
    activeTab,
    swapLocation,
    page,
    hasMore,
    onSetPage,
    setActiveTab,
    addTab,
    removeTab,
    onUpdateTab,
  } = useExchange();

  const [offerModalOpen, setOfferModalOpen] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState<OfferModel | null>(null);

  const { t } = useTranslation();

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      if (hasMore) {
        onSetPage(page + 1);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasMore]);

  const toggleOfferModalOpen = () => {
    setOfferModalOpen(!offerModalOpen);
  };

  const handleTabChange = (event: React.ChangeEvent<any>, newValue: number) => {
    event.preventDefault();
    setActiveTab(newValue);
  };

  const getTabLabel = (index: number) => {
    if (tabs[index].loadingSearch && tabs[index].unloadingSearch) {
      return `${tabs[index].loadingSearch.address.countryCode}${tabs[index].loadingSearch.address.postalCode} - ${tabs[index].unloadingSearch.address.postalCode}`;
    }
    if (tabs[index].loadingSearch) {
      return `${tabs[index].loadingSearch.address.countryCode}${tabs[index].loadingSearch.address.postalCode} - `;
    }
    if (tabs[index].unloadingSearch) {
      return `- ${tabs[index].unloadingSearch.address.countryCode}${tabs[index].unloadingSearch.address.postalCode}`;
    }
    return `Tab ${index + 1}`;
  };

  const onRowClick = (row: any) => {
    setSelectedOffer(offers.find((offer) => offer.ref_id === row.id) || null);
  };

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <Tabs value={activeTab} onChange={handleTabChange}>
          {tabs.map((_, index) => (
            <Tab
              key={index}
              label={
                <Box display="flex" alignItems="center">
                  {getTabLabel(index)}
                  {tabs.length > 1 && (
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        removeTab(index);
                      }}
                    >
                      <MdClose fontSize="small" />
                    </IconButton>
                  )}
                </Box>
              }
            />
          ))}
          <Button onClick={addTab}>+ {t("exchange.newTab")}</Button>
        </Tabs>
      </div>
      <div className="flex justify-between items-center">
        <div className="flex gap-4 w-1/2 p-4">
          <div className="flex gap-4 w-full">
            <div className="w-2/3">
              <GeoAutocomplete
                label={t("exchange.loadingSearch")}
                value={tabs[activeTab].loadingSearch}
                onChange={(value) => {
                  if (typeof value !== "string") {
                    onUpdateTab(activeTab, "loadingSearch", value);
                  }
                }}
              />
            </div>
            <div className="w-1/3">
              <TextField
                label={t("exchange.loadingDistance")}
                value={tabs[activeTab].loadingDistance}
                select
                onChange={(e) => {
                  onUpdateTab(
                    activeTab,
                    "loadingDistance",
                    Number(e.target.value)
                  );
                }}
              >
                {OFFER_SEARCH_DISTANCES.map((distance) => (
                  <MenuItem key={distance} value={distance}>
                    {distance} km
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
          <IconButton onClick={swapLocation}>
            <MdSwapHoriz />
          </IconButton>
          <div className="flex gap-4 w-full">
            <div className="w-2/3">
              <GeoAutocomplete
                label={t("exchange.unloadingSearch")}
                value={tabs[activeTab].unloadingSearch}
                onChange={(value) => {
                  if (typeof value !== "string") {
                    onUpdateTab(activeTab, "unloadingSearch", value);
                  }
                }}
              />
            </div>
            <div className="w-1/3">
              <TextField
                label={t("exchange.unloadingDistance")}
                value={tabs[activeTab].unloadingDistance}
                select
                onChange={(e) => {
                  onUpdateTab(
                    activeTab,
                    "unloadingDistance",
                    Number(e.target.value)
                  );
                }}
              >
                {OFFER_SEARCH_DISTANCES.map((distance) => (
                  <MenuItem key={distance} value={distance}>
                    {distance} km
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
        </div>
        <div className="flex gap-4 w-2/12">
          <TextField
            label={t("exchange.weightFrom")}
            value={tabs[activeTab].weightFrom}
            size="small"
            onChange={(e) => {
              onUpdateTab(activeTab, "weightFrom", Number(e.target.value));
            }}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <span>t</span>,
            }}
          />
          <TextField
            label={t("exchange.weightTo")}
            value={tabs[activeTab].weightTo}
            size="small"
            onChange={(e) => {
              onUpdateTab(activeTab, "weightTo", Number(e.target.value));
            }}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <span>t</span>,
            }}
          />
        </div>
        <div>
          <Button onClick={() => toggleOfferModalOpen()} variant="contained">
            {t("exchange.addOffer")}
          </Button>
        </div>
      </div>
      <div className="flex justify-between items-center mb-4 text-black dark:text-secondary">
        <FormControl component="fieldset" className="mb-4" required>
          <FormGroup row>
            {OFFER_TRUCK_TYPES.map((requirement) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      tabs[activeTab].truckTypes?.includes(requirement) || false
                    }
                    onChange={() => {
                      const activeTabTruckTypes =
                        tabs[activeTab].truckTypes || [];
                      if (activeTabTruckTypes.includes(requirement)) {
                        const index = activeTabTruckTypes.indexOf(requirement);
                        activeTabTruckTypes.splice(index, 1);
                      } else {
                        activeTabTruckTypes.push(requirement);
                      }
                      onUpdateTab(activeTab, "truckTypes", [
                        ...activeTabTruckTypes,
                      ]);
                    }}
                  />
                }
                label={t(`offerRequirements.${requirement}`)}
              />
            ))}
          </FormGroup>
        </FormControl>
      </div>
      <Table
        onRowClick={onRowClick}
        columns={[
          {
            label: t("exchange.table.loading"),
            name: "loading",
            type: "operation",
          },
          {
            label: t("exchange.table.unloading"),
            type: "operation",
            name: "unloading",
          },
          {
            label: t("exchange.table.information"),
            type: "information",
            name: "information",
          },
          {
            label: t("exchange.table.price"),
            type: "price",
            name: "price",
          },
          {
            label: t("exchange.table.date"),
            type: "dates",
            name: "dates",
          },
          {
            label: t("exchange.table.shipper"),
            type: "shipper",
            name: "shipper",
          },
        ]}
        data={offers.map((offer) => ({
          id: offer.ref_id,
          loading: {
            operation: offer.operations.length > 1 ? offer.operations[0] : null,
            distance: offer.origin_distance,
          },
          unloading: {
            operation:
              offer.operations.length > 1
                ? offer.operations[offer.operations.length - 1]
                : null,
            distance: offer.destination_distance,
          },
          information: {
            estimatedDistance: offer.estimated_distance,
            estimatedPricePerKm: offer.price_value / offer.estimated_distance,
            currency: offer.price_currency,
            truckTypes: offer.truck_types,
            truckBodyTypes: offer.truck_body_types,
            capacity: offer.capacity,
            ldm: offer.ldm,
          },
          price: {
            amount: offer.price_value,
            currency: offer.price_currency,
          },
          dates: {
            validUntil: offer.valid_until,
            createdAt: offer.created_at,
          },
          shipper: {
            shipper: offer.shipper,
          },
        }))}
      />
      <Dialog
        open={offerModalOpen}
        onClose={toggleOfferModalOpen}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle>{t("exchange.addOfferModal.title")}</DialogTitle>
        <DialogContent>
          <AddOfferForm onClose={toggleOfferModalOpen} />
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleOfferModalOpen} color="primary">
            {t("common.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
      <Drawer
        anchor="right"
        open={selectedOffer !== null}
        onClose={() => setSelectedOffer(null)}
      >
        <div className="p-4 flex flex-col">
          <IconButton
            onClick={() => setSelectedOffer(null)}
            className="self-end"
          >
            <MdClose />
          </IconButton>
          <h2>{t("exchange.offerDetails.title")}</h2>
          {selectedOffer && <OfferContent offer={selectedOffer} />}
        </div>
      </Drawer>
    </div>
  );
};

export default ExchangeContainer;
