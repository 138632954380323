import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { GRAPH_COLORS } from "../../../utils/colors";
import NoDataAvailable from "../NoData";

interface Expense {
  created_expense__currency: string;
  total_expense: number;
}

interface MonthlyIncome {
  month: number;
  year: number;
  expenses: Expense[];
}

interface MonthlyExpensesGraphProps {
  monthlyExpenses: MonthlyIncome[];
}

const MonthlyExpensesGraph: React.FC<MonthlyExpensesGraphProps> = ({
  monthlyExpenses,
}) => {
  const aggregatedData = monthlyExpenses.map((monthlyIncome) => {
    const expenseByCurrency: { [key: string]: number } = {};
    monthlyIncome.expenses.forEach((expense) => {
      if (expenseByCurrency[expense.created_expense__currency]) {
        expenseByCurrency[expense.created_expense__currency] += expense.total_expense;
      } else {
        expenseByCurrency[expense.created_expense__currency] = expense.total_expense;
      }
    });
    return {
      month: `${monthlyIncome.month}/${monthlyIncome.year}`,
      ...expenseByCurrency,
    };
  });

  const uniqueCurrencies = Array.from(
    new Set(
      monthlyExpenses.flatMap((monthlyIncome) =>
        monthlyIncome.expenses.map((expense) => expense.created_expense__currency)
      )
    )
  );

  return (
    <div>
      <h2 className="text-center">Monthly Expenses</h2>
      {uniqueCurrencies.length === 0 ? (
        <NoDataAvailable message="No expenses available for the selected period."/>
      ) : (
        <ResponsiveContainer width="100%" height={400}>
          <LineChart
            width={600}
            height={300}
            data={aggregatedData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Legend />
            {uniqueCurrencies.map((currency, index) => (
              <Line
                type="monotone"
                dataKey={currency}
                stroke={GRAPH_COLORS[index % GRAPH_COLORS.length]}
                key={currency}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default MonthlyExpensesGraph;
