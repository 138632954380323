import { Button, TextField } from "@mui/material";
import { InvoiceModel } from "../../types/invoices";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";

interface InvoiceEditProps {
  invoice: InvoiceModel;
  onSubmit: (data: any) => void;
}

const InvoiceEdit: React.FC<InvoiceEditProps> = ({ invoice, onSubmit }) => {
  const { t } = useTranslation();
  const [loading, setLoading] =useState(false);
  const { control, handleSubmit } = useForm({
    defaultValues: {
      mail_tracking_id: invoice.mail_tracking_id,
    },
  });

  const changeTrackingId = (data: any) => {
    setLoading(true);
    try{
    onSubmit(data);
    } finally {
      setLoading(false);
    }
  }

  return (
    <form onSubmit={handleSubmit(changeTrackingId)} className="p-4 flex flex-col gap-2">
      <Controller
        name="mail_tracking_id"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            name="mail_tracking_id"
            label={t("invoices.invoiceActionModal.mailTrackingId")}
            variant="outlined"
          />
        )}
      />
      <Button variant="contained" type="submit" disabled={loading}>
        {t('common.save')}
      </Button>
    </form>
  );
};

export default InvoiceEdit;
