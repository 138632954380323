import { useTranslation } from "react-i18next";
import { Driver } from "../../../types/driver";

interface MaintenanceIncomingProps {
  data: {
    driver: Driver;
    documents: string[];
  };
}

const MissingDriverDocument: React.FC<MaintenanceIncomingProps> = ({
  data,
}) => {
  const { t } = useTranslation();

  const document = data.documents[0];
  return (
    <>
      <div className="flex items-center justify-between">
        <div>
          <p>{data.driver.full_name}</p>
        </div>
      </div>
      <div className="mt-4">
        <p>{t(`documentTypes.${document}`)}</p>
      </div>
    </>
  );
};

export default MissingDriverDocument;
