import { useTranslation } from "react-i18next";
import IntegrationCard from "./IntegrationCard"; // Make sure to adjust the import path as needed

const integrations = {
  gps: [
    {
      name: "gbox",
      logo: "integrations/gbox-logo.png",
      link: "/integrations/gbox",
    },
  ],
  freightExchange: [
    {
      name: "transeu",
      logo: "integrations/transeu-logo.jpeg",
      link: "/integrations/trans-eu",
    },
  ],
};

const IntegrationsContainer = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full bg-secondary p-8">
      <h1 className="text-3xl font-bold text-primary mb-6">
        {t("integrations.title")}
      </h1>

      {Object.entries(integrations).map(([category, items]) => (
        <div key={category} className="mb-8">
          <h2 className="text-2xl font-bold text-black mb-4">
            {t(`integrations.${category}.title`)}
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-6 gap-6">
            {items.map((item) => (
              <IntegrationCard
                key={item.name}
                name={t(`integrations.${category}.${item.name}.name`)}
                description={t(`integrations.${category}.${item.name}.description`)}
                logo={item.logo}
                link={item.link}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default IntegrationsContainer;
