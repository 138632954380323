import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  Chip,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { COUNTRIES, DEFAULT_COUNTRY } from "../../../consts/countries";
import ReactCountryFlag from "react-country-flag";
import { CURRENCIES, DEFAULT_CURRENCY } from "../../../consts/currencies";
import { getCurrencySymbol } from "../../../utils/currencies";
import { addMonths } from "date-fns";
import {
  CARRIER_EXPENSE_TYPES,
  RECURRING_INTERVALS,
} from "../../../consts/expenses";
import { format } from "date-fns-tz";
import { startOfMonth } from "date-fns";

interface ExpenseFormSubmitProps {
  loading: boolean;
  onSubmit: (data: any) => void;
}

const ExpenseForm: React.FC<ExpenseFormSubmitProps> = ({ loading, onSubmit }) => {
  const { t } = useTranslation();
  const { control, handleSubmit, watch, setValue, reset } = useForm({
    defaultValues: {
      description: "",
      date: new Date(),
      net_amount: "",
      currency: DEFAULT_CURRENCY,
      vat_amount: "",
      gross_amount: "",
      vat_rate: "23",
      category: "",
      country: DEFAULT_COUNTRY,
      taxable: true,
      is_recurring: false,
      interval: RECURRING_INTERVALS[0],
      start_date: new Date(),
      end_date: null,
    },
  });

  const [autoFillDates, setAutoFillDates] = useState<Date[]>([]);
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [submittedDates, setSubmittedDates] = useState<Date[]>([]);
  const [lockNet, setLockNet] = useState(false);
  const [lockVat, setLockVat] = useState(true);

  const watchIsRecurring = watch("is_recurring");
  const watchStartDate = watch("start_date");
  const watchEndDate = watch("end_date");
  const watchInterval = watch("interval");
  const watchNetAmount = watch("net_amount");
  const watchVatRate = watch("vat_rate");
  const watchGrossAmount = watch("gross_amount");

  const [open, setOpen] = useState(false);

  const handleAutoFillOpen = () => {
    setOpen(true);
    if (watchIsRecurring && watchStartDate && watchInterval) {
      const dates: Date[] = [];
      let current = startOfMonth(watchStartDate);

      while (current <= (watchEndDate || new Date())) {
        dates.push(current);
        current = startOfMonth(addMonths(current, 1));
      }
      setAutoFillDates(dates);
      setSelectedDates(dates);
    }
  };

  useEffect(() => {

    if (lockNet) {
      const grossAmount = parseFloat(watchGrossAmount) || 0;
      const vatRate = parseFloat(watchVatRate) || 0;

      const vatAmount = grossAmount / (1 + vatRate / 100) * (vatRate / 100);
      setValue("vat_amount", vatAmount.toFixed(2));
      setValue("net_amount", (grossAmount - vatAmount).toFixed(2));

    } else if (lockVat) {
      const netAmount = parseFloat(watchNetAmount) || 0;
      const vatRate = parseFloat(watchVatRate) || 0;

      const vatAmount = netAmount * (vatRate / 100);
      setValue("vat_amount", vatAmount.toFixed(2));
      setValue("gross_amount", (netAmount + vatAmount).toFixed(2));
    }
  }, [watchNetAmount, watchVatRate, watchGrossAmount, lockNet, lockVat]);

  const handleAutoFillSubmit = () => {
    setSubmittedDates(selectedDates);
    setOpen(false);
  };

  const handleDeleteDate = (dateToDelete: Date) => {
    setSubmittedDates(submittedDates.filter((date) => date !== dateToDelete));
  };

  const onFormSubmit = async(data: any) => {
    const payload = { ...data };

    if (watchIsRecurring) {
      payload.auto_fill_dates = selectedDates;
    }
    await onSubmit(payload);
    setSubmittedDates([]);
    reset();
  };

  return (
    <>
      <form onSubmit={handleSubmit(onFormSubmit)} className="mb-8 p-4">
        <Grid container spacing={2}>
          {/* Add the existing form fields here */}
          <Grid item xs={12} sm={6}>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("expenseList.fields.description")}
                  variant="outlined"
                  fullWidth
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="currency"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label={t("expenseList.fields.currency")}
                  variant="outlined"
                  fullWidth
                >
                  {CURRENCIES.map((currency) => (
                    <MenuItem key={currency} value={currency}>
                      {getCurrencySymbol(currency)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="net_amount"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("expenseList.fields.netAmount")}
                  variant="outlined"
                  fullWidth
                  required
                  type="number"
                  disabled={lockNet}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <Button
                        onClick={() => {
                          setLockNet(!lockNet);
                          setLockVat(!lockVat);
                        }}
                      >
                        {lockNet ? t("Unlock") : t("Lock")}
                      </Button>
                    ),
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Controller
              name="vat_amount"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("expenseList.fields.vatAmount")}
                  variant="outlined"
                  fullWidth
                  required
                  type="number"
                  disabled={lockVat}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="gross_amount"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("expenseList.fields.grossAmount")}
                  variant="outlined"
                  fullWidth
                  required
                  type="number"
                  disabled={lockVat}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <Button
                        onClick={() => {
                          setLockVat(!lockVat);
                          setLockNet(!lockNet);
                        }}
                      >
                        {lockVat ? t("Unlock") : t("Lock")}
                      </Button>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="vat_rate"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("expenseList.fields.vatRate")}
                  variant="outlined"
                  fullWidth
                  required
                  type="number"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="category"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label={t("expenseList.fields.category")}
                  variant="outlined"
                  fullWidth
                  required
                >
                  {CARRIER_EXPENSE_TYPES.map((category) => (
                    <MenuItem key={category} value={category}>
                      {t(`expenseTypes.${category}`)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label={t("account.companyDetails.country")}
                  fullWidth
                  variant="outlined"
                >
                  {COUNTRIES.map((country) => (
                    <MenuItem key={country} value={country}>
                      <div className="flex items-center gap-2">
                        <ReactCountryFlag countryCode={country} svg />
                        {t(`countries.${country}`)}
                      </div>
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="taxable"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      checked={field.value}
                      color="primary"
                    />
                  }
                  label={t("expenseList.fields.taxable")}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="is_recurring"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      checked={field.value}
                      color="primary"
                    />
                  }
                  label={t("expenseList.fields.isRecurring")}
                />
              )}
            />
          </Grid>
          {watchIsRecurring && (
            <>
              <Grid item xs={12} sm={3}>
                <Controller
                  name="interval"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      label={t("expenseList.fields.interval")}
                      variant="outlined"
                      fullWidth
                      required
                    >
                      {RECURRING_INTERVALS.map((interval) => (
                        <MenuItem key={interval} value={interval}>
                          {t(`recurringIntervals.${interval}`)}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Controller
                  name="start_date"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      label={t("expenseList.fields.startDate")}
                      maxDate={watchEndDate ? watchEndDate : null}
                      disableFuture
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Controller
                  name="end_date"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      minDate={watchStartDate}
                      label={t("expenseList.fields.endDate")}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Button
                  disabled={!watchStartDate}
                  variant="contained"
                  color="primary"
                  onClick={() => handleAutoFillOpen()}
                >
                  {t("expenseList.actions.autoFill")}
                </Button>
              </Grid>
            </>
          )}
          {!watchIsRecurring && (
            <Grid item xs={12} sm={6}>
              <Controller
                name="date"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    label={t("expenseList.fields.date")}
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
          disabled={loading}
        >
          {t("expenseList.actions.addExpense")}
        </Button>
      </form>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          {t("expenseList.fields.autoFillTitle")}
        </DialogTitle>
        <DialogContent>
          <FormGroup>
            {autoFillDates.map((date) => (
              <FormControlLabel
                key={date.toISOString()}
                control={
                  <Checkbox
                    checked={selectedDates.includes(date)}
                    onChange={() => {
                      if (selectedDates.includes(date)) {
                        setSelectedDates(
                          selectedDates.filter((d) => d !== date)
                        );
                      } else {
                        setSelectedDates([...selectedDates, date]);
                      }
                    }}
                    color="primary"
                  />
                }
                label={format(date, "dd MMMM yyyy")}
              />
            ))}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            {t("common.cancel")}
          </Button>
          <Button onClick={handleAutoFillSubmit} color="primary">
            {t("expenseList.actions.autoFill")}
          </Button>
        </DialogActions>
      </Dialog>

      {submittedDates.length > 0 && (
        <div className="mt-4">
          <h4>{t("expenseList.fields.selectedDates")}</h4>
          <div className="flex flex-wrap gap-2">
            {submittedDates.map((date) => (
              <Chip
                key={date.toISOString()}
                label={format(date, "dd MMM yyyy")}
                onDelete={() => handleDeleteDate(date)}
                color="primary"
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default ExpenseForm;
