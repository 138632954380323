import { BaseTruck } from "../../../types/truck";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button, TextField } from "@mui/material";

interface ParametersFormProps {
  truck: BaseTruck;
  onSubmit: (data: TruckFormData) => void;
}

interface TruckFormData {
  parameters: {
    vehicle_height_min: number;
    vehicle_height_max: number;
    vehicle_width: number;
    vehicle_length: number;
    vehicle_weight: number;
    engine_ccm: number;
    axle_count: number;
    max_speed: number;
    fuel_capacity: number;
  };
}

const ParametersForm: React.FC<ParametersFormProps> = ({ truck, onSubmit }) => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm<TruckFormData>({
    defaultValues: {
      parameters: {
        vehicle_height_min: truck.parameters?.vehicle_height_min || 0,
        vehicle_height_max: truck.parameters?.vehicle_height_max || 0,
        vehicle_width: truck.parameters?.vehicle_width || 0,
        vehicle_length: truck.parameters?.vehicle_length || 0,
        vehicle_weight: truck.parameters?.vehicle_weight || 0,
        engine_ccm: truck.parameters?.engine_ccm || 3000,
        axle_count: truck.parameters?.axle_count || 2,
        max_speed: truck.parameters?.max_speed || 90,
        fuel_capacity: truck.parameters?.fuel_capacity || 100,
      },
    },
  });

  const onFormSubmit = handleSubmit((data) => {
    onSubmit(data);
  });

  return (
    <form onSubmit={onFormSubmit} className="bg-neutral-300 dark:bg-zinc-800 p-4 border border-gray-400 text-black dark:text-white">
      <div className="flex justify-between items-center border-b p-2 my-2">
        <p className="text-lg font-bold">{truck.name}</p>
      </div>
      <div className="grid grid-cols-4 gap-4">
        <Controller
          name="parameters.vehicle_weight"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("truckDetail.parameters.vehicleWeight")}
              variant="outlined"
              fullWidth
            />
          )}
        />
        <Controller
          name="parameters.fuel_capacity"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("truckDetail.parameters.fuelCapacity")}
              variant="outlined"
              fullWidth
            />
          )}
        />
        <Controller
          name="parameters.vehicle_height_min"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("truckDetail.parameters.vehicleHeightMin")}
              variant="outlined"
              fullWidth
            />
          )}
        />
        <Controller
          name="parameters.vehicle_height_max"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("truckDetail.parameters.vehicleHeightMax")}
              variant="outlined"
              fullWidth
            />
          )}
        />
        <Controller
          name="parameters.vehicle_length"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("truckDetail.parameters.vehicleLength")}
              variant="outlined"
              fullWidth
            />
          )}
        />
        <Controller
          name="parameters.vehicle_width"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("truckDetail.parameters.vehicleWidth")}
              variant="outlined"
              fullWidth
            />
          )}
        />
        <Controller
          name="parameters.axle_count"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("truckDetail.parameters.axleCount")}
              variant="outlined"
              fullWidth
            />
          )}
        />
        <Controller
          name="parameters.max_speed"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("truckDetail.parameters.maxSpeed")}
              variant="outlined"
              fullWidth
            />
          )}
        />
        <Controller
          name="parameters.vehicle_weight"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("truckDetail.parameters.vehicleWeight")}
              variant="outlined"
              fullWidth
            />
          )}
        />
        <Controller
          name="parameters.vehicle_weight"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("truckDetail.parameters.vehicleWeight")}
              variant="outlined"
              fullWidth
            />
          )}
        />
        <Controller
          name="parameters.vehicle_weight"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("truckDetail.parameters.vehicleWeight")}
              variant="outlined"
              fullWidth
            />
          )}
        />
      </div>
      <Button variant="contained" type="submit" className="py-2 mt-2">
        {t("common.save")}
      </Button>
    </form>
  );
};

export default ParametersForm;
