import { useEffect, useState } from "react";
import useAxios from "../../../hooks/useAxios";
import { WidgetSkeleton } from "../../../components/skeletons/WidgetSkeleton";
import { useTranslation } from "react-i18next";
import NoData from "../../../components/NoData";
import {
  CompanyRecommendation,
  CompanyRecommendationItem,
} from "../../../types/alerts";
import RecommendationCard from "../RecommendationHandlers/RecommendationCard";
import InvoiceNotSetTrackingOrDelivery from "../RecommendationHandlers/InvoiceNotSetTrackingOrDelivery";
import MissingDriverDocument from "../RecommendationHandlers/MissingDriverDocument";
import MissingEmployeeDocuments from "../RecommendationHandlers/MissingEmployeeDocuments";
import NoFreightExchange from "../RecommendationHandlers/NoFreightExchange";
import NoLoadingInfo from "../RecommendationHandlers/NoLoadingInfo";
import NoOcz from "../RecommendationHandlers/NoOcz";
import NoParameters from "../RecommendationHandlers/NoParameters";
import NoRegistration from "../RecommendationHandlers/NoRegistration";
import NotInvoicedOrders from "../RecommendationHandlers/NotInvoicedOrders";
import OrdersPastDue from "../RecommendationHandlers/OrdersPastDue";
import OrdersWithoutDriver from "../RecommendationHandlers/OrdersWithoutDriver";
import OrdersWithoutEta from "../RecommendationHandlers/OrdersWithoutEta";
import OrdersWithoutRefNumber from "../RecommendationHandlers/OrdersWithoutRefNumber";
import OrdersWithoutRoute from "../RecommendationHandlers/OrdersWithoutRoute";
import OrdersWithoutTruck from "../RecommendationHandlers/OrdersWithoutTruck";
import RecurringExpenseNotPaid from "../RecommendationHandlers/RecurringExpenseNotPaid";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import OrdersWithoutRate from "../RecommendationHandlers/OrdersWithoutRate";
import NoDrivers from "../RecommendationHandlers/NoDrivers";
import NoEmployees from "../RecommendationHandlers/NoEmployees";
import NoOrders from "../RecommendationHandlers/NoOrders";
import NoTrucks from "../RecommendationHandlers/NoTrucks";
import NoDocuments from "../RecommendationHandlers/NoDocuments";
import NoUsers from "../RecommendationHandlers/NoUsers";
import NoTelematics from "../RecommendationHandlers/NoTelematics";
import NoExpenses from "../RecommendationHandlers/NoExpenses";
import OrdersWithoutCarrier from "../RecommendationHandlers/OrdersWithoutCarriers";
import NoOc from "../RecommendationHandlers/NoOc";
import NoMaintenance from "../RecommendationHandlers/NoMaintenance";
import MissingParameters from "../RecommendationHandlers/MissingParameters";
import NoOcp from "../RecommendationHandlers/NoOcp";

const FeedbackMessage = ({ score }: { score: number }) => {
  const { t } = useTranslation();
  let message = "";
  let color = "";

  if (score >= 0 && score <= 30) {
    message = t("dashboard.poorCondition");
    color = "text-red-600";
  } else if (score > 30 && score <= 60) {
    message = t("dashboard.fairCondition");
    color = "text-yellow-600";
  } else if (score > 60 && score <= 80) {
    message = t("dashboard.goodCondition");
    color = "text-blue-600";
  } else {
    message = t("dashboard.excellentCondition");
    color = "text-green-600";
  }

  return <div className={`text-lg font-semibold ${color}`}>{message}</div>;
};
const ConditionScore = ({ score }: { score: number }) => {
  const roundedScore = Math.round(score);
  return (
    <div className="flex flex-col items-center gap-4 p-4 w-full">
      <div className="w-24 h-24">
        <CircularProgressbar
          value={roundedScore}
          text={`${roundedScore}%`}
          styles={buildStyles({
            textSize: "16px",
            pathColor: `rgba(62, 152, 199, ${roundedScore / 100})`,
            textColor: "#3e98c7",
            trailColor: "#d6d6d6",
          })}
        />
      </div>
      <FeedbackMessage score={roundedScore} />
    </div>
  );
};

const Recommendations = () => {
  const [recommendations, setRecommendations] =
    useState<CompanyRecommendation | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const axios = useAxios();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchLoadings = async () => {
      setLoading(true);
      try {
        const response = await axios.get("alerts/recommendations");
        if (response.data) {
          setRecommendations(response.data);
        }
      } catch (error) {
        console.error("Error fetching loadings:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLoadings();
  }, []);

  const renderRecommendation = (event: CompanyRecommendationItem) => {
    switch (event.type) {
      case "missing_driver_documents":
        return (
          <RecommendationCard
            title={t("dashboard.missingDriverDocumentsTitle")}
          >
            <MissingDriverDocument data={event.data} key={event.id} />
          </RecommendationCard>
        );
      case "missing_employee_documents":
        return (
          <RecommendationCard
            title={t("dashboard.missingEmployeeDocumentsTitle")}
          >
            <MissingEmployeeDocuments data={event.data} key={event.id} />
          </RecommendationCard>
        );
      case "no_maintenance":
        return (
          <RecommendationCard title={t("dashboard.noMaintenanceTitle")}>
            <NoMaintenance data={event.data} key={event.id} />
          </RecommendationCard>
        );
      case "missing_parameters":
        return (
          <RecommendationCard title={t("dashboard.missingParametersTitle")}>
            <MissingParameters data={event.data} key={event.id} />
          </RecommendationCard>
        );
      case "no_ocp":
        return (
          <RecommendationCard title={t("dashboard.noOcpTitle")}>
            <NoOcp  key={event.id} />
          </RecommendationCard>
        );

      case "no_oc":
        return (
          <RecommendationCard title={t("dashboard.noOcTitle")}>
            <NoOc data={event.data} key={event.id} />
          </RecommendationCard>
        );
      case "orders_without_carriers":
        return (
          <RecommendationCard title={t("dashboard.ordersWithoutCarrierTitle")}>
            <OrdersWithoutCarrier data={event.data} key={event.id} />
          </RecommendationCard>
        );
      case "orders_without_eta":
        return (
          <RecommendationCard title={t("dashboard.ordersWithoutEtaTitle")}>
            <OrdersWithoutEta data={event.data} key={event.id} />
          </RecommendationCard>
        );
      case "orders_without_driver":
        return (
          <RecommendationCard title={t("dashboard.ordersWithoutDriverTitle")}>
            <OrdersWithoutDriver data={event.data} key={event.id} />
          </RecommendationCard>
        );
      case "orders_without_truck":
        return (
          <RecommendationCard title={t("dashboard.ordersWithoutTruckTitle")}>
            <OrdersWithoutTruck data={event.data} key={event.id} />
          </RecommendationCard>
        );
      case "orders_without_route":
        return (
          <RecommendationCard title={t("dashboard.ordersWithoutRouteTitle")}>
            <OrdersWithoutRoute data={event.data} key={event.id} />
          </RecommendationCard>
        );
      case "order_past_due":
        return (
          <RecommendationCard title={t("dashboard.ordersPastDueTitle")}>
            <OrdersPastDue data={event.data} key={event.id} />
          </RecommendationCard>
        );
      case "orders_without_ref_number":
        return (
          <RecommendationCard
            title={t("dashboard.ordersWithoutRefNumberTitle")}
          >
            <OrdersWithoutRefNumber data={event.data} key={event.id} />
          </RecommendationCard>
        );
      case "not_invoiced_orders":
        return (
          <RecommendationCard title={t("dashboard.notInvoicedOrdersTitle")}>
            <NotInvoicedOrders data={event.data} key={event.id} />
          </RecommendationCard>
        );
      case "no_ocz":
        return (
          <RecommendationCard title={t("dashboard.noOczTitle")}>
            <NoOcz data={event.data} key={event.id} />
          </RecommendationCard>
        );
      case "no_registration":
        return (
          <RecommendationCard title={t("dashboard.noRegistrationTitle")}>
            <NoRegistration data={event.data} key={event.id} />
          </RecommendationCard>
        );
      case "no_loading_info":
        return (
          <RecommendationCard title={t("dashboard.noLoadingInfoTitle")}>
            <NoLoadingInfo data={event.data} key={event.id} />
          </RecommendationCard>
        );
      case "no_parameters":
        return (
          <RecommendationCard title={t("dashboard.noParametersTitle")}>
            <NoParameters data={event.data} key={event.id} />
          </RecommendationCard>
        );
      case "no_freight_exchange":
        return (
          <RecommendationCard title={t("dashboard.noFreightExchangeTitle")}>
            <NoFreightExchange key={event.id} />
          </RecommendationCard>
        );
      case "recurring_expense_not_paid":
        return (
          <RecommendationCard
            title={t("dashboard.recurringExpenseNotPaidTitle")}
          >
            <RecurringExpenseNotPaid data={event.data} key={event.id} />
          </RecommendationCard>
        );
      case "invoice_not_set_tracking_or_delivery":
        return (
          <RecommendationCard
            title={t("dashboard.invoiceNotSetTrackingOrDeliveryTitle")}
          >
            <InvoiceNotSetTrackingOrDelivery data={event.data} key={event.id} />
          </RecommendationCard>
        );
      case "orders_without_rate":
        return (
          <RecommendationCard title={t("dashboard.ordersWithoutRateTitle")}>
            <OrdersWithoutRate data={event.data} key={event.id} />
          </RecommendationCard>
        );
      case "no_drivers":
        return (
          <RecommendationCard title={t("dashboard.noDriversTitle")}>
            <NoDrivers key={event.id} />
          </RecommendationCard>
        );
      case "no_employees":
        return (
          <RecommendationCard title={t("dashboard.noEmployeesTitle")}>
            <NoEmployees key={event.id} />
          </RecommendationCard>
        );
      case "no_orders":
        return (
          <RecommendationCard title={t("dashboard.noOrdersTitle")}>
            <NoOrders key={event.id} />
          </RecommendationCard>
        );
      case "no_trucks":
        return (
          <RecommendationCard title={t("dashboard.noTrucksTitle")}>
            <NoTrucks key={event.id} />
          </RecommendationCard>
        );
      case "no_documents":
        return (
          <RecommendationCard title={t("dashboard.noDocumentsTitle")}>
            <NoDocuments key={event.id} />
          </RecommendationCard>
        );
      case "no_users":
        return (
          <RecommendationCard title={t("dashboard.noUsersTitle")}>
            <NoUsers key={event.id} />
          </RecommendationCard>
        );
      case "no_telematics":
        return (
          <RecommendationCard title={t("dashboard.noTelematicsTitle")}>
            <NoTelematics key={event.id} />
          </RecommendationCard>
        );
      case "no_expenses":
        return (
          <RecommendationCard title={t("dashboard.noExpensesTitle")}>
            <NoExpenses key={event.id} />
          </RecommendationCard>
        );
      default:
        return <div key={event.id}>{event.type}</div>;
    }
  };

  return (
    <div className="relative">
      {loading ? (
        <WidgetSkeleton />
      ) : (
        <div className="overflow-y-auto max-h-96 min-h-96 flex">
          {recommendations?.recommendations.length === 0 && (
            <NoData message={t("dashboard.noUpcomingLoads")} />
          )}
          <div className="flex flex-col gap-2 w-1/5 sticky top-0">
            <ConditionScore score={recommendations?.condition_score || 0} />
          </div>
          <div className="flex flex-col gap-2 w-4/5">
            {recommendations?.recommendations.map((recommendation) =>
              renderRecommendation(recommendation)
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Recommendations;
