import React from "react";
import { useTranslation } from "react-i18next";
import { WidgetSkeleton } from "../../../components/skeletons/WidgetSkeleton";
import NoData from "../../../components/NoData";
import { Typography, Card, CardContent } from "@mui/material";
import { Performance as PerformanceType } from "../../../types/insights";
import { getCurrencySymbol } from "../../../utils/currencies";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { format } from "date-fns-tz";

const calculatePercentageDifference = (current: number, previous: number) => {
  if (previous === 0) return 0;
  return ((current - previous) / previous) * 100;
};

const calculateProgress = (current: number, target: number) => {
  if (target === 0) return 0;
  return (current / target) * 100;
};

interface PerformanceProps {
  performance: PerformanceType | null;
  loading: boolean;
}

const Performance: React.FC<PerformanceProps> = ({ performance, loading }) => {
  const { t } = useTranslation();

  if (loading) {
    return <WidgetSkeleton />;
  }
  if (!performance) {
    return <NoData message={t("dashboard.noPerformance")} />;
  }

  const percentageData = [
    {
      name: t("dashboard.incomes.incomeThisMonth"),
      value: performance.income_this_month,
      oldVal: performance.income_from_last_month_up_to_current_month_day,
      difference: calculatePercentageDifference(
        performance.income_this_month,
        performance.income_from_last_month_up_to_current_month_day
      ),
      progress: calculateProgress(
        performance.income_this_month,
        performance.target_income
      ),
      target: performance.target_income,
    },
    {
      name: t("dashboard.incomes.incomeLast30Days"),
      value: performance.income_from_last_30_days,
      oldVal: performance.income_from_before_last_30_days,
      difference: calculatePercentageDifference(
        performance.income_from_last_30_days,
        performance.income_from_before_last_30_days
      ),
      progress: null,
      target: null,
    },
  ];

  const weeklyIncomesData = performance.weekly_incomes.map((item) => ({
    date: format(new Date(item.date), "dd/MM"),
    income: item.income,
  }));

  return (
    <div className="p-4">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {percentageData.map((item) => (
          <Card
            variant="outlined"
            sx={{ minWidth: 275, mb: 2 }}
            key={item.name}
          >
            <CardContent>
              <Typography variant="h5" component="div">
                {item.name}
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                {item.progress !== null && (
                  <div
                    style={{
                      width: "100px",
                      height: "100px",
                      marginRight: "20px",
                    }}
                  >
                    <CircularProgressbar
                      value={item.progress}
                      text={`${item.progress.toFixed(2)}%`}
                      styles={buildStyles({
                        textColor: item.progress >= 100 ? "green" : "red",
                        pathColor: item.progress >= 100 ? "green" : "red",
                      })}
                    />
                  </div>
                )}
                <div>
                  <Typography
                    variant="h3"
                    component="div"
                    sx={{ color: item.difference > 0 ? "green" : "red" }}
                  >
                    {item.value}
                    {getCurrencySymbol("eur")}
                    <Typography variant="body1" component="span">
                      {item.difference > 0
                        ? `+${item.difference.toFixed(2)}% MoM`
                        : `${item.difference.toFixed(2)}% MoM`}
                    </Typography>
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {t("dashboard.incomes.previous")}: {item.oldVal}
                    {getCurrencySymbol("eur")}
                  </Typography>
                  {item.target !== null && (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ mt: 1 }}
                    >
                      {t("dashboard.incomes.target")}: {item.target}
                      {getCurrencySymbol("eur")}
                    </Typography>
                  )}
                </div>
              </div>
            </CardContent>
          </Card>
        ))}
        <ResponsiveContainer width="100%" height={400}>
          <LineChart
            data={weeklyIncomesData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="income"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Performance;
