import "regenerator-runtime/runtime";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ErrorPage from "./error-page.tsx";
import { AuthLayout } from "./layouts/AuthLayout.tsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ProtectedRoute } from "./container/ProtectedRoute.tsx";
import SignInContainer from "./container/SignIn/SignInContainer.tsx";
import TransEuAuthCallbackContainer from "./container/TransEuAuthCallback/TransEuAuthCallbackContainer.tsx";
import IntegrationsContainer from "./container/Integrations/IntegrationsContainer.tsx";
import DashboardContainer from "./container/Dashboard/DashboardContainer.tsx";
import OrderContainer from "./container/OrderDetail/OrderContainer.tsx";

import ShipperDetailContainer from "./container/ShipperDetail/ShipperContainer.tsx";
import InsightsContainer from "./container/Insights/InsightsContainer.tsx";
import TruckInsightsContainer from "./container/TruckInsights/TruckInsightsContainer.tsx";
import TruckContainer from "./container/TruckDetail/TruckContainer.tsx";
import MapContainer from "./container/Map/MapContainer.tsx";
import AccountContainer from "./container/Account/AccountContainer.tsx";
import InvitationContainer from "./container/Invitation/InvitationContainer.tsx";
import InvoicingContainer from "./container/Invoicing/InvoicingContainer.tsx";
import TrackingContainer from "./container/Tracking/TrackingContainer.tsx";
import QuoteContainer from "./container/Quote/QuoteContainer.tsx";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CarriersContainer from "./container/Carriers/CarriersContainer.tsx";
import CarrierDetailContainer from "./container/Carrier/CarrierDetailContainer.tsx";
import TransEuContainer from "./container/Integrations/freight-exchanges/TransEuContainer.tsx";
import ResetPasswordContainer from "./container/ResetPassword/ResetPasswordContainer.tsx";
import IneloContainer from "./container/Integrations/gps/IneloContainer.tsx";
import "./i18n";
import ShippersContainer from "./container/Shippers/ShippersContainer.tsx";
import * as Sentry from "@sentry/react";
import ForwardersContainer from "./container/Forwarders/ForwardersContainer.tsx";
import SubscriptionContainer from "./container/Subscription/SubscriptionContainer.tsx";
import { AuthRoute } from "./container/AuthRoute.tsx";
import { MapProvider } from "./contexts/MapContext.tsx";
import { ThemeProvider } from "./contexts/ThemeContext.tsx";
import TrackingDispatcherContainer from "./container/TrackingDispatcher/TrackingDispatcherContainer.tsx";
import MyFleetContainer from "./container/MyFleet/MyFleetContainer.tsx";
import OrdersContainer from "./container/Orders/OrdersContainer.tsx";
import MyFleetTruckContainer from "./container/MyFleetTruckDetail/MyFleetTruckContainer.tsx";
import CarrierEmployeeDetailContainer from "./container/CarrierEmployeeDetail/EmployeeDetailContainer.tsx";
import MyEmployeeDetailContainer from "./container/MyEmployeeDetail/EmployeeDetailContainer.tsx";
import CarrierDriverDetailContainer from "./container/CarrierDriverDetail/CarrierDriverDetailContainer.tsx";
import MyDriverDetailContainer from "./container/MyDriverDetail/MyDriverDetailContainer.tsx";
import RegisterSecondStepContainer from "./container/RegisterSecondStep/RegisterSecondStepContainer.tsx";
import RegisterFirstStepSuccessContainer from "./container/RegisterFirstStepSuccess/RegisterFirstStepSuccessContainer.tsx";
import SubscriptionSuccessContainer from "./container/SubscriptionSuccess/SubscriptionSuccessContainer.tsx";
import ExchangeContainer from "./container/Exchange/Exchange.tsx";
import { ExchangeProvider } from "./contexts/ExchangeContext.tsx";
import UserContainer from "./container/User/UserContainer.tsx";
import DelegationsContainer from "./container/Delegations/DelegationsContainer.tsx";
import DelegationDetailContainer from "./container/DelegationDetail/DelegationDetailContainer.tsx";
Sentry.init({
  dsn: "https://44b733ac26e74a75cb2e0b8e6323bc3e@o1275214.ingest.us.sentry.io/4507526517555200",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/app.frozex\.io\//],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.NODE_ENV,
});

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <AuthLayout />
        <ToastContainer autoClose={3000} />
      </>
    ),
    children: [
      {
        path: "/",
        element: (
          <ProtectedRoute>
            <DashboardContainer />
          </ProtectedRoute>
        ),
        index: true,
      },
      {
        path: "/map",
        element: (
          <ProtectedRoute>
            <MapProvider>
              <MapContainer />
            </MapProvider>
          </ProtectedRoute>
        ),
      },
      {
        path: "/insights",
        element: (
          <ProtectedRoute requirePermission="insights" requireAction="read">
            <InsightsContainer />
          </ProtectedRoute>
        ),
      },
      {
        path: "/account",
        element: (
          <ProtectedRoute>
            <AccountContainer />
          </ProtectedRoute>
        ),
      },
      {
        path: "/account/users/:userId",
        element: (
          <ProtectedRoute>
            <UserContainer />
          </ProtectedRoute>
        ),
      },
      {
        path: "/insights/:truckId",
        element: (
          <ProtectedRoute>
            <TruckInsightsContainer />
          </ProtectedRoute>
        ),
      },
      {
        path: "/orders/",
        element: (
          <ProtectedRoute>
            <OrdersContainer />
          </ProtectedRoute>
        ),
      },
      {
        path: "/orders/:orderId",
        element: (
          <ProtectedRoute>
            <OrderContainer />
          </ProtectedRoute>
        ),
      },
      {
        path: "/shippers",
        element: (
          <ProtectedRoute>
            <ShippersContainer />
          </ProtectedRoute>
        ),
      },
      {
        path: "/shippers/:shipperId",
        element: (
          <ProtectedRoute>
            <ShipperDetailContainer />
          </ProtectedRoute>
        ),
      },
      {
        path: "/forwarders",
        element: (
          <ProtectedRoute>
            <ForwardersContainer />
          </ProtectedRoute>
        ),
      },
      {
        path: "/integrations",
        element: (
          <ProtectedRoute>
            <IntegrationsContainer />
          </ProtectedRoute>
        ),
      },
      {
        path: "/delegations/:delegationId",
        element: (
          <ProtectedRoute>
            <DelegationDetailContainer />
          </ProtectedRoute>
        ),
      },
      {
        path: "/delegations",
        element: (
          <ProtectedRoute>
            <DelegationsContainer />
          </ProtectedRoute>
        ),
      },
      {
        path: "/integrations/trans-eu",
        element: (
          <ProtectedRoute>
            <TransEuContainer />
          </ProtectedRoute>
        ),
      },
      {
        path: "/integrations/gbox",
        element: (
          <ProtectedRoute>
            <IneloContainer />
          </ProtectedRoute>
        ),
      },
      {
        path: "/carriers",
        element: (
          <ProtectedRoute>
            <CarriersContainer />
          </ProtectedRoute>
        ),
      },
      {
        path: "/carriers/:carrierId",
        element: (
          <ProtectedRoute>
            <CarrierDetailContainer />
          </ProtectedRoute>
        ),
      },
      {
        path: "/carriers/:carrierId/employees/:employeeId",
        element: (
          <ProtectedRoute>
            <CarrierEmployeeDetailContainer />
          </ProtectedRoute>
        ),
      },
      {
        path: "/carriers/:carrierId/drivers/:driverId",
        element: (
          <ProtectedRoute>
            <CarrierDriverDetailContainer />
          </ProtectedRoute>
        ),
      },
      {
        path: "/carriers/:carrierId/trucks/:truckId",
        element: (
          <ProtectedRoute>
            <TruckContainer />
          </ProtectedRoute>
        ),
      },
      {
        path: "/my-fleet",
        element: (
          <ProtectedRoute>
            <MyFleetContainer />
          </ProtectedRoute>
        ),
      },
      {
        path: "/my-fleet/employees/:employeeId",
        element: (
          <ProtectedRoute>
            <MyEmployeeDetailContainer />
          </ProtectedRoute>
        ),
      },
      {
        path: "/my-fleet/drivers/:driverId",
        element: (
          <ProtectedRoute>
            <MyDriverDetailContainer />
          </ProtectedRoute>
        ),
      },
      {
        path: "/my-fleet/trucks/:truckId",
        element: (
          <ProtectedRoute>
            <MyFleetTruckContainer />
          </ProtectedRoute>
        ),
      },
      {
        path: "/invoices",
        element: (
          <ProtectedRoute>
            <InvoicingContainer />
          </ProtectedRoute>
        ),
      },
      {
        path: "/subscription",
        element: (
          <AuthRoute>
            <SubscriptionContainer />
          </AuthRoute>
        ),
      },
      {
        path: "/subscription-success",
        element: (
          <AuthRoute>
            <SubscriptionSuccessContainer />
          </AuthRoute>
        ),
      },
      {
        path: "/orders/:orderId/tracking",
        element: (
          <ProtectedRoute>
            <TrackingDispatcherContainer />
          </ProtectedRoute>
        ),
      },
      {
        path: "/exchange",
        element: (
          <ProtectedRoute>
            <ExchangeProvider>
              <ExchangeContainer />
            </ExchangeProvider>
          </ProtectedRoute>
        ),
      },
      {
        path: "/register/:token",
        element: <RegisterSecondStepContainer />,
      },
      {
        path: "/sign-in",
        element: <SignInContainer />,
      },
      {
        path: "/trans-auth-redirect",
        element: <TransEuAuthCallbackContainer />,
      },
      {
        path: "/invitation/:token",
        element: <InvitationContainer />,
      },
      {
        path: "/reset-password/:token",
        element: <ResetPasswordContainer />,
      },
      {
        path: "/tracking/:uuid",
        element: <TrackingContainer />,
      },
      {
        path: "/quote",
        element: <QuoteContainer />,
      },
      {
        path: "/error",
        element: <ErrorPage />,
      },
      {
        path: "/thank-you-for-registering",
        element: <RegisterFirstStepSuccessContainer />,
      },
    ],
    errorElement: <ErrorPage />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <DndProvider backend={HTML5Backend}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider>
          <RouterProvider router={router} />
        </ThemeProvider>
      </LocalizationProvider>
    </DndProvider>
  </React.StrictMode>
);
