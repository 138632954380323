import { useTranslation } from "react-i18next";
import { OfferModel } from "../../../types/offers";
import { Avatar, IconButton } from "@mui/material";
import NegotiateForm from "./NegotiateForm";
import OfferOperations from "./OfferOperations/OfferOperations";
import OfferDetails from "./OfferDetails";
import { HiChat } from "react-icons/hi";
import useAxios from "../../../hooks/useAxios";

interface OfferContentProps {
  offer: OfferModel;
}

const OfferContent: React.FC<OfferContentProps> = ({ offer }) => {
  const { t } = useTranslation();
  const axios = useAxios();

  const placeBid = async (data: any) => {
    await axios.post(`/offers/${offer.ref_id}/bids/`, data);
  };

  return (
    <div className="p-4">
      <div className="flex flex-row">
        <OfferOperations offer={offer} />
        <div className="border border-gray-200 rounded p-4 mt-4">
          <h3 className="text-lg font-bold">
            {t("exchange.offerDetails.negotiation")}
          </h3>
          <div className="flex">
            <Avatar
              src={offer.assignee.preferences?.avatar}
              alt={offer.assignee.email}
            />
            <span className="ml-2">{offer.assignee.email}</span>
            <IconButton>
              <HiChat />
            </IconButton>
          </div>
          <NegotiateForm offer={offer} onSubmit={placeBid} />
        </div>
      </div>
      <OfferDetails offer={offer} />
    </div>
  );
};

export default OfferContent;
