import "react-chat-elements/dist/main.css";
import { useTranslation } from "react-i18next";
import { TrackingOrderInfo } from "../../../types/orders";
import OrderChat from "../../../components/chat/OrderChat";

interface TrackingOrderChatProps {
  order: TrackingOrderInfo;
}

const TrackingOrderChat: React.FC<TrackingOrderChatProps> = ({ order }) => {
  const { t } = useTranslation();

  return (
    <div className="p-4 flex flex-col justify-end h-full">
      {order.tracking_link?.uuid && (
        <OrderChat
          title={t("trackingOrderDetails.chat")}
          orderId={order.tracking_link?.uuid}
        />
      )}
    </div>
  );
};

export default TrackingOrderChat;
