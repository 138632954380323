import React from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Carrier } from "../../../types/carriers";
import { Company } from "../../../types/companies";

interface CarrierDetailsProps {
  carrier: Carrier | Company | null;
}

const CarrierAssigneeDetails: React.FC<CarrierDetailsProps> = ({ carrier }) => {
  const { t } = useTranslation();

  if (!carrier) return null;

  return (
    <div className="p-4 border border-neutral-300 shadow-lg">
      <p className="text-xl font-bold">
        {t("trackingOrderDetails.carrierAssigneeDetails")}
      </p>{" "}
      <div className="w-full border-b border-neutral-400 my-4 opacity-70" />
      <Typography>
        {t("trackingOrderDetails.carrierAssigneeName")}: {carrier.name}
      </Typography>
      <Typography>
        {t("trackingOrderDetails.carrierAssigneeEmail")}: {carrier.email}
      </Typography>
      <Typography>
        {t("trackingOrderDetails.carrierAssigneePhone")}: {carrier.phone}
      </Typography>
    </div>
  );
};

export default CarrierAssigneeDetails;
