import { TextField, MenuItem, Button } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { CURRENCIES } from "../../../consts/currencies";
import { useTranslation } from "react-i18next";
import { getCurrencySymbol } from "../../../utils/currencies";
import { EMPLOYMENT_TYPES, EmployeeDetails } from "../../../types/employees";

interface EmployeePaymentForm {
  employee: EmployeeDetails;
  onFormSubmit: (data: any) => void;
}

const EmployeePaymentForm: React.FC<EmployeePaymentForm> = ({
  employee,
  onFormSubmit,
}) => {
  const { t } = useTranslation();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      base_taxable_net_daily_pay: employee.payment_info?.base_taxable_net_daily_pay,
      base_net_daily_pay: employee.payment_info?.base_net_daily_pay,
      currency: employee.payment_info?.currency,
      employment_type: employee.payment_info?.employment_type,
      vat_rate: employee.payment_info?.vat_rate,
    },
  });

  return (
    <form
      onSubmit={handleSubmit(onFormSubmit)}
      className="grid gap-4 grid-cols-2"
    >
      <Controller
        name="base_taxable_net_daily_pay"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("employeeDetail.payments.fields.baseTaxableNetDailyPay")}
            variant="outlined"
            type="number"
          />
        )}
      />
      <Controller
        name="currency"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            select
            label={t("employeeDetail.payments.fields.currency")}
            variant="outlined"
          >
            {CURRENCIES.map((currency) => (
              <MenuItem key={currency} value={currency}>
                {getCurrencySymbol(currency)}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
      <Controller
        name="base_net_daily_pay"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("employeeDetail.payments.fields.baseNetDailyPay")}
            variant="outlined"
            type="number"
          />
        )}
      />
      <Controller
        name="vat_rate"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("employeeDetail.payments.fields.vatRate")}
            variant="outlined"
          />
        )}
      />
      <Controller
        name="employment_type"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            select
            label={t("employeeDetail.payments.fields.employmentType")}
            variant="outlined"
          >
            {EMPLOYMENT_TYPES.map((type) => (
              <MenuItem key={type} value={type}>
                {t(`employmentTypes.${type}`)}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
      <Button variant="contained" color="primary" type="submit">
        {t("common.save")}
      </Button>
    </form>
  );
};

export default EmployeePaymentForm;
