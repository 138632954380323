import { Link, useParams } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import { useEffect, useState } from "react";
import FormInput from "../../components/input/FormInput";
import { FaUser } from "react-icons/fa6";
import { FaUserLock } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { useToast } from "../../hooks/useToast";
import { extractErrorMessages } from "../../utils/errors";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

interface InvitationFormData {
  email: string;
  password: string;
  confirm_password: string;
}

const InvitationContainer = () => {
  const {t} = useTranslation();
  const { token } = useParams<{ token: string }>();
  const axios = useAxios();
  const { register, handleSubmit, setValue, setError, formState } =
    useForm<InvitationFormData>();
  const { errors } = formState;
  const [authToken, setAuthToken] = useState<string | null>(null);
  const navigate = useNavigate();

  const { onSetToken } = useAuth();

  const toast = useToast();
  const onSubmit = handleSubmit((data) => {
    if (data.password !== data.confirm_password) {
      setError("confirm_password", {
        type: "manual",
        message: "Passwords do not match",
      });
      return;
    }

    axios
      .put(`/account/invitations/${token}/`, data)
      .then((res) => {
        setAuthToken(res.data.auth_token);
      })
      .catch((error) => {
        const errors = extractErrorMessages(error.response.data);
        toast({
          message: errors.join(" "),
          type: "error",
        });
      });
  });

  useEffect(() => {
    axios
      .get(`/account/invitations/${token}/`)
      .then((res) => {
        setValue("email", res.data.email);
      })
      .catch(() => {
        toast({
          message: t("invitation.invalidInvitation"),
          type: "error",
        });
        setTimeout(() => {
          navigate("/sign-in", { replace: true });
        }, 5000);
      });
  }, [token]);

  useEffect(() => {
    if (authToken) {
      onSetToken(authToken);
      navigate("/", { replace: true });
    }
  }, [authToken]);

  if (!token) {
    return (
      <div className="flex flex-col items-center justify-center w-full min-h-screen space-y-8 md:p-0 p-8 text-center bg-primary">
        <h1 className="font-semibold text-4xl mb-16 text-center text-white">
          {t("invitation.invalidInvitation")}
        </h1>
        <Link to="/sign-in" className="text-white">
          {t("invitation.signIn")}
        </Link>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center w-full min-h-screen space-y-8 md:p-0 p-8 text-center bg-gradient-prime">
      <form onSubmit={onSubmit}>
        <div className="flex flex-col items-center justify-center px-16 py-8 shadow-2xl bg-white rounded-xl">
          <div className="flex flex-col items-center justify-center">
            <h1 className="font-semibold text-4xl mb-16 text-center text-black">
              Join Frozex Now
            </h1>
            <div className="flex flex-col gap-4 my-4">
              <div className="w-full h-10 rounded-lg mx-auto mb-4 items-center justify-between flex px-8 py-4">
                <FormInput
                  label="Email"
                  disabled
                  inputProps={{
                    ...register("email"),
                    required: true,
                    autoComplete: "username",
                  }}
                  icon={
                    <FaUser className="text-gray-300 rounded-xl bg-gray-100" />
                  }
                />
              </div>
              <div className="w-full h-10 rounded-lg mx-auto mb-4 items-center justify-between flex px-8 py-4">
                <FormInput
                  icon={
                    <FaUserLock className="text-gray-300 rounded-xl bg-gray-100" />
                  }
                  label="Password"
                  inputProps={{
                    type: "password",
                    placeholder: "Enter your password",
                    ...register("password"),
                    required: true,
                    autoComplete: "new-password",
                  }}
                  errorMessage={errors.password?.message}
                />
              </div>
              <div className="w-full h-10 rounded-lg mx-auto mb-4 items-center justify-between flex px-8 py-4">
                <FormInput
                  icon={
                    <FaUserLock className="text-gray-300 rounded-xl bg-gray-100" />
                  }
                  label="Confirm Password"
                  inputProps={{
                    type: "password",
                    placeholder: "Enter your password",
                    ...register("confirm_password"),
                    required: true,
                    autoComplete: "new-password",
                  }}
                  errorMessage={errors.confirm_password?.message}
                />
              </div>
            </div>
            <div className="w-full h-10 rounded-lg mx-auto mb-4 items-center justify-center flex px-8 py-4">
              <Button variant="contained" type="submit" className="w-full py-2">
                Join Frozex
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default InvitationContainer;
