import { TruckLocation } from "../../../types/truck";

interface TruckSideOverlayProps {
  truck: TruckLocation;
  truckColors: Record<string, string> | null;
}

const TruckSideOverlay: React.FC<TruckSideOverlayProps> = ({
  truck,
  truckColors,
}) => {
  return (
    <div className="absolute right-0 top-0 w-2/12 z-50 bg-accent bg-opacity-95 p-4 flex flex-col gap-3 rounded-bl-md">
      <div
        key={truck.truck}
        className={`flex items-center space-x-3 cursor-pointer hover:bg-primary hover:bg-opacity-50 p-2 rounded-lg ${
          truck.truck === truck?.truck ? "bg-primary bg-opacity-50" : ""
        }`}
      >
        <div
          className="w-8 h-8 rounded-full"
          style={{
            backgroundColor: truckColors
              ? truckColors[truck.truck as keyof typeof truckColors]
              : "#fff",
          }}
        ></div>
        <span className="text-black text-sm font-medium">
          {truck.truck_name} - {truck.truck}
        </span>
      </div>
    </div>
  );
};
export default TruckSideOverlay;
