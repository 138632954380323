import { useTranslation } from "react-i18next";
import { Avatar, CircularProgress, MenuItem, TextField } from "@mui/material";
import { CARRIER_USER_ROLES } from "../../consts/user";
import { hasAccess } from "../../utils/permissions";
import { useAuth } from "../../contexts/AuthContext";
import { FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Company } from "../../types/companies";

interface UsersProps {
  carrier: Company;
  loading: boolean;
  onRoleChange: (userId: number, role: string) => void;
}

const Users: React.FC<UsersProps> = ({ loading, carrier, onRoleChange }) => {
  const { t } = useTranslation();
  const { account, accountLoading } = useAuth();

  if (accountLoading || !account) return <CircularProgress />;

  const hasAccessForEdit = hasAccess(account.role, "users", "write");

  return (
    <div className="bg-neutral-300 dark:bg-zinc-800 p-4 border border-gray-400 text-black dark:text-white">
      {carrier?.users.map((user) => (
        <div
          key={user.id}
          className="flex items-center justify-between p-4 border-b border-gray-200"
        >
          <div className="flex items-center gap-2">
            <Avatar src={user.user.preferences?.avatar} alt="profile" />
            <div className="ml-4">
              <p className="text-lg font-semibold">
                {user.first_name} {user.last_name}
              </p>
              <p className="text-gray-500">{user.email}</p>
            </div>
            {hasAccessForEdit && (
              <Link className="cursor-pointer items-center" to={`/account/users/${user.id}`}>
                <FaEdit className="text-gray-500" />
              </Link>
            )}
          </div>
          <TextField
            value={user.role}
            select
            label={t("account.users.role")}
            variant="outlined"
            disabled={loading || !hasAccess(account.role, "users", "write")}
            onChange={(e) => onRoleChange(user.id, e.target.value)}
          >
            {CARRIER_USER_ROLES.map((role) => (
              <MenuItem key={role} value={role}>
                <div className="flex items-center gap-2">
                  {t(`account.users.roles.${role}`)}
                </div>
              </MenuItem>
            ))}
          </TextField>
        </div>
      ))}
    </div>
  );
};

export default Users;
