import { OrderInfo } from "../../../types/orders";
import BasicDetailsForm from "../BasicDetailsForm";
import { useTranslation } from "react-i18next";
import { FaCopy } from "react-icons/fa6";
import { useState } from "react";
import { Button, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { IoMdOpen } from "react-icons/io";
import { HiChatAlt2 } from "react-icons/hi";

interface GeneralOrderDetailsProps {
  order: OrderInfo;
  trackingLinkLoading: boolean;
  onFormSubmit: (order: OrderInfo) => void;
  onCancelOrder: (orderId: number) => void;
  onCreateTrackingLink: (duration: number) => void;
}

const GeneralOrderDetails: React.FC<GeneralOrderDetailsProps> = ({
  order,
  trackingLinkLoading,
  onFormSubmit,
  onCancelOrder,
  onCreateTrackingLink,
}) => {
  const [copySuccess, setCopySuccess] = useState("");

  const copyToClipboard = () => {
    if (!order) return;
    navigator.clipboard
      .writeText(order.tracking_link?.link || "")
      .then(() => setCopySuccess("Copied!"))
      .catch(() => setCopySuccess("Failed to copy!"));
  };
  const { t } = useTranslation();
  return (
    <div className="bg-neutral-300 dark:bg-zinc-800 p-4 border border-gray-400">
      <BasicDetailsForm
        order={order}
        onFormSubmit={onFormSubmit}
        onCancelOrder={onCancelOrder}
      />
      <div>
        <p className="text-lg font-semibold mt-4">
          {t("orderDetails.general.trackingLink")}
        </p>
        {order.tracking_link && (
          <div className="mt-2 text-black flex-shrink-0">
            <span className="ml-2 text-gray-500">
              {t("orderDetails.general.validUntil")}:{" "}
              {order.tracking_link.valid_until}
            </span>
            <div className="flex items-center">
              <button
                onClick={copyToClipboard}
                className="ml-2 text-black px-2 py-1 rounded flex items-center gap-2 border-black border-2"
              >
                <div className="bg-white px-2 py-1 flex items-center gap-2 border-r border-black">
                  {order.tracking_link.link}
                </div>
                <FaCopy />
                <Link
                  to={order.tracking_link.link}
                  target="_blank"
                  className="bg-white px-2 py-2 gap-2 border-l border-black"
                >
                  <IoMdOpen />
                </Link>
              </button>
              <Link to={`/orders/${order.id}/tracking`}>
                <IconButton>
                  <HiChatAlt2 className="text-accent" />
                </IconButton>
              </Link>
            </div>

            {copySuccess && (
              <span className="ml-2 text-green-500">{copySuccess}</span>
            )}
          </div>
        )}
        {!order.tracking_link && (
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onCreateTrackingLink(1)}
              disabled={
                order.status === "canceled" ||
                order.status === "done" ||
                order.status === "discarded" ||
                trackingLinkLoading
              }
            >
              {t("orderDetails.general.create")}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default GeneralOrderDetails;
