import { TextField, Button } from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface AddEmployeeFormProps {
  loading: boolean;
  onSubmit: (data: any) => void;
}

const AddEmployeeForm: React.FC<AddEmployeeFormProps> = ({
  loading,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-2 gap-4 p-2">
      <Controller
        name="first_name"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("employeeList.addEmployeeModal.firstName")}
            variant="outlined"
            required
          />
        )}
      />
      <Controller
        name="last_name"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("employeeList.addEmployeeModal.lastName")}
            variant="outlined"
            required
          />
        )}
      />
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("employeeList.addEmployeeModal.email")}
            variant="outlined"
            type="email"
            required
          />
        )}
      />
      <Controller
        name="phone"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("employeeList.addEmployeeModal.phone")}
            variant="outlined"
            required
          />
        )}
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={loading}
      >
        {t("employeeList.addEmployeeModal.save")}
      </Button>
    </form>
  );
};

export default AddEmployeeForm;
