import { Link, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useAuth } from "../../contexts/AuthContext";
import { Button, TextField } from "@mui/material";
import { useState } from "react";

interface ResetPasswordContainerFormData {
  password: string;
  confirm_password: string;
}

const ResetPasswordContainer = () => {
  const { resetConfirm } = useAuth();
  const { token } = useParams<{ token: string }>();
  const { handleSubmit, setError, control } =
    useForm<ResetPasswordContainerFormData>();
  const [valid, setValid] = useState<boolean>(true);

  const onSubmit = handleSubmit(async (data) => {
    if (data.password !== data.confirm_password) {
      setError("confirm_password", {
        type: "manual",
        message: "Passwords do not match",
      });
      return;
    }
    const payload = {
      ...data,
      reset_token: token,
    };
    const isValid = await resetConfirm(payload);
    setValid(isValid);
  });

  if (!token) {
    return (
      <div className="flex flex-col items-center justify-center w-full min-h-screen space-y-8 md:p-0 p-8 text-center bg-primary">
        <h1 className="font-semibold text-4xl mb-16 text-center text-white">
          Password Reset Link is Invalid
        </h1>
        <Link to="/" className="text-white">
          Return
        </Link>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center w-full min-h-screen space-y-8 md:p-0 p-8 text-center bg-gradient-prime">
      <form onSubmit={onSubmit}>
        <div className="flex flex-col items-center justify-center px-16 py-8 shadow-2xl bg-white rounded-xl">
          <div className="flex flex-col items-center justify-center">
            <h1 className="font-semibold text-4xl mb-16 text-center text-black">
              Reset Your Password
            </h1>
            <div className="flex flex-col gap-4 my-4 w-full">
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    name="password"
                    label="Password"
                    variant="outlined"
                    type="password"
                    required
                    autoComplete="current-password"
                  />
                )}
              />
              <Controller
                name="confirm_password"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    name="confirm_password"
                    label="Confirm Password"
                    variant="outlined"
                    type="password"
                    required
                    autoComplete="current-password"
                  />
                )}
              />
            </div>
            <div className="w-full h-10 rounded-lg mx-auto mb-4 items-center justify-center flex px-8 py-4">
              <Button variant="contained" type="submit" className="w-full py-2">
                Reset Password
              </Button>
            </div>
            {!valid && (
              <Link to="/sign-in">
                <Button variant="contained" color="error" className="w-full py-2">
                  Try Sending Email Again
                </Button>
              </Link>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default ResetPasswordContainer;
