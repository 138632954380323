interface NoDataProps {
  message: string;
}

const NoDataAvailable: React.FC<NoDataProps> = ({ message }) => {
  return (
    <div className="flex w-full h-full items-center justify-center gap-4 flex-col p-8">
      <h3 className="text-center text-3xl">No Data Available</h3>
      <p className="text-center">{message}</p>
    </div>
  );
};

export default NoDataAvailable;
