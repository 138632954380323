import { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Link, useParams } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import { useTranslation } from "react-i18next";
import { Breadcrumbs, CircularProgress, Typography } from "@mui/material";
import { useToast } from "../../hooks/useToast";
import { DriverDetails, DriverDocumentFormData } from "../../types/driver";
import { Carrier } from "../../types/carriers";
import DriverDocuments from "../../components/Drivers/Documents/DriverDocuments";
import DriverBaseDetailsForm from "../../components/Drivers/DriverBaseDetailsForm";

const CarrierDriverDetailContainer = () => {
  const { t } = useTranslation();
  const { carrierId, driverId } = useParams();
  const [carrier, setCarrier] = useState<Carrier | null>(null);
  const axios = useAxios();
  const [driver, setDriver] = useState<DriverDetails | null>(null);
  const toast = useToast();
  useEffect(() => {
    if (!driverId || !carrierId) return;

    axios
      .get(`/carriers/${carrierId}/drivers/${driverId}`)
      .then((response) => {
        setDriver(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
    axios
      .get(`/carriers/${carrierId}`)
      .then((response) => {
        setCarrier(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [carrierId, driverId]);

  const onSubmit = (data: any) => {
    axios
      .patch(`/carriers/${carrierId}/drivers/${driverId}/`, data)
      .then(() => {
        toast({
          message: t("driverDetail.toast.success"),
          type: "success",
        });
      });
  };

  const onUpdateDocument = async (documentId: number, data: any) => {
    const response = await axios.patch(
      `/carriers/${carrierId}/drivers/${driverId}/documents/${documentId}/`,
      data
    );
    setDriver((prevDriver) => {
      if (!prevDriver) return null;
      return {
        ...prevDriver,
        documents: prevDriver.documents.map((doc) =>
          doc.id === documentId ? response.data : doc
        ),
      };
    });
    toast({
      message: "Document updated",
      type: "success",
    });
  };
  const onAddDocument = async (document: DriverDocumentFormData) => {
    const response = await axios.post(
      `/carriers/${carrierId}/drivers/${driverId}/documents/`,
      document
    );
    setDriver((prevDriver) => {
      if (!prevDriver) return null;
      return {
        ...prevDriver,
        documents: [...prevDriver.documents, response.data],
      };
    });
    toast({
      message: "Document added",
      type: "success",
    });
  };

  const onDeleteDocument = async (fileId: number) => {
    try {
      const response = await axios.delete(
        `/carriers/${carrierId}/drivers/${driverId}/documents/${fileId}/`
      );
      if (response.status === 204) {
        setDriver((prevDriver) => {
          if (!prevDriver) return null;
          return {
            ...prevDriver,
            documents: prevDriver.documents.filter((doc) => doc.id !== fileId),
          };
        });
        toast({
          message: "Document deleted",
          type: "success",
        });
      } else {
        console.error("Upload failed:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return driver ? (
    <div className="p-4 text-black">
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">{t("breadcrumb.home")}</Link>
        <Link to="/carriers">{t("breadcrumb.carriers")}</Link>
        <Link to={`/carriers/${carrierId}`}>
          {t("carrierDetail.title")} - {carrier?.name}
        </Link>
        <Typography color="text.primary">
          {t("driverDetail.title")} - {driver.full_name}
        </Typography>
      </Breadcrumbs>
      <Tabs>
        <TabList>
          <Tab>{t("driverDetail.tabs.general")}</Tab>
          <Tab>{t("driverDetail.tabs.documents")}</Tab>
        </TabList>

        <TabPanel>
          <DriverBaseDetailsForm driver={driver} onSubmit={onSubmit} />
        </TabPanel>
        <TabPanel>
          <DriverDocuments
            driver={driver}
            onUpdateDocument={onUpdateDocument}
            onAddDocument={onAddDocument}
            onDeleteDocument={onDeleteDocument}
          />
        </TabPanel>
      </Tabs>
    </div>
  ) : (
    <CircularProgress />
  );
};

export default CarrierDriverDetailContainer;
