import { Box, TextField, MenuItem, Button } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import {
  Maintenance,
  MaintenanceModel,
  TRUCK_MAINTENANCE_TYPES,
} from "../../../types/truck";
import { CURRENCIES } from "../../../consts/currencies";
import { getCurrencySymbol } from "../../../utils/currencies";

interface TruckMaintenanceEditProps {
  maintenance: MaintenanceModel;
  onEditMaintenanceSubmit: (maintenance: Maintenance) => void;
}

const TruckMaintenanceEdit: React.FC<TruckMaintenanceEditProps> = ({
  maintenance,
  onEditMaintenanceSubmit,
}) => {
  const { control, handleSubmit } = useForm<MaintenanceModel>({
    defaultValues: maintenance,
  });

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onEditMaintenanceSubmit)}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        p: 4,
        backgroundColor: "white",
        borderRadius: 1,
        boxShadow: 24,
      }}
    >
      <h2>Edit Maintenance</h2>
      <Controller
        name="service_type"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            {...field}
            label="Service Type"
            variant="outlined"
            fullWidth
            margin="normal"
            select
          >
            {TRUCK_MAINTENANCE_TYPES.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
      <Controller
        name="due_date"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label="Due Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            fullWidth
            margin="normal"
          />
        )}
      />
      <Controller
        name="date_performed"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label="Date Performed"
            type="date"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            fullWidth
            margin="normal"
          />
        )}
      />
      <Controller
        name="due_mileage"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label="Due Mileage"
            type="number"
            variant="outlined"
            fullWidth
            margin="normal"
          />
        )}
      />
      <Controller
        name="details"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label="Details"
            variant="outlined"
            fullWidth
            margin="normal"
            multiline
            rows={4}
          />
        )}
      />
      <Controller
        name="currency"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            select
            label="Currency"
            variant="outlined"
            className="w-64"
          >
            {CURRENCIES.map((currency) => (
              <MenuItem key={currency} value={currency}>
                {getCurrencySymbol(currency)}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
      <Controller
        name="price"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label="Price"
            variant="outlined"
            fullWidth
            margin="normal"
            type="number"
          />
        )}
      />
      <Button type="submit" variant="contained" color="primary">
        Save
      </Button>
    </Box>
  );
};

export default TruckMaintenanceEdit;
