import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";
import CountryFlag from "react-country-flag";
import { GRAPH_COLORS } from "../../../utils/colors";

interface CabotageIncomesProps {
  country_incomes: { [countries: string]: number };
}

const CabotageIncomes: React.FC<CabotageIncomesProps> = ({
  country_incomes,
}) => {
  const data = Object.keys(country_incomes).map((key, index) => {
    return {
      country: key,
      income: country_incomes[key],
      color: GRAPH_COLORS[index % GRAPH_COLORS.length],
    };
  });
  data.sort((a, b) => b.income - a.income);

  const renderCustomAxisTick = ({
    x,
    y,
    payload,
  }: {
    x: number;
    y: number;
    payload: any;
  }) => {
    return (
      <foreignObject
        x={x - 50}
        y={y}
        width={100}
        height={50}
        style={{ overflow: "visible" }}
      >
        <div className="flex flex-col items-center justify-center">
          <div className="flex">
            <CountryFlag
              countryCode={payload.value}
              svg
              style={{
                width: "1em",
                height: "1em",
                marginRight: "5px",
              }}
              title={payload.value}
            />
          </div>
          <span>{payload.value}</span>
        </div>
      </foreignObject>
    );
  };

  return (
    <div>
      <h1 className="text-center">Cabotage Incomes</h1>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          width={600}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="country" tick={renderCustomAxisTick} interval={0} />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="income">
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CabotageIncomes;
