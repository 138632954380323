import { Button, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface TruckForm {
  name: string;
  license_plate: string;
}

interface AddTruckFormProps {
  onSubmit: (data: TruckForm) => void;
}

const AddTruckForm: React.FC<AddTruckFormProps> = ({ onSubmit }) => {
  const { control, handleSubmit } = useForm<TruckForm>();
  const { t } = useTranslation();

  return (
    <>
      <div className="p-4">
        <h1 className="text-2xl font-bold mb-4 text-black">
          {t("truckList.addTruckModal.title")}
        </h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("truckList.addTruckModal.name")}
                  variant="outlined"
                  required
                />
              )}
            />
          </div>
          <div className="mb-4">
            <Controller
              name="license_plate"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("truckList.addTruckModal.licensePlate")}
                  variant="outlined"
                  required
                />
              )}
            />
          </div>
          <Button type="submit" variant="contained" color="primary">
            {t("common.save")}
          </Button>
        </form>
      </div>
    </>
  );
};

export default AddTruckForm;
