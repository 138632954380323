import axios from "axios";
import { useAuth } from "../contexts/AuthContext";
import { useToast } from "./useToast";
import { extractErrorMessages } from "../utils/errors";

const useAxios = () => {
  const { logout, token } = useAuth();
  const toast = useToast();
  const axiosInstance = axios.create({
    baseURL: import.meta.env.VITE_PUBLIC_API_URL,
    headers: {
      Authorization: token ? `Token ${token}` : "",
    },
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.error("Axios error:", error);
      if (error.response?.status === 401) {
        logout();
      } else if (error.response?.status === 403) {
        toast({
          message: "You don't have permission to access this resource",
          type: "error",
        });
      } else if (error.response?.status >= 500) {
        toast({
          message: "Something went wrong, please try again later",
          type: "error",
        });
      } else if (error.response?.status === 400) {
        const errors = extractErrorMessages(error.response.data);
        toast({
          message: errors.join(", "),
          type: "error",
        });
      }
      else if (error.response?.status === 404) {
        toast({
          message: "Resource not found",
          type: "error",
        });
      }

      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default useAxios;
