import { CircularProgress, TextField, InputAdornment } from "@mui/material";
import { useEffect, useState } from "react";
import { Control, Controller, FieldArrayWithId } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useAxios from "../../../hooks/useAxios";
import { getCurrencySymbol } from "../../../utils/currencies";

interface OrderCreateSummaryProps {
  control: Control<any, any>;
  fields: FieldArrayWithId<any, "operations", "id">[];
  getValues: any;
  setValue: any;
  watch: any;
}

const OrderCreateSummary: React.FC<OrderCreateSummaryProps> = ({
  control,
  fields,
  getValues,
  setValue,
  watch,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const axios = useAxios();
  const [ratePerKm, setRatePerKm] = useState<string>("");

  useEffect(() => {
    const coordinates = getValues("operations").map((operation: any) => {
      return {
        latitude: operation.latitude,
        longitude: operation.longitude,
      };
    });

    if (coordinates.length < 2) {
      setLoading(false);
      return;
    }

    const allCoordinatesValid = coordinates.every(
      (coord: any) => coord.latitude != null && coord.longitude != null
    );

    if (!allCoordinatesValid || coordinates.length < 2) {
      setLoading(false);
      return;
    }

    const fetchDistance = async () => {
      try {
        const response = await axios.post(`route/calculate/`, {
          coordinates,
        });
        const routes = response.data.routes;
        if (routes.length === 0) return;
        const distance = routes[0].summary.distance;
        const formattedDistance = (distance / 1000).toFixed(1);
        setValue("estimated_distance", formattedDistance);
      } catch (error) {
        console.error("Error fetching distance:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDistance();
  }, [fields]);

  const value = watch("payment.price_value");
  const currency = watch("payment.price_currency");
  const estimatedDistance = watch("estimated_distance");

  useEffect(() => {
    if (value && currency && estimatedDistance) {
      setRatePerKm((value / estimatedDistance).toFixed(2));
    }
  }, [value, currency, estimatedDistance]);

  return (
    <div>
      {!loading ? (
        <div>
          <Controller
            name="estimated_distance"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("orders.orderCreate.estDistance")}
                sx={{ mb: 2 }}
                type="number"
                fullWidth
                disabled
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">km</InputAdornment>
                  ),
                  readOnly: true,
                }}
              />
            )}
          />
          <TextField
            label={t("orders.orderCreate.estRatePerKm")}
            sx={{ mb: 2 }}
            type="number"
            fullWidth
            disabled
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {getCurrencySymbol(currency)}
                </InputAdornment>
              ),
              readOnly: true,
            }}
            value={ratePerKm}
          />
        </div>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
};

export default OrderCreateSummary;
