import { useTranslation } from "react-i18next";
import { Employee } from "../../../types/employees";

interface MaintenanceIncomingProps {
  data: {
    employee: Employee;
    documents: string[];
  };
}

const MissingEmployeeDocuments: React.FC<MaintenanceIncomingProps> = ({
  data,
}) => {
  const { t } = useTranslation();
  
  return (
    <>
      <div className="flex items-center justify-between">
        <div>
          <p>{data.employee.full_name}</p> 
          <p>{t(`documentTypes.${data.documents[0]}`)}</p>
        </div>
        <div></div>
      </div>
    </>
  );
};

export default MissingEmployeeDocuments;
