import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { GRAPH_COLORS } from "../../../utils/colors";
import { useTranslation } from "react-i18next";

interface CarrierContractorMarginsProps {
  carrier_contractor_margins: { [carriers: string]: number };
}

const CarrierContractorMargins: React.FC<CarrierContractorMarginsProps> = ({ carrier_contractor_margins }) => {
  const { t } = useTranslation();

  const data = Object.keys(carrier_contractor_margins).map((key, index) => {
    return {
      carrier: key,
      margin: carrier_contractor_margins[key],
      color: GRAPH_COLORS[index % GRAPH_COLORS.length],
    };
  });
  data.sort((a, b) => b.margin - a.margin);

  return (
    <div>
      <h1 className="text-center">
        {t("insights.profit.carrier_contractor_margins.title")}
      </h1>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          width={600}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="carrier" interval={0} />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="margin">
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CarrierContractorMargins;
