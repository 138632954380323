import { Link } from "react-router-dom";
import { Assignee } from "../../types/orders";
import { FaPencilAlt } from "react-icons/fa";
import {
  Typography,
  IconButton,
  Divider,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { CorporateDetails } from "../../types/base";

interface OrderShipperProps {
  shipper: CorporateDetails;
  shipperAssignee?: Assignee;
}

const OrderShipper: React.FC<OrderShipperProps> = ({
  shipper,
  shipperAssignee,
}) => {
  const { t } = useTranslation();
  return (
    <div className="bg-neutral-300 dark:bg-zinc-800 p-4 border border-gray-400">
          <div className="flex items-center justify-between">
            <Typography variant="h5" component="h2" className="font-semibold">
              {t("orderDetails.shipper.title")}
            </Typography>
            <IconButton
              component={Link}
              to={`/shippers/${shipper.id}`}
              aria-label="edit"
            >
              <FaPencilAlt />
            </IconButton>
          </div>
          <Divider className="my-2" />
          <div className="grid grid-cols-1 gap-2">
            <Typography variant="body1">
              <strong className="font-semibold">
                {t("orderDetails.shipper.name")}:
              </strong>{" "}
              {shipper.name}
            </Typography>
            <Typography variant="body1">
              <strong className="font-semibold">
                {t("orderDetails.shipper.vatId")}:
              </strong>{" "}
              {shipper.vat_id}
            </Typography>
          </div>

      {shipperAssignee && (
        <>
            <Typography variant="h5" component="h2" className="font-semibold">
              {t("orderDetails.shipper.assignee.title")}
            </Typography>
            <Divider className="my-2" />
            <div className="grid grid-cols-1 gap-2">
              <Typography variant="body1">
                <strong className="font-semibold">
                  {t("orderDetails.shipper.assignee.lastName")}:
                </strong>{" "}
                {shipperAssignee.family_name}
              </Typography>
              <Typography variant="body1">
                <strong className="font-semibold">
                  {t("orderDetails.shipper.assignee.firstName")}:
                </strong>{" "}
                {shipperAssignee.given_name}
              </Typography>
              <Typography variant="body1">
                <strong className="font-semibold">
                  {t("orderDetails.shipper.assignee.phone")}:
                </strong>{" "}
                {shipperAssignee.phone}
              </Typography>
              <Typography variant="body1">
                <strong className="font-semibold">
                  {t("orderDetails.shipper.assignee.email")}:
                </strong>{" "}
                {shipperAssignee.email}
              </Typography>
            </div>
        </>
      )}
    </div>
  );
};

export default OrderShipper;
