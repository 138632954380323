import useAxios from "../../hooks/useAxios";
import { useToast } from "../../hooks/useToast";
import { useEffect, useState } from "react";
import CompanyDetailsForm from "./CompanyDetailsForm";
import { CircularProgress } from "@mui/material";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useTranslation } from "react-i18next";
import AccountDetailsForm from "./AccountDetailsForm";
import { useAuth } from "../../contexts/AuthContext";
import { changeLanguage } from "i18next";
import Users from "./Users";
import { Company } from "../../types/companies";
import { useSearchParams } from "react-router-dom";
import InvoicingForm from "./InvoicingForm";

const AccountContainer = () => {
  const { t } = useTranslation();
  const [carrier, setCarrier] = useState<Company | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [roleLoading, setRoleLoading] = useState<boolean>(false);
  const axios = useAxios();
  const toast = useToast();
  const { setAccount } = useAuth();
  const [selectedTab, setSelectedTab] = useState(0);

  const [query, setQuery] = useSearchParams();

  const tabToIndex = {
    account: 0,
    company: 1,
    invoicing: 2,
    subscription: 3,
    users: 4,
  };

  const indexToTab = {
    0: "account",
    1: "company",
    2: "invoicing",
    3: "subscription",
    4: "users",
  };

  useEffect(() => {
    const tab = query.get("tab");
    if (tab && tabToIndex[tab as keyof typeof tabToIndex] !== undefined) {
      setSelectedTab(tabToIndex[tab as keyof typeof tabToIndex]);
    }
  }, [query]);

  const handleTabSelect = (index: number) => {
    setSelectedTab(index);
    setQuery({ tab: indexToTab[index as keyof typeof indexToTab] });
  };

  useEffect(() => {
    axios.get<Company>("/companies/my-company/").then((res) => {
      setCarrier(res.data);
      setLoading(false);
    });
  }, []);

  const onSubmit = (data: any) => {
    axios.patch(`/companies/my-company/`, data).then(() => {
      toast({
        message: "Carrier updated",
        type: "success",
      });
    });
  };

  const onAccountDetailsSubmit = (data: any) => {
    axios
      .patch(`/account/profile/`, data)
      .then((response) => {
        setAccount(response.data);
        changeLanguage(response.data.preferences.language);
        toast({
          message: "Account updated",
          type: "success",
        });
      })
      .catch(() => {
        toast({
          message: "Error updating account",
          type: "error",
        });
      });
  };

  const onRoleChange = (userId: number, role: string) => {
    setRoleLoading(true);
    axios
      .patch(`/companies/my-company/users/${userId}/`, { role })
      .then(() => {
        setCarrier((prev) => {
          if (!prev) {
            return null;
          }

          return {
            ...prev,
            users: prev.users.map((user) => {
              if (user.id === userId) {
                return {
                  ...user,
                  role,
                };
              }

              return user;
            }),
          };
        });
        toast({
          message: "User role updated",
          type: "success",
        });
      })
      .finally(() => {
        setRoleLoading(false);
      });
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress />
      </div>
    );
  }

  if (!carrier) {
    return null;
  }

  return (
    <div className="p-4 mb-2">
      <Tabs
        selectedTabClassName="bg-neutral-300 dark:bg-zinc-800 text-black dark:text-secondary border border-neutral-400"
        selectedIndex={selectedTab}
        onSelect={handleTabSelect}
      >
        <TabList className="text-black dark:text-secondary">
          <Tab>{t("account.tabs.account")}</Tab>
          <Tab>{t("account.tabs.company")}</Tab>
          <Tab>{t("account.tabs.invoicing")}</Tab>
          <Tab>{t("account.tabs.subscription")}</Tab>
          <Tab>{t("account.tabs.users")}</Tab>
        </TabList>

        <TabPanel>
          <AccountDetailsForm onSubmit={onAccountDetailsSubmit} />
        </TabPanel>

        <TabPanel>
          <CompanyDetailsForm carrier={carrier} onSubmit={onSubmit} />
        </TabPanel>
        <TabPanel>
          <InvoicingForm />
        </TabPanel>
        <TabPanel></TabPanel>
        <TabPanel>
          <Users
            carrier={carrier}
            loading={roleLoading}
            onRoleChange={onRoleChange}
          />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default AccountContainer;
