import { Button, MenuItem, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { BaseDelegation } from "../../types/delegations";
import { useAuth } from "../../contexts/AuthContext";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useState } from "react";

interface DelegationFormProps {
  onSubmit: (delegation: BaseDelegation) => Promise<void>;
}

const DelegationForm: React.FC<DelegationFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit } = useForm<BaseDelegation>();

  const { company } = useAuth();

  if (!company) {
    return null;
  }

  const onAddDelegation = async (delegation: BaseDelegation) => {
    try {
      setLoading(true);
      await onSubmit(delegation);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onAddDelegation)}
      className="grid grid-cols-2 gap-4 p-4"
    >
      <Controller
        name="driver"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            select
            label={t("orderDetails.carrier.selectDriver")}
            fullWidth
            variant="outlined"
            required
          >
            {company.drivers.map((driver) => (
              <MenuItem key={driver.id} value={driver.id}>
                {driver.first_name} {driver.last_name}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
      <Controller
        name="truck"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            select
            label={t("delegations.addDelegationModal.selectTruck")}
            fullWidth
            variant="outlined"
            required
          >
            {company.trucks.map((truck) => (
              <MenuItem key={truck.license_plate} value={truck.license_plate}>
                {truck.name} {truck.license_plate}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
      <Controller
        name="stand_start_date"
        control={control}
        render={({ field }) => (
          <DateTimePicker
            {...field}
            format="yyyy-MM-dd HH:mm"
            value={field.value ? new Date(field.value) : null}
            label={t("delegations.addDelegationModal.standDate")}
            slotProps={{ textField: { fullWidth: true, required: true } }}
          />
        )}
      />
      <Controller
        name="start_date"
        control={control}
        render={({ field }) => (
          <DateTimePicker
            {...field}
            format="yyyy-MM-dd HH:mm"
            value={field.value ? new Date(field.value) : null}
            label={t("delegations.addDelegationModal.dateFrom")}
            slotProps={{ textField: { fullWidth: true, required: true } }}
          />
        )}
      />
      <Controller
        name="end_date"
        control={control}
        render={({ field }) => (
          <DateTimePicker
            {...field}
            format="yyyy-MM-dd HH:mm"
            value={field.value ? new Date(field.value) : null}
            label={t("delegations.addDelegationModal.dateTo")}
            slotProps={{ textField: { fullWidth: true } }}
          />
        )}
      />
      <Button type="submit" variant="contained" disabled={loading}>
        {t("common.save")}
      </Button>
    </form>
  );
};

export default DelegationForm;
