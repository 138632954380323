import { useEffect, useState } from "react";
import useAxios from "../../../hooks/useAxios";
import { format } from "date-fns-tz";
import TruckIncomesGraph from "./TruckIncomesGraph";
import { Skeleton } from "@mui/material";
import TruckMilageGraph from "./TruckMilageGraph";

interface TruckInsightsProps {
  startDate: Date;
  endDate: Date;
}

interface TruckReport {
  "monthly_truck_costs": any;
  "monthly_fuel_costs": any;
  "monthly_milage": any;
  "monthly_consumption": any;
  "monthly_maintenance_costs": any;
  "monthly_incomes": any;
}

const TruckInsights: React.FC<TruckInsightsProps> = ({
  startDate,
  endDate,
}) => {
  const axios = useAxios();
  const [truckReport, setTruckReport] = useState<TruckReport | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get("/insights/reports/trucks", {
        params: {
          start_date: format(startDate, "yyyy-MM-dd"),
          end_date: format(endDate, "yyyy-MM-dd"),
        },
      })
      .then((response) => {
        setTruckReport(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [startDate, endDate]);

  if (loading || !truckReport) {
    return (
      <div className="grid grid-cols-1">
        <Skeleton variant="rectangular" width="100%" height={400} />
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 graph-container">
      <TruckIncomesGraph monthlyIncomes={truckReport.monthly_incomes} />
      <TruckMilageGraph monthlyMilages={truckReport.monthly_milage} />
    </div>
  );
};

export default TruckInsights;
