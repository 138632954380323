import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import TopNavBar from "../components/navbar/Navbar";
import { CircularProgress } from "@mui/material";

interface AuthRouteProps {
  children: React.ReactNode;
}

export const AuthRoute: React.FC<AuthRouteProps> = ({ children }) => {
  const { accountLoading, token } = useAuth();

  const currentRoute = window.location.pathname.split("/")[1];

  if (accountLoading) {
    return <CircularProgress />;
  }
  if (!token) {
    return <Navigate to="/sign-in" />;
  }
  
  return (
    <div className="flex bg-secondary">
      <div className="w-full h-full">
        {(currentRoute !== "map") && <TopNavBar />}
        {children}
      </div>
    </div>
  );
};
