import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useAuth } from "../../contexts/AuthContext";
import PasswordReset from "./PasswordReset";
import RegisterComponent from "./RegisterComponent";
import { Button, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

interface LoginFormData {
  email: string;
  password: string;
}

const SignInContainer = () => {
  const { t } = useTranslation();
  const { login, registerUser, resetPassword } = useAuth();
  const [isRegistering, setIsRegistering] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const { handleSubmit, control } = useForm<LoginFormData>({
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const [loading, setLoading] = useState(false);

  const onSubmit = handleSubmit(async(data) => {
    try {
      setLoading(true);
      await login(data.email, data.password);
    } finally {
      setLoading(false);
    }
  });

  const onRegister = async (data: any) => {
    try {
      setLoading(true);
      await registerUser(data);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center w-full min-h-screen space-y-8 md:p-0 p-8 text-center bg-gradient-prime">
      {!isRegistering && !isResettingPassword && (
        <form
          onSubmit={onSubmit}
          className="flex flex-col items-center justify-center px-16 py-8 shadow-2xl rounded-xl dark:bg-zinc-800 bg-secondary w-full max-w-xl"
        >
          <h1 className="font-semibold text-4xl mb-16 text-center dark:text-secondary text-black">
            {t("signIn.signIn")}
          </h1>
          <div className="grid gap-4 w-full my-4">
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  name="email"
                  label={t("signIn.email")}
                  variant="outlined"
                  type="email"
                  required
                  autoComplete="email"
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  name="password"
                  label={t("signIn.password")}
                  variant="outlined"
                  type="password"
                  required
                  autoComplete="current-password"
                />
              )}
            />
          </div>
          <div className="w-full h-10 rounded-lg mx-auto mb-4 items-center justify-center flex px-8 py-4">
            <Button variant="contained" type="submit" className="w-full py-2">
              {t("signIn.signIn")}
            </Button>
          </div>
          <div className="w-full rounded-lg mx-auto mb-4 items-stretch justify-center flex px-8 py-4 gap-2">
            <Button
              variant="outlined"
              className="w-full py-2"
              onClick={() => setIsRegistering(true)}
              disabled={loading}
            >
              {t("signIn.noAccount")}
            </Button>
            <Button
              variant="outlined"
              className="w-full py-2"
              onClick={() => setIsResettingPassword(true)}
              disabled={loading}
            >
              {t("signIn.forgotPassword")}
            </Button>
          </div>
        </form>
      )}

      {isRegistering && (
        <RegisterComponent
          onRegisterUser={onRegister}
          onIsRegistering={() => setIsRegistering(false)}
          loading={loading}
        />
      )}

      {isResettingPassword && (
        <PasswordReset
          onResetPassword={resetPassword}
          onIsResettingPassword={() => setIsResettingPassword(false)}
        />
      )}
    </div>
  );
};

export default SignInContainer;
