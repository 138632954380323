import React, { ChangeEvent } from "react";
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useTranslation } from "react-i18next";

interface InvoicedFilterProps {
  selectedInvoicedStatus: {
    invoiced: boolean;
    notInvoiced: boolean;
    all: boolean;
  };
  onChange: (newStatus: {
    invoiced: boolean;
    notInvoiced: boolean;
    all: boolean;
  }) => void;
}

const InvoicedFilter: React.FC<InvoicedFilterProps> = ({
  selectedInvoicedStatus,
  onChange,
}) => {
  const {t} = useTranslation();
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;

    const newStatus = {
      invoiced: false,
      notInvoiced: false,
      all: false,
      [name]: event.target.checked,
    };

    onChange(
      newStatus as { invoiced: boolean; notInvoiced: boolean; all: boolean }
    );
  };

  return (
    <FormControl component="fieldset">
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedInvoicedStatus.invoiced}
              onChange={handleChange}
              name="invoiced"
              color="primary"
            />
          }
          label={t("orders.filters.invoiced")}
          sx={{ color: "text.primary" }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedInvoicedStatus.notInvoiced}
              onChange={handleChange}
              name="notInvoiced"
              color="primary"
            />
          }
          label={t("orders.filters.notInvoiced")}
          sx={{ color: "text.primary" }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedInvoicedStatus.all}
              onChange={handleChange}
              name="all"
              color="primary"
            />
          }
          label={t("orders.filters.all")}
          sx={{ color: "text.primary" }}
        />
      </FormGroup>
    </FormControl>
  );
};

export default InvoicedFilter;
