import { useEffect, useState } from "react";
import useAxios from "../../../hooks/useAxios";
import { WidgetSkeleton } from "../../../components/skeletons/WidgetSkeleton";
import { useTranslation } from "react-i18next";

interface Usage {
  total_file_size: number;
  total_file_count: number;
  orders_this_month: number;
  route_calculations_this_month: number;
  ai_order_generation_this_month: number;
}

const CompanyUsage = () => {
  const [usage, setUsage] = useState<Usage | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const axios = useAxios();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchUsage = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          "companies/my-company/usage-statistics"
        );
        setUsage(response.data);
      } catch (error) {
        console.error("Error fetching usage:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsage();
  }, []);

  return (
    <div>
      {loading ? (
        <WidgetSkeleton />
      ) : (
        <div className="flex text-xs">
          <div className="w-1/3">
            <div className="text-center">
              <div className="text-gray-500">
                {t("dashboard.usage.totalFileSize")}
              </div>
              <div className="text-2xl font-bold">
                {usage?.total_file_size} MB
              </div>
            </div>
          </div>
          <div className="w-1/3">
            <div className="text-center">
              <div className="text-gray-500">
                {t("dashboard.usage.totalFileCount")}
              </div>
              <div className="text-2xl font-bold">
                {usage?.total_file_count}
              </div>
            </div>
          </div>
          <div className="w-1/3">
            <div className="text-center">
              <div className="text-gray-500">
                {t("dashboard.usage.ordersThisMonth")}
              </div>
              <div className="text-2xl font-bold">
                {usage?.orders_this_month}
              </div>
            </div>
          </div>
          <div className="w-1/3">
            <div className="text-center">
              <div className="text-gray-500">
                {t("dashboard.usage.routeCalculationsThisMonth")}
              </div>
              <div className="text-2xl font-bold">
                {usage?.route_calculations_this_month}
              </div>
            </div>
          </div>
          <div className="w-1/3">
            <div className="text-center">
              <div className="text-gray-500">
                {t("dashboard.usage.aiOrderGenerationThisMonth")}
              </div>
              <div className="text-2xl font-bold">
                {usage?.ai_order_generation_this_month}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompanyUsage;
