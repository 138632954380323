import React from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Carrier } from "../../../types/carriers";

interface CarrierDetailsProps {
  carrier: Carrier | null;
}

const CarrierDetails: React.FC<CarrierDetailsProps> = ({ carrier }) => {
  const { t } = useTranslation();

  if (!carrier) return null;

  return (
    <div className="p-4 border border-neutral-300 shadow-lg">
      <p className="text-3xl font-bold">
        {t("trackingOrderDetails.carrierDetails")}
      </p>
      <div className="w-full border-b border-neutral-400 my-4 opacity-70"/>
      <Typography>
        {t("trackingOrderDetails.carrierName")}: {carrier.name}
      </Typography>
      <Typography>
        {t("trackingOrderDetails.carrierVatId")}: {carrier.vat_id}
      </Typography>
      <Typography>
        {t("trackingOrderDetails.carrierEmail")}: {carrier.email}
      </Typography>
      <Typography>
        {t("trackingOrderDetails.carrierPhone")}: {carrier.phone}
      </Typography>
    </div>
  );
};

export default CarrierDetails;
