export const extractEndpoint = (url: string) => {
  if (!url) return "";
  const urlObject = new URL(url);
  let endpoint = `${urlObject.pathname}${urlObject.search}`;
  const prefix = "/api/v1";
  if (endpoint.startsWith(prefix)) {
    endpoint = endpoint.substring(prefix.length);
  }
  return endpoint;
};

export const getPageNumBasedOnUrl = (url: string) => {
  const match = RegExp(/[?&]page=(\d+)/).exec(url);
  const pageNum = match ? parseInt(match[1], 10) : 1;
  return pageNum;
};
