import React from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { GRAPH_COLORS } from "../../../utils/colors";

interface MonthlyIncome {
  month: number;
  year: number;
  income_per_km: number;
}

interface MonthlyIncomesGraphProps {
  monthlyIncomes: MonthlyIncome[];
}

const MonthlyPerKmIncomes: React.FC<MonthlyIncomesGraphProps> = ({
  monthlyIncomes,
}) => {
  // Format the data to include a readable month-year format
  const formattedData = monthlyIncomes.map((income) => ({
    monthYear: `${income.month}/${income.year}`,
    income_per_km: income.income_per_km,
  }));

  return (
    <div>
      <h1 className="text-center">Monthly Incomes Per KM</h1>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          width={600}
          height={300}
          data={formattedData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="monthYear" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="income_per_km"
            stroke={GRAPH_COLORS[0]}
            name="Income per Km"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MonthlyPerKmIncomes;
