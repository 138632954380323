import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { GRAPH_COLORS } from "../../../utils/colors";
import { useTranslation } from "react-i18next";

interface TruckProfitsProps {
  truck_profits: { [trucks: string]: number };
}

const TruckProfits: React.FC<TruckProfitsProps> = ({ truck_profits }) => {
  const { t } = useTranslation();

  const data = Object.keys(truck_profits).map((key, index) => {
    return {
      truck: key,
      income: truck_profits[key],
      color: GRAPH_COLORS[index % GRAPH_COLORS.length],
    };
  });
  data.sort((a, b) => b.income - a.income);

  return (
    <div>
      <h1 className="text-center">
        {t("insights.profit.truck_profits.title")}
      </h1>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          width={600}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="truck" interval={0} />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="income">
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TruckProfits;
