// useToast.tsx
import { toast } from 'react-toastify';
type ToastOptions = {
  type: 'success' | 'error',
  message: string,
  actionMessage?: string,
  actionUrl?: string,
};

export function useToast() {
  return (options: ToastOptions) => {
    const { type, message} = options;

    const toastContent = (
      <div className="flex flex-col items-center justify-center padding-8 gap-2">
        <div className="text-center">{message}</div>
       
      </div>
    );

    if (type === 'success') {
      toast.success(toastContent, {
        position: "bottom-center",
        autoClose: 10000,
      });
    } else if (type === 'error') {
      toast.error(toastContent, {
        position: "bottom-center",
        autoClose: 10000,
      });
    }
  };
}