import React, { useEffect, useState } from "react";
import Modal from "../modal/Modal";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  TextField,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import DragDropInput from "../input/DragDropInput";
import { useToast } from "../../hooks/useToast";
import { useTranslation } from "react-i18next";
import { Document, DocumentFormData, TruckDocument } from "../../types/documents";
import useAxios from "../../hooks/useAxios";
import TruckDocumentEdit from "../Truck/TruckDocumentEdit";

interface DocumentsProps {
  link: string;
  documentTypes: string[];
}

const Documents: React.FC<DocumentsProps> = ({ documentTypes, link }) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [isModalOpen, setModalOpen] = useState(false);
  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      file: "",
      type: "",
      end_date: null,
      description: "",
    },
  });
  const [documents, setDocuments] = useState<Document[]>([]);

  const [fileUploading, setFileUploading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [selectedDocument, setSelectedDocument] = useState<Document | null>(
    null
  );
  const [isDocumentEditModalOpen, setDocumentEditModalOpen] = useState(false);
  const axios = useAxios();

  useEffect(() => {
    axios
      .get(link)
      .then((response) => {
        setDocuments(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const onAddDocument = async (document: DocumentFormData) => {
    const response = await axios.post(link, document);
    setDocuments((prevDocuments) => [...prevDocuments, response.data]);
    toast({
      message: "Document added",
      type: "success",
    });
  };

  const onDeleteDocument = async (fileId: number) => {
    try {
      const response = await axios.delete(`/documents/my-documents/${fileId}/`);
      if (response.status === 204) {
        setDocuments((prevDocuments) =>
          prevDocuments.filter((document) => document.id !== fileId)
        );
        toast({
          message: "Document deleted",
          type: "success",
        });
      } else {
        console.error("Upload failed:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const onUpdateDocument = async (documentId: number, data: any) => {
    const response = await axios.patch(
      `${link}${documentId}/`,
      data
    );
    setDocuments((prevDocuments) =>
      prevDocuments.map((document) =>
        document.id === documentId ? response.data : document
      )
    );
    toast({
      message: "Document updated",
      type: "success",
    });
  };

  const onSubmit = async (data: DocumentFormData) => {
    if (!file) {
      toast({
        message: "Please upload a file",
        type: "error",
      });
      return;
    }

    const reader = new FileReader();
    reader.onload = async () => {
      setFileUploading(true);
      const fileContent = reader.result;
      const newDocument = {
        ...data,
        file: {
          name: file.name,
          base64: fileContent,
        },
      };
      try {
        await onAddDocument(newDocument);
        setModalOpen(false);
        reset();
      } catch (error) {
        console.error(error);
        toast({
          message: "Failed to add document",
          type: "error",
        });
      } finally {
        setFileUploading(false);
      }
    };

    reader.readAsDataURL(file);
  };

  const handleFileChange = (files: FileList) => {
    if (files.length > 0) {
      setFile(files[0]);
      setValue("file", files[0].name);
    }
  };

  return (
    <div className="bg-neutral-300 dark:bg-zinc-800 p-4 border border-gray-400 text-black dark:text-white">
      <h2 className="text-2xl font-semibold mb-4">
        {t("documentsList.title")}
      </h2>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setModalOpen(true)}
      >
        {t("documentsList.addDocument")}
      </Button>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 border-t border-gray-400 mt-4 pt-4">
        {documents.map((document) => (
          <div
            key={document.id}
            className="flex flex-col items-center bg-slate-300 p-4 rounded-lg shadow hover:shadow-lg transition-shadow duration-200"
          >
            <div className="flex flex-col items-center w-full">
              <span className="text-sm text-gray-500 mb-1">
                {t("documentsList.fields.type")}
              </span>
              <span className="text-lg font-medium text-gray-800 capitalize">
                {t(`documentTypes.${document.type}`)}
              </span>
            </div>
            {document.end_date && (
              <div className="flex flex-col items-center w-full mt-2">
                <span className="text-sm text-gray-500 mb-1">
                  {t("documentsList.fields.validTo")}
                </span>
                <span className="text-md text-gray-700">
                  {new Date(document.end_date).toLocaleDateString()}
                </span>
              </div>
            )}
            <div className="flex items-center w-full mt-2 justify-center gap-4">
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  onDeleteDocument(document.id);
                }}
              >
                {t("common.delete")}
              </Button>
              <a
                href={document.file}
                target="_blank"
                rel="noopener noreferrer"
                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200"
              >
                {t("documentsList.fields.view")}{" "}
              </a>
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={() => {
                  setSelectedDocument(document);
                  setDocumentEditModalOpen(true);
                }}
              >
                {t("common.edit")}
              </Button>
            </div>
          </div>
        ))}
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            p: 4,
            backgroundColor: "white",
            borderRadius: 1,
            boxShadow: 24,
          }}
        >
          <h2>{t("documentsList.addDocumentModal.title")}</h2>
          <DragDropInput
            uploading={fileUploading}
            name={t("documentsList.addDocumentModal.dragDropTitle")}
            label={t("documentsList.addDocumentModal.cta")}
            types={[
              "PDF",
              "DOC",
              "DOCX",
              "ODT",
              "TXT",
              "RTF",
              "PNG",
              "JPG",
              "JPEG",
            ]}
            multiple={true}
            handleChange={handleFileChange}
          />
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("documentsList.addDocumentModal.documentType")}
                variant="outlined"
                fullWidth
                select
                required
              >
                {documentTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {t(`documentTypes.${type}`)}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("documentsList.addDocumentModal.description")}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                fullWidth
                multiline
                rows={4}
              />
            )}
          />
          <Controller
            name="end_date"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("documentsList.addDocumentModal.endDate")}
                type="date"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                fullWidth
              />
            )}
          />
          <Button type="submit" variant="contained" color="primary">
            {t("common.save")}
          </Button>
        </Box>
      </Modal>
      <Dialog
        open={isDocumentEditModalOpen}
        onClose={() => setDocumentEditModalOpen(false)}
      >
        <DialogTitle>{t("documentsList.editDocumentModal.title")}</DialogTitle>
        <DialogContent style={{ width: "600px" }}>
          {selectedDocument && (
            <TruckDocumentEdit
              document={selectedDocument as TruckDocument}
              onUpdateDocument={async (data: any) => {
                await onUpdateDocument(selectedDocument.id, data);
                setDocumentEditModalOpen(false);
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDocumentEditModalOpen(false)}
            color="primary"
          >
            {t("common.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Documents;
