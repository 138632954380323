import { useTranslation } from "react-i18next";
import { OfferModel } from "../../../types/offers";

interface OfferDetailsProps {
  offer: OfferModel;
}

const OfferDetails: React.FC<OfferDetailsProps> = ({ offer }) => {
  const { t } = useTranslation();
  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold text-center">
        {t(`offerTypes.${offer.type}`)}
      </h2>
      <p className="text-lg mt-4">{offer.description}</p>
      <div className="flex flex-col w-1/2">
        <h3 className="text-lg font-bold">
          {t("exchange.offerDetails.details")}
        </h3>
        <p className="text-sm">
          {t("exchange.offerDetails.price")}: {offer.price_value}{" "}
          {offer.price_currency}
        </p>
        <p className="text-sm">
          {t("exchange.offerDetails.capacity")}: {offer.capacity} t
        </p>
        <p className="text-sm">
          {t("exchange.offerDetails.ldm")}: {offer.ldm} ldm
        </p>
        <p className="text-sm">
          {t("exchange.offerDetails.truckTypes")}:{" "}
          {offer.truck_types.join(", ") || "-"}
        </p>
        <p className="text-sm">
          {t("exchange.offerDetails.truckBodyTypes")}: {offer.truck_body_types}
        </p>
        <p className="text-sm">
          {t("exchange.offerDetails.loadingMethods")}:{" "}
          {offer.loading_methods?.join(", ") || "-"}
        </p>
        <p className="text-sm">
          {t("exchange.offerDetails.validUntil")}:{" "}
          {new Date(offer.valid_until).toLocaleDateString()}
        </p>
      </div>
    </div>
  );
};

export default OfferDetails;
