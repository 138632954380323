import { useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { OfferModel } from "../../../../types/offers";
import { formatOrderOperationTime } from "../../../../utils/time";
import OfferOperationDetail from "./OfferOperationDetail";
import { useTranslation } from "react-i18next";

interface OfferOperationsProps {
  offer: OfferModel;
}

const OfferOperations: React.FC<OfferOperationsProps> = ({ offer }) => {
  const [selectedOperationIndex, setSelectedOperationIndex] = useState(0);
  const [selectedOperation, setSelectedOperation] = useState(
    offer.operations[0]
  );
  const handleSelectOperation = (index: number) => {
    setSelectedOperationIndex(index);
    setSelectedOperation(offer.operations[index]);
  };

  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-2 justify-center m-2">
      <div className="flex flex-col gap-2 items-center dark:bg-zinc-900 bg-secondary shadow-lg rounded-xl p-6 border border-gray-300 w-full">
        {offer.operations.map((operation, index) => (
          <div
            key={index}
            className={`flex items-center justify-between space-x-4 p-3 w-full cursor-pointer rounded-lg transition-colors duration-200  hover:text-black  ${
              index === selectedOperationIndex
                ? "bg-neutral-400"
                : "hover:bg-gray-100"
            }`}
            onClick={() => handleSelectOperation(index)}
          >
            <div className="text-xl font-semibold">{index + 1}</div>
            <ReactCountryFlag
              countryCode={operation.country}
              svg
              style={{ width: "2.2em", height: "2.2em" }}
              className="rounded-full shadow-sm"
            />
            <p className="text-xs font-medium">
              {t(`operationTypes.${operation.operation_type}`)}
            </p>
            <p className="text-base flex-grow font-medium">
              {operation.locality}
            </p>
            <p className="text-xs flex-grow font-medium">
              {formatOrderOperationTime(operation)}
            </p>
          </div>
        ))}
      </div>
      <div className="flex-grow dark:bg-zinc-900 bg-secondary shadow-md rounded-lg p-2 border border-gray-300 ml-2">
        <OfferOperationDetail operation={selectedOperation} />
      </div>
    </div>
  );
};

export default OfferOperations;
