import { MenuItem, TextField, ListSubheader, InputAdornment, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TruckLocation } from "../../types/truck";
import { FaTruck } from "react-icons/fa6";
import { FaTimes } from "react-icons/fa";

interface MapTruckSelectProps {
  trucks: TruckLocation[];
  truckColors: Record<string, string> | null
  selectedTruck: TruckLocation | null;
  label: string;
  value: string | null;
  onChange: (truck: string | TruckLocation, isPredefined: boolean) => void;
  onUnselectTruck: () => void;
  size?: "small" | "medium";
}

const PREDEFINED_TRUCKS = [
  {
    id: "predefined-bus",
  },
  {
    id: "predefined-solo",
  },
  {
    id: "predefined-semi",
  },
  {
    id: "predefined-car",
  },
];

const MapTruckSelect: React.FC<MapTruckSelectProps> = ({
  trucks,
  truckColors,
  selectedTruck,
  label,
  value,
  onChange,
  onUnselectTruck,
  size = "small",
}) => {
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedValue = event.target.value as string;
    const isPredefined = selectedValue.startsWith("predefined-");
    const id = selectedValue.split("-")[1];
    onChange(id, isPredefined);
  };
  const { t } = useTranslation();

  return (
    <TextField
      select
      label={label}
      value={value}
      onChange={handleChange}
      variant="outlined"
      fullWidth
      size={size}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton
              edge="start"
              style={{
                color: (selectedTruck && truckColors)
                  ? truckColors[selectedTruck.truck]
                  : "#fff",
              }}
            >
              <FaTruck />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end" sx={{ marginRight: "12px" }}>
            {selectedTruck && (
              <IconButton
                onClick={onUnselectTruck}
                style={{ color: "#fff" }}
              >
                <FaTimes />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    >
      {PREDEFINED_TRUCKS.map((truck) => (
        <MenuItem key={truck.id} value={truck.id}>
          {t(`mapTruckSelect.${truck.id}`)}
        </MenuItem>
      ))}
      <ListSubheader>{t("mapTruckSelect.yourTrucks")}</ListSubheader>
      {trucks.map((t) => (
        <MenuItem key={`${t.truck}`} value={`${t.truck}`}>
          {t.truck_name} - {t.truck}
        </MenuItem>
      ))}
    </TextField>
    
  );
};

export default MapTruckSelect;
