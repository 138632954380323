import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";
import CountryFlag from "react-country-flag";
import { GRAPH_COLORS } from "../../../utils/colors";
import { useTranslation } from "react-i18next";

interface CountryIncomesProps {
  country_incomes: { [countries: string]: number };
}

const CountryIncomes: React.FC<CountryIncomesProps> = ({ country_incomes }) => {
  const { t } = useTranslation();
  const data = Object.keys(country_incomes).map((key, index) => {
    const [from, to] = key.split("-");
    return {
      countries: `${from.toUpperCase()} to ${to.toUpperCase()}`,
      from,
      to,
      income: country_incomes[key],
      color: GRAPH_COLORS[index % GRAPH_COLORS.length],
    };
  });
  data.sort((a, b) => b.income - a.income);

  const displayedData = data.slice(0, 20);

  const renderCustomAxisTick = ({
    x,
    y,
    payload,
  }: {
    x: number;
    y: number;
    payload: any;
  }) => {
    const [from, to] = payload.value.split(" to ");
    return (
      <foreignObject
        x={x - 50}
        y={y}
        width={100}
        height={50}
        style={{ overflow: "visible" }}
      >
        <div className="flex flex-col items-center justify-center">
          <div className="flex">
            <CountryFlag
              countryCode={from}
              svg
              style={{
                width: "1em",
                height: "1em",
                marginRight: "5px",
              }}
              title={from}
            />
            <CountryFlag
              countryCode={to}
              svg
              style={{
                width: "1em",
                height: "1em",
                marginLeft: "5px",
              }}
              title={to}
            />
          </div>
          <span className="text-xs">{`${from} to ${to}`}</span>
        </div>
      </foreignObject>
    );
  };

  return (
    <div>
      <h2 className="text-center">
        {t("insights.incomes.internationalIncomes.title")}
      </h2>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          width={600}
          height={300}
          data={displayedData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="countries" tick={renderCustomAxisTick} interval={0} />
          <YAxis />
          <Tooltip />
          <Legend
            wrapperStyle={{
              paddingTop: "10px",
            }}
          />
          <Bar dataKey="income">
            {displayedData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CountryIncomes;
