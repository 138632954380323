import {
  BaseTruck,
  FUEL_TYPES,
  GVM_TYPES,
  TRUCK_SUBTYPES,
  TRUCK_TYPES,
} from "../../../types/truck";
import { Controller, useForm } from "react-hook-form";
import { Button, MenuItem, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import FreeFlowTable from "./FreeFlowSpeedTable";

interface BasicFormProps {
  truck: BaseTruck;
  onSubmit: (data: TruckFormData) => void;
}

interface TruckFormData {
  vin: string;
  brand: string;
  model: string;
  parameters: {
    fuel_type: string;
    free_flow_speed_table: string;
    truck_type: string;
    truck_sub_type: string;
  };
  gvm: string;
}

const BasicForm: React.FC<BasicFormProps> = ({ truck, onSubmit }) => {
  const { t } = useTranslation();
  const { control, setValue, handleSubmit } = useForm<TruckFormData>({
    defaultValues: {
      vin: truck.vin,
      brand: truck.brand,
      model: truck.model,
      parameters: {
        fuel_type: truck.parameters?.fuel_type,
        truck_type: truck.parameters?.truck_type,
        truck_sub_type: truck.parameters?.truck_sub_type,
        free_flow_speed_table:
          truck.parameters?.free_flow_speed_table || "80,0.13,90,0.15",
      },
      gvm: truck?.gvm,
    },
  });

  const onFormSubmit = handleSubmit((data) => {
    onSubmit(data);
  });

  return (
    <form onSubmit={onFormSubmit} className="bg-neutral-300 dark:bg-zinc-800 p-4 border border-gray-400 text-black dark:text-white">
      <div className="grid grid-cols-2 gap-4">
        <Controller
          name="vin"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("truckDetail.general.vin")}
              variant="outlined"
              fullWidth
            />
          )}
        />
        <Controller
          name="brand"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("truckDetail.general.brand")}
              variant="outlined"
              fullWidth
            />
          )}
        />
        <Controller
          name="model"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("truckDetail.general.model")}
              variant="outlined"
              fullWidth
            />
          )}
        />
        <Controller
          name="parameters.truck_type"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              select
              label={t("truckDetail.general.type")}
              fullWidth
              variant="outlined"
            >
              {TRUCK_TYPES.map((truckType) => (
                <MenuItem key={truckType} value={truckType}>
                  <div className="flex items-center gap-2">
                    {t(`truckTypes.${truckType}`)}
                  </div>
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <Controller
          name="gvm"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              select
              label={t("truckDetail.general.gvm")}
              fullWidth
              variant="outlined"
            >
              {GVM_TYPES.map((gvmType) => (
                <MenuItem key={gvmType} value={gvmType}>
                  <div className="flex items-center gap-2">{gvmType}</div>
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <TextField
          label={t("truckDetail.general.licensePlate")}
          variant="outlined"
          fullWidth
          value={truck.license_plate}
          disabled
        />
        <Controller
          name="parameters.fuel_type"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              select
              label={t("truckDetail.general.fuel_type")}
              fullWidth
              variant="outlined"
            >
              {FUEL_TYPES.map((fuelType) => (
                <MenuItem key={fuelType} value={fuelType}>
                  <div className="flex items-center gap-2">
                    {t(`fuelTypes.${fuelType}`)}
                  </div>
                </MenuItem>
              ))}
            </TextField>
          )}
        />
         <Controller
          name="parameters.truck_sub_type"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              select
              label={t("truckDetail.general.sub_type")}
              fullWidth
              variant="outlined"
            >
              {TRUCK_SUBTYPES.map((fuelType) => (
                <MenuItem key={fuelType} value={fuelType}>
                  <div className="flex items-center gap-2">
                    {t(`truckSubtypes.${fuelType}`)}
                  </div>
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </div>
      <FreeFlowTable
        value={truck.parameters?.free_flow_speed_table || ""}
        onChange={(value) =>
          setValue("parameters.free_flow_speed_table", value)
        }
      />
      <Button variant="contained" type="submit" className="py-2 mt-2">
        {t("common.save")}
      </Button>
    </form>
  );
};

export default BasicForm;
