import { MutableRefObject } from "react";
import { Operation, OperationType } from "../../../types/orders";
import { API_KEY } from "./consts";
import { TruckLocation } from "../../../types/truck";
import { formatOrderOperationTime } from "../../../utils/time";

export const calculateAverageCoordinateForTrucks = (
  trucks: TruckLocation[]
) => {
  const averageLat =
    trucks.reduce((sum, truck) => sum + truck.latitude, 0) / trucks.length;
  const averageLng =
    trucks.reduce((sum, truck) => sum + truck.longitude, 0) / trucks.length;
  return { lat: averageLat, lng: averageLng };
};

export const getTruckIcon = (color: string) => {
  const truckIconSvg = `<svg fill="${color}" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
	 width="800px" height="800px" viewBox="0 0 612 612" xml:space="preserve">
<g>
	<path d="M541.322,500.219v-94.372c0-20.277-16.438-36.716-36.715-36.716h-9.598V24.598c0-3.082-1.547-5.958-4.117-7.657
		L467.587,1.537c-6.103-4.033-14.239,0.342-14.239,7.657v110.652l-6.945-18.734c-9.34-25.196-33.373-41.918-60.245-41.918H225.702
		c-27.03,0-51.169,16.916-60.394,42.323l-6.655,18.329V9.194c0-7.314-8.137-11.69-14.24-7.657L121.107,16.94
		c-2.571,1.699-4.118,4.575-4.118,7.657v344.534h-9.597c-20.277,0-36.715,16.438-36.715,36.716v94.372H55.035
		c-5.069,0-9.178,4.109-9.178,9.179v50.743c0,5.069,4.109,9.179,9.178,9.179h39.598v24.322c0,10.139,8.219,18.357,18.358,18.357
		h48.645c10.139,0,18.358-8.219,18.358-18.357V569.32h252.014v24.322c0,10.139,8.22,18.357,18.357,18.357h48.646
		c10.139,0,18.357-8.219,18.357-18.357V569.32h39.598c5.07,0,9.179-4.11,9.179-9.179v-50.742c0-5.07-4.109-9.179-9.179-9.179
		L541.322,500.219L541.322,500.219z M170.814,170.975h270.372v90.44H170.814V170.975z M164.527,474.533H133.17
		c-9.581,0-17.348-7.768-17.348-17.349v-0.438c0-9.581,7.767-17.348,17.348-17.348h31.356c9.581,0,17.348,7.767,17.348,17.348v0.438
		C181.875,466.765,174.108,474.533,164.527,474.533z M368.398,479.648H243.602c-10.139,0-18.358-8.22-18.358-18.357V344.976
		c0-10.138,8.219-18.357,18.358-18.357h124.796c10.138,0,18.357,8.22,18.357,18.357v116.314
		C386.756,471.428,378.536,479.648,368.398,479.648z M478.829,474.533h-31.356c-9.58,0-17.348-7.768-17.348-17.349v-0.438
		c0-9.581,7.768-17.348,17.348-17.348h31.356c9.581,0,17.349,7.767,17.349,17.348v0.438
		C496.178,466.765,488.41,474.533,478.829,474.533z M365.607,393.801H246.099c-5.019,0-9.087-4.068-9.087-9.088v-0.184
		c0-5.019,4.068-9.086,9.087-9.086h119.508c5.019,0,9.087,4.067,9.087,9.086v0.184C374.694,389.733,370.626,393.801,365.607,393.801
		z M365.607,357.085H246.099c-5.019,0-9.087-4.068-9.087-9.087v-0.184c0-5.018,4.068-9.086,9.087-9.086h119.508
		c5.019,0,9.087,4.068,9.087,9.086v0.184C374.694,353.017,370.626,357.085,365.607,357.085z M365.607,467.232H246.099
		c-5.019,0-9.087-4.068-9.087-9.087v-0.184c0-5.019,4.068-9.087,9.087-9.087h119.508c5.019,0,9.087,4.068,9.087,9.087v0.184
		C374.694,463.164,370.626,467.232,365.607,467.232z M365.607,430.516H246.099c-5.019,0-9.087-4.068-9.087-9.086v-0.184
		c0-5.019,4.068-9.087,9.087-9.087h119.508c5.019,0,9.087,4.068,9.087,9.087v0.184C374.694,426.448,370.626,430.516,365.607,430.516
		z"/>
</g>
</svg>`;

  return new H.map.Icon(truckIconSvg, {
    size: { w: 32, h: 32 },
    anchor: { x: 10, y: 10 },
    stickColor: "white",
    stickHeight: 50,
  });
};

export const getOrderOperationIcon = (
  operationType: OperationType,
  color: string,
  size: number
) => {
  let icon;
  if (operationType === "loading") {
    icon = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 256 256" enable-background="new 0 0 256 256" xml:space="preserve">
    <g><g><g>
    <path fill="${color}" d="M80.9,26.9c-0.8,0.3-2.1,1.2-2.8,1.9c-3,2.8-3,2.6-3,29.4v25l-15.4,0.2c-15,0.2-15.4,0.2-16.7,1.5c-1.2,1.2-1.2,1.5-0.4,3c0.8,1.6,40.9,41.3,41.7,41.3c0.2-0.1,9.8-9.2,21.2-20.5c13.7-13.5,20.8-20.9,20.8-21.6c-0.1-3.3-2.2-3.7-19-3.8H93.8l-0.2-25.2c-0.2-25-0.2-25.1-1.5-27.3C89.9,27.2,84.7,25.4,80.9,26.9z"/>
    <path fill="${color}" d="M151.3,77c-1.5,0.8-3.1,2.1-3.8,3c-1.2,1.6-1.2,2.3-1.4,38.8l-0.1,37.1H88.2H30.3v-18.5c0-11.6-0.2-19.3-0.6-20.8c-1.3-4.5-6.6-7.9-11.2-7c-3,0.6-5.8,2.7-7.2,5.6l-1.3,2.6v42.7v42.7l1.7,1.7l1.7,1.7h8.8h8.7l0.3-2.2c1.5-9.8,4-15,9.8-20.8c4.9-4.8,8.5-6.9,14.7-8.5c11.3-2.9,22.6,0.2,31,8.7c5.5,5.5,9.3,14,9.3,20.5v2.2h30.5h30.5V204c0-6.8,3.6-14.5,9.6-20.5c12.8-12.8,32.6-12.8,45.3,0c5.9,5.9,9.6,13.8,9.6,20.6v2.4h10.5h10.5l1.7-1.7l1.7-1.7v-9.4c0-5.6-0.2-9.9-0.6-10.7c-0.7-1.5-2.7-2.7-4.6-2.7c-1.2,0-1.2-0.6-1.2-30.1c0-26.9-0.1-30.3-1-32.5c-1.4-3.5-28.7-39.1-31-40.6c-1.8-1.1-3.1-1.2-27.8-1.3l-25.9-0.2L151.3,77z M194.3,94.3c1.2,0.3,2.9,1.4,3.9,2.4c1.8,1.9,18.2,24.1,18.2,24.6c0,0.2-11,0.3-24.4,0.3h-24.4v-14v-14h12.4C186.7,93.7,193.2,94,194.3,94.3z"/>
    <path fill="${color}" d="M57.7,184c-11.6,3.4-18.9,14.7-16.9,26c2.8,15.3,18.7,23.8,32.3,17.4c5-2.3,9.1-6.3,11.5-11c1.7-3.6,1.9-4.2,1.9-9.9c0-5.8-0.1-6.4-1.9-10c-2.4-4.9-7.5-9.8-12.1-11.5C68.2,183.4,61.4,182.9,57.7,184z M67.6,199.9c1.7,0.8,4,4.7,4,6.6c0,1.7-2.4,5.6-4,6.6c-4.1,2.4-9.5,0.4-11.1-4.1c-0.8-2.2-0.8-2.7,0-4.9C58.1,199.6,63.3,197.6,67.6,199.9z"/>
    <path fill="${color}" d="M182.5,184.3c-6.3,2.1-11.1,6.2-14,12.3c-1.7,3.5-1.8,4.2-1.8,9.9c0,5.4,0.2,6.5,1.6,9.5c3.3,6.9,10.1,12.1,17.7,13.4c9.7,1.7,20.2-4,24.5-13.4c1.4-3,1.6-4.1,1.6-9.5c0-5.5-0.2-6.5-1.7-9.8c-2.2-4.8-7.7-9.9-12.4-11.7C193.2,183.1,186.8,182.9,182.5,184.3z M193.5,200c1.9,1.2,3.7,4.3,3.7,6.5c0,0.7-0.5,2.2-1,3.3c-2.7,5.6-11,5.6-13.7,0c-1.2-2.6-1.2-4.1,0-6.6c1.2-2.6,3.9-4.2,6.9-4.2C190.6,198.9,192.5,199.4,193.5,200z"/>
    </g></g></g>
    </svg>`;
  } else if (operationType === "unloading") {
    icon = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 256 256" enable-background="new 0 0 256 256" xml:space="preserve">
    <g><g><g>
    <path fill="${color}" d="M63.4,46.8C52,58,42.4,67.8,42.3,68.5c-0.2,0.9,0.1,1.7,0.8,2.4c1.2,1.2,1.9,1.2,16.6,1.2h15.4v25.4c0,24.4,0.1,25.5,1.2,27.4c1.7,2.7,4.4,4.2,7.9,4.2c3.8,0,6.5-1.7,8.3-5.2c1.3-2.6,1.3-2.8,1.3-27.1V72.2h15c16.1,0,17.6-0.2,17.6-3c0-1.4-7.4-9.1-28.5-29.5L84.1,26.4L63.4,46.8z"/>
    <path fill="${color}" d="M151.3,76.9c-1.5,0.8-3.1,2.1-3.8,3c-1.2,1.6-1.2,2.3-1.4,38.8l-0.1,37.1H88.2H30.3v-18.5c0-11.6-0.2-19.3-0.6-20.8c-1.3-4.5-6.6-7.9-11.2-7c-3,0.6-5.8,2.7-7.2,5.6l-1.3,2.6v42.7v42.7l1.7,1.7l1.7,1.7h8.8h8.7l0.3-2.2c1.5-9.8,4-15,9.8-20.8c4.9-4.8,8.5-6.9,14.7-8.5c11.3-2.9,22.6,0.2,31,8.7c5.5,5.5,9.3,14,9.3,20.5v2.2h30.5h30.5v-2.5c0-6.8,3.6-14.5,9.6-20.5c12.8-12.8,32.6-12.8,45.3,0c5.9,5.9,9.6,13.8,9.6,20.6v2.4h10.5h10.5l1.7-1.7l1.7-1.7v-9.4c0-5.6-0.2-9.9-0.6-10.7c-0.7-1.5-2.7-2.7-4.6-2.7c-1.2,0-1.2-0.6-1.2-30.1c0-26.9-0.1-30.3-1-32.5c-1.4-3.5-28.7-39.1-31-40.6c-1.8-1.1-3.1-1.2-27.8-1.3l-25.9-0.2L151.3,76.9z M194.3,94.3c1.2,0.3,2.9,1.4,3.9,2.4c1.8,1.9,18.2,24.1,18.2,24.6c0,0.2-11,0.3-24.4,0.3h-24.4v-14v-14h12.4C186.7,93.7,193.2,94,194.3,94.3z"/>
    <path fill="${color}" d="M57.7,184c-11.6,3.4-18.9,14.7-16.9,26c2.8,15.3,18.7,23.8,32.3,17.4c5-2.3,9.1-6.3,11.5-11c1.7-3.6,1.9-4.2,1.9-9.9c0-5.8-0.1-6.4-1.9-10c-2.4-4.9-7.5-9.8-12.1-11.5C68.2,183.4,61.4,182.9,57.7,184z M67.6,199.8c1.7,0.8,4,4.6,4,6.6c0,1.7-2.4,5.6-4,6.6c-4.1,2.4-9.5,0.4-11.1-4.1c-0.8-2.2-0.8-2.7,0-4.9C58.1,199.5,63.3,197.5,67.6,199.8z"/>
    <path fill="${color}" d="M182.5,184.3c-6.3,2.1-11.1,6.2-14,12.3c-1.7,3.5-1.8,4.2-1.8,9.9c0,5.4,0.2,6.5,1.6,9.5c3.3,6.9,10.1,12.1,17.7,13.4c9.7,1.7,20.2-4,24.5-13.4c1.4-3,1.6-4.1,1.6-9.5c0-5.5-0.2-6.5-1.7-9.8c-2.2-4.8-7.7-9.9-12.4-11.7C193.2,183.1,186.8,182.8,182.5,184.3z M193.5,200c1.9,1.2,3.7,4.3,3.7,6.5c0,0.7-0.5,2.2-1,3.3c-2.7,5.6-11,5.6-13.7,0c-1.2-2.6-1.2-4.1,0-6.6c1.2-2.6,3.9-4.2,6.9-4.2C190.6,198.9,192.5,199.4,193.5,200z"/></g></g></g>
    </svg>`;
  } else {
    icon = `<svg width="20" height="20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="marker">
    <circle cx="10" cy="10" r="7" fill="#707070" stroke="#707070" stroke-width="4" />
    </g></svg>`;
  }
  return new H.map.Icon(icon, {
    anchor: {
      x: 10,
      y: 10,
    },
    size: {
      h: size,
      w: size,
    },
    stickHeight: 50,
    stickColor: "white",
  });
};

export const createTruckMarker = (
  truck: TruckLocation,
  map: React.MutableRefObject<H.Map | null>,
  color: string
) => {
  const truckMarker = new H.map.Marker(
    {
      lat: truck.latitude,
      lng: truck.longitude,
    },
    {
      data: truck,
      icon: getTruckIcon(color),
    }
  );

  const tooltip = document.createElement("div");
  tooltip.className =
    "hidden absolute bg-white text-black p-2 text-xs border-0 shadow-lg z-50";
  tooltip.innerText = truck.truck;
  document.body.appendChild(tooltip);

  truckMarker.addEventListener("tap", () => {
    const center = {
      lat: truck.latitude,
      lng: truck.longitude,
    };
    map.current?.setCenter(center);
    map.current?.setZoom(10);
  });

  truckMarker.addEventListener("pointerenter", (e: any) => {
    truckMarker.setIcon(
      new H.map.Icon(getTruckIcon(color).getBitmap()!, {
        size: { w: 30, h: 30 },
        anchor: { x: 15, y: 15 },
      })
    );
    if (map.current) {
      map.current.getViewPort().element.style.cursor = "pointer";
    }

    tooltip.className =
      "absolute bg-white text-black p-2 text-xs border-0 shadow-lg z-50";
    const mapContainerPosition = map.current
      ?.getViewPort()
      .element.getBoundingClientRect();
    const pointerPosition = map.current?.screenToGeo(
      e.currentPointer.viewportX,
      e.currentPointer.viewportY
    );

    if (pointerPosition && mapContainerPosition) {
      const screenPosition = map.current?.geoToScreen(pointerPosition);
      if (screenPosition) {
        tooltip.style.top = `${mapContainerPosition.top + screenPosition.y}px`;
        tooltip.style.left = `${
          mapContainerPosition.left + screenPosition.x
        }px`;
      }
    }
  });

  truckMarker.addEventListener("pointerleave", () => {
    truckMarker.setIcon(getTruckIcon(color));
    if (map.current) {
      map.current.getViewPort().element.style.cursor = "default";
    }
    tooltip.className = "hidden";
  });
  return truckMarker;
};

export const createOrderOperationMarker = (
  operation: Operation,
  map: React.MutableRefObject<H.Map | null>,
  color: string,
  size: number
) => {
  const lat = operation.latitude;
  const lng = operation.longitude;
  const operationMarker = new H.map.Marker(
    { lat, lng },
    {
      data: operation,
      icon: getOrderOperationIcon(operation.operation_type, color, size),
    }
  );

  const tooltip = document.createElement("div");
  tooltip.className = "hidden";
  const time = formatOrderOperationTime(operation);
  tooltip.innerText = `${operation.operation_type} - ${time}`;
  document.body.appendChild(tooltip);

  operationMarker.addEventListener("tap", () => {
    const center = {
      lat: operation.latitude,
      lng: operation.longitude,
    };
    map.current?.setCenter(center);
    map.current?.setZoom(10);
  });

  operationMarker.addEventListener("pointerenter", (e: any) => {
    if (map.current) {
      map.current.getViewPort().element.style.cursor = "pointer";
    }

    tooltip.className =
      "absolute bg-white text-black p-2 text-xs border-0 shadow-lg z-50"; // Show the tooltip by removing 'hidden'
    const mapContainerPosition = map.current
      ?.getViewPort()
      .element.getBoundingClientRect();
    const pointerPosition = map.current?.screenToGeo(
      e.currentPointer.viewportX,
      e.currentPointer.viewportY
    );

    if (pointerPosition && mapContainerPosition) {
      const screenPosition = map.current?.geoToScreen(pointerPosition);
      if (screenPosition) {
        tooltip.style.top = `${mapContainerPosition.top + screenPosition.y}px`;
        tooltip.style.left = `${
          mapContainerPosition.left + screenPosition.x
        }px`;
      }
    }
  });

  operationMarker.addEventListener("pointerleave", () => {
    if (map.current) {
      map.current.getViewPort().element.style.cursor = "default";
    }
    tooltip.className = "hidden";
  });
  return operationMarker;
};

export const getMarkerIcon = (id: string, color: string, radius = 10) => {
  const svgCircle = `<svg width="30" height="30" version="1.1" xmlns="http://www.w3.org/2000/svg">
  <g id="marker">
    <circle cx="15" cy="15" r="${radius}" fill="${color}" stroke="${color}" stroke-width="4" />
    <text x="50%" y="50%" text-anchor="middle" fill="#FFFFFF" font-family="Arial, sans-serif" font-size="12px" dy=".3em">${id}</text>
  </g></svg>`;
  return new H.map.Icon(svgCircle, {
    anchor: {
      x: 10,
      y: 10,
    },
  });
};

export const generateTruckColors = (trucks: TruckLocation[]) => {
  if (trucks.length === 0) return null;
  const truckColors = trucks.reduce((acc, truck, index) => {
    acc[truck.truck] = getIconColor(index);
    return acc;
  }, {} as Record<string, string>);
  return truckColors;
};

export const RED = "#ab1111";
export const GREEN = "#11ab11";
export const BLUE = "#1111fb";
export const YELLOW = "#c0c011";
export const ORANGE = "#aa8c00";
export const PURPLE = "#800080";
export const CYAN = "#00aaaa";
export const PINK = "#aa69c4";

export const GRAY = "#707070";

export const getIconColor = (index: number) => {
  const colors = [RED, GREEN, BLUE, YELLOW, ORANGE, PURPLE, CYAN, PINK];
  return colors[index % colors.length];
};

export const getRouteColors = (color: string) => {
  switch (color) {
    case RED: {
      return [RED, "#a04444"];
    }
    case GREEN: {
      return [GREEN, "#118811"];
    }
    case BLUE: {
      return [BLUE, "#0000fb"];
    }
    case YELLOW: {
      return [YELLOW, "#fefe00"];
    }
    case ORANGE: {
      return [ORANGE, "#ff8c00"];
    }
    case PURPLE: {
      return [PURPLE, "#800080"];
    }
    case CYAN: {
      return [CYAN, "#00ffff"];
    }
    case PINK: {
      return [PINK, "#ff69b4"];
    }
    case GRAY: {
      return [GRAY, "#909090", "#5a5a5a", "#3a3a3a", "#1a1a1a", "#ddd"];
    }
    default: {
      return [color];
    }
  }
};

const restrictMap = (map: H.Map) => {
  const bounds = new H.geo.Rect(70, -10, 37, 42);

  map.getViewModel().addEventListener("sync", function () {
    const center = map.getCenter();

    if (!bounds.containsPoint(center)) {
      if (center.lat > bounds.getTop()) {
        center.lat = bounds.getTop();
      } else if (center.lat < bounds.getBottom()) {
        center.lat = bounds.getBottom();
      }
      if (center.lng < bounds.getLeft()) {
        center.lng = bounds.getLeft();
      } else if (center.lng > bounds.getRight()) {
        center.lng = bounds.getRight();
      }
      map.setCenter(center);
    }
  });
};

export const createMap = (
  platformRef: MutableRefObject<H.service.Platform | null>,
  mapWrapperRef: MutableRefObject<HTMLElement | null>,
  mapRef: MutableRefObject<H.Map | null>,
  variant = "logistics.day"
) => {
  if (mapWrapperRef.current === null) return;
  if (mapRef !== null && mapRef.current !== null) {
    mapRef.current.dispose();
  }
  platformRef.current = new H.service.Platform({ apikey: API_KEY });
  const rasterTileService = platformRef.current.getRasterTileService({
    queryParams: {
      style: variant,
      size: 512,
      features:
        "vehicle_restrictions:active_and_inactive,environmental_zones:all,congestion_zones:all",
      lang: "pl",
      lang2: "en",
    },
  });
  const rasterTileProvider = new H.service.rasterTile.Provider(
    rasterTileService
  );
  const rasterTileLayer = new H.map.layer.TileLayer(rasterTileProvider);
  const newMap = new H.Map(mapWrapperRef.current, rasterTileLayer, {
    pixelRatio: window.devicePixelRatio || 1,
    center: { lat: 46.5, lng: 5.2 },
    zoom: 6,
  });
  window.addEventListener("resize", () => newMap.getViewPort().resize());

  new H.mapevents.Behavior(new H.mapevents.MapEvents(newMap));
  H.ui.UI.createDefault(newMap, rasterTileLayer, "en-US");
  restrictMap(newMap);
  mapRef.current = newMap;
};
