import React from "react";
import { Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

interface NoDataProps {
  message?: string;
}

const NoData: React.FC<NoDataProps> = ({ message }) => {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="200px"
    >
      <Typography variant="h6" color="textSecondary">
        {message ? message : t("common.noData")}
      </Typography>
    </Box>
  );
};

export default NoData;
