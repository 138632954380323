import { useEffect, useState } from "react";
import useAxios from "../../../hooks/useAxios";
import { Operation } from "../../../types/orders";
import { WidgetSkeleton } from "../../../components/skeletons/WidgetSkeleton";
import { useTranslation } from "react-i18next";
import NoData from "../../../components/NoData";
import ReactCountryFlag from "react-country-flag";
import { format } from "date-fns-tz";
import { Link } from "react-router-dom";

interface Loadings {
  upcoming: Operation[];
  recent: Operation[];
}

const Loadings = () => {
  const [loadings, setLoadings] = useState<Loadings | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [timers, setTimers] = useState<{ [key: number]: string }>({});
  const axios = useAxios();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchLoadings = async () => {
      setLoading(true);
      try {
        const response = await axios.get("orders/loadings");
        setLoadings(response.data);

        // Initialize timers for upcoming loadings
        const initialTimers = response.data.upcoming.reduce((acc: any, loading: Operation) => {
          acc[loading.id] = calculateTimeRemaining(new Date(loading.time_begin));
          return acc;
        }, {});
        setTimers(initialTimers);
      } catch (error) {
        console.error("Error fetching loadings:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLoadings();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (loadings?.upcoming) {
        const updatedTimers = loadings.upcoming.reduce((acc: any, loading: Operation) => {
          acc[loading.id] = calculateTimeRemaining(new Date(loading.time_begin));
          return acc;
        }, {});
        setTimers(updatedTimers);
      }
    }, 1000); // Update every second

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [loadings]);

  const calculateTimeRemaining = (time_begin: Date) => {
    const now = new Date();
    const difference = time_begin.getTime() - now.getTime();

    if (difference <= 0) {
      return t("dashboard.timeElapsed");
    }

    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / (1000 * 60)) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    return `${hours}h ${minutes}m ${seconds}s`;
  };

  return (
    <div>
      {loading ? (
        <WidgetSkeleton />
      ) : (
        <div className="flex text-xs">
          <div className="w-1/2">
            <div className="flex items-center">
              <h2 className="text-2xl font-semibold">
                {t("dashboard.upcoming")}
              </h2>
            </div>
            {loadings?.upcoming.length === 0 && (
              <NoData message={t("dashboard.noUpcomingLoads")} />
            )}
            {loadings?.upcoming.map((loading) => (
              <Link to={`/orders/${loading.order_id}`} key={loading.id}>
                <div className="p-1 border rounded-lg shadow-sm hover:shadow-md transition-shadow hover:bg-neutral-400">
                  <h3 className="text-lg font-semibold text-blue-600">
                    {t("operationTypes.loading")}
                  </h3>
                  <p>{loading.truck?.license_plate}</p>
                  <div className="text-gray-600 flex gap-2 items-center">
                    <div>
                      <ReactCountryFlag
                        countryCode={loading.country}
                        svg
                        className="rounded-full"
                      />
                    </div>
                    <p>{loading.place_display}</p>
                  </div>
                  <p className="text-gray-600">
                    {format(new Date(loading.time_begin), "yyyy-MM-dd HH:mm")}
                  </p>
                  <p className="text-gray-600">
                    {t("dashboard.driver")}:{" "}
                    {loading.driver
                      ? `${loading.driver.full_name} (${loading.driver.phone})`
                      : t("dashboard.notAssigned")}
                  </p>
                  <p className="text-red-600 font-bold text-xl">
                    {t("dashboard.timeRemaining")}: {timers[loading.id]}
                  </p>
                </div>
              </Link>
            ))}
          </div>
          <div className="w-1/2">
            <div className="flex items-center">
              <h2 className="text-2xl font-semibold">
                {t("dashboard.recent")}
              </h2>
            </div>
            {loadings?.recent.length === 0 && (
              <NoData message={t("dashboard.noRecentLoads")} />
            )}
            {loadings?.recent.map((loading) => (
              <Link to={`/orders/${loading.order_id}`} key={loading.id}>
                <div className="p-1 border rounded-lg shadow-sm hover:shadow-md transition-shadow hover:bg-neutral-400">
                  <h3 className="text-lg font-semibold text-green-600">
                    {t("operationTypes.loading")}
                  </h3>
                  <p>{loading.truck?.license_plate}</p>
                  <div className="text-gray-600 flex gap-2 items-center">
                    <div>
                      <ReactCountryFlag
                        countryCode={loading.country}
                        svg
                        className="rounded-full"
                      />
                    </div>
                    <p>{loading.place_display}</p>
                  </div>
                  <p className="text-gray-600">
                    {format(new Date(loading.time_begin), "yyyy-MM-dd HH:mm")}
                  </p>
                  <p className="text-gray-600">
                    {t("dashboard.driver")}:{" "}
                    {loading.driver
                      ? `${loading.driver.full_name} (${loading.driver.phone})`
                      : t("dashboard.notAssigned")}
                  </p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Loadings;
