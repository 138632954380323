import { useEffect, useState } from "react";

interface MessageBoxProps {
  position: "left" | "right" | string;
  title: string;
  date: Date;
  avatar: string;
  children: React.ReactNode;
}

const MessageBox: React.FC<MessageBoxProps> = ({
  position,
  title,
  date,
  avatar,
  children,
}) => {
  const [dateDisplay, setDateDisplay] = useState<string>("");

  useEffect(() => {
    setDateDisplay(date.toLocaleTimeString());
  });

  return (
    <div
      className={`flex items-center bg-secondary m-2 rounded-xl p-1 ${
        position === "right" ? "justify-end" : "justify-start"
      }`}
    >
      {position === "left" && (
        <img src={avatar} alt={title} className="w-10 h-10 rounded-full mr-4" />
      )}
      <div
        className={`flex flex-col ${
          position === "right" ? "items-end" : "items-start"
        }`}
      >
        {" "}
        <p className="text-xs text-neutral-500 font-bold">{title}</p>
        <div
          className={`p-2 bg-${
            position === "right" ? "primary" : "neutral"
          }-500 rounded-3xl text-black`}
        >
          {children}
        </div>
        <span className="text-xs text-neutral-500">{dateDisplay}</span>
      </div>
      {position === "right" && (
        <img src={avatar} alt={title} className="w-10 h-10 rounded-full ml-4" />
      )}
    </div>
  );
};

export default MessageBox;
