import { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import CarriersTable from "./CarriersTable";
import {
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AddCarrierForm from "./AddCarrierForm";
import { Carrier } from "../../types/carriers";

const CarriersContainer = () => {
  const { t } = useTranslation();
  const axios = useAxios();
  const [carriers, setCarriers] = useState<Carrier[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchCarriers = async () => {
      setLoading(true);
      try {
        const response = await axios.get("/carriers/");
        setCarriers(response.data);
      } catch (error) {
        console.error("Error fetching carriers:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCarriers();
  }, []);

  const onAddCarrier = async (data: any) => {
    try {
      const response = await axios.post("/carriers/", data);
      setIsModalOpen(false);
      setCarriers([...carriers, response.data]);
    } catch (error) {
      console.error("Error adding carrier:", error);
    }
  };

  return (
    <div className="p-4">
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">{t("breadcrumb.home")}</Link>
        <Typography color="text.primary">{t("breadcrumb.carriers")}</Typography>
      </Breadcrumbs>
      <div className="container mx-auto p-4">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold mb-4">{t("carriers.title")}</h1>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            {t("carriers.addCarrier")}
          </Button>
        </div>
        <CarriersTable carriers={carriers} loading={loading} />
      </div>
      <Dialog
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
      >
        <DialogTitle>{t("carriers.addCarrierModal.title")}</DialogTitle>
        <DialogContent style={{ width: "600px" }}>
          <AddCarrierForm onSubmit={onAddCarrier} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsModalOpen(false)} color="primary">
            {t("common.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CarriersContainer;
