import { useTranslation } from "react-i18next";
import { WidgetSkeleton } from "../../../components/skeletons/WidgetSkeleton";
import NoData from "../../../components/NoData";
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { Typography, Card, CardContent } from "@mui/material";
import { Performance as PerformanceType } from "../../../types/insights";

const calculatePercentageDifference = (current: number, previous: number) => {
  if (previous === 0) return 0;
  return ((current - previous) / previous) * 100;
};

interface PerformanceProps {
  performance: PerformanceType | null;
  loading: boolean;
}

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
const Performance: React.FC<PerformanceProps> = ({ performance, loading }) => {
  const { t } = useTranslation();

  if (loading) {
    return <WidgetSkeleton />;
  }
  if (!performance) {
    return <NoData message={t("dashboard.noPerformance")} />;
  }

  const pieData = [
    {
      name: t("dashboard.performance.contractorOrders"),
      value: performance.subcontractor_orders,
    },
    {
      name: t("dashboard.performance.ownOrders"),
      value: performance.own_orders,
    },
  ];

  const statusData = [
    { name: t("dashboard.performance.cancelled"), value: performance.cancelled_orders }, 
    { name: t("dashboard.performance.inProgress"), value: performance.in_progress_orders },
    { name: t("dashboard.performance.completed"), value: performance.completed_orders },
  ].sort((a, b) => b.value - a.value);

  const percentageData = [
    {
      name: t("dashboard.performance.ordersThisMonth"),
      value: performance.orders_this_month,
      oldVal: performance.orders_from_last_month_up_to_current_month_day,
      difference: calculatePercentageDifference(
        performance.orders_this_month,
        performance.orders_from_last_month_up_to_current_month_day
      ),
    },
    {
      name: t("dashboard.performance.ordersLast30Days"),
      value: performance.orders_from_last_30_days,
      oldVal: performance.orders_from_before_last_30_days,
      difference: calculatePercentageDifference(
        performance.orders_from_last_30_days,
        performance.orders_from_before_last_30_days
      ),
    },
  ];

  return (
    <div className="p-4">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div className="bg-white shadow-lg rounded-lg p-4">
          <div className="space-y-4">
            {percentageData.map((item) => (
              <Card
                variant="outlined"
                sx={{ minWidth: 275, mb: 2 }}
                key={item.name}
              >
                <CardContent>
                  <Typography variant="h5" component="div">
                    {item.name}
                  </Typography>
                  <Typography
                    variant="h3"
                    component="div"
                    sx={{ mt: 1, color: item.difference > 0 ? "green" : "red" }}
                  >
                    {item.value}{" "}
                    <Typography variant="body1" component="span">
                      {item.difference > 0
                        ? `+${item.difference.toFixed(2)}% MoM`
                        : `${item.difference.toFixed(2)}% MoM`}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {t("dashboard.performance.lastMonth")}: {" "}
                      {item.oldVal}
                    </Typography>
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>

        <div className="bg-white shadow-lg rounded-lg p-4">
          <h2 className="text-xl font-semibold mb-2">
            {t("dashboard.performance.ordersDistribution")}
          </h2>
          <p className="text-3xl font-bold text-gray-500">
            {performance.orders_total} {t("dashboard.performance.ordersTotal")}
          </p>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={pieData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={100}
                fill="#8884d8"
              >
                {pieData.map((_, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>

        <div className="bg-white shadow-lg rounded-lg p-4">
          <h2 className="text-xl font-semibold mb-2">
            {t("dashboard.performance.orderStatus")}
          </h2>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={statusData} layout="vertical">
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" />
              <YAxis type="category" dataKey="name" />
              <Tooltip />
              <Legend />
              <Bar dataKey="value" fill="#ff7300" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default Performance;
