import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import OrderChat from "../../components/chat/OrderChat";

const TrackingDispatcherContainer = () => {
  const { t } = useTranslation();
  const { orderId } = useParams<{ orderId: string }>();

  return (
    <div className="p-4 my-12 px-64 min-h-screen max-h-screen">
      {orderId && (
        <OrderChat title={t("trackingOrderDetails.chat")} orderId={orderId} />
      )}
    </div>
  );
};

export default TrackingDispatcherContainer;
