import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBankAccounts } from "../../contexts/BankAccountsContext";
import { Controller, useForm } from "react-hook-form";
import { BankAccount, BankAccountModel } from "../../types/companies";
import {
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import { getCurrencySymbol } from "../../utils/currencies";
import { CURRENCIES } from "../../consts/currencies";
import { useToast } from "../../hooks/useToast";
import useAxios from "../../hooks/useAxios";
import { useAuth } from "../../contexts/AuthContext";
import DragDropInput from "../../components/input/DragDropInput";

const InvoicingForm = () => {
  const { t } = useTranslation();
  const { bankAccounts, addBankAccount, updateBankAccount, deleteBankAccount } =
    useBankAccounts();
  const [loading, setLoading] = useState<boolean>(false);

  const { control, handleSubmit, reset } = useForm<BankAccount>();
  const {
    control: editControl,
    handleSubmit: handleEditSubmit,
    setValue,
  } = useForm<BankAccountModel>();
  const toast = useToast();
  const axios = useAxios();
  const [editingId, setEditingId] = useState<number | null>(null);
  const [invoiceLogoUploading, setInvoiceLogoUploading] = useState(false);
  const [invoiceLogoPreview, setInvoiceLogoPreview] = useState<string | null>(
    null
  );

  const { company } = useAuth();

  useEffect(() => {
    if (!company) {
      return;
    }
    setInvoiceLogoPreview(company.invoice_logo);
  }, [company]);

  const handleLogoChange = async (files: FileList) => {
    if (files.length === 0) return;

    const file = files[0];
    const reader = new FileReader();
    reader.onload = async () => {
      setInvoiceLogoUploading(true);
      const fileContent = reader.result;
      const payload = {
        file: {
          name: file.name,
          base64: fileContent,
        },
      };
      try {
        const response = await axios.patch("/companies/my-company/", payload);
        console.log(response.data);
        setInvoiceLogoPreview(response.data.invoice_logo);
      } catch (error) {
        console.error(error);
        toast({
          message: "Failed to add document",
          type: "error",
        });
      } finally {
        setInvoiceLogoUploading(false);
      }
    };

    reader.readAsDataURL(file);
  };

  const startEditing = (account: BankAccountModel) => {
    setEditingId(account.id);
    setValue("bank_name", account.bank_name);
    setValue("account_number", account.account_number);
    setValue("swift", account.swift);
    setValue("currency", account.currency);
    setValue("is_default", account.is_default);
  };

  const resetForm = () => {
    setValue("bank_name", "");
    setValue("account_number", "");
    setValue("swift", "");
    setValue("currency", "");
    setValue("is_default", false);
  };

  const onSubmit = async (data: any) => {
    setLoading(true);
    const parsedData = {
      ...data,
      account_number: data.account_number.replace(/\s/g, ""),
    };
    try {
      await addBankAccount(parsedData);
      toast({
        type: "success",
        message: t("account.invoicing.accountAdded"),
      });
      resetForm();
      reset();
    } catch (error: any) {
      console.log(error);
      toast({
        type: "error",
        message: t("account.invoicing.accountAddError"),
      });
    } finally {
      setLoading(false);
    }
  };

  const onEditSubmit = async (data: any) => {
    if (!editingId) return;
    await updateBankAccount(editingId, data);
    setEditingId(null);
    resetForm();
  };

  return (
    <div className="max-w-4xl mx-auto mt-10 p-6 bg-white shadow-md rounded-lg text-black">
      {invoiceLogoPreview && (
        <>
          {" "}
          <p>{t("account.invoicing.invoiceLogoPreview")}</p>
          <div className="my-4 p-4 border border-gray-300 rounded-lg shadow-sm bg-gray-50">
            <table width="100%" cellPadding="0" cellSpacing="0">
              <tbody>
                <tr>
                  <td
                    width="50%"
                    valign="top"
                    style={{
                      padding: 0,
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={invoiceLogoPreview}
                      alt="Invoice logo"
                      className="w-1/2"
                    />
                  </td>

                  <td
                    width="50%"
                    valign="top"
                    style={{
                      padding: 0,
                      textAlign: "center",
                    }}
                  >
                    <table width="100%" cellPadding="0" cellSpacing="0">
                      <tbody>
                        <tr>
                          <td
                            className="head"
                            style={{
                              padding: 0,
                              textAlign: "center",
                            }}
                          >
                            Place of issue
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="body"
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {company ? (
                              <b>
                                {company.country} {company.city}{" "}
                                {company.street} {company.apartment_number}{" "}
                                {company.building_number}
                              </b>
                            ) : (
                              <b>Warsaw, Poland</b>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontSize: "1px",
                            }}
                          ></td>
                        </tr>
                        <tr>
                          <td
                            className="head"
                            style={{
                              textAlign: "center",
                            }}
                          >
                            Date of issue
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="body"
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <b>{new Date().toLocaleDateString()}</b>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontSize: "1px",
                            }}
                          ></td>
                        </tr>
                        <tr>
                          <td
                            className="head"
                            style={{
                              textAlign: "center",
                            }}
                          >
                            Date of performing the service
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="body"
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <b>{new Date().toLocaleDateString()}</b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
      <DragDropInput
        uploading={invoiceLogoUploading}
        name={t("account.invoicing.uploadNewLogo")}
        types={["png", "jpg", "jpeg", "svg"]}
        handleChange={handleLogoChange}
        label={t("account.invoicing.uploadLogo")}
        multiple={true}
      />
      <h1 className="text-2xl font-semibold mb-5">
        {t("account.invoicing.bankAccounts")}
      </h1>

      <form onSubmit={handleSubmit(onSubmit)} className="mb-4 bg-gray-50 p-4">
        <p className="text-gray-600 mb-4">
          {t("account.invoicing.newAccount")}
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <Controller
            name="bank_name"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label={t("account.invoicing.bankName")}
                placeholder={t("account.invoicing.enterBankName")}
                className="mt-1 w-full"
              />
            )}
          />

          <Controller
            name="account_number"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label={t("account.invoicing.accountNumber")}
                placeholder={t("account.invoicing.enterAccountNumber")}
                className="mt-1 w-full"
              />
            )}
          />
          <Controller
            name="swift"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label={t("account.invoicing.swift")}
                placeholder={t("account.invoicing.enterSwift")}
                className="mt-1 w-full"
              />
            )}
          />
          <Controller
            name="currency"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                select
                label={t("account.invoicing.currency")}
                variant="outlined"
              >
                {CURRENCIES.map((currency) => (
                  <MenuItem key={currency} value={currency}>
                    {getCurrencySymbol(currency)}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Controller
            name="is_default"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox {...field} checked={field.value} color="primary" />
                }
                label={t("account.invoicing.defaultAccount")}
              />
            )}
          />
        </div>

        <div className="mt-4">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {t("account.invoicing.addAccount")}
          </Button>
        </div>
      </form>

      <div>
        {bankAccounts.length === 0 ? (
          <p className="text-gray-600">{t("account.invoicing.noAccounts")}</p>
        ) : (
          bankAccounts.map((account) => (
            <div
              key={account.id}
              className="border border-gray-300 p-4 mb-4 rounded-lg shadow-sm bg-gray-50"
            >
              {editingId === account.id ? (
                <form
                  onSubmit={handleEditSubmit(onEditSubmit)}
                  className="space-y-4"
                >
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <Controller
                      name="bank_name"
                      control={editControl}
                      defaultValue={account.bank_name}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={t("account.invoicing.bankName")}
                          placeholder={t("account.invoicing.enterBankName")}
                          className="mt-1 w-full"
                        />
                      )}
                    />
                    <Controller
                      name="account_number"
                      control={editControl}
                      defaultValue={account.account_number}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={t("account.invoicing.accountNumber")}
                          placeholder={t(
                            "account.invoicing.enterAccountNumber"
                          )}
                          className="mt-1 w-full"
                        />
                      )}
                    />
                    <Controller
                      name="swift"
                      control={editControl}
                      defaultValue={account.swift}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={t("account.invoicing.swift")}
                          placeholder={t("account.invoicing.enterSwift")}
                          className="mt-1 w-full"
                        />
                      )}
                    />
                    <Controller
                      name="currency"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          select
                          label={t("account.invoicing.currency")}
                          variant="outlined"
                        >
                          {CURRENCIES.map((currency) => (
                            <MenuItem key={currency} value={currency}>
                              {getCurrencySymbol(currency)}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                    <Controller
                      name="is_default"
                      control={editControl}
                      defaultValue={account.is_default}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...field}
                              checked={field.value}
                              color="primary"
                            />
                          }
                          label={t("account.invoicing.defaultAccount")}
                        />
                      )}
                    />
                  </div>
                  <div className="flex justify-between mt-4">
                    <Button type="submit" variant="contained" color="primary">
                      {t("common.save")}
                    </Button>
                    <Button
                      onClick={() => setEditingId(null)}
                      color="error"
                      type="button"
                    >
                      {t("common.cancel")}
                    </Button>
                  </div>
                </form>
              ) : (
                // View mode
                <>
                  <h2 className="text-xl font-semibold text-gray-800">
                    {account.bank_name}
                  </h2>
                  <p>
                    {t("account.invoicing.accountNumber")}:{" "}
                    {account.account_number}
                  </p>
                  <p>
                    {t("account.invoicing.swift")}: {account.swift}
                  </p>
                  <p>
                    {t("account.invoicing.currency")}: {account.currency}
                  </p>
                  {account.is_default && (
                    <p className="text-indigo-600">
                      {t("account.invoicing.defaultAccount")}
                    </p>
                  )}
                  <p>
                    {t("account.invoicing.defaultAccount")}:{" "}
                    {account.is_default ? t("common.yes") : t("common.no")}
                  </p>
                  <div className="flex justify-between mt-4">
                    <button
                      onClick={() => startEditing(account)}
                      className="text-indigo-600 hover:text-indigo-900 font-semibold"
                    >
                      {t("common.edit")}
                    </button>
                    <button
                      onClick={() => deleteBankAccount(account.id)}
                      className="text-red-600 hover:text-red-800 font-semibold"
                    >
                      {t("common.delete")}
                    </button>
                  </div>
                </>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default InvoicingForm;
