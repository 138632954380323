import React from "react";
import { Operation } from "../../../types/orders";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { FaExpand } from "react-icons/fa6";
import { formatOrderOperationTime } from "../../../utils/time";
import { useTranslation } from "react-i18next";

interface ShipmentDetailsProps {
  operations: Operation[];
}

const ShipmentDetails: React.FC<ShipmentDetailsProps> = ({ operations }) => {
  const { t } = useTranslation();
  return (
    <Accordion>
      <AccordionSummary expandIcon={<FaExpand />}>
        <p className="text-3xl font-bold">
          {t("trackingOrderDetails.shipmentDetails")}
        </p>
      </AccordionSummary>
      <AccordionDetails>
        <div className="w-full border-b border-neutral-400 opacity-70" />
        <List>
          {operations.map((operation, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={`${t(`operationTypes.${operation.operation_type}`)} ${
                  operation.name
                }`}
                secondary={`Address: ${operation.address}, ${
                  operation.locality
                }, ${operation.country}. Time: ${formatOrderOperationTime(
                  operation
                )}`}
              />
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default ShipmentDetails;
