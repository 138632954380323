import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { TruckDocument } from "../../../types/documents";

interface MaintenanceIncomingProps {
  data: TruckDocument;
}

const TruckDocumentEnding: React.FC<MaintenanceIncomingProps> = ({ data }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex items-center justify-between">
        <div>
          <h3 className="font-semibold">
            {t("dashboard.documentEnding.date")}
          </h3>
          <p>
            {data.end_date ? new Date(data.end_date).toLocaleDateString() : ""}
          </p>
          <p className="italic">{data.truck_license_plate}</p>
          <p className="font-semibold">{t(`documentTypes.${data.type}`)}</p>
        </div>
      </div>
      <div className="mt-4">
        <Link to={`/my-fleet/trucks/${data.truck_license_plate}?tab=documents&recommendation=document_ending&document=${data.id}`}>
          <Button variant="contained" color="primary">
            {t("dashboard.documentEnding.renew")}
          </Button>
        </Link>
      </div>
    </>
  );
};

export default TruckDocumentEnding;
