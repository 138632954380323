import { useTranslation } from "react-i18next";
import { OrderInfo } from "../../../types/orders";

interface MaintenanceIncomingProps {
  data: {
    orders: OrderInfo[];
  };
}

const OrdersWithoutRefNumber: React.FC<MaintenanceIncomingProps> = ({
  data,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex items-center justify-between">
        <div>
          <p>
            {data.orders.length}
            {t("orders_without_ref_number")}
          </p>
          {data.orders[0].trans_ref_number}
        </div>
      </div>
    </>
  );
};

export default OrdersWithoutRefNumber;
