import { Controller, useForm } from "react-hook-form";
import { useAuth } from "../../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import { COUNTRIES } from "../../consts/countries";
import ReactCountryFlag from "react-country-flag";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { useState } from "react";

interface RegisterSecondStepData {
  token: string;
  password: string;
  confirm_password: string;
  company_name: string;
  company_vat: string;
  company_address: string;
  company_city: string;
  company_postal_code: string;
  company_country: string;
  company_building_number?: string;
  company_apartment_number?: string;
}

const RegisterSecondStepContainer = () => {
  const { t } = useTranslation();
  const { token } = useParams<{ token: string }>();
  const { registerUserSecondStep } = useAuth();
  const { handleSubmit, control } = useForm<RegisterSecondStepData>({
    defaultValues: {
      token: token,
    },
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const onSubmit = handleSubmit((data) => {
    if (data.password !== data.confirm_password) {
      alert(t("signIn.passwordsDoNotMatch"));
      return;
    }
    registerUserSecondStep(data);
  });

  return (
    <div className="flex flex-col items-center justify-center w-full min-h-screen space-y-8 md:p-0 p-8 text-center bg-gradient-prime">
      <form onSubmit={onSubmit}>
        <div className="flex flex-col items-center justify-center px-16 py-8 shadow-2xl dark:bg-zinc-800 bg-secondary rounded-xl">
          <div className="flex flex-col items-center justify-center gap-4">
            <h1 className="font-semibold text-4xl mb-16 text-center dark:text-secondary text-black">
              {t("signIn.register")}
            </h1>
            <div className="grid grid-cols-2 gap-2 w-full">
              <div className="col-span-2">
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="new-password"
                      label={t("signIn.password")}
                      variant="outlined"
                      type={showPassword ? "text" : "password"}
                      required
                      fullWidth
                      autoComplete="new-password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <MdVisibilityOff />
                              ) : (
                                <MdVisibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </div>
              <div className="col-span-2">
                <Controller
                  name="confirm_password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="confirm-new-password"
                      label={t("signIn.confirmPassword")}
                      variant="outlined"
                      type={showConfirmPassword ? "text" : "password"}
                      required
                      fullWidth
                      autoComplete="new-password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowConfirmPassword}
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <MdVisibilityOff />
                              ) : (
                                <MdVisibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <h1 className="font-semibold text-2xl mb-4 text-center dark:text-secondary text-black">
              {t("signIn.companyDetails")}
            </h1>
            <div className="grid grid-cols-2 gap-2 w-full">
              <Controller
                name="company_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t("signIn.companyName")}
                    variant="outlined"
                    required
                  />
                )}
              />
              <Controller
                name="company_vat"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t("signIn.companyVat")}
                    variant="outlined"
                    required
                  />
                )}
              />
              <Controller
                name="company_address"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t("signIn.companyAddress")}
                    variant="outlined"
                    required
                  />
                )}
              />
              <Controller
                name="company_building_number"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t("signIn.buildingNumber")}
                    variant="outlined"
                  />
                )}
              />
              <Controller
                name="company_apartment_number"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t("signIn.apartmentNumber")}
                    variant="outlined"
                  />
                )}
              />
              <Controller
                name="company_city"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t("signIn.companyCity")}
                    variant="outlined"
                    required
                  />
                )}
              />
              <Controller
                name="company_postal_code"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t("signIn.companyPostalCode")}
                    variant="outlined"
                    required
                  />
                )}
              />
              <Controller
                name="company_country"
                control={control}
                defaultValue="pl"
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    label={t("signIn.companyCountry")}
                    variant="outlined"
                    required
                    value={"pl"}
                    disabled
                    inputProps={{ readOnly: true }}
                  >
                    {COUNTRIES.map((country) => (
                      <MenuItem key={country} value={country}>
                        <div className="flex items-center gap-2">
                          <ReactCountryFlag countryCode={country} svg />
                          {t(`countries.${country}`)}
                        </div>
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </div>
            <div className="mt-4 w-full flex justify-center">
              <Button variant="contained" color="primary" type="submit">
                {t("signIn.register")}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default RegisterSecondStepContainer;
