import React from "react";
import { MenuItem, SelectChangeEvent, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useStrider } from "../../contexts/StriderContext";

interface CarrierExcludeFilterProps {
  excludedCarriers: number[];
  onExcludedCarriersChange: (excludedCarriers: number[]) => void;
}

const CarrierExcludeFilter: React.FC<CarrierExcludeFilterProps> = ({
  excludedCarriers,
  onExcludedCarriersChange,
}) => {
  const { t } = useTranslation();
  const handleSelectChange = (event: SelectChangeEvent<any>) => {
    const selectedValues = (event.target.value as number[]).map(
      (value) => value
    );
    onExcludedCarriersChange(selectedValues);
  };
  const { carriers } = useStrider();

  return (
    <TextField
      select
      label={t("orders.carrierExcludeFilter")}
      fullWidth
      variant="outlined"
      value={excludedCarriers}
      SelectProps={{
        multiple: true,
        value: excludedCarriers,
        onChange: handleSelectChange,
      }}
    >
      {carriers.map((carrier) => (
        <MenuItem key={carrier.id} value={carrier.id}>
          {carrier.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default CarrierExcludeFilter;
