export const PERMISSIONS = {
  admin: {
    orders: ["read", "write"],
    routes: ["read", "write"],
    carriers: ["read", "write"],
    shippers: ["read", "write"],
    forwarders: ["read", "write"],
    invoices: ["read", "write"],
    insights: ["read"],
    integrations: ["read", "write"],
    users: ["read", "write"],
    employees: ["read", "write"],
    drivers: ["read", "write"],
    trucks: ["read", "write"],
    carrierDocuments: ["read", "write"],
    employeeDocuments: ["read", "write"],
    driversDocuments: ["read", "write"],
    company: ["read", "write"],
    subscription: ["read", "write"],
  },
  manager: {
    orders: ["read", "write"],
    routes: ["read", "write"],
    carriers: ["read", "write"],
    shippers: ["read", "write"],
    forwarders: ["read", "write"],
    invoices: ["read", "write"],
    insights: ["read"],
    users: ["read"],
    employees: ["read", "write"],
    drivers: ["read", "write"],
    trucks: ["read", "write"],
    carrierDocuments: ["read", "write"],
    employeeDocuments: ["read", "write"],
    driversDocuments: ["read", "write"],
    company: ["read"],
  },
  forwarder: {
    orders: ["read", "write"],
    routes: ["read", "write"],
    carriers: ["read"],
    shippers: ["read"],
    forwarders: ["read"],
    users: ["read"],
    employees: ["read"],
    drivers: ["read"],
    trucks: ["read"],
    carrierDocuments: ["read"],
    company: ["read"],
  },
  dispatcher: {
    orders: ["read", "write"],
    routes: ["read", "write"],
    carriers: ["read"],
    shippers: ["read"],
    forwarders: ["read"],
    users: ["read"],
    employees: ["read"],
    drivers: ["read"],
    trucks: ["read"],
    carrierDocuments: ["read"],
    company: ["read"],
  },
  driver: {
    routes: ["read", "write"],
    employees: ["read"],
    drivers: ["read"],
    trucks: ["read"],
    company: ["read"],
  },
};

//export const PERMISSIONS = [
//  "orders",
//  "routes",
//  "carriers",
//  "my-fleet",
//  "my-fleet-costs",
//  "my-fleet-documents",
//  "my-fleet-employees",
//  "my-fleet-employees-details",
//  "my-fleet-drivers",
//  "my-fleet-drivers-details",
//  "shippers",
//  "carriers",
//  "invoices",
//  "insights",
//  "integrations",
//  "delegations",
//  "company",
//];
//