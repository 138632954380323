import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const NoEmployees = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Link to="/my-fleet?tab=employees&recommendation=add_employee">
        <Button variant="contained" color="primary">
          {t("dashboard.addEmployee")}{" "}
        </Button>
      </Link>
    </div>
  );
};

export default NoEmployees;
