import { useEffect, useRef, useState } from "react";
import { ORANGE, createMap, getMarkerIcon } from "../Map/utils/maps";
import { Route } from "../../types/routes";
import { getSectionStyle } from "../Map/utils/routeStyle";
import { debounce } from "lodash";
import useAxios from "../../hooks/useAxios";
import { Card, CardContent, Typography } from "@mui/material";

interface QuoteMapProps {
  loadingPlace: any;
  unloadingPlace: any;
}

const QuoteMap: React.FC<QuoteMapProps> = ({
  loadingPlace,
  unloadingPlace,
}) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const map = useRef<H.Map | null>(null);
  const platform = useRef<H.service.Platform | null>(null);
  const axios = useAxios();

  const [loadingPlaceDetails, setLoadingPlaceDetails] = useState<any>(null);
  const [unloadingPlaceDetails, setUnloadingPlaceDetails] = useState<any>(null);
  const waypointsGroup = useRef(new H.map.Group());

  const [route, setRoute] = useState<Route | null>(null);
  const routePolylinesGroups = useRef<Map<string, H.map.Group>>(new Map());

  const drawRoutePolylines = (route: Route) => {
    const polylines: H.map.Polyline[] = [];

    route.sections.forEach((section) => {
      const lineStrings: H.geo.LineString[] = [];
      lineStrings.push(H.geo.LineString.fromFlexiblePolyline(section.polyline));
      const multiLineString = new H.geo.MultiLineString(lineStrings);

      const sectionStyle = getSectionStyle(
        ORANGE,
        true,
        true,
        false,
        false,
        false,
        0
      );
      const sectionPolyline = new H.map.Polyline(multiLineString, {
        data: section,
        style: sectionStyle,
      });
      polylines.push(sectionPolyline);
    });

    return polylines;
  };

  useEffect(() => {
    if (!map.current && mapRef && mapRef.current) {
      createMap(platform, mapRef, map, "explore.night");
    }
  }, [map, platform, mapRef]);

  useEffect(() => {
    if (map.current && platform && platform.current && route) {
      routePolylinesGroups.current.forEach((group) =>
        map.current?.removeObject(group)
      );
      routePolylinesGroups.current.clear();
      const routeGroup = new H.map.Group();
      const polylines = drawRoutePolylines(route);
      polylines.forEach((polyline) => routeGroup.addObject(polyline));
      routePolylinesGroups.current.set(route.id, routeGroup);
      map.current?.addObject(routeGroup);
    }
  }, [map, route]);

  useEffect(() => {
    if (!map.current || !loadingPlaceDetails || !unloadingPlaceDetails) {
      return;
    }
    waypointsGroup.current.removeAll();

    const { lat: loadingLat, lng: loadingLng } = loadingPlaceDetails.position;

    const loadingPlaceMarker = new H.map.Marker(
      { lat: loadingLat, lng: loadingLng },
      {
        data: loadingPlaceDetails,
        icon: getMarkerIcon("loading", "#fff"),
      }
    );

    const { lat, lng } = unloadingPlaceDetails.position;

    const unloadingPlaceMarker = new H.map.Marker(
      { lat, lng },
      {
        data: unloadingPlace,
        icon: getMarkerIcon("unloading", "#fff"),
      }
    );

    const boundingBox = new H.geo.Rect(
      Math.min(loadingLat - 1, lat - 1),
      Math.min(loadingLng - 1, lng - 1),
      Math.max(loadingLat + 1, lat + 1),
      Math.max(loadingLng + 1, lng + 1)
    );

    map.current.getViewModel().setLookAtData({
      bounds: boundingBox,
    });

    waypointsGroup.current.addObject(loadingPlaceMarker);
    waypointsGroup.current.addObject(unloadingPlaceMarker);

    map.current.addObject(waypointsGroup.current);
  }, [map, loadingPlaceDetails, unloadingPlaceDetails]);

  useEffect(() => {
    if (loadingPlaceDetails && unloadingPlaceDetails) {
      findRoute();
    }
  }, [loadingPlaceDetails, unloadingPlaceDetails]);

  const findRoute = async () => {
    try {
      const response = await axios.post(`integrations/here/route/`, {
        destination: `${loadingPlaceDetails.position.lat},${loadingPlaceDetails.position.lng}`,
        origin: `${unloadingPlaceDetails.position.lat},${unloadingPlaceDetails.position.lng}`,
      });
      const routes = response.data.routes;
      if (routes.length === 0) {
        return;
      }
      const route = routes[0];
      setRoute(route);
    } catch (error) {
      console.error("Error fetching route:", error);
    }
  };

  const lookupPlaces = async () => {
    try {
      const sourceLookupResponse = await axios.post(
        `integrations/here/lookup/`,
        {
          id: loadingPlace.id,
          lang: "pl",
        }
      );
      const destinationLookupResponse = await axios.post(
        `integrations/here/lookup/`,
        {
          id: unloadingPlace.id,
          lang: "pl",
        }
      );
      setLoadingPlaceDetails(sourceLookupResponse.data.result);
      setUnloadingPlaceDetails(destinationLookupResponse.data.result);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const fetchSuggestionsWithDebounce = debounce(async () => {
    lookupPlaces();
  }, 300);

  useEffect(() => {
    if (loadingPlace && unloadingPlace) {
      fetchSuggestionsWithDebounce();
    }
  }, [loadingPlace, unloadingPlace]);

  return (
    <div>
      <div ref={mapRef} className="w-full" style={{ height: "500px" }} />
      {route && (
        <Card className="w-full p-2">
          <CardContent className="bg-secondary">
            <Typography
              variant="h5"
              component="h2"
              className="text-center text-accent"
            >
              Szczegóły przewozu
            </Typography>
            <div className="mt-4 space-y-2">
              <Typography variant="body1">
                <strong>Dystans:</strong>  {(route.summary.distance / 1000).toFixed(1)}km
              </Typography>
              <Typography variant="body1">
                <strong>Czas:</strong>   {(route.summary.duration / 3600).toFixed(1)}h
              </Typography>
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default QuoteMap;
