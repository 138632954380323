import { Controller, useForm } from "react-hook-form";
import { Button, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

interface RegisterComponentProps {
  onRegisterUser: (data: RegisterFormData) => void;
  onIsRegistering: () => void;
  loading: boolean;
}

interface RegisterFormData {
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
}

const RegisterComponent: React.FC<RegisterComponentProps> = ({
  onRegisterUser,
  onIsRegistering,
  loading,
}) => {
  const { handleSubmit, control } = useForm<RegisterFormData>();
  const { t } = useTranslation();

  return (
    <form
      onSubmit={handleSubmit(onRegisterUser)}
      className="flex flex-col items-center justify-center px-16 py-8 shadow-2xl dark:bg-zinc-800 bg-secondary rounded-xl w-full max-w-xl"
    >
      <h1 className="font-semibold text-4xl mb-16 text-center dark:text-secondary text-black">
        {t("signIn.register")}
      </h1>
      <div className="grid grid-cols-2 gap-2 w-full">
        <div className="col-span-2">
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("signIn.email")}
                variant="outlined"
                type="email"
                required
                fullWidth
                autoComplete="email"
              />
            )}
          />
        </div>
        <div className="col-span-2">
          <Controller
            name="first_name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("signIn.firstName")}
                variant="outlined"
                required
                fullWidth
                autoComplete="given-name"
              />
            )}
          />
        </div>
        <div className="col-span-2">
          <Controller
            name="last_name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("signIn.lastName")}
                variant="outlined"
                required
                fullWidth
                autoComplete="family-name"
              />
            )}
          />
        </div>
        <div className="col-span-2">
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("signIn.phone")}
                variant="outlined"
                required
                fullWidth
                type="tel"
                autoComplete="tel"
              />
            )}
          />
        </div>
      </div>
      <div className="mt-4 w-full flex justify-center">
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={loading}
        >
          {t("signIn.register")}
        </Button>
      </div>
      <div className="w-full h-10 rounded-lg mx-auto mb-4 items-center justify-center flex px-8 py-4">
        <button
          type="button"
          onClick={onIsRegistering}
          className="text-blue-500 underline"
        >
          {t("signIn.backToSignIn")}
        </button>
      </div>
    </form>
  );
};

export default RegisterComponent;
