import { useDrag, useDrop } from "react-dnd";
import { useRef } from "react";
import { Waypoint as WaypointType } from "../../types/geo";
import type { Identifier, XYCoord } from "dnd-core";
import { IconButton, Box, TextField, MenuItem } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { t } from "i18next";
import { FaTrash } from "react-icons/fa";
import { RiDragMove2Fill } from "react-icons/ri";
import { ORDER_OPERATION_TYPES } from "../../types/orders";
import GeoAutocomplete from "../geoAutocomplete/GeoAutocomplete";

interface DragItem {
  index: number;
  id: string;
}

interface WaypointProps {
  index: number;
  waypoint: WaypointType;
  moveWaypoint: (dragIndex: number, hoverIndex: number) => void;
  updateWaypoint: (id: string, waypoint: WaypointType) => void;
  removeWaypoint: (id: string) => void;
}

const Waypoint: React.FC<WaypointProps> = ({
  index,
  waypoint,
  moveWaypoint,
  updateWaypoint,
  removeWaypoint,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: "WAYPOINT",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveWaypoint(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "WAYPOINT",
    item: () => {
      return { id: waypoint.id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    options: {
      dropEffect: "move",
    },
  });

  const style = {
    marginBottom: ".5rem",
  };

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <div ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId}>
      <div className="flex flex-col gap-2 p-4 border rounded-md shadow-sm">
        <div className="flex items-center justify-between mb-2 gap-4">
          <IconButton>
            <RiDragMove2Fill />
          </IconButton>
          <div className="w-full flex-col flex gap-2">
            <div className="w-full">
              <GeoAutocomplete
                size="small"
                value={waypoint.location}
                label={t("waypoints.location")}
                onChange={(location) => {
                  updateWaypoint(waypoint.id, {
                    ...waypoint,
                    location,
                  });
                }}
              />
            </div>
            <div className="w-full">
              <TextField
                size="small"
                label={t("waypoints.coordinates")}
                fullWidth
                variant="outlined"
                value={`${
                  typeof waypoint.location !== "string" &&
                  waypoint.location?.latitude &&
                  waypoint.location?.longitude
                    ? `${waypoint.location.latitude}, ${waypoint.location.longitude}`
                    : ""
                }`}
                disabled
              />
            </div>
          </div>
          <IconButton size="small" onClick={() => removeWaypoint(waypoint.id)}>
            <FaTrash />
          </IconButton>
        </div>
        <Box display="flex" gap={2}>
          <DateTimePicker
            slotProps={{ textField: { size: "small" } }}
            label={t("waypoints.time_begin")}
            value={waypoint.time_begin}
            onChange={(time_begin) => {
              if (!time_begin) {
                return;
              }
              if (time_begin > waypoint.time_end) {
                time_begin = waypoint.time_end;
              }
              updateWaypoint(waypoint.id, {
                ...waypoint,
                time_begin,
              });
            }}
            maxDate={waypoint.time_end}
          />
          <DateTimePicker
            label={t("waypoints.time_end")}
            value={waypoint.time_end}
            slotProps={{ textField: { size: "small" } }}
            onChange={(time_end) => {
              if (!time_end) {
                return;
              }
              if (time_end < waypoint.time_begin) {
                time_end = waypoint.time_begin;
              }
              updateWaypoint(waypoint.id, {
                ...waypoint,
                time_end,
              });
            }}
            minDate={waypoint.time_begin}
          />
        </Box>
        <TextField
          select
          size="small"
          label={t("orders.orderCreate.operationType")}
          fullWidth
          variant="outlined"
          value={waypoint.type}
          onChange={(e) => {
            updateWaypoint(waypoint.id, {
              ...waypoint,
              type: e.target.value,
            });
          }}
        >
          {ORDER_OPERATION_TYPES.map((type) => (
            <MenuItem key={type} value={type}>
              {t(`operationTypes.${type}`)}
            </MenuItem>
          ))}
        </TextField>
      </div>
    </div>
  );
};

export default Waypoint;
