import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { GRAPH_COLORS } from "../../../utils/colors";
import NoDataAvailable from "../NoData";

interface ExpensesGraphProps {
  expenses: { [category: string]: number };
}

const ExpensesGraph: React.FC<ExpensesGraphProps> = ({ expenses }) => {
  const data = Object.keys(expenses).map((key, index) => {
    return {
      category: key,
      expense: expenses[key],
      color: GRAPH_COLORS[index % GRAPH_COLORS.length],
    };
  });
  data.sort((a, b) => b.expense - a.expense);

  return (
    <div>
      <h2 className="text-center">Expenses by Category</h2>
      {data.length === 0 ? (
        <NoDataAvailable message="No expenses to show - add some expenses first" />
      ) : (
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            width={600}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="category"
              interval={0}
            />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="expense">
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default ExpensesGraph;
