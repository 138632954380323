import { DEFAULT_EMPLOYEE_CURRENCY } from "../consts/currencies";
import { DEFAULT_DRIVER_PAYMENT, DriverPayment } from "../types/driver";
import { EmployeePayment } from "../types/employees";

export const calculateNewDriverPayment = (
  driverPaymentInfo?: DriverPayment
) => {
  const defaultStartDate = new Date();
  defaultStartDate.setMonth(defaultStartDate.getMonth() - 1);

  if (!driverPaymentInfo) {
    return {
        taxable_net_pay: 0,
        net_pay: 0,
      gross_pay: 0,
      vat_amount: 0,
      employer_cost_amount: 0,
      category: DEFAULT_DRIVER_PAYMENT,
      date_start: defaultStartDate,
      date_end: new Date(),
      payment_date: new Date(),
      notes: "",
      currency: DEFAULT_EMPLOYEE_CURRENCY,
    };
  }

  const timeDifference = new Date().getTime() - defaultStartDate.getTime();
  const daysDifference = timeDifference / (1000 * 3600 * 24);

  const taxableNetPay =
    driverPaymentInfo.base_taxable_net_daily_pay * daysDifference;
  const netPay = driverPaymentInfo.base_net_daily_pay * daysDifference;

  let grossPay = 0;
  let vatAmount = 0;
  if (driverPaymentInfo.employment_type === "b2b") {
    vatAmount = taxableNetPay * (driverPaymentInfo.vat_rate / 100);
    grossPay = taxableNetPay + vatAmount;
  } else {
    grossPay = taxableNetPay + taxableNetPay * 0.5;
  }

  return {
    taxable_net_pay: taxableNetPay,
    net_pay: netPay,
    gross_pay: grossPay,
    vat_amount: vatAmount,
    category: DEFAULT_DRIVER_PAYMENT,
    date_start: defaultStartDate,
    date_end: new Date(),
    payment_date: new Date(),
    notes: "",
    currency: DEFAULT_EMPLOYEE_CURRENCY,
  };
};



export const calculateNewEmployeePayment = (
  driverPaymentInfo?: EmployeePayment
) => {
  const defaultStartDate = new Date();
  defaultStartDate.setMonth(defaultStartDate.getMonth() - 1);

  if (!driverPaymentInfo) {
    return {
        taxable_net_pay: 0,
        net_pay: 0,
      gross_pay: 0,
      vat_amount: 0,
      employer_cost_amount: 0,
      category: DEFAULT_DRIVER_PAYMENT,
      date_start: defaultStartDate,
      date_end: new Date(),
      payment_date: new Date(),
      notes: "",
      currency: DEFAULT_EMPLOYEE_CURRENCY,
    };
  }

  const timeDifference = new Date().getTime() - defaultStartDate.getTime();
  const daysDifference = timeDifference / (1000 * 3600 * 24);

  const taxableNetPay =
    driverPaymentInfo.base_taxable_net_daily_pay * daysDifference;
  const netPay = driverPaymentInfo.base_net_daily_pay * daysDifference;

  let grossPay = 0;
  let vatAmount = 0;
  if (driverPaymentInfo.employment_type === "b2b") {
    vatAmount = taxableNetPay * (driverPaymentInfo.vat_rate / 100);
    grossPay = taxableNetPay + vatAmount;
  } else {
    grossPay = taxableNetPay + taxableNetPay * 0.5;
  }

  return {
    taxable_net_pay: taxableNetPay,
    net_pay: netPay,
    gross_pay: grossPay,
    vat_amount: vatAmount,
    category: DEFAULT_DRIVER_PAYMENT,
    date_start: defaultStartDate,
    date_end: new Date(),
    payment_date: new Date(),
    notes: "",
    currency: DEFAULT_EMPLOYEE_CURRENCY,
  };
};
