import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Autocomplete,
  debounce,
  Divider,
  Popover,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import useAxios from "../../hooks/useAxios";
import ReactCountryFlag from "react-country-flag";
import QuoteMap from "./QuoteMap";
import { useToast } from "../../hooks/useToast";
import { SuggestionType } from "../../types/geo";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const QuoteComponent = () => {
  const query = useQuery();
  const lang = "pl-PL";
  const [source, setSource] = useState(query.get("source") || "");
  const [destination, setDestination] = useState(
    query.get("destination") || ""
  );
  const toast = useToast();
  const [selectedSourceSuggestion, setSelectedSourceSuggestion] =
    useState<SuggestionType | null>(null);
  const [selectedDestinationSuggestion, setSelectedDestinationSuggestion] =
    useState<SuggestionType | null>(null);
  const axios = useAxios();
  const [destinationLoading, setDestinationLoading] = useState(false);
  const [sourceLoading, setSourceLoading] = useState(false);
  const [sourceSuggestions, setSourceSuggestions] = useState<SuggestionType[]>(
    []
  );
  const [destinationSuggestions, setDestinationSuggestions] = useState<
    SuggestionType[]
  >([]);
  const [showError, setShowError] = useState(false);
  const today = new Date();
  const loadingDefaultDate = new Date(today);
  loadingDefaultDate.setDate(loadingDefaultDate.getDate() + 1);
  const [cargoDescription, setCargoDescription] = useState("");
  const [loadingDate, setLoadingDate] = useState<Date | null>(
    loadingDefaultDate
  );
  const [unloadingDate, setUnloadingDate] = useState<Date | null>(null);
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [vatId, setVatId] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const setLoading = (type: string, loading: boolean) => {
    if (type === "source") setSourceLoading(loading);
    if (type === "destination") setDestinationLoading(loading);
  };

  const setSuggestions = (type: string, suggestions: SuggestionType[]) => {
    if (type === "source") setSourceSuggestions(suggestions);
    if (type === "destination") setDestinationSuggestions(suggestions);
  };

  const handleSubmit = async () => {
    if (
      !source ||
      !destination ||
      !loadingDate ||
      !unloadingDate ||
      !cargoDescription ||
      !email
    ) {
      setShowError(true);
      return;
    }

    const quoteData = {
      loading_place: source,
      unloading_place: destination,
      loading_date: loadingDate,
      unloading_date: unloadingDate,
      cargo: cargoDescription,
      first_name: firstName,
      last_name: lastName,
      company_name: company,
      vat_id: vatId,
      email,
      phone_number: phoneNumber,
    };

    try {
      const response = await axios.post(`/contact/cargo-quote/`, quoteData);
      if (response.status === 201) {
        toast({
          type: "success",
          message: "Wycena przesyłki została wysłana",
        });
        setShowError(false);
        setTimeout(() => {
          window.location.href =
            "https://www.frozex.pl/dziekujemy-za-przeslanie-zapytania";
        }, 3000); // 3 seconds delay
      } else {
        setShowError(true);
      }
    } catch (error) {
      console.error("Error submitting quote:", error);
      toast({
        type: "error",
        message: "Wystąpił błąd podczas wyceny przesyłki",
      });
    }
  };

  const fetchSuggestions = async (
    query: string,
    setType: string,
    setSelectedSuggestion?: React.Dispatch<
      React.SetStateAction<SuggestionType | null>
    >
  ) => {
    setLoading(setType, true);
    setSuggestions(setType, []);

    try {
      const response = await axios.post(`integrations/here/autocomplete/`, {
        query,
        lang,
      });
      setSuggestions(setType, response.data.suggestions);
      if (setSelectedSuggestion && response.data.suggestions.length > 0) {
        setSelectedSuggestion(response.data.suggestions[0]);
      }
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    } finally {
      setLoading(setType, false);
    }
  };

  const fetchSuggestionsWithDebounce = debounce(
    async (query: string, setType: string) => {
      if (!query) return;
      if (query.length < 3) return;
      fetchSuggestions(query, setType);
    },
    300
  );

  useEffect(() => {
    if (source) fetchSuggestions(source, "source", setSelectedSourceSuggestion);
  }, [source]);

  useEffect(() => {
    if (destination)
      fetchSuggestions(
        destination,
        "destination",
        setSelectedDestinationSuggestion
      );
  }, [destination]);

  return (
    <div className="min-h-screen w-full flex flex-col items-center justify-between text-black py-16">
      <div className="w-full p-8 text-center">
        <Typography variant="h4" component="h1" sx={{ mb: 2 }}>
          Wyceń przesyłkę
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Wprowadź dane przesyłki, aby uzyskać wycenę
        </Typography>
      </div>
      <div className="flex w-full items-center max-w-7xl p-8 gap-4">
        <form className="flex flex-col gap-2 w-1/2">
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Miejsca
          </Typography>
          <Autocomplete
            freeSolo
            options={sourceSuggestions}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.title
            }
            value={source}
            onInputChange={(event, newInputValue) => {
              event?.preventDefault();
              setSource(newInputValue);
              fetchSuggestionsWithDebounce(newInputValue, "source");
            }}
            loading={sourceLoading}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Miejsce załadunku"
                variant="outlined"
                sx={{ mb: 2 }}
              />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option.title}>
                <div className="flex items-center gap-2">
                  {option.address?.country && (
                    <ReactCountryFlag
                      countryCode={option.address.country}
                      svg
                      style={{ width: "0.7em", height: "0.7em" }}
                      title={option.address.country}
                    />
                  )}
                  {option.title}
                </div>
              </li>
            )}
          />
          <Autocomplete
            freeSolo
            options={destinationSuggestions}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.title
            }
            value={destination}
            onInputChange={(event, newInputValue) => {
              event?.preventDefault();
              setDestination(newInputValue);
              fetchSuggestionsWithDebounce(newInputValue, "destination");
            }}
            loading={destinationLoading}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Miejsce rozładunku"
                variant="outlined"
                sx={{ mb: 2 }}
              />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option.title}>
                <div className="flex items-center gap-2">
                  {option.address?.country && (
                    <ReactCountryFlag
                      countryCode={option.address.country}
                      svg
                      style={{ width: "0.7em", height: "0.7em" }}
                      title={option.address.country}
                    />
                  )}
                  {option.title}
                </div>
              </li>
            )}
          />
          <Divider sx={{ mb: 2 }} />
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Terminy
          </Typography>
          <DateTimePicker
            label="Data załadunku"
            sx={{ mb: 2 }}
            value={loadingDate}
            onChange={(newValue) => setLoadingDate(newValue)}
            minDate={today}
            maxDate={
              unloadingDate ||
              new Date(today.getTime() + 6 * 30 * 24 * 60 * 60 * 1000)
            }
          />
          <DateTimePicker
            label="Data rozładunku"
            sx={{ mb: 2 }}
            value={unloadingDate}
            onChange={(newValue) => setUnloadingDate(newValue)}
            minDate={loadingDate || today}
            maxDate={new Date(today.getTime() + 6 * 30 * 24 * 60 * 60 * 1000)}
          />{" "}
          <TextField
            label="Opis przesyłki"
            variant="outlined"
            sx={{ mb: 2 }}
            multiline
            rows={4}
            value={cargoDescription}
            onChange={(e) => setCargoDescription(e.target.value)}
          />
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Dane kontaktowe
          </Typography>
          <div className="grid grid-cols-2 gap-4">
            <TextField
              label="Imię"
              variant="outlined"
              sx={{ mb: 2 }}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              label="Nazwisko"
              variant="outlined"
              sx={{ mb: 2 }}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <TextField
              label="Firma"
              variant="outlined"
              sx={{ mb: 2 }}
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            />
            <TextField
              label="NIP"
              variant="outlined"
              sx={{ mb: 2 }}
              value={vatId}
              onChange={(e) => setVatId(e.target.value)}
            />
            <TextField
              label="Email"
              variant="outlined"
              sx={{ mb: 2 }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              label="Numer telefonu"
              variant="outlined"
              sx={{ mb: 2 }}
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
          <Button
            ref={buttonRef}
            variant="contained"
            color="primary"
            size="large"
            sx={{ mt: 2 }}
            onClick={handleSubmit}
          >
            Dalej
          </Button>
          <Popover
            open={showError}
            anchorEl={buttonRef.current}
            onClose={() => setShowError(false)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Typography sx={{ p: 2 }} color="error">
              Ustaw punkt początkowy końcowy, daty załadunku rozładunku oraz
              opisz przesyłkę, aby uzyskać wycenę.
            </Typography>
          </Popover>
        </form>
        <div className="w-1/2">
          {selectedDestinationSuggestion && selectedSourceSuggestion && (
            <QuoteMap
              loadingPlace={selectedSourceSuggestion}
              unloadingPlace={selectedDestinationSuggestion}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default QuoteComponent;
