import { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Link, useParams } from "react-router-dom";
import useAxios from "../../hooks/useAxios";

import { useToast } from "../../hooks/useToast";
import { useTranslation } from "react-i18next";
import { Breadcrumbs, CircularProgress, Typography } from "@mui/material";
import BaseInfoForm from "../../components/Forms/BaseInfoForm";
import Employees from "../../components/Employees/Employees";
import Drivers from "../../components/Drivers/Drivers";
import Documents from "../../components/Forms/Documents";
import { Carrier, CARRIER_DOCUMENT_TYPES } from "../../types/carriers";
import TruckList from "../../components/Forms/TruckList";

const CarrierDetail = () => {
  const { t } = useTranslation();
  const [carrier, setCarrier] = useState<Carrier | null>(null);
  const { carrierId } = useParams();
  const axios = useAxios();
  const toast = useToast();

  useEffect(() => {
    if (!carrierId) return;

    axios
      .get(`/carriers/${carrierId}`)
      .then((response) => {
        setCarrier(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [carrierId]);

  const onUpdate = async (data: any) => {
    const response = await axios.patch(`/carriers/${carrierId}/`, data);
    setCarrier(response.data);
    toast({
      message: "Carrier updated",
      type: "success",
    });
  };

  return carrier ? (
    <div className="p-4 mb-2">
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">{t("breadcrumb.home")}</Link>
        <Link to="/carriers">{t("breadcrumb.carriers")}</Link>
        <Typography color="text.primary">
          {t("carrierDetail.title")} - {carrier.name}
        </Typography>
      </Breadcrumbs>

      <Tabs selectedTabClassName="bg-neutral-300 dark:bg-zinc-800 text-black dark:text-secondary border border-neutral-400">
        <TabList className="text-black dark:text-secondary">
          <Tab>{t("carrierDetail.tabs.general")}</Tab>
          <Tab>{t("carrierDetail.tabs.employees")}</Tab>
          <Tab>{t("carrierDetail.tabs.drivers")}</Tab>
          <Tab>{t("carrierDetail.tabs.trucks")}</Tab>
          <Tab>{t("carrierDetail.tabs.documents")}</Tab>
        </TabList>

        <TabPanel>
          <BaseInfoForm info={carrier} onSubmit={onUpdate} />
        </TabPanel>

        <TabPanel>
          <Employees link={`/carriers/${carrierId}/employees/`} />
        </TabPanel>

        <TabPanel>
          <Drivers link={`/carriers/${carrierId}/drivers/`} />
        </TabPanel>

        <TabPanel>
          <TruckList link={`/carriers/${carrierId}/trucks/`} />
        </TabPanel>
        <TabPanel>
          <Documents
            documentTypes={CARRIER_DOCUMENT_TYPES}
            link={`/carriers/${carrierId}/documents/`}
          />
        </TabPanel>
      </Tabs>
    </div>
  ) : (
    <CircularProgress />
  );
};

export default CarrierDetail;
