import React, { useEffect, useRef, useState } from "react";
import {
  CardContent,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ReactCountryFlag from "react-country-flag";
import { DatePicker } from "@mui/x-date-pickers";
import { useSearchParams } from "react-router-dom";
import { getCurrencySymbol } from "../../utils/currencies";
import { ExpenseHistoryEntry, RecurringDetail } from "../../types/expenses";

interface ExpenseListProps {
  expensesHistory: ExpenseHistoryEntry[];
  recurringExpenses: RecurringDetail[];
  historyLoading: boolean;
  recurringLoading: boolean;
  onDeleteRecurringExpense: (id: number) => void;
  onDeleteExpenseHistory: (id: number) => void;
  onExecuteRecurringExpense: (id: number, data: any) => void;
}

const ExpenseList: React.FC<ExpenseListProps> = ({
  expensesHistory,
  recurringExpenses,
  historyLoading,
  recurringLoading,
  onDeleteRecurringExpense,
  onDeleteExpenseHistory,
  onExecuteRecurringExpense,
}) => {
  const [open, setOpen] = useState(false);
  const [currentRecurringExpenseId, setCurrentRecurringExpenseId] = useState<
    number | null
  >(null);
  const [date, setDate] = useState<Date>(new Date());
  const { t } = useTranslation();

  const handleExecuteRecurringExpense = (id: number) => {
    setOpen(true);
    setCurrentRecurringExpenseId(id);
  };

  const [searchParams] = useSearchParams();
  const highlightRef = useRef<{ [key: number]: HTMLDivElement | null }>({});

  useEffect(() => {
    const recommendation = searchParams.get("recommendation");
    const expenseId = parseInt(searchParams.get("expense") || "", 10);

    if (recommendation === "expense_incoming" && expenseId) {
      const element = highlightRef.current[expenseId];
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
        element.classList.add("highlight-animation");
        setTimeout(() => {
          element.classList.remove("highlight-animation");
        }, 8000);
      }
    }
  }, [searchParams, recurringExpenses]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <p className="text-2xl mb-4 border-b border-gray-600 p-4">
          {t("expenseList.recurringExpenses")}
        </p>
        <div className="flex flex-wrap gap-4 items-center justify-center">
          {recurringExpenses.map((recurringExpense) => (
            <div
              key={recurringExpense.id}
              ref={(el) =>
                recurringExpense &&
                (highlightRef.current[recurringExpense.id] = el)
              }
              className="duration-200 transform hover:scale-105 bg-white dark:bg-zinc-800 p-4 rounded-lg shadow-lg"
            >
              <div className="">
                <CardContent>
                  <Typography variant="h6">
                    {recurringExpense.expense.description}
                  </Typography>
                  <Typography>{`${recurringExpense.expense.net_amount} ${recurringExpense.expense.currency}`}</Typography>
                  <Typography>
                    {t("expenseList.fields.interval")}:{" "}
                    {t(`recurringIntervals.${recurringExpense.interval}`)}
                  </Typography>
                  <p>{recurringExpense.last_occurrence?.date}</p>
                  <Typography>
                    {t(`expenseTypes.${recurringExpense.expense.category}`)}
                  </Typography>
                  <Typography>
                    {" "}
                    <div className="flex items-center gap-2">
                      <ReactCountryFlag
                        countryCode={recurringExpense.expense.country}
                        svg
                      />
                      {t(`countries.${recurringExpense.expense.country}`)}
                    </div>
                  </Typography>
                  <Typography>
                    {recurringExpense.expense.taxable
                      ? t("expenseList.taxable")
                      : t("expenseList.nonTaxable")}
                  </Typography>
                  <div className="flex gap-4 mt-4">
                    <Button
                      onClick={() =>
                        handleExecuteRecurringExpense(recurringExpense.id)
                      }
                      variant="contained"
                    >
                      {t("expenseList.execute")}
                    </Button>
                    <Button
                      onClick={() =>
                        onDeleteRecurringExpense(recurringExpense.id)
                      }
                      variant="contained"
                      color="error"
                    >
                      {t("common.delete")}
                    </Button>
                  </div>
                </CardContent>
              </div>
            </div>
          ))}
        </div>
      </Grid>
      <Grid item xs={12}>
        <p className="text-2xl mb-4 border-b border-gray-600 p-4">
          {t("expenseList.history")}
        </p>
        <div className="overflow-x-auto mt-6">
          <h1 className="text-2xl font-bold text-center my-4">
            {t("expenseList.history")}
          </h1>
          <table className="min-w-full leading-normal">
            <thead>
              <tr>
                <th className="px-5 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  {t("expenseList.fields.date")}
                </th>
                <th className="px-5 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  {t("expenseList.fields.netAmount")}
                </th>
                <th className="px-5 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  {t("expenseList.fields.vatAmount")}
                </th>
                <th className="px-5 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  {t("expenseList.fields.country")}
                </th>
                <th className="px-5 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  {t("expenseList.fields.category")}
                </th>
                <th className="px-5 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  {t("expenseList.fields.description")}
                </th>
                <th className="px-5 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  {t("expenseList.fields.actions")}
                </th>
              </tr>
            </thead>
            <tbody>
              {expensesHistory.length === 0 ? (
                <tr>
                  <td
                    colSpan={8}
                    className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-center"
                  >
                    {t("expenseList.noExpensesYet")}
                  </td>
                </tr>
              ) : (
                expensesHistory.map((expenseHistory) => (
                  <tr key={expenseHistory.id}>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {expenseHistory.date}
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {expenseHistory.expense.net_amount}
                      {getCurrencySymbol(expenseHistory.expense.currency)}
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {expenseHistory.expense.vat_amount}
                      {getCurrencySymbol(expenseHistory.expense.currency)}
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <ReactCountryFlag
                        countryCode={expenseHistory.expense.country}
                        svg
                      />
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {t(`expenseTypes.${expenseHistory.expense.category}`)}
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {expenseHistory.expense.description}
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <Button
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        onClick={() => onDeleteExpenseHistory(expenseHistory.id)}
                        variant="contained"
                        color="error"
                      >
                        {t("common.delete")}
                      </Button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </Grid>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          {t("expenseList.executeRecurringExpenseModal.title")}
        </DialogTitle>
        <DialogContent style={{ width: "600px" }}>
          <div className="my-4">
            <DatePicker
              value={date}
              label={t("expenseList.executeRecurringExpenseModal.date")}
              onChange={(date) => {
                if (date) setDate(date);
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            {t("common.cancel")}
          </Button>
          <Button
            onClick={() => {
              if (!currentRecurringExpenseId) return;

              onExecuteRecurringExpense(currentRecurringExpenseId, {
                date,
              });
              setOpen(false);
            }}
            color="primary"
            disabled={historyLoading || recurringLoading}
            variant="contained"
          >
            {t("common.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default ExpenseList;
