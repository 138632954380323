import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { GRAPH_COLORS } from "../../../utils/colors";
import NoDataAvailable from "../NoData";

interface Expense {
  currency: string;
  total_profit: number;
}

interface MonthlyIncome {
  month: number;
  year: number;
  profits: Expense[];
}

interface MonthlyExpensesGraphProps {
  monthlyProfits: MonthlyIncome[];
}

const MonthlyExpensesGraph: React.FC<MonthlyExpensesGraphProps> = ({
  monthlyProfits,
}) => {
  const aggregatedData = monthlyProfits.map((monthlyIncome) => {
    const profitByCurrency: { [key: string]: number } = {};
    monthlyIncome.profits.forEach((profit) => {
      if (profitByCurrency[profit.currency]) {
        profitByCurrency[profit.currency] += profit.total_profit;
      } else {
        profitByCurrency[profit.currency] = profit.total_profit;
      }
    });
    return {
      month: `${monthlyIncome.month}/${monthlyIncome.year}`,
      ...profitByCurrency,
    };
  });


  const uniqueCurrencies = Array.from(
    new Set(
      monthlyProfits.flatMap((monthlyIncome) =>
        monthlyIncome.profits.map((profit) => profit.currency)
      )
    )
  );

  return (
    <div>
      <h2 className="text-center">Monthly Profit</h2>
      {uniqueCurrencies.length === 0 ? (
        <NoDataAvailable message="No profits available for the selected period."/>
      ) : (
        <ResponsiveContainer width="100%" height={400}>
          <LineChart
            width={600}
            height={300}
            data={aggregatedData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Legend />
            {uniqueCurrencies.map((currency, index) => (
              <Line
                type="monotone"
                dataKey={currency}
                stroke={GRAPH_COLORS[index % GRAPH_COLORS.length]}
                key={currency}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default MonthlyExpensesGraph;
