import { FaEdit } from "react-icons/fa";
import { FaTrash } from "react-icons/fa6";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { MaintenanceModel } from "../../../types/truck";

interface MaintenanceTableProps {
  maintenances: MaintenanceModel[];
  onEditMaintenanceClicked: (maintenance: MaintenanceModel) => void;
  onDeleteMaintenance: (maintenance: MaintenanceModel) => void;
}

const MaintenanceTable: React.FC<MaintenanceTableProps> = ({
  maintenances,
  onEditMaintenanceClicked,
  onDeleteMaintenance,
}) => {
  return (
    <div>
      {" "}
      <TableContainer component={Paper} className="mt-4">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Service Type</TableCell>
              <TableCell>Date Performed</TableCell>
              <TableCell>Due Date</TableCell>
              <TableCell>Due Mileage</TableCell>
              <TableCell>Details</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Currency</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {maintenances.map((maintenance, index) => (
              <TableRow key={index}>
                <TableCell>{maintenance.service_type}</TableCell>
                <TableCell>{maintenance.date_performed}</TableCell>
                <TableCell>{maintenance.due_date}</TableCell>
                <TableCell>{maintenance.due_mileage}</TableCell>
                <TableCell>{maintenance.details}</TableCell>
                <TableCell>{maintenance.price}</TableCell>
                <TableCell>{maintenance.currency}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => {
                        onEditMaintenanceClicked(maintenance);
                    }}
                    color="primary"
                  >
                    <FaEdit />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      onDeleteMaintenance(maintenance);
                    }}
                    color="secondary"
                  >
                    <FaTrash />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default MaintenanceTable;
