import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import {
  Typography,
  Box,
  MenuItem,
  TextField,
  Button,
} from "@mui/material";
import ReactCountryFlag from "react-country-flag";
import { COUNTRIES } from "../../consts/countries";
import { CorporateDetails } from "../../types/base";

interface BaseInfoFormProps {
  info: CorporateDetails;
  onSubmit: (data: any) => void;
}

const BaseInfoForm: React.FC<BaseInfoFormProps> = ({
  info,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      country: info.country,
      city: info.city,
      street: info.street,
      postal_code: info.postal_code,
      building_number: info.building_number,
      apartment_number: info.apartment_number,
      regon: info.regon,
      email: info.email,
    },
  });

  return (
    <div className="bg-neutral-300 dark:bg-zinc-800 p-4 border border-gray-400 text-black dark:text-white">
      <Typography variant="h5" component="h2" gutterBottom>
        {t("infoDetail.title")}
      </Typography>
      <Box mb={2}>
        <Typography variant="body1">
          <strong>{t("infoDetail.name")}:</strong> {info.name}
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="body1">
          <strong>{t("infoDetail.vatId")}:</strong> {info.vat_id}
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4 grid grid-cols-3 gap-4">
          <Controller
            name="country"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                select
                label={t("infoDetail.country")}
                fullWidth
                variant="outlined"
                required
              >
                {COUNTRIES.map((country) => (
                  <MenuItem key={country} value={country}>
                    <div className="flex items-center gap-2">
                      <ReactCountryFlag countryCode={country} svg />
                      {t(`countries.${country}`)}
                    </div>
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Controller
            name="city"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("infoDetail.city")}
                variant="outlined"
                required
              />
            )}
          />
          <Controller
            name="regon"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("infoDetail.regon")}
                variant="outlined"
                required
              />
            )}
          />
          <Controller
            name="street"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("infoDetail.street")}
                variant="outlined"
                required
              />
            )}
          />
          <Controller
            name="postal_code"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("infoDetail.postalCode")}
                variant="outlined"
                required
              />
            )}
          />
          <Controller
            name="building_number"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("infoDetail.buildingNo")}
                variant="outlined"
              />
            )}
          />
          <Controller
            name="apartment_number"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("infoDetail.apartmentNo")}
                variant="outlined"
              />
            )}
          />{" "}
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("infoDetail.email")}
                variant="outlined"
                type="email"
                required
              />
            )}
          />
        </div>
        <Button variant="contained" type="submit">
          {t("common.save")}
        </Button>
      </form>
    </div>
  );
};

export default BaseInfoForm;
