import React from "react";
import { useTranslation } from "react-i18next";
import { WidgetSkeleton } from "../../../components/skeletons/WidgetSkeleton";
import NoData from "../../../components/NoData";
import { MilagePerformance as PerformanceType } from "../../../types/insights";
import "react-circular-progressbar/dist/styles.css";
import { Card, CardContent, Typography } from "@mui/material";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

interface PerformanceProps {
  performance: PerformanceType | null;
  loading: boolean;
}
const calculatePercentageDifference = (current: number, previous: number) => {
  if (previous === 0) return 0;
  return ((current - previous) / previous) * 100;
};

interface PercentageData {
  name: string;
  value: number;
  oldVal: number;
  difference: number;
  progress: number | null;
  target: number | null;
}

const FuelPerformance: React.FC<PerformanceProps> = ({
  performance,
  loading,
}) => {
  const { t } = useTranslation();

  if (loading) {
    return <WidgetSkeleton />;
  }
  if (!performance) {
    return <NoData message={t("dashboard.noPerformance")} />;
  }

  const percentageData = [
    {
      name: t("dashboard.fuel.consumptionThisMonth"),
      value: performance.consumption_this_month,
      oldVal: performance.consumption_from_last_month_up_to_current_month_day,
      difference: calculatePercentageDifference(
        performance.consumption_this_month,
        performance.consumption_from_last_month_up_to_current_month_day
      ),
      progress: null,
      target: null,
    },
    {
      name: t("dashboard.fuel.consumptionLast30Days"),
      value: performance.consumption_from_last_30_days.toFixed(1),
      oldVal: performance.consumption_from_before_last_30_days,
      difference: calculatePercentageDifference(
        performance.consumption_from_last_30_days,
        performance.consumption_from_before_last_30_days
      ),
      progress: null,
      target: null,
    },
    {
      name: t("dashboard.fuel.fuelCostThisMonth"),
      value: performance.fuel_cost_from_last_30_days,
      oldVal: performance.fuel_cost_from_before_last_30_days,
      difference: calculatePercentageDifference(
        performance.fuel_cost_from_last_30_days,
        performance.fuel_cost_from_before_last_30_days
      ),
      progress: null,
      target: null,
    },
    {
      name: t("dashboard.fuel.fuelCostLast30Days"),
      value: Math.round(
        performance.fuel_cost_from_last_30_days /
          performance.fleet_km_from_last_30_days
      ),
      oldVal: Math.round(
        performance.fuel_cost_from_before_last_30_days /
          performance.fleet_km_from_before_last_30_days
      ),
      difference: calculatePercentageDifference(
        performance.fuel_cost_from_last_30_days /
          performance.fleet_km_from_last_30_days,
        Math.round(
          performance.fuel_cost_from_before_last_30_days /
            performance.fleet_km_from_before_last_30_days
        )
      ),
      progress: null,
      target: null,
    },
    {
      name: t("dashboard.fuel.consumptionPer100KmThisMonth"),
      value:
        Math.round(performance.fuel_cost_from_last_30_days /
        performance.fleet_km_from_last_30_days),
      oldVal: Math.round(
        performance.fuel_cost_from_before_last_30_days /
          performance.fleet_km_from_before_last_30_days
      ),
      difference: calculatePercentageDifference(
        performance.fuel_cost_from_last_30_days /
          performance.fleet_km_from_last_30_days,
        performance.fuel_cost_from_before_last_30_days /
          performance.fleet_km_from_before_last_30_days
      ),
      progress: null,
      target: null,
    },
  ] as PercentageData[];

  return (
    <div className="p-4">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {percentageData.map((item) => (
          <Card
            variant="outlined"
            sx={{ minWidth: 275, mb: 2 }}
            key={item.name}
          >
            <CardContent>
              <Typography variant="h5" component="div">
                {item.name}
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                {item.progress !== null && (
                  <div
                    style={{
                      width: "100px",
                      height: "100px",
                      marginRight: "20px",
                    }}
                  >
                    <CircularProgressbar
                      value={item.progress}
                      text={`${item.progress.toFixed(2)}%`}
                      styles={buildStyles({
                        textColor: item.progress >= 100 ? "green" : "red",
                        pathColor: item.progress >= 100 ? "green" : "red",
                      })}
                    />
                  </div>
                )}
                <div>
                  <Typography
                    variant="h3"
                    component="div"
                    sx={{ color: item.difference > 0 ? "green" : "red" }}
                  >
                    {item.value}
                    km
                    <Typography variant="body1" component="span">
                      {item.difference > 0
                        ? `+${item.difference.toFixed(2)}% MoM`
                        : `${item.difference.toFixed(2)}% MoM`}
                    </Typography>
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {t("dashboard.fuel.lastMonth")}: {item.oldVal}
                    km
                  </Typography>
                  {item.target !== null && (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ mt: 1 }}
                    >
                      {t("dashboard.fuel.target")}: {item.target} km
                    </Typography>
                  )}
                </div>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default FuelPerformance;
