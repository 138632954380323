import { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { OrderInfo } from "../../types/orders";
import OrderOperationsView from "./Operations/OrderOperationsView";
import { useToast } from "../../hooks/useToast";
import StatusBadge from "../../components/statusBadge/StatusBadge";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import OrderPaymentForm from "./OrderPaymentForm";
import RequirementsForm from "./RequirementsForm";
import { BaseInvoiceType } from "../../types/invoices";
import Modal from "../../components/modal/Modal";
import InvoicePreview from "./InvoicePreview";
import FilesForm from "./FilesForm";
import CarrierForm from "./CarrierForm";
import { useTranslation } from "react-i18next";
import OrderShipper from "./OrderShipper";
import { formatOrderOperationTime } from "../../utils/time";
import { Breadcrumbs, CircularProgress, Typography } from "@mui/material";
import GeneralOrderDetails from "./GeneralOrderDetails/GeneralOrderDetails";

const OrderContainer = () => {
  const { t } = useTranslation();
  const [order, setOrder] = useState<OrderInfo | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [invoicePreview, setInvoicePreview] = useState<BaseInvoiceType | null>(
    null
  );
  const [invoicePreviewModalOpen, setInvoicePreviewModalOpen] = useState(false);
  const { orderId } = useParams();
  const axios = useAxios();
  const toast = useToast();
  const [fileUploading, setFileUploading] = useState(false);
  const [carrierUpdateLoading, setCarrierUpdateLoading] = useState(false);
  const [trackingLinkLoading, setTrackingLinkLoading] = useState(false);

  const [selectedTab, setSelectedTab] = useState(0);
  const [query, setQuery] = useSearchParams();

  const tabToIndex = {
    general: 0,
    shipper: 1,
    carrier: 2,
    payment: 3,
    requirements: 4,
    files: 5,
    operations: 6,
  };

  const indexToTab = {
    0: "general",
    1: "shipper",
    2: "carrier",
    3: "payment",
    4: "requirements",
    5: "files",
    6: "operations",
  };

  useEffect(() => {
    const tab = query.get("tab");
    if (tab && tabToIndex[tab as keyof typeof tabToIndex] !== undefined) {
      setSelectedTab(tabToIndex[tab as keyof typeof tabToIndex]);
    }
  }, [query]);

  const handleTabSelect = (index: number) => {
    setSelectedTab(index);
    setQuery({ tab: indexToTab[index as keyof typeof indexToTab] });
  };

  useEffect(() => {
    if (!orderId) return;
    fetchOrderDetails();
  }, [orderId]);

  const fetchOrderDetails = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`/orders/${orderId}/`);
      setOrder(response.data);
    } catch (error) {
      console.error(error);
      setError("Failed to load order details");
    } finally {
      setIsLoading(false);
    }
  };

  const onDeleteInvoice = async () => {
    if (!order || !order.invoice) {
      return;
    }
    try {
      await axios.delete(`invoices/${order.invoice.id}/`);
      toast({
        type: "success",
        message: t("orderDetails.payment.invoiceDeleted"),
      });
      setOrder((prevState) => {
        if (prevState) {
          return {
            ...prevState,
            invoice: null,
          };
        }
        return prevState
      });
      setInvoicePreview(null);
    } catch (error: any) {
      toast({
        type: "error",
        message: error.message,
      });
    }
  };

  const onUploadTransportOrderFile = async (files: FileList) => {
    if (files.length === 0) return;
    const file = files[0];
    const formData = new FormData();
    formData.append("transport_order_files", file);
    try {
      setFileUploading(true);
      const response = await axios.patch(
        `/orders/${orderId}/transport-files/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "Content-Disposition": `attachment; filename=${file.name}`,
          },
        }
      );

      if (response.status === 200) {
        setOrder(response.data);
      } else {
        console.error("Upload failed:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setFileUploading(false);
    }
  };

  const onUploadCmrFiles = async (files: FileList, position: number) => {
    if (files.length === 0) return;

    const formData = new FormData();

    Array.from(files).forEach((file) => {
      formData.append(`cmrs`, file, file.name);
    });

    try {
      setFileUploading(true);
      const response = await axios.patch(
        `/orders/${orderId}/operations/${position}/cmrs/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status !== 200) {
        console.error("Upload failed:", response.status, response.statusText);
      } else {
        setOrder(response.data);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setFileUploading(false);
    }
  };

  const onDeleteTransportOrderFile = async (fileId: number) => {
    try {
      const response = await axios.delete(
        `/orders/${orderId}/transport-files/${fileId}/`
      );

      if (response.status === 204) {
        setOrder((prevState) => {
          if (prevState) {
            return {
              ...prevState,
              transport_order_files: prevState.transport_order_files.filter(
                (file) => file.id !== fileId
              ),
            };
          }
          return prevState;
        });
      } else {
        console.error("Upload failed:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const onDeleteSubcontractorOrderFile = async (fileId: number) => {
    try {
      const response = await axios.delete(
        `/orders/${orderId}/subcontractor-transport-files/${fileId}/`
      );

      if (response.status === 204) {
        setOrder((prevState) => {
          if (prevState) {
            return {
              ...prevState,
              subcontractor_order_file: null,
            };
          }
          return prevState;
        });
      } else {
        console.error("Upload failed:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const onDeleteCmrFile = async (fileId: number, position: number) => {
    try {
      const response = await axios.delete(
        `/orders/${orderId}/operations/${position}/cmrs/${fileId}/`
      );

      if (response.status === 204) {
        setOrder((prevState) => {
          if (prevState) {
            return {
              ...prevState,
              operations: prevState.operations.map((operation, index) => {
                if (index === position) {
                  return {
                    ...operation,
                    cmrs: operation.cmrs
                      ? operation.cmrs.filter((cmr) => cmr.id !== fileId)
                      : [],
                  };
                }
                return operation;
              }),
            };
          }
          return prevState;
        });
      } else {
        console.error("Upload failed:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const onFormSubmit = (data: any) => {
    setCarrierUpdateLoading(true);
    axios
      .patch(`/orders/${orderId}/`, data)
      .then((response) => {
        toast({
          type: "success",
          message: "Order updated successfully",
        });
        setOrder(response.data);
      })
      .finally(() => {
        setCarrierUpdateLoading(false);
      });
  };

  const onOrderOperationSubmit = (data: any, position: number) => {
    axios.patch(`/orders/${orderId}/operations/${position}/`, data).then(() => {
      fetchOrderDetails();
      toast({
        type: "success",
        message: "Operation updated successfully",
      });
    });
  };

  const onCreateInvoice = async (invoice: any) => {
    const response = await axios.post(`invoices/`, {
      ...invoice,
      order: orderId,
    });
    setOrder((prevState) => {
      if (prevState) {
        return {
          ...prevState,
          invoice: response.data,
        };
      }
      return prevState;
    });
    setInvoicePreview(null);
    setInvoicePreviewModalOpen(false);
  };

  const onCreateTrackingLink = async (duration: number) => {
    setTrackingLinkLoading(true);
    try {
      await axios.post(`/orders/${orderId}/tracking-links/`, {
        duration,
      });
    } finally {
      setTrackingLinkLoading(false);
    }
    fetchOrderDetails();
  };

  const onSetIsCompleted = async (status: string, operation: number) => {
    await axios.patch(`/orders/${orderId}/operations/${operation}/`, {
      status: status,
    });
    fetchOrderDetails();
  };

  const onCreateOrderFile = async (data: any) => {
    await axios.post(`/orders/${orderId}/subcontractor-transport-files/`, data);
    fetchOrderDetails();
  };

  const onCancelOrder = async (orderId: number) => {
    await axios.patch(`/orders/${orderId}/`, {
      status: "canceled",
    });
    fetchOrderDetails();
  };

  if (isLoading) {
    return (
      <div className="flex min-h-screen w-full items-center justify-center">
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return <p className="text-center text-red-500">{error}</p>;
  }
  return (
    <div className="text-black dark:text-secondary bg-secondary dark:bg-zinc-900 p-4">
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">{t("breadcrumb.home")}</Link>
        <Typography color="text.primary">
          {t("orderDetails.title")} -{" "}
          {order?.ref_number || order?.trans_ref_number}
        </Typography>
      </Breadcrumbs>

      {order ? (
        <div className="p-4 mb-2">
          <p className="mb-4">
            <StatusBadge status={order.status} />
          </p>{" "}
          <strong className="font-semibold">
            {t("orderDetails.created")}: {order.trans_date || order.created_at}
          </strong>{" "}
          <Tabs
            onSelect={handleTabSelect}
            selectedIndex={selectedTab}
            selectedTabClassName="bg-neutral-300 dark:bg-zinc-800 text-black dark:text-secondary border border-neutral-400"
          >
            <TabList className="text-black dark:text-secondary">
              <Tab>{t("orderDetails.tabs.general")}</Tab>
              <Tab> {t("orderDetails.tabs.shipper")}</Tab>
              <Tab> {t("orderDetails.tabs.carrier")}</Tab>
              <Tab> {t("orderDetails.tabs.payment")}</Tab>
              <Tab> {t("orderDetails.tabs.requirements")}</Tab>
              <Tab> {t("orderDetails.tabs.files")}</Tab>
              <Tab> {t("orderDetails.tabs.operations")}</Tab>
            </TabList>
            <TabPanel>
              <GeneralOrderDetails
                order={order}
                trackingLinkLoading={trackingLinkLoading}
                onFormSubmit={onFormSubmit}
                onCancelOrder={onCancelOrder}
                onCreateTrackingLink={onCreateTrackingLink}
              />
            </TabPanel>
            <TabPanel>
              <OrderShipper
                shipper={order.shipper}
                shipperAssignee={order.shipper_assignee}
              />
            </TabPanel>
            <TabPanel>
              <CarrierForm
                order={order}
                loading={carrierUpdateLoading}
                onFormSubmit={onFormSubmit}
                onCreateOrderFile={onCreateOrderFile}
                onDeleteSubcontractorOrderFile={onDeleteSubcontractorOrderFile}
              />
            </TabPanel>
            <TabPanel>
              <OrderPaymentForm
                order={order}
                onFormSubmit={onFormSubmit}
                setInvoicePreview={setInvoicePreview}
                setInvoicePreviewModalOpen={setInvoicePreviewModalOpen}
                onDeleteInvoice={onDeleteInvoice}
              />
            </TabPanel>
            <TabPanel>
              <RequirementsForm order={order} onFormSubmit={onFormSubmit} />
            </TabPanel>
            <TabPanel>
              <FilesForm
                order={order}
                onDeleteTransportOrderFile={onDeleteTransportOrderFile}
                onUploadTransportOrderFile={onUploadTransportOrderFile}
                fileUploading={fileUploading}
              />
            </TabPanel>
            <TabPanel>
              <div className="bg-neutral-300 dark:bg-zinc-800 p-4 border border-gray-400">
                <h2 className="text-xl font-bold">
                  {t("orderDetails.operations.spots")}
                </h2>{" "}
                <OrderOperationsView
                  operations={order.operations}
                  fileUploading={fileUploading}
                  onUploadCmrFiles={onUploadCmrFiles}
                  onDeleteCmrFile={onDeleteCmrFile}
                  handleOrderOperationSubmit={onOrderOperationSubmit}
                  setIsCompleted={onSetIsCompleted}
                />
              </div>
            </TabPanel>
          </Tabs>
          {
            <p className="mt-4">
              <strong className="font-semibold">
                {t("orderDetails.unloading")}:{" "}
              </strong>{" "}
              {formatOrderOperationTime(
                order.operations[order.operations.length - 1]
              )}
            </p>
          }
          {order.completed_at && (
            <p className="mb-4">
              <strong className="font-semibold">
                {t("orderDetails.completed")}:
              </strong>{" "}
              {order.completed_at}
            </p>
          )}
        </div>
      ) : (
        <p className="text-center text-gray-500">Loading order details...</p>
      )}
      {invoicePreview && (
        <Modal
          isOpen={invoicePreviewModalOpen}
          onClose={() => {
            setInvoicePreviewModalOpen(false);
          }}
        >
          <InvoicePreview
            invoicePreview={invoicePreview}
            onCreateInvoice={onCreateInvoice}
          />
        </Modal>
      )}
    </div>
  );
};

export default OrderContainer;
