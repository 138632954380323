import React, { useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import Button from "@mui/material/Button";
import Modal from "../../../components/modal/Modal";
import { Maintenance, MaintenanceModel, TRUCK_MAINTENANCE_TYPES } from "../../../types/truck";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useToast } from "../../../hooks/useToast";
import { MenuItem } from "@mui/material";
import { extractErrorMessages } from "../../../utils/errors";
import MaintenanceTable from "./MaintenanceTable";

interface TruckMaintenanceProps {
  maintenances: MaintenanceModel[];
  onAddMaintenance: (maintenance: Maintenance) => void;
  onEditMaintenanceClicked: (maintenance: MaintenanceModel) => void;
  onDeleteMaintenance: (maintenance: MaintenanceModel) => void;
}

const TruckMaintenance: React.FC<TruckMaintenanceProps> = ({
  maintenances,
  onAddMaintenance,
  onEditMaintenanceClicked,
  onDeleteMaintenance,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { control, handleSubmit, reset } = useForm<Maintenance>({
    defaultValues: {
      service_type: TRUCK_MAINTENANCE_TYPES[0],
      details: "",
    },
  });

  const toast = useToast();
  const onSubmit: SubmitHandler<Maintenance> = async (data) => {
    try {
      await onAddMaintenance(data);
      setIsModalOpen(false);
      reset();
    } catch (e: any) {
      console.error(e);
      const error = extractErrorMessages(e.response.data);
      toast({
        message: error.join(" "),
        type: "error",
      });
    }
  };

  return (
    <div className="bg-neutral-300 dark:bg-zinc-800 p-4 border border-gray-400 text-black dark:text-white">
      {maintenances.length === 0 && <div>No maintenance records</div>}
      <Button
        variant="contained"
        color="primary"
        onClick={() => setIsModalOpen(true)}
      >
        Add Maintenance
      </Button>
      <MaintenanceTable
        maintenances={maintenances}
        onDeleteMaintenance={onDeleteMaintenance}
        onEditMaintenanceClicked={onEditMaintenanceClicked}
      />
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            p: 4,
            backgroundColor: "white",
            borderRadius: 1,
            boxShadow: 24,
          }}
        >
          <h2>Add Maintenance</h2>
          <Controller
            name="service_type"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Service Type"
                variant="outlined"
                fullWidth
                margin="normal"
                select
              >
                {TRUCK_MAINTENANCE_TYPES.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Controller
            name="due_date"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Due Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                fullWidth
                margin="normal"
              />
            )}
          />
          <Controller
            name="due_mileage"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Due Mileage"
                type="number"
                variant="outlined"
                fullWidth
                margin="normal"
              />
            )}
          />
          <Controller
            name="details"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Details"
                variant="outlined"
                fullWidth
                margin="normal"
                multiline
                rows={4}
              />
            )}
          />
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default TruckMaintenance;
