interface CardProps {
  icon: React.ReactNode;
  title: string;
  children: React.ReactNode;
}

const Card: React.FC<CardProps> = ({ icon, title, children }) => {
  return (
    <div className="p-4 border border-neutral-300 shadow-lg h-full">
      <div className="flex items-center gap-2">
        {icon}
        <p className="text-3xl font-bold">{title}</p>
      </div>
      <div className="w-full border-b border-neutral-400 my-4 opacity-70" />
      {children}
    </div>
  );
};

export default Card;
