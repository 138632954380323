import { formatStartEndTime } from "../../../../utils/time";
import { useMap } from "../../../../contexts/MapContext";
import { OrderInfo } from "../../../../types/orders";

interface TruckOrdersProps {
  clickHandler: (order: OrderInfo) => void;
  label: string;
}

const TruckOrders: React.FC<TruckOrdersProps> = ({ label, clickHandler }) => {
  const {
    allMapOrders,
    activeRoute,
    selectedOrders,
    selectedTruck,
    ordersToAddToRoute,
  } = useMap();
  return (
    <div className="flex w-full flex-col gap-2 p-1">
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-bold">{label}</h3>
      </div>
      {allMapOrders
        .filter((order) => order.truck?.license_plate === selectedTruck?.truck)
        .filter((order) => {
          const isSelectedOrder = selectedOrders.find(
            (selectedOrder) => selectedOrder.id === order.id
          );
          if (!!activeRoute && !isSelectedOrder) return false;
          if (!!activeRoute && order.assigned_route) return false;
          return true;
        })
        .map((order) => {
          const isSelected = selectedOrders.find(
            (selectedOrder) => selectedOrder.id === order.id
          );
          const isInOrdersToAdd = ordersToAddToRoute.find(
            (o) => o.id === order.id
          );
          const timeDisplay = formatStartEndTime(
            order.operations[0].time_begin,
            order.operations[order.operations.length - 1].time_end
          );

          return (
            <div
              key={order.id}
              onClick={() => {
                clickHandler(order);
              }}
              className={`flex items-center gap-2 cursor-pointer hover:text-black
                ${
                  isInOrdersToAdd
                    ? "bg-green-300 bg-opacity-50 p-2 rounded-lg"
                    : ""
                }
                
                ${
                  isSelected
                    ? "dark:bg-zinc-800 dark:hover:bg-zinc-700 bg-opacity-50 p-2 rounded-lg"
                    : "p-2 rounded-lg "
                }
                  ${
                    !!activeRoute && order.assigned_route
                      ? ""
                      : !!activeRoute && isSelected
                      ? ""
                      : "hover:bg-slate-300"
                  }
                `}
            >
              <div className="text-sm font-medium text-ellipsis">
                <div>
                  {order.ref_number ? order.ref_number : order.trans_ref_number}
                </div>
                <div>{order.shipper?.name}</div>
                <div>{timeDisplay}</div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default TruckOrders;
