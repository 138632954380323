import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { Carrier } from "../types/carriers";
import useAxios from "../hooks/useAxios";
import { CorporateDetails } from "../types/base";

interface StriderContextType {
  carriers: Carrier[];
  shippers: CorporateDetails[];
  loading: boolean;
  addShipper: (shipper: CorporateDetails) => Promise<CorporateDetails>;
}

const StriderContext = createContext<StriderContextType>({
  carriers: [],
  shippers: [],
  loading: true,
  addShipper: async () => {
    throw new Error("StriderProvider not found");
  },
});


interface StriderProviderProps {
  children: React.ReactNode;
}

export const StriderProvider: React.FC<StriderProviderProps> = ({
  children,
}) => {
  const [carriers, setCarriers] = useState<Carrier[]>([]);
  const [shippers, setShippers] = useState<CorporateDetails[]>([]);

  const [shippersLoading, setShippersLoading] = useState<boolean>(true);
  const [carriersLoading, setCarriersLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);

  const axios = useAxios();

  const addShipper = async (shipper: CorporateDetails) => {
    const response = await axios.post('shippers/', shipper);
    setShippers((prev) => [...prev, response.data]);
    return response.data;
  };


  useEffect(() => {
    const fetchCarriers = async () => {
      setCarriersLoading(true);
      try {
        const response = await axios.get(`carriers`);
        setCarriers(response.data);
      } catch (error) {
        console.error("Error fetching carriers:", error);
      } finally {
        setCarriersLoading(false);
      }
    };

    fetchCarriers();
  }, []);


  useEffect(() => {
    const fetchShippers = async () => {
      setShippersLoading(true);
      try {
        const response = await axios.get(`shippers?no_pagination=true`);
        setShippers(response.data);
      } catch (error) {
        console.error("Error fetching shippers:", error);
      } finally {
        setShippersLoading(false);
      }
    };
    fetchShippers();
  }, []);

  useEffect(() => {
    setLoading(shippersLoading || carriersLoading);
  }, [shippersLoading, carriersLoading]);

  const value = useMemo(
    () => ({
      carriers,
      shippers,
      loading,
      addShipper,
    }),
    [carriers, shippers, loading]
  );
  return (
    <StriderContext.Provider value={value}>{children}</StriderContext.Provider>
  );
};

export const useStrider = () => {
  return useContext(StriderContext);
};
