import { Breadcrumbs, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import NoData from "../../components/NoData";
import UserForm from "./UserForm";

const UserContainer = () => {
  const { t } = useTranslation();
  const { userId } = useParams();
  const { company } = useAuth();

  if (!userId) {
    return <NoData />;
  }

  const user = company?.users.find((user) => user.id === parseInt(userId));

  if (!user) {
    return <NoData />;
  }

  return (
    <div className="p-4">
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">{t("breadcrumb.home")}</Link>
        <Link to="/account?tab=users">{t("breadcrumb.users")}</Link>
        <Typography color="text.primary">{user.email}</Typography>
      </Breadcrumbs>
      <UserForm user={user} />
    </div>
  );
};

export default UserContainer;
