import { TruckDocument } from "./documents";
import { OrderInfo } from "./orders";

export type TruckParameters = {
  vehicle_weight: number;
  vehicle_height_min: number | null;
  vehicle_height_max: number | null;
  vehicle_length: number | null;
  vehicle_width: number | null;
  fuel_type: string;
  free_flow_speed_table: string;
  axle_count: number;
  engine_ccm: number;
  max_speed: number;
  fuel_capacity: number;
  truck_type: string;
  truck_sub_type: string;
  gvm: string;
};
export type LoadingInfo = {
  loading_width: number;
  loading_length: number;
  loading_height_min: number;
  loading_height_max: number;
  cargo_unit_max_weight: number;
  cargo_unit_max_height: number;
  cargo_volume: number;
  real_max_payload_weight: number;
};

export type TruckCosts = {
  leasing_rate_per_month: number;
  leasing_currency: string;
  insurance_rate_per_year: number;
  insurance_currency: string;
  driver_salary_per_day: number;
  driver_salary_currency: string;
};

export type Maintenance = {
  service_type: string;
  due_date: string;
  due_mileage: number;
  details: string;
  truck_mileage: number;
  truck_license_plate: string;
  truck_name: string;
};

export type MaintenanceModel = Maintenance & {
  id: number;
  date_performed: string;
  price: number;
  currency: string;
};

export type TruckType =
  | "truck"
  | "tractor"
  | "trailer"
  | "van"
  | "bus"
  | "other";

export type BaseTruck = {
  id: number;
  license_plate: string;
  carrier: string;
  vin: string;
  brand: string;
  model: string;
  name: string;
  gvm: string;
  parameters?: TruckParameters | null;
  loading_info?: LoadingInfo | null;
  maintenances: MaintenanceModel[];
  costs: TruckCosts;
  mileage: number;
  documents: TruckDocument[];
};

export type TruckLocation = {
  truck: string;
  truck_name: string;
  latitude: number;
  longitude: number;
  created_at: string;
  engine_on: boolean;
  title: string;
  postal_code: string;
  asimuth: number;
  parameters: TruckParameters;
};

export type TruckOrderInfo = BaseTruck & {
  orders: OrderInfo[];
  last_location: TruckLocation;
};

export const TRUCK_DOCUMENT_TYPES = [
  "registration",
  "leaseAgreement",
  "leaseAuthorization",
  "umowaNajmu",
  "ocz",
  "ac",
  "oc",
  "adr",
  "invoice",
  "other",
];

export const TRUCK_MAINTENANCE_TYPES = [
  "service",
  "repair",
  "tireChange",
  "oilChange",
  "inspection",
];

export const TRUCK_TYPES = [
  "straightTruck",
  "semiTrailer",
  "tractor",
  "automobile",
];

export const TRUCK_SUBTYPES = [
  "refrigerator",
  "container",
  "tank",
  "tipper",
  "curtainsider",
];

export const FUEL_TYPES = ["diesel", "petrol"];

export const GVM_TYPES = ["3.5t", "7.5t", "12t", "18t"];

export const DEFAULT_GVM = "3.5t";
