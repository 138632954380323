import H from "@here/maps-api-for-javascript";

import { HereSection } from "../../../types/routes";
import { getSectionStyle } from "../../Map/utils/routeStyle";
import { RouteWithTracking as Route } from "../../../types/routes";

export const applyStyleToRoute = (
  routeId: string,
  baseColor: string,
  isActiveRoute: boolean,
  activeSectionRef: React.MutableRefObject<HereSection | null>,
  isHoveredRoute: boolean,
  hoveredSectionRef: React.MutableRefObject<HereSection | null>,
  isAlternativeRoute: boolean,
  index: number,
  routesSections: React.MutableRefObject<Map<string, H.map.Group>>
) => {
  const routeGroup = routesSections.current.get(routeId);
  if (!routeGroup) return;
  routeGroup.getObjects().forEach((polyline: any, polylineIndex) => {
    return polyline.setStyle(
      getSectionStyle(
        baseColor,
        isActiveRoute,
        activeSectionRef.current?.id === polyline.getData().id,
        isHoveredRoute,
        hoveredSectionRef.current?.id === polyline.getData().id,
        isAlternativeRoute,
        isActiveRoute ? polylineIndex : index
      )
    );
  });
};

export const addEventListeners = (
  sectionPolyline: H.map.Polyline,
  truckColors: Record<string, string> | null,
  route: Route,
  section: HereSection,
  activeRouteRef: React.MutableRefObject<Route | null>,
  activeSectionRef: React.MutableRefObject<HereSection | null>,
  hoveredSectionRef: React.MutableRefObject<HereSection | null>,
  routePolylinesGroups: React.MutableRefObject<Map<string, H.map.Group>>,
  setHoveredRoute: (route: Route | null) => void,
  setHoveredSection: (section: HereSection | null) => void,
  setActiveSection: (section: HereSection) => void
) => {
  sectionPolyline.addEventListener("tap", () => {
    applyStyleToRoute(
      route.id,
      truckColors ? truckColors[route.license_plate] : "#fff",
      activeRouteRef.current?.id === route.id,
      activeSectionRef,
      false,
      hoveredSectionRef,
      false,
      section.index,
      routePolylinesGroups
    );
    setActiveSection(section);
  });

  sectionPolyline.addEventListener("pointerenter", () => {
    applyStyleToRoute(
      route.id,
      truckColors ? truckColors[route.license_plate] : "#fff",
      activeRouteRef.current?.id === route.id,
      activeSectionRef,
      true,
      hoveredSectionRef,
      false,
      section.index,
      routePolylinesGroups
    );
    setHoveredSection(section);
    setHoveredRoute(route);
  });

  sectionPolyline.addEventListener("pointerleave", () => {
    applyStyleToRoute(
      route.id,
      truckColors ? truckColors[route.license_plate] : "#fff",
      activeRouteRef.current?.id === route.id,
      activeSectionRef,
      false,
      hoveredSectionRef,
      false,
      section.index,
      routePolylinesGroups
    );
    setHoveredSection(null);
    setHoveredRoute(null);
  });
};

const createSectionPolyline = (
  route: Route,
  section: HereSection,
  hoveredRoute: Route | null,
  activeSection: HereSection | null,
  hoveredSection: HereSection | null,
  truckColors: Record<string, string> | null,
  activeRouteRef: React.MutableRefObject<Route | null>,
  activeSectionRef: React.MutableRefObject<HereSection | null>,
  hoveredSectionRef: React.MutableRefObject<HereSection | null>,
  routePolylinesGroups: React.MutableRefObject<Map<string, H.map.Group>>,
  setHoveredRoute: (route: Route | null) => void,
  setHoveredSection: (section: HereSection | null) => void,
  setActiveSection: (section: HereSection) => void
) => {
  const lineStrings: H.geo.LineString[] = [];
  lineStrings.push(H.geo.LineString.fromFlexiblePolyline(section.polyline));
  const multiLineString = new H.geo.MultiLineString(lineStrings);

  const sectionStyle = getSectionStyle(
    truckColors ? truckColors[route.license_plate] : "#fff",
    true,
    activeSection?.id === section.id,
    hoveredRoute?.id === route.id,
    hoveredSection?.id === section.id,
    false,
    section.index
  );
  const sectionPolyline = new H.map.Polyline(multiLineString, {
    data: section,
    style: sectionStyle,
  });

  addEventListeners(
    sectionPolyline,
    truckColors,
    route,
    section,
    activeRouteRef,
    activeSectionRef,
    hoveredSectionRef,
    routePolylinesGroups,
    setHoveredRoute,
    setHoveredSection,
    setActiveSection
  );

  return sectionPolyline;
};

export const createSectionsPolylines = (
  route: Route,
  hoveredRoute: Route | null,
  activeSection: HereSection | null,
  hoveredSection: HereSection | null,
  truckColors: Record<string, string> | null,
  activeRouteRef: React.MutableRefObject<Route | null>,
  activeSectionRef: React.MutableRefObject<HereSection | null>,
  hoveredSectionRef: React.MutableRefObject<HereSection | null>,
  routePolylinesGroups: React.MutableRefObject<Map<string, H.map.Group>>,
  setHoveredRoute: (route: Route | null) => void,
  setHoveredSection: (section: HereSection | null) => void,
  setActiveSection: (section: HereSection) => void
) => {
  const sections = route.sections;
  const polylines: H.map.Polyline[] = [];

  sections.forEach((section) => {
    const sectionPolyline = createSectionPolyline(
      route,
      section,
      hoveredRoute,
      activeSection,
      hoveredSection,
      truckColors,
      activeRouteRef,
      activeSectionRef,
      hoveredSectionRef,
      routePolylinesGroups,
      setHoveredRoute,
      setHoveredSection,
      setActiveSection
    );
    polylines.push(sectionPolyline);
  });

  return polylines;
};
