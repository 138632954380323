import {
  Typography,
  List,
  Box,
  ListItem,
  ListItemText,
  Divider,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import AddDriverForm from "./AddDriverForm";
import { Link } from "react-router-dom";
import { Driver } from "../../types/driver";
import useAxios from "../../hooks/useAxios";
import { useToast } from "../../hooks/useToast";

interface DriversProps {
  link: string;
}

const Drivers: React.FC<DriversProps> = ({ link }) => {
  const { t } = useTranslation();
  const [isOpen, setIsModalOpen] = useState(false);
  const [drivers, setDrivers] = useState<Driver[]>([]);
  const axios = useAxios();
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const onAddDriver = async (data: any) => {
    setLoading(true);
    try {
      const response = await axios.post(link, data);
      const newEmployee = response.data;
      setDrivers((prevDrivers) => [...prevDrivers, newEmployee]);
      toast({
        message: "Driver added",
        type: "success",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleAddDriver = async (data: any) => {
    await onAddDriver(data);
    setIsModalOpen(false);
  };

  const handleDialogClose = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const fetchDrivers = async () => {
      try {
        const response = await axios.get(link);
        setDrivers(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchDrivers();
  }, [link]);

  return (
    <div className="bg-neutral-300 dark:bg-zinc-800 p-4 border border-gray-400 text-black dark:text-white">
      <div className="mb-4">
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          {t("driversList.addDriver")}
        </Button>
      </div>
      <Typography variant="h5" component="h2" gutterBottom>
        {t("driversList.title")}
      </Typography>
      {drivers.length > 0 ? (
        <List>
          {drivers.map((driver) => (
            <Box key={driver.id}>
              <ListItem
                alignItems="flex-start"
                component={Link}
                to={`${link}${driver.id}`}
              >
                <ListItemText
                  primary={
                    <Typography variant="body2">
                      <strong>{t("driversList.fields.name")}:</strong>{" "}
                      {driver.full_name}
                    </Typography>
                  }
                  secondary={
                    <>
                      <Typography variant="body2">
                        <strong>
                          {t("driversList.fields.email")}:
                        </strong>{" "}
                        {driver.email}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
              <Divider component="li" />
            </Box>
          ))}
        </List>
      ) : (
        <Typography variant="body1">
          {t("driversList.noDrivers")}
        </Typography>
      )}
      <Dialog open={isOpen} onClose={handleDialogClose}>
        <DialogTitle>
          {t("driversList.addDriverModal.title")}
        </DialogTitle>
        <DialogContent >
          <AddDriverForm onSubmit={handleAddDriver} loading={loading} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            {t("common.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Drivers;
