import { TextField, MenuItem } from "@mui/material";
import { t } from "i18next";
import { Controller } from "react-hook-form";
import { CURRENCIES } from "../../../consts/currencies";
import { getCurrencySymbol } from "../../../utils/currencies";

interface OrderCreateStepTwoProps {
  control: any;
}

const OrderCreateStepTwo: React.FC<OrderCreateStepTwoProps> = ({ control }) => {
  return (
    <div className="space-y-4">
      <Controller
        name="payment.price_currency"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            select
            label={t("orders.orderCreate.currency")}
            variant="outlined"
            fullWidth
            required
          >
            {CURRENCIES.map((currency) => (
              <MenuItem key={currency} value={currency}>
                {getCurrencySymbol(currency)}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
      <Controller
        name="payment.price_value"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("orders.orderCreate.rate")}
            variant="outlined"
            fullWidth
            type="number"
            required
          />
        )}
      />
      <Controller
        name="payment.days"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("orders.orderCreate.dueDays")}
            variant="outlined"
            fullWidth
            required
            type="number"
          />
        )}
      />
    </div>
  );
};

export default OrderCreateStepTwo;
