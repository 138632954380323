import { useEffect, useState } from "react";
import useAxios from "../../../hooks/useAxios";
import { WidgetSkeleton } from "../../../components/skeletons/WidgetSkeleton";
import { useTranslation } from "react-i18next";
import NoData from "../../../components/NoData";
import { CompanyEvent } from "../../../types/alerts";
import MaintenanceDueDateIncoming from "../EventHandlers/MaintenanceDueDateIncoming";
import MaintenanceDueMilageIncoming from "../EventHandlers/MaintenanceDueMilageIncoming";
import EventCard from "../EventHandlers/EventCard";
import ExpenseIncoming from "../EventHandlers/ExpenseIncoming";
import TruckDocumentEnding from "../EventHandlers/TruckDocumentEnding";
import SubscriptionEnding from "./SubscriptionEnding";
import DocumentEnding from "../EventHandlers/DocumentEnding";

const IncomingEvents = () => {
  const [events, setEvents] = useState<CompanyEvent[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const axios = useAxios();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchLoadings = async () => {
      setLoading(true);
      try {
        const response = await axios.get("alerts/events");
        setEvents(response.data);
      } catch (error) {
        console.error("Error fetching loadings:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLoadings();
  }, []);

  const renderEvent = (event: CompanyEvent) => {
    switch (event.type) {
      case "maintenance_due_date_incoming":
        return (
          <EventCard
            title={t("dashboard.maintenanceDueDateTitle")}
          >
            <MaintenanceDueDateIncoming data={event.data} key={event.id} />
          </EventCard>
        );
      case "truck_document_ending":
        return (
          <EventCard
            title={t("dashboard.truckDocumentEndingTitle")}
          >
            <TruckDocumentEnding data={event.data} key={event.id} />
          </EventCard>
        );
      case "subscription_ending":
        return (
          <EventCard
            title={t("dashboard.subscriptionEndingTitle")}
          >
            <SubscriptionEnding data={event.data} key={event.id} />
          </EventCard>
        );
      case "maintenance_due_milage_incoming":
        return (
          <EventCard
            title={t("dashboard.maintenanceDueMilageTitle")}
          >
            <MaintenanceDueMilageIncoming data={event.data} key={event.id} />
          </EventCard>
        );
      case "expense_incoming":
        return (
          <EventCard
            title={t("dashboard.incomingExpenseTitle")}
          >
            <ExpenseIncoming data={event.data} key={event.id} />
          </EventCard>
        );
        case "document_ending":
          return (
            <EventCard
              title={t("dashboard.documentEndingTitle")}
            >
              <DocumentEnding data={event.data} key={event.id} />
            </EventCard>
          );

      default:
        return <div key={event.id}>{event.type}</div>;
    }
  };

  return (
    <div>
      {loading ? (
        <WidgetSkeleton />
      ) : (
        <div className="overflow-y-auto max-h-96 min-h-96">
          <div className="flex flex-col gap-2">
            {events.length === 0 && (
              <NoData message={t("dashboard.noEvents")} />
            )}
            <div className="flex flex-col gap-2">{events.map((event) => renderEvent(event))}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default IncomingEvents;
