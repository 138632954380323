import { useEffect, useState } from "react";
import useAxios from "../../../hooks/useAxios";
import { useToast } from "../../../hooks/useToast";
import { BaseTruck } from "../../../types/truck";
import { useTranslation } from "react-i18next";
import ExpenseForm from "./ExpenseForm";
import { Typography } from "@mui/material";
import ExpenseList from "../../../components/Expenses/ExpenseList";
import {
  ExpenseBaseData,
  ExpenseHistoryEntry,
  RecurringDetail,
} from "../../../types/expenses";

interface TruckExpensesProps {
  truck: BaseTruck;
}

const TruckExpenses: React.FC<TruckExpensesProps> = ({ truck }) => {
  const { t } = useTranslation();
  const axios = useAxios();
  const toast = useToast();

  const [recurringExpenses, setRecurringExpenses] = useState<RecurringDetail[]>(
    []
  );
  const [expensesHistory, setExpensesHistory] = useState<ExpenseHistoryEntry[]>(
    []
  );
  const [historyLoading, setHistoryLoading] = useState<boolean>(false);
  const [recurringLoading, setRecurringLoading] = useState<boolean>(false);

  const fetchHistory = async () => {
    try {
      setHistoryLoading(true);
      const response = await axios.get(`/expenses/history?truck=${truck.id}`);
      setExpensesHistory(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setHistoryLoading(false);
    }
  };

  const fetchRecurring = async () => {
    try {
      setRecurringLoading(true);
      const response = await axios.get(`/expenses/recurring?truck=${truck.id}`);
      setRecurringExpenses(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setRecurringLoading(false);
    }
  };

  useEffect(() => {
    fetchHistory();
    fetchRecurring();
  }, []);

  const onSubmit = async (data: ExpenseBaseData[]) => {
    setHistoryLoading(true);
    setRecurringLoading(true);
    try {
      await axios.post(`expenses/`, data);
      toast({
        message: "Expense added",
        type: "success",
      });
      fetchHistory();
      fetchRecurring();
    } finally {
      setHistoryLoading(false);
      setRecurringLoading(false);
    }
  };
  const executeRecurringExpense = async (id: number, data: any) => {
    setHistoryLoading(true);
    try {
      await axios.post(`expenses/recurring/${id}/execute/`, {
        ...data,
        truck: truck.id,
      });
      fetchHistory();
    } finally {
      setHistoryLoading(false);
    }
  };

  const deleteExpenseHistory = (historyId: number) => {
    axios
      .delete(`expenses/history/${historyId}/`)
      .then(() => {
        toast({
          message: "Expense deleted",
          type: "success",
        });
        fetchHistory();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const deleteRecurringExpense = (id: number) => {
    axios
      .delete(`expenses/recurring/${id}/`)
      .then(() => {
        toast({
          message: "Expense deleted",
          type: "success",
        });
        fetchRecurring();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="bg-neutral-300 dark:bg-zinc-800 p-4 border border-gray-400 text-black dark:text-white">
      <Typography variant="h3" className="mb-8 text-center">
        {t("truckDetail.expenses.title")}
      </Typography>
      <ExpenseForm
        truck_id={truck.id}
        onSubmit={onSubmit}
        loading={historyLoading || recurringLoading}
      />
      <ExpenseList
        expensesHistory={expensesHistory}
        recurringExpenses={recurringExpenses}
        historyLoading={historyLoading}
        recurringLoading={recurringLoading}
        onDeleteRecurringExpense={deleteRecurringExpense}
        onDeleteExpenseHistory={deleteExpenseHistory}
        onExecuteRecurringExpense={executeRecurringExpense}
      />
    </div>
  );
};

export default TruckExpenses;
