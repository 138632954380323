import { Button, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface CarrierForm {
  name: string;
  email: string;
  vat_id: string;
}

interface AddCarrierFormProps {
  onSubmit: (data: CarrierForm) => void;
}

const AddCarrierForm: React.FC<AddCarrierFormProps> = ({ onSubmit }) => {
  const { handleSubmit, control } = useForm<CarrierForm>();
  const { t } = useTranslation();

  return (
    <>
      <div className="p-4">
        <h1 className="text-2xl font-bold mb-4 text-black">
          {t("carriers.addCarrier")}
        </h1>
        <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-2 gap-4">
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("carriers.addCarrierModal.name")}
                variant="outlined"
                required
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("carriers.addCarrierModal.email")}
                variant="outlined"
                required
                type="email"
              />
            )}
          />
          <Controller
            name="vat_id"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("carriers.addCarrierModal.vatId")}
                variant="outlined"
                required
              />
            )}
          />
          <Button type="submit" variant="contained" color="primary">
            {t("carriers.addCarrier")}
          </Button>
        </form>
      </div>
    </>
  );
};

export default AddCarrierForm;
