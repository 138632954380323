import { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Link, useSearchParams } from "react-router-dom";
import useAxios from "../../hooks/useAxios";

import { useToast } from "../../hooks/useToast";
import { useTranslation } from "react-i18next";
import { Breadcrumbs, CircularProgress, Typography } from "@mui/material";
import Drivers from "../../components/Drivers/Drivers";
import Employees from "../../components/Employees/Employees";
import { Company, COMPANY_DOCUMENT_TYPES } from "../../types/companies";
import Documents from "../../components/Forms/Documents";
import BaseInfoForm from "../../components/Forms/BaseInfoForm";
import TruckList from "../../components/Forms/TruckList";
import Expenses from "./Expenses/Expenses";

const MyFleetContainer = () => {
  const { t } = useTranslation();
  const axios = useAxios();
  const toast = useToast();
  const [company, setCompany] = useState<Company | null>(null);
  const [selectedTab, setSelectedTab] = useState(0);

  const [query, setQuery] = useSearchParams();

  const tabToIndex = {
    general: 0,
    employees: 1,
    drivers: 2,
    trucks: 3,
    expenses: 4,
    documents: 5,
  };
  const indexToTab = {
    0: "general",
    1: "employees",
    2: "drivers",
    3: "trucks",
    4: "expenses",
    5: "documents",
  };
  useEffect(() => {
    const tab = query.get("tab");
    if (tab && tabToIndex[tab as keyof typeof tabToIndex] !== undefined) {
      setSelectedTab(tabToIndex[tab as keyof typeof tabToIndex]);
    }
  }, [query]);
  const handleTabSelect = (index: number) => {
    setSelectedTab(index);
    setQuery({ tab: indexToTab[index as keyof typeof indexToTab] });
  };

  useEffect(() => {
    axios
      .get(`/companies/my-company`)
      .then((response) => {
        setCompany(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const onUpdate = async (data: any) => {
    const response = await axios.patch(`/companies/my-company/`, data);
    setCompany(response.data);
    toast({
      message: "Company updated",
      type: "success",
    });
  };

  return company ? (
    <div className="p-4 mb-2">
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">{t("breadcrumb.home")}</Link>
        <Typography color="text.primary">
          {t("myFleet.title")} - {company.name}
        </Typography>
      </Breadcrumbs>

      <Tabs
        selectedTabClassName="bg-neutral-300 dark:bg-zinc-800 text-black dark:text-secondary border border-neutral-400"
        selectedIndex={selectedTab}
        onSelect={handleTabSelect}
      >
        <TabList className="text-black dark:text-secondary">
          <Tab>{t("myFleet.tabs.general")}</Tab>
          <Tab>{t("myFleet.tabs.employees")}</Tab>
          <Tab>{t("myFleet.tabs.drivers")}</Tab>
          <Tab>{t("myFleet.tabs.trucks")}</Tab>
          <Tab>{t("myFleet.tabs.expenses")}</Tab>
          <Tab>{t("myFleet.tabs.documents")}</Tab>
        </TabList>

        <TabPanel>
          <BaseInfoForm info={company} onSubmit={onUpdate} />
        </TabPanel>

        <TabPanel>
          <Employees link={"/my-fleet/employees"} />
        </TabPanel>

        <TabPanel>
          <Drivers link={"/my-fleet/drivers/"} />
        </TabPanel>
        <TabPanel>
          <TruckList link={"/my-fleet/trucks"} />
        </TabPanel>
        <TabPanel>
          <Expenses />
        </TabPanel>
        <TabPanel>
          <Documents
            documentTypes={COMPANY_DOCUMENT_TYPES}
            link={"documents/my-documents/"}
          />
        </TabPanel>
      </Tabs>
    </div>
  ) : (
    <CircularProgress />
  );
};

export default MyFleetContainer;
