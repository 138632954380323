import React, { ChangeEvent } from "react";
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useTranslation } from "react-i18next";

interface ReceivedFilterProps {
  selectedReceivedStatus: {
    received: boolean;
    notReceived: boolean;
    all: boolean;
  };
  onChange: (newStatus: {
    received: boolean;
    notReceived: boolean;
    all: boolean;
  }) => void;
}

const ReceivedFilter: React.FC<ReceivedFilterProps> = ({
  selectedReceivedStatus,
  onChange,
}) => {
  const { t } = useTranslation();
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;

    const newStatus = {
      received: false,
      notReceived: false,
      all: false,
      [name]: event.target.checked,
    };

    onChange(
      newStatus as { received: boolean; notReceived: boolean; all: boolean }
    );
  };

  return (
    <FormControl component="fieldset">
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedReceivedStatus.received}
              onChange={handleChange}
              name="received"
              color="primary"
            />
          }
          label={t("invoices.filters.received")}
          sx={{ color: "text.primary" }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedReceivedStatus.notReceived}
              onChange={handleChange}
              name="notReceived"
              color="primary"
            />
          }
          label={t("invoices.filters.notReceived")}
          sx={{ color: "text.primary" }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedReceivedStatus.all}
              onChange={handleChange}
              name="all"
              color="primary"
            />
          }
          label={t("invoices.filters.all")}
          sx={{ color: "text.primary" }}
        />
      </FormGroup>
    </FormControl>
  );
};

export default ReceivedFilter;
